import React, { useState, useEffect } from 'react'
import { Toast } from 'antd-mobile';
import './yyysdownload.css';
import URI from 'urijs';

const img_bg = require('./images/yyys_bg.png');
const center_00 = require('./images/yyys_center_00.png');
const center_01 = require('./images/yyys_center_01.png');
const center_02 = require('./images/yyys_center_02.png');

const YYYSDownloadApp: PageComponent =  props => {

    useEffect(() => {

    }, [])

    // 下载App
    const downloadApp = () => {
        let query: any = URI(window.location.href).query(true);
        const { channel } = query
        if (channel) {
            (window as any).gio('track', 'yyys_download_expect', {channel_id: channel})
        }
        if (isIOS()) {
            window.location.href = 'https://itunes.apple.com/cn/app/id1479391612?ls=1&mt=8'
        }
        if (isAndroid()) {
            window.location.href = 'https://m.lajsf.com/download'
        }
    }

    const isIOS = () => {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false;
    }
    const isAndroid = () => {
        return navigator.userAgent.match(/Android/i) ? true : false;
    }
    return (
        <div className="YYYSDownloadApp">
            <img className="bg-img" src={img_bg} alt=""/>
            <div className="cont">
                <img className="img-txt" src={center_00} alt=""/>
                <img className="img-person" src={center_01} alt=""/>
                <img className="img-btn" onClick={downloadApp} src={center_02} alt=""/>
            </div>
        </div>
    )
}
YYYSDownloadApp.options = { disableHeader: false, wrapperClassName: '' }
export default YYYSDownloadApp