import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'
const asyncConference = Loadable({
	loader: () => import('./Conference'),
	loading: MyLoadingComponent
})
const VipExclusive: Module = {
	name: 'vipexclusive',
	routes: [
		{
			name: 'vip专属送会员',
			path: '/vipexclusive',
			component: asyncConference,
		},
	],
}
export default VipExclusive
