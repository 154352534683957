import ShopExpertDetail from './ShopExpertDetail'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncShopExpertDetailPage = Loadable({
	loader: () => import('./ShopExpertDetail'),
	loading: MyLoadingComponent
})
const LiveStream: Module = {
	name: 'shopExpertDetail',
	routes: [
		{
			name: 'e店专家详情页',
			path: '/shopExpertDetail/share/:kid',
			component: asyncShopExpertDetailPage,
		},
	],
}
export default LiveStream
