import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'
const asyncOriginActivity = Loadable({
	loader: () => import('./OriginActivity'),
	loading: MyLoadingComponent
})
const OriginActivity: Module = {
	name: 'branstory',
	routes: [
		{
			name: '果冻橙',
			path: '/originActivity/:type',
			component: asyncOriginActivity,
		},
	],
}
export default OriginActivity
