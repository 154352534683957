import FoodDetailShare from './FoodDetailShare'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncFoodDetailShare = Loadable({
    loader: () => import('./FoodDetailShare'),
    loading: MyLoadingComponent
})
// component: FoodDetailShare,
const foodModule: Module = {
  name: 'food',
  routes: [
    {
      name: '食材详情',
      path: '/fooddetailshare/component',
      component: asyncFoodDetailShare,
    },
  ],
}
export default foodModule
