const common = {
  np_logo: require('./image/np_big.png'),
}
const goods = {
  line_left: require('./image/goods/line_left.png'),
  line_right: require('./image/goods/line_right.png'),
  decrease_icon: require('./image/goods/decrease_icon.png'),
  increase_icon: require('./image/goods/increase_icon.png'),
  safe: require('./image/goods/safe.png'),
  geography_logo: require('./image/goods/geography_logo.png'),
  organic_logo: require('./image/goods/organic_logo.png'),
  harmless_logo: require('./image/goods/harmless_logo.png'),
  green_logo: require('./image/goods/green_logo.png'),
  inspection_logo: require('./image/goods/inspection_logo.png'),
  others_logo: require('./image/goods/others_logo.png'),
  trademark_logo: require('./image/goods/trademark_logo.png'),
  cert: require('./image/goods/cert.png'),
  loc_icon: require('./image/goods/localtion-icon.png'),
  close_icon: require('./image/goods/close-icon.png'),
}
const mall = {
  delete_icon: require('./image/mall/delete.png'),
  edit_icon: require('./image/mall/edit.png'),
  address_line: require('./image/mall/address_line.png'),
  wechat_pay: require('./image/mall/wechat-pay.png'),
  bg_btm: require('./image/mall/bg_btm.png'),
  bg_top: require('./image/mall/bg_top.png'),
  pay_failed: require('./image/mall/pay_failed.png'),
  pay_success: require('./image/mall/pay_success.png'),
  receipt_bg: require('./image/mall/receipt_bg.png'),
  separater_line: require('./image/mall/separater_line.png'),
  shadow: require('./image/mall/shadow.png'),
  np_brand: require('./image/mall/np_brand.png'),
}

const shop = {
	score_empty: require('./image/shop/score_empty.png'),
	score_full: require('./image/shop/score_full.png'),
	score_half: require('./image/shop/score_half.png'),
	section_recommend: require('./image/shop/shop_section_recommend.png'),
	section_service: require('./image/shop/shop_section_service.png'),
	section_specialist: require('./image/shop/shop_section_specialist.png'),
	add_cart: require('./image/shop/shop_add_cart.png'),
	shop_bg: require('./image/shop/shop_bg.png'),
	arrow_down: require('./image/shop/arrow_down.png'),
	arrow_right: require('./image/shop/arrow_right.png'),
	expert_bg: require('./image/shop/expert_bg.png'),
}

const empty = {
  no_address: require('./image/empty/no_address.png'),
  no_cart: require('./image/empty/no_cart.png'),
  no_collect: require('./image/empty/no_collect.png'),
  no_comment: require('./image/empty/no_comment.png'),
  no_course: require('./image/empty/no_course.png'),
  no_data: require('./image/empty/no_data.png'),
  no_earnings: require('./image/empty/no_earnings.png'),
  no_follow: require('./image/empty/no_follow.png'),
  no_message: require('./image/empty/no_message.png'),
  no_network: require('./image/empty/no_network.png'),
  no_order: require('./image/empty/no_order.png'),
  no_report: require('./image/empty/no_report.png'),
  no_search: require('./image/empty/no_search.png'),
  sold_out: require('./image/empty/sold_out.png'),
}
export default {
  common,
	goods,
	shop,
  mall,
  empty,
}
