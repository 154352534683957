import VideoPlayPage from './VideoPlay'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncVideoPlayPage = Loadable({
    loader: () => import('./VideoPlay'),
    loading: MyLoadingComponent
})
// component: VideoPlayPage,
const VideoPlay: Module = {
    name: 'videoplay',
    routes: [
        {
            name: '视频播放详情',
            path: '/videoplay/share/:kid',
            component: asyncVideoPlayPage,
        },
    ],
}
export default VideoPlay