import {httpPost,httpGet,httpPut,httpDelete} from "services/http"
type int = number;
		type List<T> = Array<T>
		type Collection<T> = Array<T>
		
type JsonNode = any
type long = number
interface KeyValueDTO<T,T1>{key:T,value:T1}
export interface CartAddOrBuyCheckDTO {
  /** 区code */
  areaCode?: string
  /** 市code */
  cityCode?: string
  /** 商品id */
  productKid?: number
  /** 省code */
  provinceCode?: string
}

export interface ExpressListVO {
  /** 快递公司编号 */
  expressCode?: string
  /** 快递公司名 */
  expressName?: string
}

export interface CartAddDTO {
  /** cartKid 更新购物车用 */
  cartKid?: number
  /** 添加多少个/修改成多少 */
  num?: number
  /** skuId */
  skuKid?: number
}

export interface Cart {
  /** 添加时间 */
  addTime?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 是否删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 商户id */
  merchantKid?: number
  /** 购买数量 */
  num?: number
  /** 产品id */
  productKid?: number
  /** sku id */
  skuKid?: number
  /** 买家id */
  userId?: number
}

export interface CartListVO {
  /** 按照无效归类 */
  cartInvalidVOList?: CartProductInvalidVO[]
  /** 按照商户归类 */
  cartMerchantItemVOList?: CartMerchantItemVO[]
  /** 用户有否有vip价的权益 */
  haveVipPriceRights?: boolean
}

export interface CartProductInvalidVO {
  /** 购物车kid */
  cartKid?: number
  /** 商品kid */
  productKid?: number
  /** 商品名称 */
  productName?: string
  /** 商品失效原因 */
  reason?: string
  /** skuKid */
  skuKid?: number
  /** 封面 */
  skuPic?: string
}

export interface CartMerchantItemVO {
  /** 购物车商品列表 */
  cartProductVOList?: CartProductVO[]
  /** 商户kid */
  merchantKid?: number
  /** 商户名称 */
  merchantName?: string
  /** 店铺logo */
  shopLogo?: string
}

export interface CartProductVO {
  /** 规格属性 */
  attributes?: ProductAttributeValueVO[]
  /** 购物车kid */
  cartKid?: number
  /** 数量 */
  num?: number
  /** 商品种类  0 本地商品 1 线上 2 课程  3 线下 */
  productClass?: number
  /** 商品kid */
  productKid?: number
  /** 商品名称 */
  productName?: string
  /** skuId */
  skuKid?: number
  /** sku封面 */
  skuPic?: string
  /** sku售价 */
  skuSalePrice?: number
  /** sku展示字符串 例:300g;原味 */
  skuSpec?: string
  /** 会员价格 */
  skuVipPrice?: number
  /** 商品所有sku规格列表 */
  skus?: ProductSkuVO[]
}

export interface ProductAttributeValueVO {
  /** 商品属性ID */
  attributeId?: number
  /** 属性名 */
  attributeName?: string
  /** 属性类型  0 规格属性 1 服务说明 2 商品参数 3 适宜人群 4 禁忌人群 */
  attributeType?: number
  /** 属性类型  0 规格属性 1 服务说明 2 商品参数 3 适宜人群 4 禁忌人群 */
  attributeTypeName?: string
  /** 手动添加规格或参数的值，参数单值，规格有多个时以逗号隔开 */
  value?: string
}

export interface ProductSkuVO {
  /** 规格图片 */
  pic?: string
  /** 购物车sku限购信息 */
  productSkuLimitVO?: ProductSkuLimitVO
  /** 销售价格 */
  salePrice?: number
  /** skuId */
  skuKid?: number
  /** 销售规格 */
  spec?: string
  /** 库存 */
  stock?: number
  /** 会员价格 */
  vipPrice?: number
}

export interface ProductSkuLimitVO {
  /** 限购数量 */
  purchaseMax?: number
  /** 起购数量 */
  purchaseMin?: number
}

export interface ExaminerInfoVO {
  address?: string
  /** 年龄 */
  age?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除状态0 未删 ，1 已删 */
  delFlag?: number
  /** 体检时间 */
  examinationDate?: string
  /** 是否默认体检人 0 否， 1 是 */
  examinerFlag?: number
  /** 0：男 1：女 */
  gender?: number
  /** 体检人身份证号码 */
  idcard?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 0:未婚，1：已婚（此参数建议传值，部分套餐会有婚否限制） */
  married?: number
  /** 体检人手机号 */
  mobile?: string
  /** 当前注册用户标识符 */
  openId?: string
  serviceCode?: number
  /** 开发者站点 */
  siteUrl?: string
  /** 当前登陆人 */
  userId?: number
  /** 体检人姓名 */
  userName?: string
  verifyCode?: string
  verifyType?: string
}

export interface ExaminerInfo {
  /** 地址Id */
  address?: string
  /** 年龄 */
  age?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除状态0 未删 ，1 已删 */
  delFlag?: number
  /** 体检时间 */
  examinationDate?: string
  /** 是否默认体检人 0 否， 1 是 */
  examinerFlag?: number
  /** 0：男 1：女 */
  gender?: number
  /** 体检人身份证号码 */
  idcard?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 0:未婚，1：已婚（此参数建议传值，部分套餐会有婚否限制） */
  married?: number
  /** 体检人手机号 */
  mobile?: string
  /** 当前注册用户标识符 */
  openId?: string
  /** 开发者站点 */
  siteUrl?: string
  /** 当前登陆人 */
  userId?: number
  /** 体检人姓名 */
  userName?: string
}

export interface PageList<T> {
  /** 数据总条数，前端接口可忽略改字段 */
  count?: number
  /** 数据集合 */
  entities?: T[]
  /** 当前页码 */
  pageNo?: number
  /** 每页条数 */
  pageSize?: number
}

export interface ExcelExportRecord {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 导出Excel类型 */
  excelType?: string
  /** 导出码 */
  exportCode?: string
  /** 导出描述 */
  exportDesc?: string
  /** 导出参数 */
  exportParams?: string
  /** 导出状态：0未执行 1导出中 2全部导出 3部分导出 4导出失败 */
  exportStatus?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 结果excel文件地址 */
  resultExcel?: string
  /** 无标题模板导出 */
  withoutTitle?: boolean
}

export interface OutputPayVO {
  /** 支付金额(分) */
  amount?: number
  /** 创建时间 */
  createDate?: string
  /** 支付SDK所需参数 */
  ext?: any
  /** 支付单号 */
  payNo?: string
}

export interface ExpertAppointmentOrderDTO {
  /** 预约信息id */
  dataId?: number
  /** 支付金额 */
  payAmount?: number
  /** 支付渠道 1.支付宝 2.微信 3.苹果支付 6.营养贝支付 */
  payChannel?: number
}

export interface ExpertAppointmentOrder {
  /** 费率 */
  commissionRate?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 预约信息id */
  dataId?: number
  /** 是否删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 支付金额 */
  payAmount?: number
  /** 支付渠道 1.支付宝 2.微信 3.苹果支付 */
  payChannel?: number
  /** 支付完成时间 */
  payTime?: string
  /** 下单时间 */
  placeTime?: string
  /** 订单状态 1.待支付 2.已支付 3.已取消 4.已完成 5.已退款 */
  state?: number
}

export interface HomeEconomicOrderVO {
  /** 商品id */
  commodityId?: number
  /** 商品名称 */
  commodityName?: string
  /** 订单id */
  commodityOrderId?: number
  /** 商品价格 */
  commodityPrice?: number
  /** 1课程2直播3问卷4餐桌单次报告5餐桌看营养会员6营养家政咨询7营养家政服务8问卷单次9营养干预方案 */
  commodityType?: number
  /** 创建时间 */
  createDate?: string
  /** 删除状态 */
  delFlag?: number
  dietitianImg?: string
  dietitianName?: string
  dietitianNickName?: string
  /** 汇率 */
  exchangeRate?: number
  /** 费率 */
  feeRate?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 订单状态 */
  orderStatus?: number
  /** 付款金额 */
  payment?: number
  /** 用户联系电话 */
  phone?: string
  /** 商品提供者id */
  supplierId?: number
  /** 用户id */
  userId?: number
  userName?: string
  /** 商品持续时间 结束 */
  validTimeEnd?: string
  /** 商品持续时间 开始 */
  validTimeStart?: string
}

export interface UserProfitDetailVO {
  /** 金额 */
  amount?: number
  /** 课程名称 */
  commodittyName?: string
  commodityId?: number
  /** 用户id */
  userId?: number
  /** 付款用户 */
  userName?: string
  /** 用户角色 */
  userRole?: number
}

export interface InvoiceDetailCreateDTO {
  /** 地址 */
  address?: string
  /** 申请来源 0 营养贝订单 1 商户服务费 */
  applySource?: number
  /** 申请类型 1.企业 2.个人 */
  applyType?: number
  /** 银行账号 */
  bankCode?: string
  /** 开户银行 */
  bankName?: string
  /** 关联数据id集合(申请开票的数据Kid集合：如营养贝kid) 逗号隔开 */
  dataKid?: string
  /** 邮箱 */
  email?: string
  /** 发票抬头 */
  invoiceHead?: string
  /** 所有者id 用户传用户kid  商户传商户kid */
  ownerKid?: number
  /** 电话 */
  phone?: string
  /** 收票人地址 */
  receiverAddress?: string
  /** 收票人电话 */
  receiverPhone?: string
  /** 税号 */
  taxCode?: string
  /** 价税合计 */
  totalMoney?: string
}

export interface MerchantAccountPeriodInvoiceVO {
  /** 账期编号 */
  accountNumber?: string
  /** 账期 */
  accountPeriodTime?: string
  /** 已结算金额 */
  alreadySettlementAmount?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 结算金额 */
  currentSettlementAmount?: number
  /** 企业ID */
  enterpriseId?: number
  /** 发票表kid */
  invoiceKid?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 负债 */
  liability?: number
  /** 商户标识码 */
  merchantCode?: string
  /** 商户名称 */
  merchantName?: string
  /** 订单交易金额 */
  orderAmount?: number
  /** 订单数 */
  orderNumber?: number
  /** 待结算时间 */
  pendingTime?: string
  /** 待结算操作人id */
  pengingUserId?: number
  /** 状态 1.已出账  2.已对账 3.待结算  4.已结算 */
  periodStatus?: number
  /** 对账时间 */
  reconciliationTime?: string
  /** 对账操作人id */
  reconciliationUserId?: number
  /** 退款金额 */
  refundAmount?: number
  /** 退款单数 */
  refundNumber?: number
  /** 人民币结算金额 */
  rmbSettlementAmount?: number
  /** 账期服务费 */
  serviceFee?: number
  /** 结算类型 1.周  2.半月 3.月 */
  settlementCycle?: number
  /** 结算结束日期 */
  settlementEndDate?: string
  /** 结算对象 0:机构,1:个人 */
  settlementObject?: number
  /** 结算开始日期 */
  settlementStartDate?: string
  /** 结算时间 */
  settlementTime?: string
  /** 结算类型 0:税前,1:税后 */
  settlementType?: number
  /** 结算操作人id */
  settlementUserId?: number
  /** 营养贝结算金额 */
  shellSettlementAmount?: number
  /** 店铺名称 */
  shopName?: string
  /** 盖章附件 */
  signFile?: string
  /** 税率 */
  taxRate?: number
  /** 未结算金额 */
  unsettlementAmount?: number
}

export interface InvoiceDetail {
  /** 地址 */
  address?: string
  /** 价额合计 */
  amount?: string
  /** 申请来源 0 营养贝订单 1 商户服务费 */
  applySource?: number
  /** 申请类型 1.企业 2.个人 */
  applyType?: number
  /** 银行账号 */
  bankCode?: string
  /** 开户银行 */
  bankName?: string
  /** 创建时间 */
  createDate?: string
  /** 开票类型 0 正常发票  1冲红发票 */
  createType?: number
  /** 创建人ID */
  createUserId?: number
  /** 关联数据id集合 逗号隔开 */
  dataKid?: string
  /** 是否删除 */
  delFlag?: number
  /** 邮箱 */
  email?: string
  /** 开票时间 */
  endDate?: string
  /** 发票号码 */
  invoiceCode?: string
  /** 发票抬头 */
  invoiceHead?: string
  /** 开红票时对应正票id */
  invoiceKid?: number
  /** 发票代码 */
  invoiceNum?: string
  /** 发票状态 0 待开票 1已开票 2开票失败 */
  invoiceState?: number
  /** 发票类型 0 增值税普通发票 */
  invoiceType?: number
  /** 发票地址 */
  invoiceUrl?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 电话 */
  phone?: string
  /** 税额合计 */
  tax?: string
  /** 税号 */
  taxCode?: string
  /** 价税合计 */
  totalMoney?: string
}

export interface InvoiceProductDetail {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 是否删除 */
  delFlag?: number
  /** 发票kid */
  invoiceKid?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 商品金额 */
  productAmount?: string
  /** 商品编码 */
  productCode?: string
  /** 商品名称 */
  productName?: string
  /** 商品数量 */
  productNum?: string
  /** 商品单价 */
  productPrice?: string
  /** 商品税率 */
  productSlv?: string
  /** 商品简码 */
  productTag?: string
  /** 商品税额 */
  productTax?: string
}

export interface MedicalAppointmentOrderChangeDTO {
  /** 预约体检日期 */
  examDate?: string
  /** 预约体检时段文案 */
  examTimeIntervalContent?: string
  /** 预约体检时段，取返回值period,参考套餐可约时间查询 */
  examTimeIntervalId?: number
  /** kid */
  kid?: number
}

export interface MedicalAppointmentOrderDTO {
  /** 预约体检日期 */
  examDate?: string
  /** 预约体检时段文案 */
  examTimeIntervalContent?: string
  /** 预约体检时段，取返回值period,参考套餐可约时间查询 */
  examTimeIntervalId?: number
  /** 预约体检中心id */
  hospitalId?: number
  /** 预约体检套餐id */
  mealId?: number
  /** 就诊人唯一标识 */
  openId?: string
  /** 支付总金额 */
  payAmount?: number
  /** 支付渠道 1.支付宝 2.微信 3.苹果支付 6.营养贝支付 */
  payChannel?: number
}

export interface MedicalAppointmentOrderAppDetailVO {
  /** 就诊人年龄 */
  age?: number
  /** 是否可进行改签 撤销   0 是 1 否 */
  canUpdateStatus?: number
  /** 预约体检日期 */
  examDate?: string
  /** 预约体检时段文案 */
  examTimeIntervalContent?: string
  /** 就诊人性别  0男 1女 */
  gender?: number
  /** 体检地址 */
  hospitalAdress?: string
  /** 预约体检中心id */
  hospitalId?: number
  /** 预约体检中心 */
  hospitalName?: string
  /** 就诊人身份证号 */
  idCard?: string
  /** 体检项目 */
  items?: HealthExaminationMealItem[]
  /** 订单id */
  kid?: number
  /** 婚姻状态 0未婚 1已婚 */
  marriageStatus?: number
  /** 预约体检套餐id */
  mealId?: number
  /** 预约体检套餐 */
  mealName?: string
  /** 订单状态 0待支付 1已支付 2已预约 3体检完成 5已撤销 6已删除 7已退款 8已关闭 9已取消 */
  orderStatus?: number
  /** 支付总金额 */
  payAmount?: number
  /** 就诊人电话 */
  phone?: string
  /** 下单时间 */
  placeTime?: string
  /** 待支付订单超时剩余时间：秒 */
  placeTimeNum?: number
  /** 套餐标签 如：”糖尿病”, “三高”, “贵宾服务” */
  tags?: string
  /** 就诊人 */
  userName?: string
}

export interface HealthExaminationMealItem {
  /** 创建时间 */
  createDate?: string
  /** 单项描述 */
  description?: string
  /** 套餐婚否信息（0：通用 1：已婚（有性经历）） */
  detail?: string
  /** 项目适宜人群 */
  fitPeople?: string
  /** 项目类型 */
  itemType?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 单项编号 */
  mealId?: number
  /** 套餐名称 */
  name?: string
  /** 项目不适宜人群 */
  unfitPeople?: string
}

export interface MedicalAppointmentOrderAppVO {
  /** 是否可进行改签 撤销   0 是 1 否 */
  canUpdateStatus?: number
  /** 预约体检日期 */
  examDate?: string
  /** 预约体检时段文案 */
  examTimeIntervalContent?: string
  /** 预约体检中心id */
  hospitalId?: number
  /** 预约体检中心 */
  hospitalName?: string
  /** 订单id */
  kid?: number
  /** 预约体检套餐id */
  mealId?: number
  /** 预约体检套餐 */
  mealName?: string
  /** 订单状态 0待支付 1已支付 2已预约 3体检完成 5已撤销 6已删除 7已退款 8已关闭 9已取消 */
  orderStatus?: number
  /** 支付总金额 */
  payAmount?: number
}

export interface MyInvoice {
  /** 开户行名称 */
  bankName?: string
  /** 银行卡号 */
  cardNo?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 是否删除 */
  delFlag?: number
  /** 发票类型 1.电子普通发票 2.纸质普通发票 3.纸质专用发票 */
  invoiceType?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 联系电话 */
  phone?: string
  /** 收票人地址 */
  receiverAddress?: string
  /** 收票人邮箱 */
  receiverEmail?: string
  /** 收票人电话 */
  receiverPhone?: string
  /** 注册地址 */
  regAddress?: string
  /** 税号 */
  taxNo?: string
  /** 发票抬头 */
  title?: string
  /** 抬头类型 1.企业 2.个人 */
  titleType?: number
  /** 用户id */
  userId?: number
}

export interface ExpressDemolitionVO {
  /** 快递公司名 */
  company?: string
  /** 商品数量 */
  goodsCount?: string
  /** 商品图片 */
  goodsImage?: string
  /** 物流信息kid */
  kid?: number
  /** 快递单号 */
  no?: string
}

export interface ExpressVO {
  /** 快递公司名 */
  company?: string
  /** 快递员手机号 */
  courierPhone?: string
  /** 快递详细信息列表 */
  detailVOS?: ExpressDetailVO[]
  /** 商品数量 */
  goodsCount?: string
  /** 商品图片 */
  goodsImage?: string
  /** 快递单号 */
  no?: string
  /** 手机号 */
  phone?: string
  /** 1表示此快递单的物流信息不会发生变化，此时您可缓存下来；0表示有变化的可能性 */
  status?: string
}

export interface ExpressDetailVO {
  /** 物流事件发生的时间 */
  datetime?: string
  /** 物流事件的描述 */
  remark?: string
  /** 快件当时所在区域 */
  zone?: string
}

export interface ExpressBackVO {
  /** 快递公司名 */
  company?: string
  /** 快递员手机号 */
  courierPhone?: string
  /** 快递详细信息列表 */
  detailVOS?: ExpressDetailVO[]
  /** 商品数量 */
  goodsCount?: string
  /** 商品图片 */
  goodsImage?: string
  /** 快递单号 */
  no?: string
  /** 手机号 */
  phone?: string
  /** 订单商品详情 */
  productOrderDetailVOList?: OrderDetail[]
  /** 1表示此快递单的物流信息不会发生变化，此时您可缓存下来；0表示有变化的可能性 */
  status?: string
}

export interface OrderDetail {
  /** 总价(单价*数量) */
  amount?: number
  /** 商品品牌kid */
  brandKid?: number
  /** 平台提成 百分制 */
  commissionRate?: number
  /** 创建时间 */
  createDate?: string
  /** 是否删除 */
  delFlag?: number
  /** 运费 */
  expressAmount?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 商家优惠券金额 */
  merchantCouponAmount?: number
  /** 改价金额分摊 */
  modifiedAmount?: number
  /** 改邮费金额分摊 */
  modifiedExpressAmount?: number
  /** 数量 */
  num?: number
  /** 订单kid */
  orderKid?: number
  /** 原单价 */
  originUnitAmount?: number
  /** 支付金额 */
  payAmount?: number
  /** 平台优惠券金额 */
  platCouponAmount?: number
  /** 商品id */
  productKid?: number
  /** 商品名称 */
  productName?: string
  /** 商品编码 */
  productNo?: string
  /** 退款编号 */
  refundNo?: string
  /** 退款订单状态 1.待商家同意 2.待用户发货 3.待商家确任收货 4 已完成 5 商家拒绝 6退款中 7 退款成功 8 退款失败 9.已取消 */
  refundStatus?: number
  /** 结算价 */
  settlePrice?: number
  /** skuKid */
  skuKid?: number
  /** sku图片 */
  skuPic?: string
  /** sku规格 */
  skuSpec?: string
  /** sku规格属性 */
  skuSpecAttr?: string
  /** 单价 */
  unitAmount?: number
}

export interface UpdateExpressNoDTO {
  /** 物流详情 */
  expressVOList?: OrderExpress[]
}

export interface OrderExpress {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 是否删除 */
  delFlag?: number
  /** 快递公司 */
  expressName?: string
  /** 快递单号 */
  expressNo?: string
  /** 物流单类型  0 整订单物流  1 拆单物流 */
  expressType?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 拆单物流的订单详情id */
  orderDetailId?: string
  /** 订单id */
  orderKid?: number
}

export interface OrderInvoice {
  /** 发票金额 */
  amount?: number
  /** 申请时间 */
  applyTime?: string
  /** 开户行名称 */
  bankName?: string
  /** 银行卡号 */
  cardNo?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 是否删除 */
  delFlag?: number
  /** 发票类型 1.电子普通发票 2.纸质普通发票 3.纸质专用发票 */
  invoiceType?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 商户id */
  merchantId?: number
  /** 订单kid */
  orderKid?: number
  /** 订单编号 */
  orderNo?: string
  /** 联系电话 */
  phone?: string
  /** 收票人地址 */
  receiverAddress?: string
  /** 收票人邮箱 */
  receiverEmail?: string
  /** 收票人电话 */
  receiverPhone?: string
  /** 注册地址 */
  regAddress?: string
  /** 来源 1.商城订单 */
  source?: number
  /** 税号 */
  taxNo?: string
  /** 发票抬头 */
  title?: string
  /** 抬头类型 1.企业 2.个人 */
  titleType?: number
  /** 用户id */
  userId?: number
}

export interface OrderInvoiceQueryDTO {
  /** 申请时间结束 */
  applyTimeEnd?: string
  /** 申请时间开始 */
  applyTimeStart?: string
  /** 发票类型 1.电子普通发票 2.纸质普通发票 */
  invoiceType?: number
  pageNo?: number
  pageSize?: number
}

export interface OrderReceivingAddress {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 默认地址标识  0默认 1非默认 */
  defaultFlag?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 邮政编码 */
  postCode?: string
  /** 详细地址 */
  receivingAddresDetail?: string
  /** 收货地址市 */
  receivingCity?: string
  /** 收货地址区 */
  receivingCounty?: string
  /** 收货人姓名 */
  receivingName?: string
  /** 收货人手机号 */
  receivingPhone?: string
  /** 收货地址省 */
  receivingProvince?: string
  /** 用户id */
  userId?: number
}

export interface OrderReceivingAddressDeleteDTO {
  /** 分布式唯一ID */
  kid?: number
}

export interface OrderReceivingAddressVO {
  /** 默认地址标识  0默认 1非默认 */
  defaultFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 邮政编码 */
  postCode?: string
  /** 详细地址 */
  receivingAddresDetail?: string
  /** 收货地址市 */
  receivingCity?: string
  /** 收货地址市  回显 */
  receivingCityText?: string
  /** 收货地址区 */
  receivingCounty?: string
  /** 收货地址区  回显 */
  receivingCountyText?: string
  /** 收货人姓名 */
  receivingName?: string
  /** 收货人手机号 */
  receivingPhone?: string
  /** 收货地址省 */
  receivingProvince?: string
  /** 收货地址省  回显 */
  receivingProvinceText?: string
}

export interface CalcExpressVO {
  /** 商品对应:运费金额 */
  feeOfProductList?: KeyValueDTO<long,long>[]
  /** 商家对应运费列表 商户kid:运费金额 */
  merchantFeeList?: KeyValueDTO<long,long>[]
  /** 总运费 */
  totalFee?: number
}

export interface CalcExpressDTO {
  /** 地址id */
  addressKid?: number
  /** sku :  数量 */
  skuWithNumList?: KeyValueDTO<long,int>[]
}

export interface OrderDeliverDTO {
  /** 备注 */
  content?: string
  /** 物流公司 存标识 不存名字 韵达:yd,中通:zt,邮政:yz,顺丰:sf,百世汇通:bsht,圆通:yt */
  expressCode?: string
  /** 物流单号 */
  expressNo?: string
  /** 物流单类型  0 整订单物流  1 拆单物流 */
  expressType?: number
  /** 订单kid */
  kid?: number
  /** 拆单物流的订单详情id */
  orderDetailId?: string
}

export interface OrderDeliverDetailVO {
  /** 商品信息 */
  deliverDetailProductVOList?: OrderDetail[]
  /** 订单kid */
  kid?: number
  /** 订单备注 */
  notes?: string
  /** 邮政编码 */
  postCode?: string
  /** 收货人 */
  receiver?: string
  /** 收货地址 */
  receiverAddress?: string
  /** 手机号码 */
  receiverPhone?: string
  /** 订单状态 1.待付款 2.待发货 3.待收货 4.已完成 5.已关闭 */
  status?: number
  /** 订单总价（分） */
  totalAmount?: number
}

export interface OrderDeliverListCountVO {
  /** 交易完成 */
  end?: number
  /** 待收货 */
  received?: number
  /** 待发货 */
  shipped?: number
  /** 全部 */
  total?: number
}

export interface OrderDetailAppVO {
  /** 具体地址 */
  address?: string
  /** 详细的收货地址 */
  addressDetail?: string
  /** 区 */
  area?: string
  /** 市 */
  city?: string
  /** 线上视听数据ID */
  commodityId?: number
  /** 线上服务对话标识 */
  consultationTag?: string
  /** 评价状态 1.待评价 2.已评价 3.已追评 */
  evaluateStatus?: number
  /** 运费 */
  expressAmount?: number
  /** 发票类型 1.电子普通发票 2.纸质普通发票 3.纸质专用发票 */
  invoiceType?: number
  /** 最新的物流信息 */
  latestExpressInfo?: string
  /** 最新的物流信息时间 */
  latestExpressTime?: string
  /** 商家优惠券金额 */
  merchantCouponAmount?: number
  /** 商户kid */
  merchantKid?: number
  /** 商户名称 */
  merchantName?: string
  /** 改价金额 */
  modifiedAmount?: number
  /** 改邮费金额 */
  modifiedExpressAmount?: number
  /** 订单备注 */
  notes?: string
  /** 订单kid */
  orderKid?: number
  /** 订单列表商品列表 */
  orderListVOProductVOList?: OrderListVOProductVO[]
  /** 订单编号 */
  orderNo?: string
  /** 0.实物商品 1.线上服务 2.线上视听 3.线下服务 */
  orderType?: number
  /** 实付金额 */
  payAmount?: number
  /** 支付平台描述 */
  payPlatformDesc?: string
  /** 支付状态表 1.待支付 2已支付 */
  payStatus?: number
  /** 支付时间 */
  payTime?: string
  /** 支付超时时间 单位:秒 */
  payTimeoutSeconds?: number
  /** 电话号码(已脱敏) */
  phone?: string
  /** 下单时间 */
  placeTime?: string
  /** 平台优惠券金额 */
  platCouponAmount?: number
  /** 商品预售信息 */
  productPrepareSimpleAppVO?: ProductPrepareSimpleAppVO
  /** 省 */
  province?: string
  /** 收货人 */
  receiver?: string
  /** 店铺logo */
  shopLogo?: string
  /** 订单状态 */
  status?: number
  /** 订单状态描述 */
  statusDesc?: string
  /** 总金额 */
  totalAmount?: number
  /** 总数量 */
  totalNum?: number
  /** 线下服务数据code */
  verificationCode?: string
}

export interface OrderListVOProductVO {
  /** 数量 */
  num?: number
  /** 订单详情kid */
  orderDetailKid?: number
  /** 实际支付金额 */
  payAmount?: number
  /** 商品kid */
  productKid?: number
  /** 商品名称 */
  productName?: string
  /** 退款编号 */
  refundNo?: string
  /**  退款订单状态 1.待商家同意 2.待用户发货 3.待商家确任收货 4 已完成  5 商家拒绝 6退款中 7 退款成功 8 退款失败 9.已取消 */
  refundStatus?: number
  /** 退款状态描述 0.可申请退款 1.退款中 2.退款成功  */
  refundStatusDesc?: string
  /** skuKid */
  skuKid?: number
  /** sku封面 */
  skuPic?: string
  /** sku展示字符串 例:300g;原味 */
  skuSpec?: string
  /** 单价 */
  unitAmount?: number
}

export interface ProductPrepareSimpleAppVO {
  /** 审核状态 1.待审核 3.审核通过 4.审核驳回 */
  auditStatus?: number
  /** 服务器当前时间 */
  currentTime?: string
  /** 发货开始时间 */
  deliveryStartTime?: string
  /** 预售id */
  kid?: number
  /** 预售结束时间 */
  preSaleEndTime?: string
  /** 预售开始时间 */
  preSaleStartTime?: string
  /** 状态  1.预售中 2.已取消 3.已结束 */
  state?: number
}

export interface OrderDetailMerchantVO {
  /** 详细的收获地址 */
  addressDetail?: string
  /** 评价状态 1.待评价 2.已评价 3.已追评 */
  evaluateStatus?: number
  /** 运费 */
  expressAmount?: number
  /** 商家优惠券金额 */
  merchantCouponAmount?: number
  /** 改价金额 */
  modifiedAmount?: number
  /** 改邮费金额 */
  modifiedExpressAmount?: number
  /** 订单备注 */
  notes?: string
  /** 订单发票 */
  orderInvoice?: OrderInvoice
  /** 订单kid */
  orderKid?: number
  /** 订单列表商品列表 */
  orderListVOProductVOForMerchantList?: OrderListVOProductVOForMerchant[]
  /** 订单日志 */
  orderLogListVOList?: OrderLogListVO[]
  /** 订单编号 */
  orderNo?: string
  /** 订单轨迹 */
  orderStatusTrailList?: OrderStatusTrail[]
  /** 0.实物商品 1.线上服务 2.线上视听 3.线下服务 */
  orderType?: number
  /** 实付金额 */
  payAmount?: number
  /** 支付平台描述 */
  payPlatformDesc?: string
  /** 支付时间 */
  payTime?: string
  /** 电话号码(未脱敏) */
  phone?: string
  /** 下单时间 */
  placeTime?: string
  /** 邮编 */
  postCode?: string
  /** 订单商品预售信息 */
  productPrepareSimpleAppVO?: ProductPrepareSimpleAppVO
  /** 收货人 */
  receiver?: string
  /** 订单状态 1.待付款 2.待发货 3.待收货 4.已完成 5.已关闭 */
  status?: number
  /** 订单状态描述 */
  statusDesc?: string
  /** 总金额 */
  totalAmount?: number
  /** 总数量 */
  totalNum?: number
  /** 用户姓名 */
  userName?: string
  /** 用户昵称 */
  userNickName?: string
  /** 用户手机号 */
  userTel?: string
}

export interface OrderListVOProductVOForMerchant {
  /** 小计 */
  amount?: number
  /** 品牌名称 */
  bandName?: string
  /** 数量 */
  num?: number
  /** 订单详情kid */
  orderDetailKid?: number
  /** 商品kid */
  productKid?: number
  /** 商品名称 */
  productName?: string
  /** 商品编码 */
  productNo?: string
  /** 退款编号 */
  refundNo?: string
  /** 退款状态 0.可申请退款 1.退款中 2.退款成功 */
  refundStatus?: number
  /** 退款状态描述 0.可申请退款 1.退款中 2.退款成功  */
  refundStatusDesc?: string
  /** skuKid */
  skuKid?: number
  /** sku封面 */
  skuPic?: string
  /** sku规格 */
  skuSpec?: string
  /** sku规格属性 */
  skuSpecAttr?: string
  /** 单价 */
  unitAmount?: number
}

export interface OrderLogListVO {
  /** 操作内容 */
  contents?: string
  /** 操作时间 */
  operateTime?: string
  /** 动作类型 */
  operateTypeDesc?: string
  /** 操作人角色 1.平台后台用户 2.app用户 */
  operatorRoleDesc?: string
  /** 操作人 */
  operatorUserId?: number
  /** 订单状态 */
  orderStatusDesc?: string
  /** 支付状态 */
  payStatusDesc?: string
}

export interface OrderStatusTrail {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 是否删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 订单kid */
  orderKid?: number
  /** 状态记录时间 */
  recordTime?: string
  /** 状态轨迹 1.待付款 2.待发货 3.待收货 4.已完成 5.已关闭 6.已评价 */
  statusCode?: number
}

export interface OrderPrePayDTO {
  /** 微信小程序支付openid */
  openid?: string
  /** 订单编号列表 */
  orderNoList?: string[]
  /** 支付平台 1.支付宝 2.微信 6:余额 */
  payPlatform?: number
}

export interface OrderDeliverListVO {
  /** 订单kid */
  kid?: number
  /** 订单编号 */
  orderNo?: string
  /** 收货人 */
  receiver?: string
  /** 收货地址 */
  receiverAddress?: string
  /** 手机号码 */
  receiverPhone?: string
  /** 订单状态 1.待付款 2.待发货 3.待收货 4.已完成 5.已关闭 */
  status?: number
}

export interface OrderDeliverListDTO {
  /** 物流单号 */
  expressNo?: string
  /** 订单编号 */
  orderNo?: string
  /** 页码 */
  pageNo?: number
  /** 页大小 */
  pageSize?: number
  /** 下单时间结束 */
  placeTimeEnd?: string
  /** 下单时间开始 */
  placeTimeStart?: string
  /** 收货人 */
  receiver?: string
  /** 手机号码 */
  receiverPhone?: string
  /** 订单状态 1.待付款 2.待发货 3.待收货 4.已完成 5.已关闭 */
  status?: number
}

export interface OrderListAppVO {
  /** 线上视听数据ID */
  commodityId?: number
  /** 线上服务对话标识 */
  consultationTag?: string
  /** 评价状态 1.待评价 2.已评价 3.已追评 */
  evaluateStatus?: number
  /** 商家优惠券金额 */
  merchantCouponAmount?: number
  /** 商户kid */
  merchantKid?: number
  /** 商户名称 */
  merchantName?: string
  /** 订单发票kid */
  orderInvoiceKid?: number
  /** 订单kid */
  orderKid?: number
  /** 订单列表商品列表 */
  orderListVOProductVOList?: OrderListVOProductVO[]
  /** 订单编号 */
  orderNo?: string
  /** 0.实物商品 1.线上服务 2.线上视听 3.线下服务 */
  orderType?: number
  /** 应付金额 */
  payAmount?: number
  /** 支付状态表 1.待支付 2已支付 */
  payStatus?: number
  /** 商品预售信息 */
  productPrepareSimpleAppVO?: ProductPrepareSimpleAppVO
  /** 店铺logo */
  shopLogo?: string
  /**  订单状态 1.待付款 2.待发货 3.待收货 4.已完成 5.已关闭 */
  status?: number
  /** 订单状态描述 */
  statusDesc?: string
  /** 总金额 */
  totalAmount?: number
  /** 总数量 */
  totalNum?: number
  /** 线下服务数据code */
  verificationCode?: string
}

export interface OrderListMerchantVO {
  /** 详细的收获地址 */
  addressDetail?: string
  /** 运费 */
  expressAmount?: number
  /** 商家优惠券金额 */
  merchantCouponAmount?: number
  /** 商户id */
  merchantId?: number
  /** 商户名称 */
  merchantName?: string
  /** 订单备注 */
  notes?: string
  /** 订单kid */
  orderKid?: number
  /** 订单列表商品列表 */
  orderListVOProductVOList?: OrderListVOProductVO[]
  /** 订单编号 */
  orderNo?: string
  /** 0.实物商品 1.线上服务 2.线上视听 3.线下服务 */
  orderType?: number
  /** 实付金额 */
  payAmount?: number
  /** 电话号码(已脱敏) */
  phone?: string
  /** 下单时间 */
  placeTime?: string
  /** 平台优惠券金额 */
  platCouponAmount?: number
  /** 收货人 */
  receiver?: string
  /** 订单状态 */
  status?: number
  /** 订单状态描述 */
  statusDesc?: string
  /** 总金额 */
  totalAmount?: number
  /** 总数量 */
  totalNum?: number
  /** 用户id */
  userId?: number
}

export interface OrderQueryForMerchantDTO {
  /** 商户ID */
  merchantKid?: number
  /** 订单kid */
  orderKid?: number
  /** 订单编号 */
  orderNo?: string
  /** 订单类型  0.实物商品 1.线上服务 2.线上视听 3.线下服务 */
  orderType?: number
  /** pageNo */
  pageNo?: number
  /** pageSize */
  pageSize?: number
  /** 收货手机号 */
  phone?: string
  /** 下单时间结束 */
  placeTimeEnd?: string
  /** 下单时间开始 */
  placeTimeStart?: string
  /** 平台对接人id */
  platformDockerId?: number
  /** table类型 0.全部 1.待付款 2.待发货 3.待收货 4.已完成 5.已关闭 6.待评价 */
  tableType?: number
}

export interface MerchantAddOrderRemarkDTO {
  /** 订单id */
  orderKid?: number
  /** 备注 */
  remark?: string
}

export interface MerchantModifyAmountDTO {
  /** 将邮费改为多少钱 */
  expressModifyAmount?: number
  /** 订单id */
  orderKid?: number
}

export interface OrderSubmitConfirmVO {
  /** 当前地址购买该商户商品所需运费 */
  expressFee?: number
  /** 商家支持的发票类型 列表 1.电子普通发票 2.纸质普通发票 3.纸质专用发票 */
  invoiceTypeList?: number[]
}

export interface OrderPrintDeliverDetailVO {
  /** 提交时间 */
  createDate?: string
  /** 商品信息 */
  deliverDetailProductVOList?: OrderDetail[]
  /** 运费 */
  expressAmount?: number
  /** 订单kid */
  kid?: number
  /** 订单备注 */
  notes?: string
  /** 订单号 */
  orderNo?: string
  /** 支付总金额 */
  payAmount?: number
  /** 支付平台 1支付宝 2微信 */
  payPlatform?: number
  /** 邮政编码 */
  postCode?: string
  /** 收货人 */
  receiver?: string
  /** 收货地址 */
  receiverAddress?: string
  /** 手机号码 */
  receiverPhone?: string
  /** 订单状态 1.待付款 2.待发货 3.待收货 4.已完成 5.已关闭 */
  status?: number
  /** 订单总价（分） */
  totalAmount?: number
}

export interface UserScoreDTO {
  /** 匿名 */
  anonymous?: number
  bindDTOS?: ScoreBindDTO[]
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除 */
  del?: number
  /** 评分组标识 */
  groupKid?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 评分评价 */
  scoreContent?: string
  /** 评分图片 */
  scoreImgs?: string
  /** 评分 */
  scoreOne?: number
  /** 评分 */
  scoreThree?: number
  /** 评分 */
  scoreTwo?: number
  /** 来源标识 */
  sourceKid?: number
  /** 状态 */
  status?: number
  /** 用户头像 */
  userAvatar?: string
  /** 用户id */
  userId?: number
  /** 用户名称 */
  userName?: string
}

export interface ScoreBindDTO {
  bindContent?: string
  bindKid?: number
  recommend?: number
}

export interface OrderSubmitVO {
  /** 所有商户商品总运费 */
  allExpressAmount?: number
  /** 所有商户商品总金额 */
  allTotalAmount?: number
  /** 订单ID列表 */
  orderIdList?: number[]
  /** 订单号列表 */
  orderNoList?: string[]
  /** 支付金额 */
  payAmount?: number
  /** 支付超时时间 */
  payTimeoutSeconds?: number
  /** 下单时间 */
  placeTime?: string
}

export interface OrderSubmitDTO {
  /** 地址kid */
  addressKid?: number
  /** 发票列表 商家Id:<发票kid:发票类型>(1.电子普通发票 2.纸质普通发票 3.纸质专用发票) */
  invoiceList?: KeyValueDTO<long,KeyValueDTO<long,int>>[]
  /** 是否来自购物车 0.是1.不是 */
  isFromCart?: number
  /** 商家优惠券列表 商家Id:优惠券Id */
  merchantCouponIdList?: KeyValueDTO<long,long>[]
  /** 订单备注列表 商家Id:备注 */
  notesList?: KeyValueDTO<long,string>[]
  /** 平台券ID */
  platCouponId?: number
  /** 购买商品列表 商品skuId:购买数量  */
  productList?: KeyValueDTO<long,int>[]
}

export interface PlanOrder {
  /** 详细地址 */
  address?: string
  /** 区 */
  area?: string
  /** 市 */
  city?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 */
  delFlag?: number
  /** 商城物品运费 */
  expressAmount?: number
  /** 健康管理订单金额 */
  healthMgrAmount?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 商城商品金额 */
  mallAmount?: number
  /** 商城订单号列表 */
  mallOrderIds?: string
  /** 工单ID */
  needOrderId?: number
  /** 一对一订单金额 */
  one2oneAmount?: number
  /** 一对一服务订单id列表 */
  oneoneOrderIds?: string
  /** 订单简介 */
  orderIntro?: string
  /** 订单号 */
  orderNo?: string
  /** 1.待支付 2.已支付 */
  orderStatus?: number
  /** 支付金额 */
  payAmount?: number
  /** 支付通道：1-支付宝，2-微信，3-苹果，4-银联，5-中国银联（提现） */
  payChannel?: number
  /** 支付单号 */
  payNo?: string
  /** 支付来源：1-IOS 2-ANDROID 3-WEB 4-MINI_PROGRAM */
  paySource?: number
  /** 1.待支付 2.已支付 */
  payStatus?: number
  /** 支付时间 */
  payTime?: string
  /** 用户手机号 */
  phone?: string
  /** 下单时间 */
  placeTime?: string
  /** 方案描述 */
  planDesc?: string
  /** 方案标题 */
  planTitle?: string
  /** 邮政编码 */
  postCode?: string
  /** 省 */
  province?: string
  /** 收货人 */
  receiver?: string
  /** 方案ID */
  servicePlanId?: number
  /** 订单总金额 */
  totalAmount?: number
  /** 总数量 */
  totalNum?: number
  /** 用户id */
  userId?: number
}

export interface KidRequest<T> {
  /** 数据 */
  data?: number
  /** kid */
  kid?: number
}

export interface PlanOrderDetailVO {
  /** 详细地址 */
  address?: string
  /** 区 */
  area?: string
  /** 市 */
  city?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 */
  delFlag?: number
  /** 商城物品运费 */
  expressAmount?: number
  /** 健康管理订单金额 */
  healthMgrAmount?: number
  /** 健康管家订单 */
  healthMgrOrderVO?: HealthMgrOrderVO
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 商城商品金额 */
  mallAmount?: number
  /** 商城订单号列表 */
  mallOrderIds?: string
  /** 商城订单 */
  mallOrderList?: OrderDetailAppVO[]
  /** 工单ID */
  needOrderId?: number
  /** 一对一订单金额 */
  one2oneAmount?: number
  /** 一对一服务订单id列表 */
  oneoneOrderIds?: string
  /** 一对一订单 */
  oneoneOrderList?: PlanOneoneOrderVO[]
  /** 订单简介 */
  orderIntro?: string
  /** 订单号 */
  orderNo?: string
  /** 1.待支付 2.已支付 */
  orderStatus?: number
  /** 支付金额 */
  payAmount?: number
  /** 支付通道：1-支付宝，2-微信，3-苹果，4-银联，5-中国银联（提现） */
  payChannel?: number
  /** 支付单号 */
  payNo?: string
  /** 支付来源：1-IOS 2-ANDROID 3-WEB 4-MINI_PROGRAM */
  paySource?: number
  /** 1.待支付 2.已支付 */
  payStatus?: number
  /** 支付时间 */
  payTime?: string
  /** 用户手机号 */
  phone?: string
  /** 下单时间 */
  placeTime?: string
  /** 方案描述 */
  planDesc?: string
  /** 方案标题 */
  planTitle?: string
  /** 邮政编码 */
  postCode?: string
  /** 省 */
  province?: string
  /** 收货人 */
  receiver?: string
  /** 方案ID */
  servicePlanId?: number
  /** 订单总金额 */
  totalAmount?: number
  /** 总数量 */
  totalNum?: number
  /** 用户id */
  userId?: number
}

export interface HealthMgrOrderVO {
  /** 课程订单id列表逗号分割 */
  courseOrderIds?: string
  /** 创建时间 */
  createDate?: string
  /** 删除标记 */
  delFlag?: number
  /** 推荐说明类型 1图文 2视频 */
  descType?: number
  /** 描述 */
  description?: string
  /** 健康管家订单特权 */
  detailList?: HealthMgrOrderDetail[]
  /** 时长 */
  duration?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 管理总金额 */
  mgrTotalAmount?: number
  /** 管家用户信息 */
  mgrUser?: UserSimpleVO
  /** 管家用户ID */
  mgrUserId?: number
  /** 订单编号 */
  orderNo?: string
  /** 1.待支付 2.已支付 */
  orderStatus?: number
  /** 支付金额 */
  payAmount?: number
  /** 支付通道：1-支付宝，2-微信，3-苹果，4-银联，5-中国银联（提现） */
  payChannel?: number
  /** 支付单号 */
  payNo?: string
  /** 支付来源：1-IOS 2-ANDROID 3-WEB 4-MINI_PROGRAM */
  paySource?: number
  /** 1.待支付 2.已支付 */
  payStatus?: number
  /** 支付时间 */
  payTime?: string
  /** 下单时间 */
  placeTime?: string
  /** 方案订单ID */
  planOrderId?: number
  /** 方案ID */
  servicePlanId?: number
  /** 下属特权服务总数量 */
  subNum?: number
  /** 单位 2.月 4.次 */
  unit?: number
  /** 用户信息 */
  user?: UserSimpleVO
  /** 用户id */
  userId?: number
  /** 视频首帧图地址 */
  videoCover?: string
  /** 视频时长 */
  videoDuration?: number
  /** 视频资源URL */
  videoUrl?: string
}

export interface HealthMgrOrderDetail {
  /** 创建时间 */
  createDate?: string
  /** 有效时长 单位:月 */
  duration?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 管理服务ID */
  mgrOrderId?: number
  /** 方案订单ID */
  planOrderId?: number
  /** 增值服务ID */
  serviceId?: number
  /** 服务名称 */
  serviceName?: string
  /** 服务方案ID */
  servicePlanId?: number
  /** 增值服务类型 1问卷 2课程 3餐桌看营养 4.VIP */
  serviceType?: number
}

export interface UserSimpleVO {
  /** 星座 */
  constellation?: string
  /** 现居地 */
  livingPlace?: string
  /** 用户角色集合 */
  roles?: UserRole[]
  /** 用户背景图片 */
  userBgImg?: string
  /** 出生年月日 */
  userBirthday?: string
  /** 用户性别 0女/1男 */
  userGenders?: number
  /** 用户账户id */
  userId?: number
  /** 头像 */
  userImg?: string
  /** 姓名 */
  userName?: string
  /** 昵称 */
  userNickName?: string
  /** 用户签名 */
  userSignature?: string
  /** 马甲状态 0-普通用户 1-马甲 */
  vestFlag?: number
}

export interface UserRole {
  /** 角色 */
  role?: string
  /** 角色头衔 */
  roleTitle?: string
}

export interface PlanOneoneOrderVO {
  /** 推荐说明类型 1图文 2视频 */
  descType?: number
  /** 时长 */
  duration?: number
  /** 一对一服务营养师ID */
  nutritistId?: number
  /** 营养师头像 */
  nutritistIdUserImg?: string
  /** 营养师信息 */
  nutritistIdUserName?: string
  /** 下单时间 */
  placeTime?: string
  /** 方案订单id */
  planOrderId?: number
  /** 一对一服务单价 */
  price?: number
  /** 一对一服务推荐说明 */
  recommendDesc?: string
  /** 方案ID */
  servicePlanId?: number
  /** 单位  2月  4次 */
  unit?: number
  /** 用户信息 */
  user?: UserSimpleVO
  userId?: number
  /** 视频首帧图地址 */
  videoCover?: string
  /** 视频时长 */
  videoDuration?: number
  /** 视频资源URL */
  videoUrl?: string
}

export interface PageRequest<T> {
  /** 截止时间 */
  endTime?: string
  /** 页码 */
  pageNo?: number
  /** 每页条数 */
  pageSize?: number
  /** 参数对象 */
  param?: T
  /** 开始时间 */
  startTime?: string
  /** 时间范围 */
  timeRange?: string
}

export interface PlanOrderSubmitVO {
  /** 支付金额(分) */
  amount?: number
  /** 创建时间 */
  createDate?: string
  /** 支付SDK所需参数 */
  ext?: any
  /** 订单编号 */
  orderNo?: string
  /** 支付通道：1-支付宝，2-微信  */
  payChannel?: number
  /** 支付单号 */
  payNo?: string
  /** 下单时间 */
  placeTime?: string
}

export interface PlanSubmitDTO {
  /** 地址kid */
  addressKid?: number
  /**  方案详情类型 1.营养治疗服务 2.营养辅助（商品） 3.健康管理服务 */
  detailIdList?: number[]
  /** 订单备注列表 商户kid:备注 */
  notesList?: KeyValueDTO<long,string>[]
  /** 微信小程序支付openid */
  openid?: string
  /** 支付通道：1-支付宝，2-微信  */
  payChannel?: number
  /** 平台券ID */
  platCouponId?: number
}

export interface HealthMgrOrder {
  /** 课程订单id列表逗号分割 */
  courseOrderIds?: string
  /** 创建时间 */
  createDate?: string
  /** 删除标记 */
  delFlag?: number
  /** 时长 */
  duration?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 管理总金额 */
  mgrTotalAmount?: number
  /** 管家用户ID */
  mgrUserId?: number
  /** 订单编号 */
  orderNo?: string
  /** 1.待支付 2.已支付 */
  orderStatus?: number
  /** 支付金额 */
  payAmount?: number
  /** 支付通道：1-支付宝，2-微信，3-苹果，4-银联，5-中国银联（提现） */
  payChannel?: number
  /** 支付单号 */
  payNo?: string
  /** 支付来源：1-IOS 2-ANDROID 3-WEB 4-MINI_PROGRAM */
  paySource?: number
  /** 1.待支付 2.已支付 */
  payStatus?: number
  /** 支付时间 */
  payTime?: string
  /** 下单时间 */
  placeTime?: string
  /** 方案订单ID */
  planOrderId?: number
  /** 方案ID */
  servicePlanId?: number
  /** 下属特权服务总数量 */
  subNum?: number
  /** 单位 2.月 4.次 */
  unit?: number
  /** 用户id */
  userId?: number
}

export interface QuestionnaireBuyDTO {
  /** 支付额 */
  amount?: number
  /** 商品ID */
  commodityId?: number
  /** 问卷报告id(单次购买必传) */
  evaluateReportCollectKid?: number
  /** 支付渠道1-支付宝，2-微信，3-苹果，4-银联，5-中国银联（提现），6-余额 */
  payChannel?: number
}

export interface QuestionnaireOrderVO {
  /** 商品id */
  commodityId?: number
  /** 商品名称 */
  commodityName?: string
  /** 商品原价格 */
  commodityPrice?: number
  /** 商品类型 1课程2直播3问卷4餐桌单次报告5餐桌看营养会员6营养家政咨询7营养家政服务8问卷单次 */
  commodityType?: number
  /** 创建时间 */
  createDate?: string
  dites?: DietFormVO[]
  /** 订单号 */
  orderNo?: string
  /** 支付渠道1-支付宝，2-微信，3-苹果，4-银联，5-中国银联（提现），6-余额 */
  payChannel?: number
  /** 支付时间 */
  payTime?: string
  /** 付款金额 */
  paymentAmount?: number
  /** 价格编码 1-问卷编码 2-vip 3-套餐KID 4餐桌看营养月卡id */
  priceCode?: string
  /** 价格类型 1-问卷 2-会员 3-套餐 4餐桌看营养 */
  priceType?: number
  /** 价格是否任然有效,无效则不能再次购买，true有效 false无效 */
  priceValid?: boolean
  /** 有效时间结束 */
  validTimeEnd?: string
  /** 有效时间开始 */
  validTimeStart?: string
}

export interface DietFormVO {
  diet?: Diet
  imageUrl?: string
  tableNutritionFee?: number
}

export interface Diet {
  /** 名称 */
  dietName?: string
  /** 食谱总重量(可选，打卡) */
  dietWeight?: number
  /** 份数 */
  quality?: number
  /** 食谱单位(份/克) */
  qualityUnit?: string
  /** 食谱标识（可选，打卡) */
  recipeKid?: number
  /** 食物排序（可选，打卡） */
  sort?: number
}

export interface RefundExpressDTO {
  /** 退货地址 */
  refundAddress?: string
  /** 退货物流 */
  refundExpress?: string
  /** 退货物流单号 */
  refundExpressNo?: string
  /** 退款订单kid */
  refundKid?: number
  /** 退货收货人手机号 */
  refundPhone?: string
}

export interface RefundOrderStatusTrail {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 是否删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 退款订单kid */
  orderKid?: number
  /** 状态记录时间 */
  recordTime?: string
  /** 状态轨迹 1.待商家同意 2.待用户发货 3.待商家确任收货 4 已完成  5 商家拒绝 6退款中 7 退款成功 8 退款失败 9.已取消 */
  statusCode?: number
}

export interface RefundOrderApplyDTO {
  /** 订单详情id */
  orderDetailKid?: number
  /** 订单kid */
  orderKid?: number
  /** 退款说明 */
  refundExplain?: string
  /** 退款金额  单位 分 */
  refundMoney?: number
  /** 退款原因 */
  refundReason?: string
  /** 退款类型  0仅退款  1退货退款 */
  refundType?: number
  refundVouchers?: string[]
  /** 用户id */
  userId?: number
  /** APP用户手机号 */
  userPhone?: string
}

export interface RefundOrderExamineDTO {
  /** 审核结果  0同意  1拒绝 */
  examineResult?: number
  /** 退款单kid */
  kid?: number
  /** 退款备注 */
  refundRemark?: string
  /** 退款类型  0仅退款  1退货退款 */
  refundType?: number
}

export interface RefundOrderListVO {
  /** 详细地址 */
  address?: string
  /** 申请支付金额 */
  applyAmount?: string
  /** 区 */
  areaCode?: string
  /** 市 */
  cityCode?: string
  /** 创建时间 */
  createDate?: string
  /** 折扣金额 */
  discountAmount?: string
  /** 分布式唯一ID */
  kid?: number
  /** 商户名称 */
  merchantName?: string
  /** 商品数量 */
  num?: string
  /** 订单编号 查询条件冗余 */
  orderNo?: string
  /** 原价 */
  orginUnitAmount?: string
  /** 支付总金额 */
  payAmount?: number
  /** 支付方式 1 支付宝 2 微信 3 苹果内购支付; */
  payPlatform?: string
  /** 付款时间 */
  payTime?: string
  /** 平台对接人 */
  pickupPeople?: string
  /** 下单时间 */
  placeTime?: string
  /** 商品所属二级分类 */
  productCategory?: string
  /** 商品所属一级分类 */
  productCategoryParent?: string
  /** 商品图片 */
  productImage?: string
  /** 商品id */
  productKid?: string
  /** 商品名 */
  productName?: string
  /** 商品识别码 */
  productNo?: string
  /** 商品状态 0->上架；1->下架 */
  productStatus?: string
  /** 省 */
  provinceCode?: string
  /** 收货人 */
  receiver?: string
  /** 退款金额  单位 分 */
  refundMoney?: number
  /** 退款订单号 */
  refundNo?: string
  /** 退款原因 */
  refundReason?: string
  /** 退款订单状态 1.待商家同意 2.待用户发货 3.待商家确任收货   5 商家拒绝 6退款中 7 退款成功 8 退款失败 9.已取消 */
  refundStatus?: number
  /** 退款类型  0仅退款  1退货退款 */
  refundType?: number
  /** 商品规格属性名 */
  skuSpecAttr?: string
  /** 商品规格 */
  specifications?: string
  /** 现价 */
  unitAmount?: string
  /** APP用户手机号 查询条件冗余 */
  userPhone?: string
}

export interface RefundOrderQueryDTO {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 选择记录id */
  dataList?: number[]
  /** 结束时间 */
  endTime?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 订单编号 查询条件冗余 */
  orderNo?: string
  /** 订单编号集合 */
  orderNoset?: string[]
  /** 分页页码 */
  pageNo?: number
  /** 分页大小 */
  pageSize?: number
  /** 退款订单号 */
  refundNo?: number
  /** 退款订单状态 1.待商家同意 2.待用户发货 3.待商家确任收货 5 商家拒绝 6退款中 7 退款成功 8 退款失败 9.已取消 */
  refundStatus?: number
  /** 开始时间 */
  startTime?: string
  /** 商户id */
  tenantId?: number
  /** APP用户手机号 查询条件冗余 */
  userPhone?: string
}

export interface RefundOrderDetailAppVO {
  /** 创建时间 */
  createDate?: string
  /** 分布式唯一ID */
  kid?: number
  /** 商品数量 */
  num?: string
  /** 订单编号 查询条件冗余 */
  orderNo?: string
  /** 原价 */
  orginUnitAmount?: string
  /** 商品图片 */
  productImage?: string
  /** 商品id */
  productKid?: string
  /** 商品名 */
  productName?: string
  /** 退款金额  单位 分 */
  refundMoney?: number
  /** 退款订单号 */
  refundNo?: string
  /** 退款原因 */
  refundReason?: string
  /** 退款备注 */
  refundRemark?: string
  /** 退款订单状态 1.待商家同意 2.待用户发货 3.待商家确任收货   5 商家拒绝 6退款中 7 退款成功 8 退款失败 9.已取消 */
  refundStatus?: number
  /** 退款类型  0仅退款  1退货退款 */
  refundType?: number
  /** 退款凭证 */
  refundVoucher?: string[]
  /** 倒计时剩余秒 */
  seconds?: number
  /** 商品规格属性名 */
  skuSpecAttr?: string
  /** 商品规格 */
  specifications?: string
  /** 现价 */
  unitAmount?: string
}

export interface RefundEntryVO {
  /** 退款类型  0仅退款  1退货退款 */
  refundType?: number
}

export interface RefundOrderListAppVO {
  /** 分布式唯一ID */
  kid?: number
  /** 店铺id */
  merchantKid?: number
  /** 商品数量 */
  num?: string
  /** 订单明细id */
  orderDetailId?: number
  /** 原价 */
  orginUnitAmount?: string
  /** 支付金额  --可退款金额 */
  payAmount?: number
  /** 商品图片 */
  productImage?: string
  /** 商品id */
  productKid?: string
  /** 商品名 */
  productName?: string
  /** 订单商品预售信息 */
  productPrepareSimpleAppVO?: ProductPrepareSimpleAppVO
  /** 退款订单状态 1.待商家同意 2.待用户发货 3.待商家确任收货   5 商家拒绝 6退款中 7 退款成功 8 退款失败 9.已取消 */
  refundStatus?: number
  /** 退款类型  0仅退款  1退货退款 */
  refundType?: number
  /** 店铺logo */
  shopLogo?: string
  /** 店铺名 */
  shopName?: string
  /** 商品规格 */
  specifications?: string
  /** 现价 */
  unitAmount?: string
}

export interface RefundOrderDetailVO {
  /** 详细地址 */
  address?: string
  /** 区 */
  areaCode?: string
  /** 市 */
  cityCode?: string
  /** 创建时间 */
  createDate?: string
  /** 物流公司 */
  expressName?: string
  /** 物流单号 */
  expressNo?: string
  /** 发货时间 */
  expressTime?: string
  /** 分布式唯一ID */
  kid?: number
  /** 商品数量 */
  num?: string
  /** 订单kid */
  orderKid?: number
  /** 订单编号 查询条件冗余 */
  orderNo?: string
  /** 原价 */
  orginUnitAmount?: string
  /** 支付金额 */
  payAmount?: number
  /** 收货电话 */
  phone?: string
  /** 商品图片 */
  productImage?: string
  /** 商品id */
  productKid?: string
  /** 商品名 */
  productName?: string
  /** 省 */
  provinceCode?: string
  /** 收货人 */
  receiver?: string
  /** 退款金额  单位 分 */
  refundMoney?: number
  /** 退款订单号 */
  refundNo?: string
  /** 退款原因 */
  refundReason?: string
  /** 退款备注 */
  refundRemark?: string
  /** 退款订单状态 1.待商家同意 2.待用户发货 3.待商家确任收货   5 商家拒绝 6退款中 7 退款成功 8 退款失败 9.已取消 */
  refundStatus?: number
  /** 退款类型  0仅退款  1退货退款 */
  refundType?: number
  /** 退款凭证 */
  refundVoucher?: string[]
  /** 倒计时剩余秒 */
  seconds?: number
  /** 商品规格属性名 */
  skuSpecAttr?: string
  /** 商品规格 */
  specifications?: string
  /** 现价 */
  unitAmount?: string
  /** APP用户手机号 查询条件冗余 */
  userPhone?: string
}

export interface ShellOrderPrePayDTO {
  /** 支付渠道 1.支付宝 2.微信 3.ios */
  payChannel?: number
  /** 营养贝数目 */
  shellNum?: number
}

export interface ShellValueVO {
  /** 金额：分 */
  amount?: number
  /** ios用 */
  productIdentifier?: string
  /** 数值 */
  shellNum?: number
}

export interface ShellRechargeOrder {
  /** 营养贝金额 */
  amount?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 是否删除 */
  delFlag?: number
  /** 发票id */
  invoiceId?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 支付营养贝金额 */
  payAmount?: number
  /** 支付渠道 1.支付宝 2.微信 3.ios */
  payChancel?: number
  /** 支付成功 */
  payTime?: string
  /** 下单时间 */
  placeTime?: string
  /** 营养贝数目 */
  shellNum?: number
  /** 来源 1-IOS 2-ANDROID */
  sources?: number
  /** 充值订单状态 1.待支付 2.已支付 3.已取消 */
  state?: number
  /** userid */
  userId?: number
}

export interface VIPThroughTrainOrderSubmitDTO {
  /** 微信小程序支付openid */
  openid?: string
  /** 支付渠道1-支付宝，2-微信，3-苹果，4-银联，5-中国银联（提现），6-余额 */
  payChannel?: number
  /** 会员卡ID */
  vipCardId?: number
}

export interface VIPThroughTrainOrderVO {
  /** 商品id */
  commodityId?: number
  /** 商品名称 */
  commodityName?: string
  /** 购买天数 */
  days?: number
  /** 订单号 */
  orderNo?: string
  /** 支付渠道1-支付宝，2-微信，3-苹果，4-银联，5-中国银联（提现），6-余额 */
  payChannel?: number
  /** 支付时间 */
  payTime?: string
  /** 付款金额 */
  paymentAmount?: number
  /** 用户id */
  userId?: number
  /** 有效时间结束 */
  validTimeEnd?: string
  /** 有效时间开始 */
  validTimeStart?: string
}

export interface LecturerProfitDetailVO {
  /** 金额 */
  amount?: number
  courseId?: number
  /** 课程名称 */
  courseName?: string
  /** 用户id */
  userId?: number
  /** 付款用户 */
  userName?: string
}

export interface CourseBuyDTO {
  /** 课程类型1录播2直播 */
  courseType?: number
  /** 课程id */
  kid?: number
  /** 支付渠道1-支付宝，2-微信，3-苹果，4-银联，5-中国银联（提现） */
  payChannel?: number
  /** 支付来源：1-IOS 2-ANDROID 3-WEB 4-MINI_PROGRAM */
  paySource?: number
  /** 付款金额 */
  payment?: number
  /** 价格 */
  price?: number
}

export interface InterventionPlanDTO {
  /** 支付额 */
  amount?: number
  /** 生日 */
  birthday?: string
  /** 商品ID */
  commodityId?: number
  /** 商品类型9营养干预方案 */
  commodityType?: number
  /** 性别 */
  gender?: number
  /** 身高 */
  height?: number
  /** 劳动强度 */
  labourLevel?: number
  /** 支付渠道1-支付宝，2-微信，3-苹果，4-银联，5-中国银联（提现），6-余额 */
  payChannel?: number
  /** 编码为sbqs的问题的答案 */
  sbqs?: string
  /** 编码为sftx的问题的答案 */
  sftx?: string
  /** 体重 */
  weight?: number
}

export interface TableReportBuyDTO {
  /** 支付额 */
  amount?: number
  /** 用户餐桌看营养订单id */
  bookInfoId?: number
  /** 商品ID */
  commodityId?: number
  /** 商品类型4单次5会员 */
  commodityType?: number
  /** 支付渠道1-支付宝，2-微信，3-苹果，4-银联，5-中国银联（提现），6-余额 */
  payChannel?: number
}
export default {
  cart: {
    /**
    * 区域检查 
    */
    checkRegion(cartAddOrBuyCheckDTO: CartAddOrBuyCheckDTO): Promise<boolean> {
      return httpPost(`/order/v5.0/pb/cart/action/checkRegion`, cartAddOrBuyCheckDTO).then((res:any) => res.data.data)
    },
    /**
    * 添加商品至购物车并返回购物车数量
    */
    add(cartAddDTO: CartAddDTO): Promise<number> {
      return httpPost(`/order/v5.0/pt/cart/action/add`, cartAddDTO).then((res:any) => res.data.data)
    },
    /**
    * 批量添加商品至购物车并返回购物车数量
    */
    addMulti(dtos: CartAddDTO[]): Promise<number> {
      return httpPost(`/order/v5.0/pt/cart/action/addMulti`, dtos).then((res:any) => res.data.data)
    },
    /**
    * 编辑购物车 (1.修改当前购物车对象的skuid对应的数量 将num改为0 2.修改当前购物车对象的skuid 传需要替换的skuid)
    */
    edit(cartAddDTO: CartAddDTO): Promise<Cart> {
      return httpPost(`/order/v5.0/pt/cart/action/edit`, cartAddDTO).then((res:any) => res.data.data)
    },
    /**
    *  查询购物车商品数量 
    */
    getNum(): Promise<number> {
      return httpPost(`/order/v5.0/pt/cart/action/getNum`).then((res:any) => res.data.data)
    },
    /**
    * 购物车列表
    */
    list(): Promise<CartListVO> {
      return httpGet(`/order/v5.0/pt/cart/action/list`).then((res:any) => res.data.data)
    },
    /**
    * 删除购物车商品 只传cartKid 并返回购物车数量
    */
    remove(cartAddDTOList: CartAddDTO[]): Promise<number> {
      return httpPost(`/order/v5.0/pt/cart/action/remove`, cartAddDTOList).then((res:any) => res.data.data)
    },
  },
  healths: {
    /**
    * 健康检查
    */
    check(): Promise<boolean> {
      return httpGet(`/order/v5.0/pb/healths/action/check`).then((res:any) => res.data.data)
    },
  },
  orderExpress: {
    /**
    * 查询快递列表Express
    */
    listAdmin(): Promise<List<ExpressListVO>> {
      return httpGet(`/order/v5.0/pb/order-express/action/list-admin`).then((res:any) => res.data.data)
    },
    /**
    * 拆单明细
    */
    demolitionDetail(params: { orderId: number }): Promise<List<ExpressDemolitionVO>> {
      return httpGet(`/order/v5.0/pt/order-express/action/demolition-detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 拆单物流详情
    */
    expressDetail(params: { kid: number }): Promise<ExpressVO> {
      return httpGet(`/order/v5.0/pt/order-express/action/express-detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 商户查询后台物流详情
    */
    queryBackExpress(params: { orderId: number }): Promise<ExpressBackVO> {
      return httpGet(`/order/v5.0/pt/order-express/action/query-back-express`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 是否已拆单
    */
    queryDemolition(params: { orderId: number }): Promise<boolean> {
      return httpGet(`/order/v5.0/pt/order-express/action/query-demolition`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 物流详情
    */
    queryExpress(params: { orderId: number }): Promise<ExpressVO> {
      return httpGet(`/order/v5.0/pt/order-express/action/query-express`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 商户后台退款物流详情
    */
    queryRefundExpress(params: { refundOrderId: number }): Promise<List<ExpressDetailVO>> {
      return httpGet(`/order/v5.0/pt/order-express/action/query-refund-express`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 商户后台修改物流快递单号
    */
    updateExpressNo(dto: UpdateExpressNoDTO): Promise<boolean> {
      return httpPost(`/order/v5.0/pt/order-express/action/update-express-no`, dto).then((res:any) => res.data.data)
    },
  },
  examinerInfos: {
    /**
    * 体检人入库
    */
    create(examinerInfoVO: ExaminerInfoVO): Promise<number> {
      return httpPost(`/order/v5.0/pt/examiner-infos/action/create`, examinerInfoVO).then((res:any) => res.data.data)
    },
    /**
    * 默认体检人信息
    */
    defaultExaminer(): Promise<ExaminerInfo> {
      return httpGet(`/order/v5.0/pt/examiner-infos/action/default-examiner`).then((res:any) => res.data.data)
    },
    /**
    * 删除体检人信息
    */
    deleteExaminer(params: { kid: number }): Promise<boolean> {
      return httpGet(`/order/v5.0/pt/examiner-infos/action/delete-examiner`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 单个体检人详情
    */
    examinerDetail(params: { openId: string }): Promise<ExaminerInfo> {
      return httpGet(`/order/v5.0/pt/examiner-infos/action/examiner-detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 体检人信息列表
    */
    examinerList(params: { pageNo?: number, pageSize?: number }): Promise<PageList<ExaminerInfo>> {
      return httpGet(`/order/v5.0/pt/examiner-infos/action/examiner-list`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 体检信息完善编辑
    */
    update(examinerInfo: ExaminerInfo): Promise<boolean> {
      return httpPut(`/order/v5.0/pt/examiner-infos/action/update`, examinerInfo).then((res:any) => res.data.data)
    },
  },
  excelExports: {
    /**
    * 合作方导出数据
    */
    export(excelExportRecord: ExcelExportRecord): Promise<boolean> {
      return httpPost(`/order/v5.0/pt/excel-exports/action/export`, excelExportRecord).then((res:any) => res.data.data)
    },
  },
  excelImports: {
    /**
    * 合作方导入数据
    */
    import(params: { createDate?: string, createUserId?: number, importCode?: string, importDesc?: string, importExcel?: string, importParams?: string, importStatus?: number, kid?: number, lastUpdateDate?: string, lastUpdateUserId?: number, resultExcel?: string, withoutTitle?: boolean }): Promise<boolean> {
      return httpPost(`/order/v5.0/pt/excel-imports/action/import`,  {params} ).then((res:any) => res.data.data)
    },
  },
  expertAppointmentOrders: {
    /**
    * 创建预约订单
    */
    createOrder(expertAppointmentOrderDTO: ExpertAppointmentOrderDTO): Promise<OutputPayVO> {
      return httpPost(`/order/v5.0/pt/expert-appointment-orders/action/createOrder`, expertAppointmentOrderDTO).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<ExpertAppointmentOrder>> {
      return httpGet(`/order/v5.0/pt/expert-appointment-orders/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  homeEconomicOrders: {
    /**
    * app删除订单
    */
    deleteByKid(params: { kid: number }): Promise<boolean> {
      return httpGet(`/order/v5.0/pt/home-economic-orders/action/deleteByKid`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app查看详情
    */
    detail(params: { kid: number }): Promise<HomeEconomicOrderVO> {
      return httpGet(`/order/v5.0/pt/home-economic-orders/action/detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app营养师收益详情
    */
    listApp(params: { commodityType: string, pageNo?: number, pageSize?: number }): Promise<PageList<UserProfitDetailVO>> {
      return httpGet(`/order/v5.0/pt/home-economic-orders/action/list-app`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app分页查询用户订单
    */
    listAppUser(params: { pageNo?: number, pageSize?: number }): Promise<PageList<HomeEconomicOrderVO>> {
      return httpGet(`/order/v5.0/pt/home-economic-orders/action/list-app-user`,  {params} ).then((res:any) => res.data.data)
    },
  },
  invoiceDetails: {
    /**
    * 商户是否满足开票金额限制
    */
    canInvoice(params: { merchantId: number }): Promise<boolean> {
      return httpGet(`/order/v5.0/pt/invoice-details/action/canInvoice`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 商户申请结算服务费开票
    */
    createForSettlement(invoiceDetailCreateDTO: InvoiceDetailCreateDTO): Promise<boolean> {
      return httpPost(`/order/v5.0/pt/invoice-details/action/createForSettlement`, invoiceDetailCreateDTO).then((res:any) => res.data.data)
    },
    /**
    * 营养贝开票
    */
    createForShell(invoiceDetailCreateDTO: InvoiceDetailCreateDTO): Promise<boolean> {
      return httpPost(`/order/v5.0/pt/invoice-details/action/createForShell`, invoiceDetailCreateDTO).then((res:any) => res.data.data)
    },
    /**
    * 获取未开发票的商户账期列表
    */
    getShellForInvoiceByMerchant(params: { merchantId: number }): Promise<List<MerchantAccountPeriodInvoiceVO>> {
      return httpGet(`/order/v5.0/pt/invoice-details/action/getShellForInvoiceByMerchant`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端分页查询发票记录
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<InvoiceDetail>> {
      return httpGet(`/order/v5.0/pt/invoice-details/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 商户分页查询发票记录
    */
    listPageMerchant(params: { endDate?: string, invoiceHead?: string, invoiceType?: number, merchantKid: number, pageNo?: number, pageSize?: number, startDate?: string }): Promise<PageList<InvoiceDetail>> {
      return httpGet(`/order/v5.0/pt/invoice-details/action/list-page-merchant`,  {params} ).then((res:any) => res.data.data)
    },
  },
  invoiceProductDetails: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<InvoiceProductDetail>> {
      return httpGet(`/order/v5.0/pt/invoice-product-details/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  medicalAppointmentOrders: {
    /**
    * app取消预约
    */
    cancel(params: { kid: number }): Promise<boolean> {
      return httpGet(`/order/v5.0/pt/medical-appointment-orders/action/cancel`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app改签
    */
    changes(medicalAppointmentOrderChangeDTO: MedicalAppointmentOrderChangeDTO): Promise<boolean> {
      return httpPost(`/order/v5.0/pt/medical-appointment-orders/action/changes`, medicalAppointmentOrderChangeDTO).then((res:any) => res.data.data)
    },
    /**
    * app下单
    */
    create(medicalAppointmentOrderDTO: MedicalAppointmentOrderDTO): Promise<number> {
      return httpPost(`/order/v5.0/pt/medical-appointment-orders/action/create`, medicalAppointmentOrderDTO).then((res:any) => res.data.data)
    },
    /**
    * app删除
    */
    delete(params: { kid: number }): Promise<boolean> {
      return httpGet(`/order/v5.0/pt/medical-appointment-orders/action/delete`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app详情
    */
    detailApp(params: { kid: number }): Promise<MedicalAppointmentOrderAppDetailVO> {
      return httpGet(`/order/v5.0/pt/medical-appointment-orders/action/detail-app`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { orderStaus?: string, pageNo?: number, pageSize?: number }): Promise<PageList<MedicalAppointmentOrderAppVO>> {
      return httpGet(`/order/v5.0/pt/medical-appointment-orders/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app获取支付参数
    */
    pay(params: { kid: number, payChannel: number }): Promise<OutputPayVO> {
      return httpGet(`/order/v5.0/pt/medical-appointment-orders/action/pay`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app查看报告
    */
    showReport(params: { kid: number }): Promise<string> {
      return httpGet(`/order/v5.0/pt/medical-appointment-orders/action/show-report`,  {params} ).then((res:any) => res.data.data)
    },
  },
  myInvoices: {
    /**
    * app新增或者更新发票信息
    */
    addOrUpdate(myInvoice: MyInvoice): Promise<MyInvoice> {
      return httpPost(`/order/v5.0/pt/my-invoices/action/addOrUpdate`, myInvoice).then((res:any) => res.data.data)
    },
    /**
    * app查询我的所有发票
    */
    listApp(): Promise<List<MyInvoice>> {
      return httpGet(`/order/v5.0/pt/my-invoices/action/list-app`).then((res:any) => res.data.data)
    },
    /**
    * 商户查询商户的所有发票配置信息
    */
    listMerchant(params: { userId: number }): Promise<List<MyInvoice>> {
      return httpGet(`/order/v5.0/pt/my-invoices/action/list-merchant`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app通过发票类型查找发票  抬头类型 1.企业 2.个人
    */
    querByTitleType(params: { type?: number }): Promise<MyInvoice> {
      return httpGet(`/order/v5.0/pt/my-invoices/action/querByTitleType`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app通过发票类型查找发票  ,发票类型1.电子普通发票 2.纸质普通发票 3.纸质专用发票
    */
    queryByType(params: { type?: number }): Promise<MyInvoice> {
      return httpGet(`/order/v5.0/pt/my-invoices/action/queryByType`,  {params} ).then((res:any) => res.data.data)
    },
  },
  orderInvoices: {
    /**
    * 商户后台查找订单发票
    */
    listMerchant(dto: OrderInvoiceQueryDTO): Promise<PageList<OrderInvoice>> {
      return httpPut(`/order/v5.0/pt/order-invoices/action/list-merchant`, dto).then((res:any) => res.data.data)
    },
    /**
    * app通过订单id查找订单发票
    */
    queryByOrderKid(params: { orderKid: number }): Promise<OrderInvoice> {
      return httpGet(`/order/v5.0/pt/order-invoices/action/queryByOrderKid`,  {params} ).then((res:any) => res.data.data)
    },
  },
  orderReceivingAddresss: {
    /**
    * 新增OrderReceivingAddress
    */
    post(orderReceivingAddress: OrderReceivingAddress): Promise<boolean> {
      return httpPost(`/order/v5.0/pt/order-receiving-addresss`, orderReceivingAddress).then((res:any) => res.data.data)
    },
    /**
    * 删除OrderReceivingAddress
    */
    delete(deleteDTO: OrderReceivingAddressDeleteDTO): Promise<boolean> {
      return httpDelete(`/order/v5.0/pt/order-receiving-addresss/action/delete`, deleteDTO).then((res:any) => res.data.data)
    },
    /**
    * 查询列表
    */
    listAdmin(): Promise<List<OrderReceivingAddressVO>> {
      return httpGet(`/order/v5.0/pt/order-receiving-addresss/action/list-admin`).then((res:any) => res.data.data)
    },
    /**
    * 设置默认地址OrderReceivingAddress
    */
    updateDefaultFlagById(params: { defaultFlag: number, kid: number }): Promise<boolean> {
      return httpPut(`/order/v5.0/pt/order-receiving-addresss/action/updateDefaultFlagById`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询单个OrderReceivingAddress
    */
    get(params: { kid: number }): Promise<OrderReceivingAddressVO> {
      return httpGet(`/order/v5.0/pt/order-receiving-addresss/${params.kid}`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 编辑OrderReceivingAddress
    */
    put(params: { kid: number }, orderReceivingAddress: OrderReceivingAddress): Promise<boolean> {
      return httpPut(`/order/v5.0/pt/order-receiving-addresss/${params.kid}`, orderReceivingAddress,  {params} ).then((res:any) => res.data.data)
    },
  },
  orders: {
    /**
    * app计算邮费
    */
    calcExpress(calcExpressDTO: CalcExpressDTO): Promise<CalcExpressVO> {
      return httpPut(`/order/v5.0/pt/orders/action/calcExpress`, calcExpressDTO).then((res:any) => res.data.data)
    },
    /**
    * app取消订单
    */
    cancel(params: { orderKid?: number }): Promise<boolean> {
      return httpGet(`/order/v5.0/pt/orders/action/cancel`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 后台发货前校验是否有退款
    */
    check(params: { orderKid: number }): Promise<boolean> {
      return httpGet(`/order/v5.0/pt/orders/action/check`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app确认收货
    */
    confirm(params: { orderKid?: number }): Promise<boolean> {
      return httpGet(`/order/v5.0/pt/orders/action/confirm`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 后台发货
    */
    deliver(orderDeliverDTO: OrderDeliverDTO): Promise<boolean> {
      return httpPost(`/order/v5.0/pt/orders/action/deliver`, orderDeliverDTO).then((res:any) => res.data.data)
    },
    /**
    * 后台查询发货详情
    */
    deliverDetail(params: { orderId: number }): Promise<OrderDeliverDetailVO> {
      return httpGet(`/order/v5.0/pt/orders/action/deliver-detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 后台查询发货列表记录数
    */
    deliverListCount(): Promise<OrderDeliverListCountVO> {
      return httpGet(`/order/v5.0/pt/orders/action/deliver-list-count`).then((res:any) => res.data.data)
    },
    /**
    * app查询订单详情
    */
    detailClient(params: { orderKid: number }): Promise<OrderDetailAppVO> {
      return httpGet(`/order/v5.0/pt/orders/action/detail-client`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 商户后台查询订单详情
    */
    detailMerchant(params: { orderKid: number }): Promise<OrderDetailMerchantVO> {
      return httpGet(`/order/v5.0/pt/orders/action/detail-merchant`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询已买过某个sku的数量
    */
    getBoughtNum(params: { skuId: number }): Promise<number> {
      return httpGet(`/order/v5.0/pt/orders/action/getBoughtNum`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app获取支付参数
    */
    getPayParams(orderPrePayDTO: OrderPrePayDTO): Promise<OutputPayVO> {
      return httpPut(`/order/v5.0/pt/orders/action/getPayParams`, orderPrePayDTO).then((res:any) => res.data.data)
    },
    /**
    * 后台分页查询发货列表
    */
    listAdminBack(orderDeliverListDTO: OrderDeliverListDTO): Promise<PageList<OrderDeliverListVO>> {
      return httpPost(`/order/v5.0/pt/orders/action/list-admin-back`, orderDeliverListDTO).then((res:any) => res.data.data)
    },
    /**
    * app查询订单列表
    */
    listClient(params: { pageNo?: number, pageSize?: number, tableType?: number }): Promise<PageList<OrderListAppVO>> {
      return httpGet(`/order/v5.0/pt/orders/action/list-client`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 商户后台查询订单列表
    */
    listMerchant(orderQueryDTOForMerchant: OrderQueryForMerchantDTO): Promise<PageList<OrderListMerchantVO>> {
      return httpPut(`/order/v5.0/pt/orders/action/list-merchant`, orderQueryDTOForMerchant).then((res:any) => res.data.data)
    },
    /**
    * 商户给订单加备注
    */
    merchantAddRemark(merchantAddOrderRemarkDTO: MerchantAddOrderRemarkDTO): Promise<boolean> {
      return httpPut(`/order/v5.0/pt/orders/action/merchantAddRemark`, merchantAddOrderRemarkDTO).then((res:any) => res.data.data)
    },
    /**
    * 商户修改订单价格
    */
    merchantModifyAmount(merchantModifyAmountDTO: MerchantModifyAmountDTO): Promise<boolean> {
      return httpPut(`/order/v5.0/pt/orders/action/merchantModifyAmount`, merchantModifyAmountDTO).then((res:any) => res.data.data)
    },
    /**
    * app订单确认页数据计算 返回:商家-商家与订单相关的信息
    */
    preSubmitCalc(calcExpressDTO: CalcExpressDTO): Promise<List<KeyValueDTO<long,OrderSubmitConfirmVO>>> {
      return httpPut(`/order/v5.0/pt/orders/action/preSubmitCalc`, calcExpressDTO).then((res:any) => res.data.data)
    },
    /**
    * 后台打印发货单
    */
    printDeliver(ids: number[]): Promise<List<OrderPrintDeliverDetailVO>> {
      return httpPost(`/order/v5.0/pt/orders/action/print-deliver`, ids).then((res:any) => res.data.data)
    },
    /**
    * app删除订单
    */
    removeFinishedOrder(params: { orderKid?: number }): Promise<boolean> {
      return httpGet(`/order/v5.0/pt/orders/action/removeFinishedOrder`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app提交订单评价
    */
    submitEvaluation(userScore: UserScoreDTO): Promise<boolean> {
      return httpPost(`/order/v5.0/pt/orders/action/submitEvaluation`, userScore).then((res:any) => res.data.data)
    },
    /**
    * app下单
    */
    submitOrder(orderSubmitDTO: OrderSubmitDTO): Promise<OrderSubmitVO> {
      return httpPut(`/order/v5.0/pt/orders/action/submitOrder`, orderSubmitDTO).then((res:any) => res.data.data)
    },
  },
  offlineServer: {
    /**
    * 商户核销
    */
    verifyCode(params: { code: string }): Promise<boolean> {
      return httpGet(`/order/v5.0/pt/orders/offline-server/action/verifyCode`,  {params} ).then((res:any) => res.data.data)
    },
  },
  planorder: {
    /**
    * app删除方案订单
    */
    delete(orderKid: KidRequest<long>): Promise<PlanOrder> {
      return httpPut(`/order/v5.0/pt/planorder/action/delete`, orderKid).then((res:any) => res.data.data)
    },
    /**
    * app获取方案订单详情
    */
    detail(params: { planOrderId: number }): Promise<PlanOrderDetailVO> {
      return httpGet(`/order/v5.0/pt/planorder/action/detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app获取方案订单列表
    */
    listPage(pageRequest: PageRequest<boolean>): Promise<PageList<PlanOrder>> {
      return httpPut(`/order/v5.0/pt/planorder/action/listPage`, pageRequest).then((res:any) => res.data.data)
    },
    /**
    * app提交订单并返回支付参数
    */
    submit(planSubmitDTO: PlanSubmitDTO): Promise<PlanOrderSubmitVO> {
      return httpPut(`/order/v5.0/pt/planorder/action/submit`, planSubmitDTO).then((res:any) => res.data.data)
    },
  },
  mgr: {
    /**
    * app获取健康管家订单列表
    */
    listPageMgr(pageRequest: PageRequest<HealthMgrOrder>): Promise<PageList<HealthMgrOrderVO>> {
      return httpPut(`/order/v5.0/pt/planorder/mgr/action/listPageMgr`, pageRequest).then((res:any) => res.data.data)
    },
  },
  oneone: {
    /**
    * app获取营养师一对一订单列表
    */
    listPage(pageRequest: PageRequest<boolean>): Promise<PageList<PlanOneoneOrderVO>> {
      return httpPut(`/order/v5.0/pt/planorder/oneone/action/listPage`, pageRequest).then((res:any) => res.data.data)
    },
  },
  questionnaireOrders: {
    /**
    * 购买问卷
    */
    buy(questionnaireBuyDTO: QuestionnaireBuyDTO): Promise<OutputPayVO> {
      return httpPost(`/order/v5.0/pt/questionnaire-orders/action/buy`, questionnaireBuyDTO).then((res:any) => res.data.data)
    },
    /**
    * 查询购买问卷详情
    */
    detail(params: { orderNo: string }): Promise<QuestionnaireOrderVO> {
      return httpGet(`/order/v5.0/pt/questionnaire-orders/action/detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询购买干预方案订单分页列表
    */
    interventionPlanOrderPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<QuestionnaireOrderVO>> {
      return httpGet(`/order/v5.0/pt/questionnaire-orders/action/intervention-plan-order-page`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询购买问卷分页列表
    */
    orderPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<QuestionnaireOrderVO>> {
      return httpGet(`/order/v5.0/pt/questionnaire-orders/action/order-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  refundExpresss: {
    /**
    * 填写退款物流
    */
    post(refundExpressDTO: RefundExpressDTO): Promise<boolean> {
      return httpPost(`/order/v5.0/pt/refund-expresss`, refundExpressDTO).then((res:any) => res.data.data)
    },
  },
  refundOrderStatusTrails: {
    /**
    * 管理后台查询分页RefundOrderStatusTrail
    */
    listAdmin(params: { orderKid: number }): Promise<List<RefundOrderStatusTrail>> {
      return httpGet(`/order/v5.0/pt/refund-order-status-trails/action/list-admin`,  {params} ).then((res:any) => res.data.data)
    },
  },
  refundOrders: {
    /**
    * 申请退款
    */
    applyRefund(refundOrder: RefundOrderApplyDTO): Promise<string> {
      return httpPost(`/order/v5.0/pt/refund-orders/action/apply-refund`, refundOrder).then((res:any) => res.data.data)
    },
    /**
    * 商家确认收货
    */
    enterApplyRefund(refundOrder: RefundOrderExamineDTO): Promise<boolean> {
      return httpPost(`/order/v5.0/pt/refund-orders/action/enter-apply-refund`, refundOrder).then((res:any) => res.data.data)
    },
    /**
    * 审核申请退款
    */
    examineApplyRefund(refundOrder: RefundOrderExamineDTO): Promise<boolean> {
      return httpPost(`/order/v5.0/pt/refund-orders/action/examine-apply-refund`, refundOrder).then((res:any) => res.data.data)
    },
    /**
    * 商户后台查询分页
    */
    listAdmin(refundOrderDTO: RefundOrderQueryDTO): Promise<PageList<RefundOrderListVO>> {
      return httpPost(`/order/v5.0/pt/refund-orders/action/list-admin`, refundOrderDTO).then((res:any) => res.data.data)
    },
    /**
    * app退款订单详情
    */
    refundDetailApp(params: { refundKid: number }): Promise<RefundOrderDetailAppVO> {
      return httpGet(`/order/v5.0/pt/refund-orders/action/refund-detail-app`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app退款订单详情
    */
    refundDetailAppNo(params: { refundNo: string }): Promise<RefundOrderDetailAppVO> {
      return httpGet(`/order/v5.0/pt/refund-orders/action/refund-detail-app-no`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 退款入口选择
    */
    refundEntry(params: { orderId: number }): Promise<RefundEntryVO> {
      return httpGet(`/order/v5.0/pt/refund-orders/action/refund-entry`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app退款订单列表
    */
    refundListApp(params: { pageNo: number, pageSize: number }): Promise<PageList<RefundOrderListAppVO>> {
      return httpGet(`/order/v5.0/pt/refund-orders/action/refund-list-app`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 撤销申请退款
    */
    revokeApplyRefund(params: { refundOrderKid: number }): Promise<boolean> {
      return httpGet(`/order/v5.0/pt/refund-orders/action/revoke-apply-refund`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 商户后台查询详情RefundOrder（通过退款编号查询）
    */
    selectDetailByNo(params: { no: string }): Promise<RefundOrderDetailVO> {
      return httpGet(`/order/v5.0/pt/refund-orders/action/selectDetailByNo`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 商户后台查询详情
    */
    get(params: { kid: number }): Promise<RefundOrderDetailVO> {
      return httpGet(`/order/v5.0/pt/refund-orders/${params.kid}`,  {params} ).then((res:any) => res.data.data)
    },
  },
  shellRechargeOrders: {
    /**
    * 用户是否满足开票金额限制
    */
    canInvoice(): Promise<boolean> {
      return httpGet(`/order/v5.0/pt/shell-recharge-orders/action/canInvoice`).then((res:any) => res.data.data)
    },
    /**
    * App创建营养贝充值订单并返回支付参数
    */
    create(shellOrderPrePayDTO: ShellOrderPrePayDTO): Promise<OutputPayVO> {
      return httpPost(`/order/v5.0/pt/shell-recharge-orders/action/create`, shellOrderPrePayDTO).then((res:any) => res.data.data)
    },
    /**
    * App获取营养贝面值列表
    */
    getAllShellValue(): Promise<List<ShellValueVO>> {
      return httpGet(`/order/v5.0/pt/shell-recharge-orders/action/getAllShellValue`).then((res:any) => res.data.data)
    },
    /**
    * App获取未开发票的营养贝充值列表
    */
    getShellForInvoiceByUserId(): Promise<List<ShellRechargeOrder>> {
      return httpGet(`/order/v5.0/pt/shell-recharge-orders/action/getShellForInvoiceByUserId`).then((res:any) => res.data.data)
    },
    /**
    * 用户可开票金额总和
    */
    getTotalInvoice(): Promise<number> {
      return httpGet(`/order/v5.0/pt/shell-recharge-orders/action/getTotalInvoice`).then((res:any) => res.data.data)
    },
  },
  vipTroughTrainOrder: {
    /**
    *  购买会员直通车
    */
    buy(vipThroughTrainOrderSubmitDTO: VIPThroughTrainOrderSubmitDTO): Promise<OutputPayVO> {
      return httpPost(`/order/v5.0/pt/vip-trough-train-order/action/buy`, vipThroughTrainOrderSubmitDTO).then((res:any) => res.data.data)
    },
    /**
    * 会员直通车订单列表
    */
    list(params: { pageNo?: number, pageSize?: number }): Promise<PageList<VIPThroughTrainOrderVO>> {
      return httpGet(`/order/v5.0/pt/vip-trough-train-order/action/list`,  {params} ).then((res:any) => res.data.data)
    },
  },
  virtualCommodityOrderDetails: {
    /**
    * app讲师收益详情
    */
    listApp(params: { courseType: string, pageNo?: number, pageSize?: number }): Promise<PageList<LecturerProfitDetailVO>> {
      return httpGet(`/order/v5.0/pt/virtual-commodity-order-details/action/list-app`,  {params} ).then((res:any) => res.data.data)
    },
  },
  virtualCommodityOrders: {
    /**
    * 购买课程
    */
    buyCourse(dto: CourseBuyDTO): Promise<OutputPayVO> {
      return httpPost(`/order/v5.0/pt/virtual-commodity-orders/action/buyCourse`, dto).then((res:any) => res.data.data)
    },
    /**
    * 购买营养干预方案
    */
    buyInterventionPlan(dto: InterventionPlanDTO): Promise<OutputPayVO> {
      return httpPost(`/order/v5.0/pt/virtual-commodity-orders/action/buyInterventionPlan`, dto).then((res:any) => res.data.data)
    },
    /**
    * 购买餐桌看营养报告
    */
    buyTableNutrition(dto: TableReportBuyDTO): Promise<OutputPayVO> {
      return httpPost(`/order/v5.0/pt/virtual-commodity-orders/action/buyTableNutrition`, dto).then((res:any) => res.data.data)
    },
    getLecturerOrderSum(): Promise<number> {
      return httpGet(`/order/v5.0/pt/virtual-commodity-orders/action/getLecturerOrderSum`).then((res:any) => res.data.data)
    },
  },
}