import React, { useState, useEffect } from 'react'
// import nutrition from 'api/nutrition'
import { Button } from 'antd-mobile'
import './insu-secu.css'
import Nav from 'components/nav/Nav';
import isIphoneX from 'utils/isIphoneX';

interface InfoBrif {
	category: string
	summary: string[]

}

const InsuranceCareerDanger: PageComponent = props => {
  // const [result, setSesult] = useState({})
  useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
		// console.log('result - :',result)
		window.scrollTo(0, 0)
		// document.body.scrollTo({ top: 0, left: 0 })
		// document.body.scrollTop = document.documentElement.scrollTop = 0
	}, [])
	/**
	 */
	const info:InfoBrif[] = [
		{ category: '农牧渔业', summary: ['猛兽饲养工(动物园)、有毒动物饲养工(蛇、蝎子、蜈蚣等)、捕鱼人(内陆)、捕鱼人(沿海)养殖工人(沿海)、远洋渔 船船员、近海渔船船员 '] },
		{ category: '木材森林业', summary: ['伐木工人、锯木工人、运材车辆之司机及押运人员、起重机之操作人员、装运工人、挂钩工人、锯木工人、木材搬运工人、森林防火人员、拖拉机驾驶员、联合收割机驾驶员、农用运输车驾驶员、农用机械操作及修理人员、沼气工程 施工人员 ' ]},
		{ category: '矿业采掘业', summary: ['矿工、采掘工、爆破工、工人、海上所有作业人员、潜水人员、采石业工人、采砂业工人、陆上油矿开采技术员、油 气井清洁保养修护工、钻勘设备安装换修保养工、钻油井工人 '] },
		{ category: '交通运输业', summary: ['陆运:铁牛车驾驶、混凝土预拌车驾驶员、机动三轮车夫、货车司机、随车工人、搬运工人、装卸工人、矿石车司机、 随车工人、工程卡车司机、随车人员','液化、氧化油罐车司机、随车工人、铁路货运、铁路搬运工人 ','客货轮(沿海):船长、水手长、水手、铜匠、木匠、泵匠、电机师、厨师、服务生、实习生、游览船/小气艇之驾 驶及工作人员、救难船员','客货轮(内河):船长、水手长、水手、铜匠、木匠、泵匠、电机师、厨师、服务生、实习生','客货轮(远洋)：所有随船人员','空运:民航机飞行人员、机上服务员、直升机飞行人员'] },
		{category:'建筑工程', summary:['建筑公司:钢骨结构工人、鹰架架设工人、铁工、焊工、建筑工程机械操作员、金属门窗装修工人、拆屋、迁屋工人、 凿岩工、装饰装修工(室外)(基础装修至毛坯)','铁路公路铺设:现场勘测人员(山区)、工程机械操作员、工程车辆驾驶员、铺设工人(山地)、维护工人、电线架设 及维护工人、高速公路工程人员(含美化人员)','筑路、养护工、铁路舟桥工、道岔制修工','造修船业:工人、拆船工人','电梯、升降机:安装工人、电梯、升降机修理及维护工人','装璜:室内装璜人员(不含木工、油漆工)、室外装璜人员、金属门窗制造、装修工人','安装玻璃幕墙工人、钢结构安装工、中央空调系统安装及维护人员 地质探测员(山区)、地质探测员(海上)、海湾港口工程人员、水坝工程人员、挖井工程人员、桥梁工程人员、隧 道工程人员、潜水工作人员、爆破工作人员、挖泥船工人','测绘工程:海洋测绘工程技术人员(海上作业)']}
	]


	const renderItem = (item:InfoBrif,index:number) => {
		return <div className={index%2==0?'fee-row-l':''} key={'sec'+index} style={{display:'flex', flexDirection:"row"}}>
		<span className='career-row'>{item.category}</span>
			<div className='career-row-detail' >
				{item.summary.map((it) => {
					return <div ><span style={{fontWeight:'bold'}}>·</span>&nbsp;{it}</div>
				})}
			</div>
	</div>
	}


	const renderColumnHead = () => {
		// return <div className='fee-head'></div>
		return <div style={{display:'flex', flexDirection:"row"}}>
			<div className='fee-head'>
			<span>分类</span>
			</div>
			<div className='fee-head'>
			<span>职业</span>
			</div>
		</div>
	}

	return (
		<div style={{flex:1,position:'relative',display:'flex',flexDirection:'column'}}>
			<Nav title='危险职业表' style={{position:"fixed",backgroundColor:'#fff',padding:0,...{paddingTop:!!isIphoneX()?'0.88rem':'0.4rem'}}}></Nav>
			
			<div className='insufee-wrap'>
<div className='nav-padding'></div>
			<div className='career-title'>
			泰康在线高危职业表
			</div>
			{info.map(renderItem)}

		</div>
		</div>

		
    // <div className="demo-wrap">
    //   <div className="title">demo1</div>
    //   {/* <div>{JSON.stringify(result)}</div> */}
    //   <Button type="primary" size="small" inline className="btn">
    //     demo1
    //   </Button>
    //   <Button type="primary" size="large" className="btn">
    //     demo2
    //   </Button>
    // </div>
  )
}
// InsuranceSecurePlan.options = { disableHeader: false, wrapperClassName: '' }
export default InsuranceCareerDanger
