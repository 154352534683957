import React, { useState, useEffect } from 'react'
// import nutrition from 'api/nutrition'
import { Button } from 'antd-mobile'
import './insu-licence.css'
import Nav from 'components/nav/Nav';
import isIphoneX from 'utils/isIphoneX';

interface ClaimsItem {
	section: string,
	brife?: string[],
	extra?:{q:string,a:string}[]
}
/**
 * type ：taikang、renbao, 
 * @param props 
 */

const InsuranceCustomerToKnown: PageComponent = props => {
  
	useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
		// console.log('result - :',result)
		window.scrollTo(0, 0)
		// document.body.scrollTo({top:0,left:0})
	}, [])

	
	return (
		<div style={{flex:1,position:'relative',padding:0,...{paddingTop:!!isIphoneX()?'0.88rem':'0.4rem'}}}>
			<Nav title="" style={{position:'fixed',border:'none'}}></Nav>
		<div className='insulic-wrap'>		
				<div className='licenceTitle center'> 客户告知书</div>
				<div className='licenceSection'>尊敬的客户：</div>
				<div className='licenceContent'>感谢您选择委托中元保险经纪有限公司为保险经纪人，协助您办理相关保险业务。为了您的合法权益得到有效保护，按照监管规定的要求，本公司履行客户告知义务，现将有关事项告知如下，请您认真阅读确认知悉以下内容：</div>
				<div className='licenceSection'>
				一、请您了解我公司基本情况
				</div>
				<div className='licenceContent'>
					<div className='indent'>（一）公司名称：中元保险经纪有限公司</div>
					<div className='indent'>（二）注册地址：中国（上海）自由贸易试验区张杨路620号1206室</div>
					<div className='indent'>（三）业务范围：在全国区域范围内为投保人拟定投保方案、选择保险人、办理投保手续；协助被保险人或受益人进行索赔；中国保监会批准的其他业务。</div>
					<div className='indent'>（四）联系方式：021-58700207</div>
					<div className='indent'>（五）我公司为投保人与保险公司订立保险合同提供经纪服务，并根据保险监管部门规定及双方合同约定，依法向保险公司收取佣金。</div>
					<div className='indent'>（六）根据保险监管部门规定，我司高级管理人员在任职前均取得中国保监会派出机构核准的任职资格；我司及我司的高级管理人员与经纪业务相关的保险公司、其他保险中介机构不存在关联关系。</div>
				</div>
				<div className='licenceSection'>
				二、请您客观评估自身的保险产品消费需求和缴费能力
				</div>
				<div className='licenceContent'>
					<div className='indent'>根据保险监管部门规定，保险销售人员不得通过不确定经济利益的方式推销保险产品，不得做出超出生效的书面保险合同责任范围的任何其他承诺。因此，请您客观评估自身的保险产品消费需求和缴费能力，根据自身实际的风险保障需求和经纪实力选购合适的保险产品。</div>
					
				</div>
				<div className='licenceSection'>
				三、请您了解投诉渠道及纠纷解决方式
				</div>
				<div className='licenceContent'>
					<div className='indent'>我公司在客户办理投保、保全、理赔手续过程中提供指导和协助，为客户提供服务。对于您擅自委托销售人员代办超出我公司授权范围业务所产生的后果，我公司不承担任何经济和法律责任。如果您认为我公司服务人员存在销售误导或其他损害您合法权益的行为，您可拨打客服电话021-58700207进行投诉。</div>
					
				</div>
				<div className='licenceSection'>
				四、请您详细了解保险产品、如实填写投保资料
				</div>
				<div className='licenceContent'>
					<div className='indent'>请您确认已仔细阅读保险条款，了解保险责任、责任免除、被保险人权利义务、免赔额或免赔率的计算、犹豫期解除合同、退保损失等重点内容。同时您可以要求工作人员对上述内容进行详细讲解。依据《保险法》，您作为投保人有如实告知义务。为了有效保障自身权益，您在投保时所录入的内容必须属实；因未履行如实告知产生的保险纠纷和纷争，由您个人承担结果。依法保护客户权益是我公司的神圣责任，更需要您的积极参与和认真配合。</div>
					
				</div>
				<div className='licenceContent right'>中元保险经纪有限公司</div>
		</div>
		</div>
  )
}
// InsuranceSecurePlan.options = { disableHeader: false, wrapperClassName: '' }
export default InsuranceCustomerToKnown
