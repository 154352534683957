import React from 'react'
import './footer.css'
import { defaultProps } from 'antd-mobile/lib/search-bar/PropsType'

const Footer: PageComponent = ({ onClick }) => {
  return (
		<div className="footer-wrap" onClick={() => { 
			onClick && onClick()
		}}>
      <div className="btnOpen">
        打开App
      </div>
    </div>
  )
}
export default Footer
