import GroupMainPage from './GroupMain'

const GroupMain: Module = {
    name: 'groupmain',
    routes: [
        {
            name: '直播详情',
            path: '/groupmain/share/:kid',
            component: GroupMainPage,
        },
    ],
}
export default GroupMain