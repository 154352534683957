import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'
const asyncMallHotListInfiniteScroll = Loadable({
    loader: () => import('./MallHotListInfiniteScroll'),
    loading: MyLoadingComponent,
})
const asyncNaoHot = Loadable({
    loader: () => import('./naohot'),
    loading: MyLoadingComponent,
})
const asyncHighBlood = Loadable({
    loader: () => import('./highBlood'),
    loading: MyLoadingComponent,
})
const asyncWinterHeath = Loadable({
    loader: () => import('./winterHeath'),
    loading: MyLoadingComponent,
})
const MallHot: Module = {
    name: 'mallhot',
    routes: [
        {
            name: '商城热门产品',
            path: '/mall/hotinfinit/:type',
            component: asyncMallHotListInfiniteScroll,
        },
        {
            name: '脑卒中',
            path: '/mall/brain/:kid',
            component: asyncNaoHot,
        },
        {
            name: '高血压',
            path: '/mall/highBlood/:kid',
            component: asyncHighBlood,
        },
        {
            name: '冬季养生',
            path: '/mall/winterHeath/:kid',
            component: asyncWinterHeath,
        },
    ],
}
export default MallHot