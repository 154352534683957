import React, { useState, useEffect } from 'react'
import { Toast } from 'antd-mobile';
import './downloadapp.css';
import env from '../../constants/env.json'
import URI from 'urijs'
const ExpectApp: PageComponent =  props => {
    useEffect(() => {
       let query: any = URI(window.location.href).query(true);
       const { channel } = query
       if (channel) {
           (window as any).gio('track', 'download_expect_url', { activity_channel: channel})
       }

       console.log('env  - :', env.env);
        // if (env.env == 'test') {
        //     // iOS  https://www.pgyer.com/KgD8?sig=1o2Q6upve01aKAEnoKi34BEaRLmtHCXNcCW1YeQ1jQMx7s7GF1ILkO6UkFRdE4DU
        //     //     Android：https://apk.lajsf.com/test/test-release--3.0.0-9765.apk
        //     console.log('进来了 - - : test 地址 : ');
        //     if (isIOS()) {
        //       window.location.href = 'https://www.pgyer.com/KgD8?sig=1o2Q6upve01aKAEnoKi34BEaRLmtHCXNcCW1YeQ1jQMx7s7GF1ILkO6UkFRdE4DU'
        //     }
        //     if (isAndroid()) {
        //       window.location.href = 'https://apk.lajsf.com/test/test-release--3.0.0-9765.apk'
        //     }
        // } else if (env.env == 'mo') {
        //     if (isIOS()) {
        //         // window.location.href = 'https://url.cn/5I5SAca';
        //         window.location.href =  'https://www.pgyer.com/4d6b9f06b41718bb2b11737f963e017f';
        //     }
        //
        // } else {
            if (isIOS()) {
              window.location.href = 'https://itunes.apple.com/cn/app/id1479391612?ls=1&mt=8'
            }
            if (isAndroid()) {
              window.location.href = 'https://m.lajsf.com/download'
            }
        // }
    }, [])
    const isIOS = () => {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false;
    }
    const isAndroid = () => {
        return navigator.userAgent.match(/Android/i) ? true : false;
    }
    return (
        <div className="DownLoadAppPage">
            <div className="cont">
                {/*<img className="logo-img" src="http://cdn.lajsf.com/nutrition-plan/image/default/static/icon.png" alt=""/>*/}
            </div>
        </div>
    )
}
ExpectApp.options = { disableHeader: false, wrapperClassName: '' }
export default ExpectApp