import React, { useState, useMemo, useEffect, useContext } from 'react'

import { Carousel, WingBlank } from 'antd-mobile'
import { ProductContext } from '../ProductDetail'
import './product.css'

const GoodCarousel: React.FC<{}> = props => {
  const productContext = useContext(ProductContext)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  console.log('productContext', productContext)

  const goodList = useMemo(() => {
    let pics = productContext.goods
    if (!pics) return
    let imgs = pics.detailShowPic || ''
    return imgs.split(',')
  }, [productContext.goods])

  let goodVideo = useMemo(() => {
    let videoSrc = productContext.goods && productContext.goods.productVideo ? productContext.goods.productVideo : {}
    return videoSrc
  }, [productContext.goods])

  const bannerList = useMemo(() => {
    if (goodVideo.video) {
      return [goodVideo, ...goodList]
    }
    return goodList
  }, [goodList, goodVideo])

  if (!bannerList) {
    return null
  }
  return (
    <WingBlank>
      <Carousel
        className="good-carousel"
        dots={false}
        selectedIndex={currentSlideIndex}
        afterChange={index => {
          setCurrentSlideIndex(index)
        }}>
        {bannerList &&
          bannerList.map((val: any, index: number) => {
            return val.video ? (
              <video poster={val && val.coverImgUrl} controls className="video-play" src={val.video}></video>
            ) : (
              <img
                src={val}
                alt=""
                key={`banner${index}`}
                style={{ width: '100%', height: '7.5rem', verticalAlign: 'top' }}
              />
            )
          })}
      </Carousel>
    </WingBlank>
  )
}
export default GoodCarousel
