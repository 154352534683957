import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'
const asyncVipFree = Loadable({
	loader: () => import('./VipFree'),
	loading: MyLoadingComponent
})
const VipFree: Module = {
	name: 'branstory',
	routes: [
		{
			name: '下载落地页',
			path: '/vipFreeActivity',
			component: asyncVipFree,
		},
	],
}
export default VipFree
