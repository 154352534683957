import React, { useState, useEffect } from 'react'
import { Toast, Tabs, WhiteSpace } from 'antd-mobile';
import Header from 'components/Header'
import Footer from 'components/Footer'
import DownPop from 'components/DownPop'
import './three.css'

const ThreeReducePage: PageComponent =  props => {
    const tabs = [
        { title: '减盐' },
        { title: '减油' },
        { title: '减糖' },
        { title: '健口腔' },
        { title: '健体重' },
        { title: '健骨骼' },
    ];
    const [showPop, setShowPop] = useState(false)
    const openQuestion = () => {
        setShowPop(true)
    }
    useEffect(() => {

    },[showPop])
    return (
        <div className="ThreeReduce">
            <div className="top-infor">
                <Tabs tabs={tabs}
                      initialPage={0}
                      onChange={(tab, index) => { console.log('onChange', index, tab); }}
                      onTabClick={(tab, index) => { console.log('onTabClick', index, tab); }}>
                    <div className='content-item'>

                        <div className="part">
                            <div className="tip-top">一、为什么要“减盐”</div>
                            <div className="section">高盐、高脂、高糖等不健康饮食是引起肥胖、心血管疾病、糖尿病及其他代谢性疾病和肿瘤的危险因素。2016年全球疾病负担研究结果显示，饮食因素导致的疾病负担占到15.9%，已成为影响人群健康的重要危险因素。2012年全国18岁及以上成人超重率为30.1%，肥胖率为11.9%，与2002年相比分别增长了32.0%和67.6%；6～17岁儿童青少年超重率为9.6%，肥胖率为6.4%，与2002年相比分别增加了1倍和2倍。</div>
                            <div className="section">食盐是烹饪中最常见的调味品，主要化学成为氯化钠，钠是人体中一种重要无机元素，与人类健康密切相关。目前研究结果显示高盐摄入能够增加高血压、脑卒中、骨质疏松、肥胖、胃病和心血管病等疾病的发病风险。目前我国18 岁及以上居民平均每日盐摄入量约为10.5 克，《中国居民膳食指南》推荐健康成人每人每天食盐摄入量不超过6克；2～3岁幼儿摄入量不超过2克；4～6岁幼儿不超过3克；7～10岁儿童不超过4克；65岁以上老人不超过5克。</div>
                        </div>
                        <div className="part">
                            <div className="tip-top">二、如何“减盐”</div>
                            <div className="section">1. 采取总量控制，使用限盐勺控制用盐量，少放5%-10%的盐并不会影响菜肴的口味。
                            </div>
                            <div className="section">2.少吃榨菜、咸菜和酱制食物，建议每餐都有新鲜的蔬菜和水果。
                            </div>
                            <div className="section">3.少吃熟食，多吃新鲜食物。熟食肉类、午餐肉、香肠和罐头食品等含盐量高，建议选择新鲜的肉类、海鲜和蛋类。
                            </div>
                            <div className="section">4.逐渐减少钠盐摄入。减盐需要循序渐进，味觉对咸味的需求会随着时间的推移逐渐降低。纠正过咸口味，可以使用醋、柠檬汁、香料、姜等调味品，提高菜肴鲜味。
                            </div>
                            <div className="section">5.学会阅读营养成分表。在超市购买食品时，尽可能选择钠盐含量较低的包装食品，以及具有“低盐”、“少盐”或“无盐”标识的食品。
                            </div>
                            <div className="section">6.外出就餐选择低盐菜品。尽可能减少外出就餐，主动要求餐馆少放盐，尽量选择低盐菜品。
                            </div>
                            <div className="section">7.选低盐调味品。建议选择低盐酱油，减少味精、鸡精、豆瓣酱、沙拉酱和调料包的用量，可以使用醋、柠檬汁、香料、姜等调味品，提高菜肴鲜味。
                            </div>
                            <div className="section">8. 学会看食品标签，警惕“藏起来”的盐。一些方便食品和零食里虽然尝起来感觉不到咸味，但其实都含有较多的隐形盐，建议少吃此类加工食品。
                            </div>
                        </div>
                        <div className="part">
                            <div className="tip-top">三、如何识别“高盐”食品？</div>
                            <div className="section">2013年1月1日起，我国首个食品营养标签国家标准《预包装食品营养标签通则》正式实施。根据规定，预包装食品都必须在包装上添加营养标签，否则不得销售。</div>
                            <img src={require('../../assets/image/threereduce/three-reduce-01.png')} alt=""/>
                            <div className="section">消费者在购买预包装食品时首先要注意NVR值，即该产品中各营养素的含量占其营养素参考值的百分比。钠的NVR值大小可作为该食品中盐的含量，钠的NVR值越大则钠含量越高。如：100克坚果中的钠含量为200毫克，钠的NVR的百分比为10%，则可以认为，如果吃了100克这个产品，大概能满足一天一个成人关于盐的需求的10%，如果再吃其他含钠的食物就应适当控制了。</div>
                        </div>
                    </div>
                    <div className='content-item'>
                        <div className="part">
                            <div className="tip-top">一、为什么要“减油”</div>
                            <div className="section">高盐、高脂、高糖等不健康饮食是引起肥胖、心血管疾病、糖尿病及其他代谢性疾病和肿瘤的危险因素。2016年全球疾病负担研究结果显示，饮食因素导致的疾病负担占到15.9%，已成为影响人群健康的重要危险因素。2012年全国18岁及以上成人超重率为30.1%，肥胖率为11.9%，与2002年相比分别增长了32.0%和67.6%；6～17岁儿童青少年超重率为9.6%，肥胖率为6.4%，与2002年相比分别增加了1倍和2倍。</div>
                            <div className="section">食用油不仅是人们日常生活中炒菜做饭的必备食材，而且是人体脂肪酸的主要来源，是人体保持健康所必需的基本营养素之一。食用油包括植物油和动物油。过多脂肪摄入会导致肥胖，摄入过多反式脂肪酸会增高心血管疾病的发生风险。目前《中国居民膳食指南（2016）》推荐食用油是每天每人25-30 克，但调查显示，目前我国平均每天每人摄入42克，全国约80%家庭使用食用油时超标。</div>
                        </div>
                        <div className="part">
                            <div className="tip-top">二、如何“减油”</div>
                            1、科学认识烹饪用油。烹饪用油有助于食物中脂溶性维生素的吸收利用，是人体必需的脂肪酸和维生素E的重要来源，但过多脂肪摄入会增加糖尿病、高血压、血脂异常、动脉粥样硬化、冠心病等慢性疾病的发生风险。
                             <div className="section"></div>
                            2、控制烹饪用油摄入量。《中国居民膳食指南》推荐，健康成年人每人每天烹饪用油用量不超过25-30克。
                            <div className="section"></div>
                            3、学会使用控油壶。可把全家每天应食用的烹饪用油倒入带有刻度的控油壶，炒菜用油时均从控油壶中取出，坚持定量用油，以控制每日用油总量。
                            <div className="section"></div>
                            4、调整烹饪方法。烹饪食物时多选择如蒸、煮、炖、焖、水滑熘、凉拌、急火快炒等烹饪方法，少用煎、炸等方法烹饪食物。
                            <div className="section"></div>
                            5、少吃油炸食品。不吃或少吃如炸鸡腿、炸薯条、炸鸡翅、油条油饼等油炸食品。在外就餐时主动要求餐馆少放油，少点油炸类菜品。
                            <div className="section"></div>
                            6、少吃动物性脂肪，可用植物性油代替，食用植物性油时建议不同种类交替使用。
                            <div className="section"></div>
                            7、限制反式脂肪酸摄入。少吃含“部分氢化植物油”“起酥油”“奶精”“植脂末”“人造奶油”等的预包装食品，建议每日反式脂肪酸摄入量不超过2克。
                            <div className="section"></div>
                            8、不喝菜汤。烹饪食物时一部分油脂会留在菜汤里，建议不要喝菜汤或用菜汤泡饭吃。
                            <div className="section"></div>
                            9、关注食品营养成分表。学会阅读营养成分表，在超市购买食品时，选择含油脂低，不含反式脂肪酸的食物。
                            <div className="section"></div>
                            10、减少吃外卖和在外就餐的机会。
                            <div className="section"></div>
                        </div>
                        <div className="part">
                            <div className="tip-top">三、如何识别“高油”食品？</div>
                            <div className="section">从2013年1月1日起，我国首个食品营养标签国家标准《预包装食品营养标签通则》正式实施。根据规定，预包装食品都必须在包装上添加营养标签，否则不得销售。食品配料一般以加入量比例的多少由大到小（递减顺序）排列。也就是说，排在第一位的加入量最多，排在第二位的加入量第二多，以此类推。如该图中排名第三的就是食用植物油，提示该食品烹饪用油含量较高。其他常见烹饪用油还包括：大豆油、棕榈油等。</div>
                            <img src={require('../../assets/image/threereduce/three-reduce-02.png')} alt=""/>
                        </div>
                    </div>
                    <div className='content-item'>
                        <div className="part">
                            <div className="tip-top">一、为什么要“减糖”？</div>
                            <div className="section">高盐、高脂、高糖等不健康饮食是引起肥胖、心脑血管疾病、糖尿病及其他代谢性疾病和肿瘤的危险因素。2016年全球疾病负担研究结果显示，饮食因素导致的疾病负担占到15.9%，已成为影响人群健康的重要危险因素。2012年全国18岁及以上成人超重率为30.1%，肥胖率为11.9%，与2002年相比分别增长了32.0%和67.6%；6～17岁儿童青少年超重率为9.6%，肥胖率为6.4%，与2002年相比分别增加了1倍和2倍。</div>
                            <div className="section">糖通常是指添加糖或游离糖。添加糖是指包括由生产商、厨师或消费者在食品中添加的单糖、双糖以及天然存在于蜂蜜、糖浆、果汁和浓缩果汁中的糖分。研究表明，过多摄入添加糖可增加龋齿的发病风险，也是直接造成2型糖尿病、高血脂、高血压、心血管病的危险因素。过多摄入含糖饮料可增加龋齿和肥胖的发病风险。从总消耗量来看，我国人均目前糖摄入量并不高，2017年我国居民人均糖摄入量约为每日30克。但是，近些年我国居民受西方生活方式和饮食影响越来越大，吃得越来越甜。另外，近几年来我国含糖饮料的消费增长非常快，其中，3-17 岁儿童饮料的消费量最高。《中国居民膳食指南（2016）》建议每人每天添加糖摄入量不超过50克，最好控制在25克以下。</div>
                        </div>
                        <div className="part">
                            <div className="tip-top">二、如何“减糖”？</div>
                            1、减糖应减添加糖。各类人群均应减少添加糖（或称游离糖）的摄入，但不包括天然水果中含的糖和主食中的天然碳水化合物。
                            <div className="section"></div>
                            2、认识添加糖。添加糖是指人工加入到食品中的糖类，具有甜味特征，包括单糖和双糖。常见的有蔗糖、果糖、葡萄糖等。日常生活的白砂糖、绵白糖、冰糖、红糖都是蔗糖。
                            <div className="section"></div>
                            3、儿童青少年不喝或少喝含糖饮料。含糖饮料是儿童青少年摄入添加糖的主要来源，建议不喝或少喝含糖饮料。
                            <div className="section"></div>
                            4、婴幼儿食品无需添加糖。婴幼儿建议喝白开水为主，制作辅食时，也应避免人为添加糖。
                            <div className="section"></div>
                            5、减少食用高糖类包装食品。建议减少饼干、冰淇淋、巧克力、糖果、糕点、蜜饯、果酱等在加工过程添加糖的包装食品摄入频率。
                            <div className="section"></div>
                            6、烹饪过程少加糖。家庭烹饪过程少放糖，尝试用辣椒、大蒜、醋和胡椒等为食物提味以取代糖，减少味蕾对甜味的依赖。
                            <div className="section"></div>
                            7、外出就餐科学点菜。在外就餐时少吃糖醋排骨、鱼香肉丝、红烧肉、拔丝地瓜、甜汤等含糖较多的菜品。
                            <div className="section"></div>
                            8、用白开水代替饮料。人体补充水分的最好方式是饮用白开水，在温和气候条件下，成年男性每日最少饮用1700毫升（约8.5杯）水，女性最少饮用1500毫升（约7.5杯）水。
                            <div className="section"></div>
                        </div>
                        <div className="part">
                            <div className="tip-top">三、如何识别“高糖”食品？</div>
                            <div className="section">从2013年1月1日起，我国首个食品营养标签国家标准《预包装食品营养标签通则》正式实施。根据规定，预包装食品都必须在包装上添加营养标签，否则不得销售。食品配料一般以加入量比例的多少由大到小（递减顺序）排列。也就是说，排在第一位的加入量最多，排在第二位的加入量第二多，以此类推。如该图中排名第二的就是白砂糖，提示该食品添加糖含量较高。其他常见添加糖还包括：绵白糖、冰糖、红糖、蔗糖、果糖、葡萄糖等。</div>
                            <img src={require('../../assets/image/threereduce/three-reduce-03.png')} alt=""/>
                        </div>
                    </div>
                    <div className='content-item'>
                        <div className="part">
                            <div className="tip-top">一、为什么提倡“健口腔”？</div>
                            <div className="section">口腔健康是人体健康的重要组成部分。世界卫生组织（WHO）将牙齿健康确定为人体健康十大标准之一。牙齿健康的标准是：牙齿整洁、无龋齿、无痛感，牙龈色泽正常，无出血现象。由此可见，口腔健康是指具有良好的口腔卫生，健全的口腔功能及没有口腔疾病。目前口腔健康问题最多的是儿童和老年人，它和其他慢性病密切相关。</div>
                            <div className="section">口腔中的感染和炎症因子可导致或加剧心脑血管病、糖尿病等慢性病，危害全身健康，影响生命质量；龋齿和牙周病会破坏牙齿硬组织和牙齿周围支持组织，不仅影响咀嚼、言语、美观等功能，还会造成社会交往困难和心理障碍。</div>
                        </div>
                        <div className="part">
                            <div className="tip-top">二、如何“健口腔”？</div>
                            （一）普通群人
                            1、关注口腔健康。
                            <div className="section">龋齿和牙周疾病是最常见的口腔疾病，通过自我口腔保健和专业口腔保健清除牙菌斑是维护口腔健康的基础。</div>
                            2、定期进行口腔检查。
                            <div className="section">建议成年人每年口腔检查至少一次。</div>
                            3、定期清洁牙、保持牙周健康。
                            <div className="section">定期洁牙能够保持牙周健康。建议每年定期洁牙（洗牙）一次。</div>
                            4、坚持早晚刷牙和饭后漱口。
                            <div className="section">坚持做到每天至少刷牙两次，晚上睡前刷牙尤为重要。坚持饭后漱口。吃任何东西后都应及时漱口，或咀嚼无糖口香糖。</div>
                            5、做到一人一刷一口杯。
                            <div className="section">应根据家庭各成员年龄和健康状况的不同，选择适合各人需要的牙刷和牙膏，不可共用牙刷和漱口杯，避免疾病的交互感染。</div>
                            6、提倡使用牙线清洁牙间隙。
                            <div className="section">建议刷牙后配合使用压线或牙缝刷等工具辅助清洁。使用时应注意，若龈乳头无退缩、插入有困难时，不要勉强进入，以免损伤牙龈。</div>
                            7、提倡使用含氟牙膏预防龋齿。
                            <div className="section">使用含氟牙膏刷牙是安全有效的防龋措施，但牙膏不能替代药物，只能起到预防作用，不能治疗口腔疾病。</div>
                            8、科学吃糖少喝碳酸饮料。
                            <div className="section">建议尽量减少每天吃糖的次数，少喝或不喝碳酸饮料。儿童除每日三餐外，尽量少吃零食。进食后及时用清水漱口清除食物残渣。</div>
                            9、吸烟有害口腔健康。
                            <div className="section">吸烟是引起口腔癌的主要危险因素，90%以上的口腔癌患者是吸烟者。吸烟还是牙周病的主要危险因素之一，吸烟者患牙周病的几率较不吸烟者高出5倍。孕妇吸烟或被动吸烟，可以引起胎儿口腔颌面部畸形。吸烟者牙齿表面常常出现褐色烟斑和牙石，引发口腔异味，影响个人外观形象和社会交往。</div>
                            10、牙齿缺失应及时修复。
                            <div className="section">不论失牙多少，都应在拔牙2-3个月后及时进行义齿修复。对于配戴活动假牙（可摘义齿）的老年人，应在每次饭后取出刷洗干净。</div>
                            11、选择具备执业资质的医疗机构进行口腔保健和治疗。
                            <div className="section">在口腔诊疗工作过程中，患者的血液、唾液污染的诊疗器械等均是造成交叉感染的危险因素。具备执业资质的医疗机构具有一套完善的感染控制的管理制度、措施和消毒灭菌设备，确保一人一机一消毒，可彻底杜绝治疗过程中的交叉感染。而且具备执业资质的医疗机构的口腔医师应当受过口腔医学专业教育和临床医疗技能训练，取得医师资格并经过执业注册，具备解决患者病痛的能力。</div>
                            （二）学龄儿童
                            1、学龄儿童最大的口腔变化是换牙，发现异常应及时就诊。
                            <div className="section">学龄儿童口腔的最大变化是换牙，在此阶段，孩子的20颗乳牙会逐渐换成28颗恒牙。牙齿替换是一个生理过程，正常的顺序是乳牙先松动脱落，恒牙再萌出。如果乳牙未掉、恒牙已先萌出，新萌出的恒牙常不能顺利进入牙列，造成恒牙排列不齐，此时应尽早就诊。</div>
                            2、积极防治牙齿外伤。
                            <div className="section">参加体育活动和游戏时，儿童最好穿胶底防滑的旅游鞋、运动鞋。在进行滑板、滑轮等高速度、高风险运动时，应戴头盔、牙托等防护用具，减少牙齿受伤的风险。牙齿是不可再生的硬组织，如果受伤后出现牙龈出血、牙齿裂纹、折断、松动、移位，应立即到医院就诊。</div>
                            <div className="section">如果整个牙齿脱落了，要尽快找到牙齿，用手捏住牙冠部位用凉开水或自来水冲洗掉牙表面的脏东西，但千万不要刷、刮牙根部，然后将冲洗干净的牙齿放回到牙槽窝中；也可以将牙齿泡在新鲜的冷牛奶、生理盐水或含在口腔内，迅速到医院就诊。牙齿离开口腔的时间越短，再植成功的可能性越大，最好在30分钟内治疗。</div>
                            3、用窝沟封闭方法预防“六龄牙”（第一恒磨牙）的窝沟龋。
                            <div className="section">“六龄牙”是萌出时间最早的恒磨牙，其咀嚼功能最强大，也最容易发生龋齿，甚至造成过早脱落，所以保护儿童的第一恒磨牙很重要。窝沟封闭是预防恒磨牙窝沟龋的最有效方法。其原理是用高分子材料把牙齿的窝沟填平，使牙面变得光滑易清洁，细菌不易存留，达到预防窝沟龋的作用。需要提醒的是窝沟封闭后还应好好刷牙，在进行定期口腔检查时，如果发现封闭剂脱落应重新封闭。</div>
                            4、注意防治青少年牙龈炎。
                            <div className="section">青少年牙龈炎表现为刷牙和咬硬物时牙龈出血、牙龈肿胀、口腔异味等，其病因与青春期性激素水平变化有关，更主要的是牙菌斑堆积。所以，预防和治疗青少年牙龈炎最有效的方法是有效刷牙清除牙菌斑。在出现牙龈出血后，更应注意刷牙，可在出血部位稍微多放些牙膏，轻柔地反复多刷几次，并结合使用牙线彻底清除该处牙菌斑。上述方法不能奏效时，应到具备执业资质的医疗机构就诊。</div>
                            5、牙齿排列不齐应及时诊治。
                            <div className="section">接受正畸治疗的儿童每餐后均应刷牙以清除菌斑和滞留的食物残屑，建议选择正畸专用牙刷和牙间刷清洁牙齿。</div>
                            （三）老年人
                            1、幸福的晚年需要健康的牙齿。
                            <div className="section">老年人颌面部骨骼、咀嚼肌、表情肌、软组织等组织器官也会发生一系列退行性变化，加上因口腔疾病导致的牙齿缺失，将会严重影响口腔咀嚼功能、外观形象、发音和社会交往能力。因此，拥有较为完整的牙列，至少保持20颗有功能的牙齿，是幸福晚年的重要保证。</div>
                            2、人老不掉牙，有牙就要坚持刷。
                            <div className="section">人老掉牙不是必然规律，大多数是由于长期患有龋齿、牙周病等口腔疾病造成的。只要预防和控制口腔疾病，掌握科学的口腔保健方法，形成良好的口腔卫生习惯，就可以终生拥有一副健康的牙齿。需要特别提醒的是，只要口腔内存留牙齿，就应按照科学的方法坚持刷牙，没牙也要注意清洁口腔。</div>
                            3、积极防治牙根面龋。
                            <div className="section">老年人由于牙龈萎缩，牙根暴露于口腔环境，根面易发生龋坏，称根面龋，是老年人的口腔常见病和多发病。预防根面龋需要做到以下几点：使用含氟牙膏等局部用氟方法、保健牙刷，用正确的方法早晚刷牙；饭后漱口，有条件者可使用漱口液漱口；不吸烟；适当控制各种甜食摄入频率，多吃新鲜蔬菜与瓜果，安排合理膳食，保证微量元素的摄取，增加牙齿抗龋能力。出现了根面龋应及时治疗。</div>
                            4、食物嵌塞应及时到医院诊治。
                            <div className="section">食物嵌塞，俗称“塞牙”，是老年人最常见的口腔不适之一，遇到塞牙情况时，应立即刷牙、漱口或选择使用牙线、牙间刷清理，避免用粗糙牙签剔牙。刷不掉的嵌塞物可用质地较柔软的细牙签轻轻剔出，不可用力过猛过快。反复塞牙者应到医院进行口腔专业治疗。</div>
                            5、牙本质敏感应及时到医院诊治。
                            <div className="section">牙本质敏感，俗称“倒牙”，主要是指对冷、热、酸、甜等刺激产生的短暂而尖锐的疼痛。建议：①饭后漱口；②减少酸性食物和饮料的摄入；③进食酸性食物和饮料后不要即刻刷牙，一小时后再刷牙；④选择合格的牙刷，采用正确的刷牙方法，避免刷牙时用力过大；⑤使用抗敏感牙膏，如4～8周后无明显效果，应及时就医。</div>
                            6、每天清洁可摘义齿（活动假牙）。
                            <div className="section">戴假牙也要保持清洁卫生，对于配戴活动假牙（可摘义齿）的老年人，应在每次饭后取出活动假牙以软毛牙刷刷洗干净，夜间不戴假牙时应清洗后放置清水中保存，最好使用假牙清洁片帮助清洁。假牙每天摘、刷、泡，晚上做好这一套。</div>
                            7、关注口腔黏膜变化，发现异常应及时诊治。
                            <div className="section">老年是口腔黏膜疾病高发的年龄，老年人应该关注口腔黏膜变化，发现口腔内有两周以上没有愈合的溃疡，口腔粘膜有硬结、白色或红色斑块及出现牙痛、牙龈出血等不适症状后要及时就医。如果口腔粘膜长期受到不良刺激或有烟酒不良嗜好，容易发生口腔白斑甚至口腔癌。因此，应早期预防，消除不良刺激和戒除烟酒嗜好，一旦出现疾病症状要及时就诊，做到早发现、早诊断、早治疗。</div>
                            8、叩齿可以增进牙周健康。
                            <div className="section">叩齿是我国传统的中医口腔保健方法，每天叩齿1～2次，每次叩齿36下，可以促进牙周血液循环、增进牙周组织健康，长期坚持可固齿强身。如果牙齿松动、咬合错乱，叩齿往往会造成牙周组织创伤，不宜作叩齿保健。</div>
                            9、每半年去医疗机构做一次口腔健康检查，每年至少洁牙一次。
                            <div className="section">由于老年人口腔解剖生理的特殊性，口腔疾病发展变化速度快，口腔自我修复能力减弱。因此，为老年人提供定期检查、洁治等保健措施对维持口腔健康必不可少。老年人应每半年至少进行一次口腔健康检查，发现问题，及时处理。每年至少洁牙一次。</div>
                            10、根据医生建议拔除残根残冠。
                            <div className="section">残根（因龋坏、外伤等因素造成的牙冠缺失及部分牙根缺失）、残冠（因龋坏、磨损等因素造成的牙冠的大部分缺失）可成为全身感染的病灶，往往可引起全身性疾病。因此，老年人应该及时拔除没有治疗价值的残根或残冠，此外，很松动、无功能的牙齿也需要拔除。牙齿缺失或拔牙3个月后，要及时镶牙，保持口腔牙列的完整，恢复口腔的基本功能。</div>
                            （四）孕产妇
                            1、孕妇的口腔健康影响胎儿健康。
                            <div className="section">孕妇患有牙周病可能会导致婴儿早产或出生时低体重。孕妇钙摄入不足会影响胎儿牙齿发育。</div>
                            2、计划怀孕时应接受口腔健康检查。
                            <div className="section">女性在计划怀孕时就应主动接受口腔健康检查，及时发现并处理口腔内的疾病或隐患，避免在怀孕期间可能因为发生口腔急症，而带来的治疗不便和风险。</div>
                            3、怀孕4-6个月是孕期治疗口腔疾病的最佳时期。
                            <div className="section">怀孕1-3个月，口腔治疗一般仅限于处理急症，要避免X线照射。怀孕4-6个月是孕期治疗口腔疾病的最佳时期，口腔治疗最好在此阶段完成，但也应注意在保护措施下使用X线。怀孕7～9个月尽可能避免口腔治疗，急症需治疗时，选择不含肾上腺素等收缩血管的药物进行局部麻醉。</div>
                            4、孕期和产后更应坚持刷牙、漱口。
                            <div className="section">孕妇和产妇进食次数增多，食物中糖等碳水化合物的含量大，若不注意保持口腔卫生，很容易导致菌斑的堆积，引发口腔疾病。因此，“坐月子不刷牙”的说法是错误的，孕产妇更应保持正常的口腔卫生习惯，餐后漱口，早晚刷牙等。</div>
                            （五）婴幼儿
                            1、口腔健康是婴幼儿正常生长发育的基础。
                            <div className="section">维护婴幼儿期的口腔健康有利于均衡摄入营养，养成良好的饮食习惯，保证全身的正常生长发育。</div>
                            2、从出生开始，家长应为婴幼儿清洁口腔。
                            <div className="section">婴儿出生之后，家长应每天用软纱布为孩子擦洗口腔，可有效预防口腔白色念珠菌感染（俗称“鹅口疮”）。牙齿萌出后，可用纱布或软毛刷轻轻地为孩子擦洗口腔和牙齿。当多颗牙齿萌出后，家长可用指套刷或软毛刷为孩子每天刷牙2次，并确保清洁上下颌所有的牙面，特别是接近牙龈缘的部位。两岁大的孩子会想自己刷牙，但父母应明白这个年龄孩子手的精细运动能力尚未形成，不能真正刷干净牙齿。因此，家长应帮孩子刷牙，每日至少2次。</div>
                            3、不当的喂养会危害婴幼儿口腔健康。
                            <div className="section">乳牙萌出之后，不要让幼儿长时间含着装有甜奶或甜饮料的奶瓶，尤其不能含奶瓶睡觉，否则会造成婴幼儿龋。1岁后应尽量减少使用奶瓶，且奶瓶内只能装白水和无糖奶，用杯子或勺喂含糖液体（如甜奶、果汁、蜂蜜水等）。1岁半到2岁应停止使用奶瓶。因为长期用奶瓶喂养，除了容易发生龋齿外，还可妨碍孩子咀嚼功能的发育。</div>
                            4、莫把病菌口口相传给孩子们。
                            <div className="section">唾液是细菌传播的载体。喂养人可以通过把食物嚼碎喂孩子，以及把奶嘴或勺子放到自己口中试温度等方式将口腔中的致病菌传播给孩子。致龋细菌越早传给孩子，孩子越易患龋齿。所以看护人应注意喂养卫生，纠正不良的喂养方式，同时关注自身的口腔卫生，避免把致病菌传播给婴幼儿。</div>
                            5、注意喂养器具的消毒。
                            <div className="section">奶瓶等婴幼儿喂养器具必须做到消毒灭菌，否则，宝宝吃奶口疮”。需要注意的是，消毒后24小时内没有使用的奶瓶，仍需重新消毒，以免滋生细菌。</div>
                            6、婴幼儿从牙萌出开始，每半年接受一次口腔健康检查和口腔卫生指导。
                            <div className="section">婴幼儿应该在第一颗牙齿萌出后6个月内，就由家长带去医院检查牙齿，请医生帮助判断孩子牙齿萌出情况，并评估其患龋齿的风险，提供有针对性的口腔卫生指导，如果发现龋齿等口腔疾病应及早诊治。此后每半年检查一次牙齿。</div>
                            （六）学前儿童
                            1、健康完整的乳牙列是恒牙健康的基础。
                            <div className="section">完整健康的乳牙列能够发挥正常的咀嚼功能，可保障恒牙和颌面部骨骼的正常生长发育，有利于孩子准确发音，引导恒牙正常萌出，使儿童获得健康并使用终生的恒牙。</div>
                            2、鼓励儿童多吃纤维性食物，增强咀嚼功能。
                            <div className="section">健康的饮食结构和良好的饮食习惯是口腔健康和全身健康的基础，养成良好的饮食习惯会使儿童受益终生。儿童应注意平衡膳食，做到不挑食，特别是多吃蔬菜和新鲜水果等纤维含量高、营养又丰富的食物，这样，既有利于牙齿的自洁作用、预防龋齿，又有利于口腔颌面的生长发育，促使牙齿排列整齐，增强咀嚼功能。</div>
                            3、睡前刷牙后不再进食。
                            <div className="section">由于人在睡眠期间口腔运动少，唾液分泌量低，口腔的自洁作用差，如果刷牙后睡前再进食易患龋齿和牙龈炎。此外，儿童应养成规律饮食的习惯，除每日三餐外，尽量少吃零食。如果吃零食也应有规律，可在两正餐之间加零食。</div>
                            4、儿童学习刷牙，家长应帮助和监督。
                            <div className="section">从3～4岁开始，儿童动手能力和四肢协调性明显增强，家长和幼儿园老师可开始教儿童自己用最简单的“画圈法”刷牙，其要领是将刷毛放置在牙面上，轻压使刷毛屈曲，在牙面上画圈，每部位反复画圈5次以上，前牙舌侧需将牙刷竖放，牙齿的各个面（包括唇颊侧、舌侧及咬合面）均应刷到。此外，家长还应每日帮孩子刷牙1次（最好是晚上），直到上小学，这样才能保证刷牙的效果。儿童应选用适合自己年龄的儿童牙刷。</div>
                            5、帮助孩子尽早戒除口腔不良习惯。
                            <div className="section">儿童口腔不良习惯有：吮指、咬下唇、吐舌、口呼吸等，应尽早戒除，否则会造成上颌前突、牙弓狭窄、牙列拥挤等口颌畸形。如果3岁以上的儿童仍存在上述不良习惯，且不能通过劝导而戒除，应及时到医院诊治，通过适当的矫正方法，帮助其戒除不良习惯。对有口呼吸习惯的孩子，应检查其上呼吸道是否通畅，治疗扁桃体肿大、腺样体肥大、鼻甲肥厚等病症，及时纠正口呼吸。</div>
                            6、提倡学龄前儿童每6个月接受一次口腔健康检查。
                            <div className="section">3～6岁是儿童患龋的高峰期。该阶段牙弓开始发生变化，出现牙间隙，为换牙做准备，但易造成食物嵌塞，引发邻面龋。龋齿早期治疗时间短、痛苦小、效果好、花费少。所以提倡学龄前儿童每6个月接受一次口腔健康检查。在对儿童进行口腔健康检查的同时，医生应提供有针对性的专业口腔健康指导，增强家长和孩子的口腔健康意识。</div>
                            7、早期矫治前牙“地包天”（前牙反咬合）畸形。
                            <div className="section">上颌骨发育不足和遗传等先天因素是前牙反咬合的病因，不良的喂奶姿势和儿童的不良习惯也可造成前牙反咬合。前牙反咬合可限制上颌骨发育，导致下颌过度前伸，造成颜面中部三分之一凹陷，明显影响面貌，早期矫治可纠正或减轻面貌改变，取得相对好的治疗效果。乳前牙反咬合的最佳矫治时间为3～4岁。</div>
                            8、局部用氟预防乳牙龋齿。
                            <div className="section">含氟牙膏具有肯定的预防龋齿的作用。学龄前儿童一般都会漱口，并把口腔内的异物吐出，故可用儿童含氟牙膏刷牙，但每次用量为豌豆粒大小，并在家长或老师的监督指导下应用，以防误吞。不要给孩子使用成人牙膏。另外，可在医院和幼儿园接受由专业人员实施的牙齿涂氟，预防龋齿。</div>
                            9、乳牙龋齿应及时治疗。
                            <div className="section">龋齿影响儿童口腔和全身健康。龋齿最初的表现是牙齿局部变色，一般为黑色，有时在上前牙表现为白垩色改变，进而牙齿表面硬组织剥脱，形成龋洞，直至牙齿完全崩解，脱落。龋齿可以引起孩子牙痛，牙龈、面部肿胀，甚至高热等全身症状。龋齿长期得不到治疗可造成儿童偏侧咀嚼，双侧面部发育不对称；还可影响恒牙的正常发育和萌出。如果没有健康的牙齿，孩子就不愿吃含纤维多的蔬菜和肉食，造成偏食等不良饮食习惯，影响全身正常生长发育。因此，“乳牙总是要换的，坏了不用治”的看法是错误的。</div>
                            10、及时治疗乳牙外伤。
                            <div className="section">乳牙外伤常发生于2岁以后的幼儿，多为前牙，一般是由跌倒引起，外伤可能会把牙齿碰松、碰折、碰掉等，乳牙外伤可能会影响以后恒牙的发育和正常萌出，应及时到具备执业资质的医疗机构就诊。</div>
                            （七）残疾人
                            1、残疾人更应注意口腔健康。
                            <div className="section">口腔健康是残疾人最基本的需求，残疾人往往由于各种生理、智力障碍及多种社会因素影响，使得他们维护口腔卫生效率不高，口腔健康状况欠佳。因此，他们的口腔健康更需要家庭、医疗保健机构、社会的关心与照顾。亲属或护理人员应适时带他们进行口腔健康检查，及时治疗口腔疾病，保持口腔卫生，维护口腔健康。</div>
                            2、应给予残疾人必要的口腔卫生指导和帮助。
                            <div className="section">为了使残疾人能养成良好的口腔卫生习惯，较好地维护口腔健康，口腔专业人员应对残疾人开展口腔卫生指导，亲属或护理人员应给予必要的帮助。对于有生活自理能力的残疾人，应指导其刷牙；对于缺乏生活自理能力的残疾人，亲属或护理人员应在每餐后帮助其清理口腔，每天帮助其刷牙1～2次。</div>
                            3、可选择适宜的口腔清洁用品。
                            <div className="section">根据残疾的程度和残疾人的配合能力，选择清洁口腔的适宜用品，如电动牙刷、漱口水、冲牙器等。应尽量减少粘性与含糖食物的进食次数。在可能的条件下，最好选用局部用氟方法防龋，如每天使用含氟牙膏，或用氟水含漱，或由专业人员使用含氟泡沫、含氟凝胶等。</div>
                        </div>
                    </div>
                    <div className='content-item'>
                        <div className="part">
                            <div className="tip-top">一、为什么提倡“健体重”？</div>
                            <div className="section">中国营养学会公布的《中国肥胖预防与控制蓝皮书》（以下简称《蓝皮书》）指出，目前全球范围内约40%的成年人肥胖或者超重，而儿童超重和肥胖患病率的上升更会加剧慢性疾病在整个生命周期内的发病率增加。肥胖及其相关疾病已成为全球性的重大公共卫生问题。《蓝皮书》显示，我国成年人的超重率达到30.1%，肥胖率达到11.9%；全国约有4.4亿成年人超重，其中近1.3亿人肥胖。《蓝皮书》指出，肥胖的病因非常复杂，除遗传等不可改变的因素外，环境因素和不良行为习惯是最主要的原因，其中不健康饮食和缺乏身体运动是最主要的不良行为习惯。肥胖对健康的危害是全方位的，不仅可导致严重的心脑血管疾病、内分泌代谢疾病，还可以引起呼吸、消化、运动系统障碍，还与多种恶性肿瘤的发生有关。</div>
                        </div>
                        <div className="part">
                            <div className="tip-top">二、如何“健体重”？</div>
                            1、维持健康体重。各年龄段人群都应该每天坚持身体活动，维持能量平衡，保持健康体重。体重过高或过低都会影响健康。
                            <div className="section"></div>
                            2、定期测量体质指数（BMI）。
                            <div className="section">BMI=体重（kg）/身高（m）的平方</div>
                            <img src={require('../../assets/image/threereduce/three-reduce-04.png')} alt=""/>
                            3、维持健康腰围。重视控制腰围，预防腹型肥胖，建议男性腰围不超过85厘米，女性不超过80厘米。
                             <div className="section"></div>
                            4、践行“健康一二一理念”。践行“日行一万步，吃动两平衡，健康一辈子”的“健康一二一”理念，通过合理饮食与科学运动来保持健康体重。
                             <div className="section"></div>
                            5、食物多样，饮食规律。能量摄入适量，建议平均每天摄入12种以上、每周25种以上食物。鼓励摄入以复合碳水化合物（即粗杂粮主食）、优质蛋白质为基础的，低能量、低脂肪、低糖、低盐并富含微量元素和维生素的膳食。坚持规律饮食，切忌暴饮暴食。
                             <div className="section"></div>
                            6、坚持日常身体活动。推荐每周至少有5天进行中等强度的身体活动，每周累计150分钟以上。减少久坐时间，建议不超过一小时就起来动一动。
                             <div className="section"></div>
                            7、正确树立减重目标。超重肥胖者制定的减重目标不宜过高过急，减少脂肪类能量摄入，增加运动时间和强度，做好记录，以利于长期坚持。
                             <div className="section"></div>
                            8、从儿童青少年开始关注体重。儿童应注意平衡膳食，减少静坐时间，增加体力活动和运动锻炼时间。儿童肥胖控制可通过饮食控制、行为修正和运动指导来实现。饮食控制的目的是降低能量摄入，但不宜过度节食。
                             <div className="section"></div>
                            9、老年人根据自身身体状况适宜运动。建议每周坚持至少进行3次平衡能力锻炼，预防跌倒；适量进行增加肌肉的训练，预防少肌症。
                             <div className="section"></div>
                            10、将身体活动融入到日常生活中。上下班路上多步行、多骑车、少开车；工作时少乘电梯多走楼梯，时常做做伸展运动，减少久坐；居家时间多做家务、多散步，减少看电视、手机和其他屏幕的时间。运动要多样化，把生活、娱乐、工作与运动锻炼相结合。
                        </div>
                    </div>
                    <div className='content-item'>
                        <div className="part">
                            <div className="tip-top">一、为什么提倡“健骨骼”？</div>
                            <div className="section">骨骼健康是人体健康的重要保证和支撑。骨骼是人类抵御能力的坚实基础，在人的一生中，骨骼要承受支撑、抗压、抗拉、抗扭、抗弯、抗折等，可谓任务艰巨。目前全世界大约有3.55亿人患有各种骨关节疾病，病情严重的甚至可造成终身残疾。到2050年，我国骨量减少和骨质疏松症的患者将达2亿多人，60岁以上的老年人患关节疾病的将占50%以上。未来，骨关节疾病将成为影响人们生活质量和生命质量的重要问题之一。目前，有约3/4的人在一生当中有过腰痛的经历；颈部疾患过去是进入中老年期的标志，现在年轻的白领人群中也相当普遍；更年期女性骨关节问题尤为明显。</div>
                        </div>
                        <div className="part">
                            <div className="tip-top">二、如何“健骨骼”？</div>
                            1、认识骨质疏松。骨质疏松症是中老年人最常见的一种全身性骨骼疾病，疼痛、驼背、身高降低和骨折是骨质疏松症的主要表现，骨质疏松症是可防可治的慢性病。
                            <div className="section"></div>
                            2、关注骨质疏松预防。各个年龄阶段都应注重骨质疏松的预防，绝经期后的女性及中老年人是骨质疏松的高发人群。
                            <div className="section"></div>
                            3、骨量积累不容忽视。人体骨骼中的矿物含量在30岁左右达到最高的峰值骨量，峰值骨量越高，到老年发生骨质疏松症的时间越推迟，症状与程度也越轻。
                            <div className="section"></div>
                            4、均衡饮食促进钙吸收。饮食习惯对钙的吸收密切相关，选择富含钙、低盐和适量蛋白质的均衡饮食对预防骨质疏松有益。
                            <div className="section"></div>
                            5、日光照射有助于钙吸收。充足的光照会促进维生素D的生成，建议每天日照的时间至少20分钟。
                            <div className="section"></div>
                            6、坚持运动预防骨质疏松。体育锻炼对于预防骨质疏松具有积极作用，提倡进行中速步行、跑步、骑行等多种户外运动形式。另外，负重运动可以让身体获得及保持最大的骨强度。
                            <div className="section"></div>
                            7、老年人需预防跌倒。关节的柔韧性和灵活性锻炼运动负荷小、能量消耗低，有助于老年人预防跌倒和外伤。
                            <div className="section"></div>
                            8、戒烟限酒，改变不良生活习惯。吸烟和过度饮酒等不良生活习惯都会增加骨质疏松风险，《中国居民膳食指南（2016）》提出以酒精量计算，成年男性和女性一天的最大饮酒酒精量建议不超过25克和15克，高危人群应在此基础上减少。
                            <div className="section"></div>
                            <img src={require('../../assets/image/threereduce/three-reduce-05.png') } alt=""/>
                            9、自我检测鉴别高危人群。
                            <div className="section">以下问题可以帮助居民进行骨质疏松症高危情况的自我检测，任何一项回答为“是”者，则为高危人群，应当到骨质疏松专科门诊就诊，早诊断、早预防、早治疗。</div>
                            ①您是否曾经因轻微的碰撞或者跌倒伤到自己的骨骼？
                            ②您连续3个月以上服用激素类药品吗？
                            ③您的身高是否比年轻时降低了？
                            ④您经常饮酒吗？（每天饮酒2次，或一周中只有1-2天不饮酒视为经常饮酒）
                            ⑤您每天吸烟超过20支吗？
                            ⑥您经常腹泻吗？（由于腹腔疾病或肠炎而引起）
                            ⑦父母有没有因轻微碰撞或跌倒发生髋部骨折的情况？
                            ⑧女士回答：您是否在45岁之前就绝经了？
                            ⑨您是否曾经有过连续12个月以上没有月经（怀孕期间除外）？
                            ⑩男士回答：您是否患有阳痿或者缺乏性欲？
                        </div>
                    </div>
                </Tabs>
            </div>

            <div onClick={openQuestion} className="what-three">
                <img src={require('../../assets/image/threereduce/what-three-infor-2@2x.png')} alt=""/>
            </div>
            {showPop ?
            <div className="content-item content-info-pop">
                <div onClick={() => setShowPop(false)} className="bg"></div>
                <div className="part">
                    <div className="tip-top">一、何为“三减三健”</div>
                    <div className="section">国务院办公厅在2017年1月22日发布的《中国防治慢性病中长期规划（2017—2025年）》中提出开展“三减三健”（减盐、减油、减糖、健康口腔、健康体重、健康骨骼）等专项行动，以提升人民群众健康素养。</div>
                    <div className="section">“三减三健”专项行动确定“减盐、减油、减糖”行动以餐饮从业人员、儿童青少年、家庭主厨为主；健康口腔行动以儿童青少年和老年人为主；健康体重行动以职业人群和儿童青少年为主；健康骨骼行动以中青年和老年人为主。“三减三健”专项行动旨在传播核心信息，提高群众对少盐少油低糖饮食与健康关系的认知，帮助群众掌握口腔健康知识与保健技能，倡导天天运动、维持能量平衡、保持健康体重的生活理念，增强群众对骨质疏松的警惕意识和自我管理能力。</div>
                </div>
            </div>
            :''}
        </div>
    )
}
ThreeReducePage.options = { disableHeader: false, wrapperClassName: '' }
export default ThreeReducePage