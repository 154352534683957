import React, { useState, useEffect } from 'react'
// import nutrition from 'api/nutrition'
import { Button, Icon } from 'antd-mobile'
import './insu-licence.css'
import Nav from 'components/nav/Nav';
import isIphoneX from 'utils/isIphoneX';


const InsuranceLicenceExtend: PageComponent = props => {
  // const [result, setSesult] = useState({})
  useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
		// console.log('result - :',result)
		window.scrollTo(0, 0)
		// document.body.scrollTo({top:0,left:0})
	}, [])

	const detail = () => {
		return <div className='insulic-wrap' style={{backgroundColor:'#fff'}}>

		<span className='licenceTitle center bold'>泰康在线财产保险股份有限公司</span>
					<span className='licenceTitle center bold'>附加扩展门（急）诊医疗保险条款</span>
		
					<span className='licenceSection center bold' >总则</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第一条</span> <span className='space'></span> 本附加保险合同（以下简称 “本附加合同”）须附加于医疗保险合同（以下
		简称“主险合同”）。本附加合同由保险条款、投保单、保险单或者其他保险凭证、与本附
		加合同有关的投保文件、<span className='bold'>合法有效</span>的声明、批注、批单及其他投保人与保险人共同认可的书
		面或者电子协议组成。凡涉及本附加合同的约定，均应采用书面或者电子形式。
					</div>
						<div className='indent bold'>
						若主险合同与本附加合同条款互有冲突，则以本附加合同条款为准。本附加合同条款
		未尽事宜，以主险合同的条款规定为准。
						</div>
						<div className='indent'>
						<span className='bold' >第二条</span> <span className='space'></span> 本附加合同的投保人和被保险人与主险合同一致。
						</div>
						<div className='indent'>
						<span className='bold' >第三条</span> <span className='space'></span> 除另有约定外，本附加合同的受益人与主险合同一致。
						</div>
					</div>
		
					<span className='licenceSection center bold' >保险责任</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第四条</span> <span className='space'></span> 在本附加合同保险期间内，被保险人因遭受意外伤害事故或在主险合同约定
		的等待期后罹患主险合同约定的疾病并因该意外伤害事故或疾病在医院经具有相应资质的
		医生诊断必须接受门（急）诊治疗的，保险人对被保险人在前述医院发生的应当由被保险人
		支付的必需且合理的<span className='bold'>门（急）诊医疗费用，在扣除约定的免赔额后，按照约定的赔偿比例
		进行赔付。</span>
					</div>
						<div className='indent bold'>
						若被保险人发生主险项下的特殊门诊医疗费用、门诊手术医疗费用、住院前后门急诊
		医疗费用以及重大疾病特殊门诊医疗费用、重大疾病门诊手术医疗费用、重大疾病住院前
		后门急诊医疗费用，保险人需按照主险合同约定承担保险责任，本附加险不再进行相应费
		用的赔偿。
						</div>
						<div className='indent'>
						<span className='bold' >第五条</span> <span className='space'></span> 本附加合同适用医疗费用补偿原则，补偿原则与主险合同一致。
						</div>
					</div>
					
					
		
					
					<span className='licenceSection center bold' >责任免除</span>
					<div className='licenceContent'>
						
						<div className='indent bold'>
						<span className='bold' >第六条</span> <span className='space'></span> 主险合同的责任免除事项适用于本附加合同。
						</div>
					</div>
		
					
					<span className='licenceSection center bold' >保险期间</span>
					<div className='licenceContent'>
						
						<div className='indent'>
						<span className='bold' >第七条</span> <span className='space'></span> 除另有约定外，本附加合同的保险期间与主险合同一致。
						</div>
					</div>
		
					<span className='licenceSection center bold' >保险金额、保险费与免赔额</span>
					<div className='licenceContent'>
						
						<div className='indent bold'>
						<span className='bold' >第八条</span> <span className='space'></span> 本附加合同的保险金额包含于主险合同的年度累计保险金额之内，在主险
			合同的年度累计保险金额之内单独设置门（急）诊医疗费用保险金额，若保险人在主险合
			同及本附加合同下累计赔偿保险金的金额达到主险合同约定的年度累计保险金额，则主险
			合同和本附加合同同时终止，保险人对被保险人不再承担赔偿保险金的责任。
						</div>
						<div className='indent'>
						<span className='bold' >第九条</span> <span className='space'></span> 保险费依据保险金额与保险费率计收，并在保险单中载明。保险费支付方式
			由投保人在投保时与保险人约定，并在保险单上载明。
						</div>
						<div className='indent bold'>
						除另有约定外，本附加合同与主险合同共用免赔额，若免赔额在主险合同下赔付时已
		完全扣除，则在本附加合同赔付时不再扣除免赔额；若免赔额在本附加合同赔付时已经完
		全扣除，则在主险合同下赔付时不再扣除免赔额。
						</div>
					</div>
		
		
					<span className='licenceSection center bold' >释义</span>
					<div className='licenceContent'>
						
						<div className='indent bold'>
						<span className='bold' >第十条</span> <span className='space'></span> 本附加合同涉及下列术语时，适用以下释义:
						</div>
						
						<div className='indent'>
						<span className='bold'>【医院】</span>指国家卫生部医院等级分类中的二级或二级以上公立医院普通部，<span className='bold'>但不包括观
		察室、特需医疗、国际医疗、联合病房、康复病房和干部病房以及附属于前述医院或单独
		作为诊所、康复、护理、疗养、戒酒、戒毒等或相类似的医疗机构。</span>同时该医院必须具有
							符合有关医院管理规定设置标准的医疗设备，并且全日二十四小时有合格医师及护士驻院提
							供医疗和护理等服务。
						</div>
						<div className='indent'>
						<span className='bold'>【门（急）诊医疗费用】</span>指治疗期间发生的必需且合理的门（急）诊医疗费用，包括护
			理费、检查检验费、诊疗费、治疗费、药品费。
						</div>
						<div className='indent'>
						（一）护理费指被保险人在门（急）诊期间根据医嘱所示的护理等级确定的费用；
						</div>
						<div className='indent'>
						（二）检查检验费指实际发生的、以诊断为目的，采取必要的医学手段进行检查及检验
			而发生的合理的医疗费用，包括诊查费、妇检费、X光费、心电图费、B超费、脑电图费、内
			窥镜费、肺功能仪费、分子生化检验费和血、尿、便常规检验费等；
						</div>
						<div className='indent'>
						（三）诊疗费指被保险人在门（急）诊期间发生的经治医生或会诊医生的劳务费用；
						</div>
						<div className='indent'>
						（四）治疗费指以治疗疾病为目的，提供必要的医学手段而合理发生的治疗者的技术劳
			务费和和医疗器械使用费以及消耗品的费用，包括注射费、机疗费、理疗费、输血费、输氧
			费、体外反搏费等；
						</div>
						<div className='indent'>
						（五）药品费根据医生处方使用的具有国家药品监督管理部门核发的药品批准文号或者
		进口药品注册证书、医药产品注册证书的国产药品。<span className='bold'>但不包括营养补充类药品，免疫功能
		调节类药品，美容及减肥类药品，预防类药品，以及下列中药类药品：①主要起营养滋补
		作用的单方、复方中药或中成药品，如花旗参，冬虫草，白糖参，朝鲜红参，玳瑁，蛤蚧，
		珊瑚，狗宝，红参，琥珀，灵芝，羚羊角尖粉，马宝，玛瑙，牛黄，麝香，西红花，血竭，
		燕窝，野山参，移山参，珍珠（粉），紫河车，阿胶，阿胶珠，血宝胶囊，红桃K口服液，
		十全大补丸，十全大补膏等；②部分可以入药的动物及动物脏器，如鹿茸，海马，胎盘，
		鞭，尾，筋，骨等；③用中药材和中药饮片炮制的各类酒制剂等。</span>
						</div>
						<div className='indent bold'>
						本附加合同的未释义名词，以本附加合同所附属主险合同中的释义为准。
						</div>
					</div>
		
		
				</div>
	}


	return (
		<div style={{ flex: 1, position: 'relative' }}>
			<div style={{position:'fixed',padding:0,height:!!isIphoneX()?'0.88rem':'0.4rem',backgroundColor:'#fff',zIndex:99, top:0,width:'100%'}}></div>
			<Nav title='' style={{border:'none',position:'fixed',padding:0,...{paddingTop:!!isIphoneX()?'0.88rem':'0.4rem'}}}></Nav>
			{detail()}
		</div>
  )
}
// InsuranceSecurePlan.options = { disableHeader: false, wrapperClassName: '' }
export default InsuranceLicenceExtend
