import VideoDetailPlayPage from './VideoDetailPlay'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncVideoDetailPlayPage = Loadable({
	loader: () => import('./VideoDetailPlay'),
	loading: MyLoadingComponent
})
const VideoPlay: Module = {
	name: 'videodetailplay',
	routes: [
		{
			name: '视频播放详情',
			path: '/videodetailplay/share/:type/:kid',//1 帖子 2 资讯
			component: asyncVideoDetailPlayPage,
		},
        {
            name: '视频播放详情',
            path: '/videodetailplay/share/:kid',//1 帖子 2 资讯
            component: asyncVideoDetailPlayPage,
        },
	],
}
export default VideoPlay