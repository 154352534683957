import React, { useEffect, useState, useMemo, Fragment } from 'react'
import mall, { ProductDetailVO } from 'api/mall'
import GoodCarousel from './components/GoodCarousel'
import InlineItem from 'modules/mall/components/InlineItem'
import GoodSummary from './components/GoodSummary'
interface ProductContext {
  goods: ProductDetailVO
}
export const ProductContext = React.createContext(null as ProductContext)

const ProductDetail: PageComponent<{}> = ({ ...props }) => {
  const [goods, setGoods] = useState<ProductDetailVO>()

  const kid = useMemo(() => {
    return props.match.params && props.match.params.kid
  }, [props.match])

  useEffect(() => {
    async function getGoods() {
      let res = await mall.products.get({ kid })
      res && setGoods(res)
    }
    getGoods()
  }, [kid])

  return (
    <ProductContext.Provider value={{ goods }}>
      <GoodCarousel></GoodCarousel>
      <GoodSummary></GoodSummary>
    </ProductContext.Provider>
  )
}
export default ProductDetail
