import React, { useState, useEffect } from 'react'
import { Toast } from 'antd-mobile';
import './downloadapp.css'

const DownLoadAppPage: PageComponent =  props => {
    useEffect(() => {
       if (isIOS()) {
         window.location.href = 'https://itunes.apple.com/cn/app/id1479391612?ls=1&mt=8'
       }
       if (isAndroid()) {
         window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.laj.nutritionplan'
       }
    }, [])
    const isIOS = () => {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false;
    }
    const isAndroid = () => {
        return navigator.userAgent.match(/Android/i) ? true : false;
    }
    return (
        <div className="DownLoadAppPage">
            <div className="cont">
                <img className="logo-img" src="http://cdn.lajsf.com/nutrition-plan/image/default/static/icon.png" alt=""/>
            </div>
        </div>
    )
}
DownLoadAppPage.options = { disableHeader: false, wrapperClassName: '' }
export default DownLoadAppPage