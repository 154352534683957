import React, { useState, useEffect, useCallback } from 'react'
// import nutrition from 'api/nutrition'
import { Button, Icon } from 'antd-mobile'
import './insu-licence.css'
import Nav from 'components/nav/Nav';
import isIphoneX from 'utils/isIphoneX';
import { getScrollTop } from 'modules/activepage/epidemic/scrollHeight';


const InsuranceLicenceC: PageComponent = props => {
	
	useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
		// console.log('result - :',result)
		window.scrollTo(0, 0)
		// document.body.scrollTo({top:0,left:0})
	}, [])
	
	const detail = () => {
		return <div className='insulic-wrap' style={{backgroundColor:'#fff'}}>

		<span className='licenceTitle center bold'>泰康在线财产保险股份有限公司</span>
					<span className='licenceTitle center bold'>个人住院医疗保险（C 款）条款</span>
		
					<span className='licenceSection center bold' >总则</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第一条</span> <span className='space'></span> 本保险合同（以下简称为“本合同”)由保险条款、保险单或者其他保险凭证、
		投保单、与本合同有关的投保文件、<span className='bold'>合法有效</span>的声明、批注、批单及其他投保人与<span className='bold'>保险人</span>共
		同认可的书面或者电子协议组成。凡涉及本合同的约定，均应采用书面或者电子形式。
					</div>
						<div className='indent'>
						<span className='bold' >第二条</span> <span className='space'></span> 凡投保时身体健康，投保年龄为0<span className='bold'>周岁</span>（出生满30日，<span className='bold'>不含第30日</span>）至60周岁
		（含）的自然人，均可作为本合同的被保险人。
						</div>
						<div className='indent'>
						<span className='bold' >第三条</span> <span className='space'></span> 具有完全民事行为能力的被保险人本人或在本合同订立时对被保险人具有保
			险利益的其他人，均可作为本合同的投保人。
						</div>
						<div className='indent'>
						<span className='bold' >第四条</span> <span className='space'></span> 除另有约定外，本合同的保险金受益人为被保险人本人。
						</div>
					</div>
		
					
					<span className='licenceSection center bold' >保险责任</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第五条</span> <span className='space'></span> 投保人为被保险人首次投保本保险或非续保本保险时，自本合同生效之日起
		30日（含第30日）为等待期。
					</div>
						<div className='indent bold'>
						被保险人在等待期内非因意外伤害事故罹患本合同所定义的重大疾病以外的疾病，导
				致需要接受第六条第（一）款约定的住院治疗、特殊门诊治疗、门诊手术治疗或住院前后
				门急诊治疗的，无论该治疗是否延续至等待期后，保险人不承担赔偿保险金的责任，本合
				同继续有效。
						</div>
						<div className='indent bold'>
						被保险人在等待期内经医院初次确诊非因意外伤害事故罹患本合同所定义的重大疾病
		（无论一种或多种），保险人不承担保险责任，但无息退还投保人所交保险费，本合同终
		止。
						</div>
						<div className='indent'>
						投保人续保本保险或被保险人因遭受<span className='bold'>意外伤害事故</span>需要接受本合同约定的治疗的无等
		待期。
						</div>
						
						<div className='indent'>
						<span className='bold' >第六条</span> <span className='space'></span> 在本合同保险期间内，保险人根据合同约定承担以下保险责任：
						</div>
						<div className='indent'>
						（一）一般医疗保险金
						</div>
						<div className='indent'>
						在本合同保险期间内，被保险人因遭受意外伤害事故或在等待期后罹患本合同定义的重
		大疾病以外的疾病，在<span className='bold'>医院</span>经具有相应资质的<span className='bold'>医生</span>诊断必须接受治疗的，对于被保险人支付
		的以下医疗费用，<span className='bold'>保险人在扣除本合同约定的免赔额后，</span>依照本合同的约定赔偿一般医疗
							保险金：
						</div>
						<div className='indent'>
						1.住院医疗费用
						</div>
						<div className='indent'>
						被保险人因遭受意外伤害事故或在等待期后罹患本合同定义的重大疾病以外的疾病，在
		医院经具有相应资质的医生诊断必须住院治疗的，保险人对被保险人在住院期间发生的应当
		由被保险人支付的<span className='bold'>必需且合理</span>的<span>住院医疗费用，保险人在扣除本合同约定的免赔额后，按
		2 / 30
		照约定的赔偿比例进行赔偿。</span>
						</div>
						<div className='indent'>
						2.特殊门诊医疗费用
						</div>
						<div className='indent'>
						被保险人因遭受意外伤害事故或在等待期后罹患本合同定义的重大疾病以外的疾病，在
		医院经具有相应资质的医生诊断必需接受以下特殊门诊治疗的，保险人对被保险人在接受特
		殊门诊治疗期间发生的应当由被保险人支付的必需且合理的特殊门诊医疗费用，<span className='bold'>保险人在扣
		除本合同约定的免赔额后，按照约定的赔偿比例进行赔偿。</span>
						</div>
						<div className='indent bold'>
						特殊门诊治疗包括：
						</div>
						<div className='indent'>
						（1）门诊肾透析；
						</div>
						<div className='indent'>
						（2）<span className='bold'>化学疗法、放射疗法、肿瘤免疫疗法、肿瘤内分泌疗法、肿瘤靶向疗法；</span>
						</div>
						<div className='indent'>
						（3）器官移植后的门诊抗排异治疗。
						</div>
						<div className='indent'>
						3.门诊手术医疗费用
						</div>
						<div className='indent'>
						被保险人因遭受意外伤害事故或在等待期后罹患本合同定义的重大疾病以外的疾病，在
		医院经具有相应资质的医生诊断必须接受门诊手术治疗的，保险人对被保险人在接受门诊手
		术治疗期间发生的应当由被保险人支付的必需且合理的门诊手术费用，<span className='bold'>保险人在扣除本合同
		约定的免赔额后，按照约定的赔偿比例进行赔偿。</span>
						</div>
						<div className='indent'>
						4.住院前后门急诊医疗费用
						</div>
						<div className='indent'>
						被保险人因遭受意外伤害事故或在等待期后罹患本合同定义的重大疾病以外的疾病，在
		医院经具有相应资质的医生诊断必须接受住院治疗，在住院前（含住院当日）7日（含）和
		出院后（含出院当日）30日（含）内，被保险人因与该次住院相同原因而接受门急诊治疗的，
		保险人对被保险人在接受门急诊治疗期间发生的应当由被保险人支付的必需且合理的<span className='bold'>门急
		诊医疗费用（此项费用不包括本条第（一）款第2项、第3项约定的特殊门诊医疗费用和门
		诊手术医疗费用），保险人在扣除本合同约定的免赔额后，按照约定的赔偿比例进行赔偿。</span>
						</div>
						<div className='indent bold'>
						保险人对于以上四类医疗费用的累计赔偿金额之和以本合同约定的一般医疗保险金的
			保险金额为限，保险人一次或累计赔偿金额达到本合同约定的保险金额时，保险人对被保
			险人在一般医疗保险金项下的保险责任终止。
						</div>
						<div className='indent'>
						（二）重大疾病医疗保险金
						</div>
						<div className='indent'>
						在本合同保险期间内，被保险人经医院初次确诊因意外伤害事故导致罹患本合同所定义
		的重大疾病（无论一种或多种），或者在等待期后经医院初次确诊非因意外伤害事故导致罹
		患本合同所定义的重大疾病（无论一种或多种），且无论前述哪一种情形，在医院经具有相
		应资质的医生诊断并接受相关治疗，对于被保险人支付的以下医疗费用，保险人依照本合同
		<span className='bold'>约定的赔偿比例赔偿</span>重大疾病医疗保险金：
						</div>
						<div className='indent'>
						1.重大疾病住院医疗费用
						</div>
						<div className='indent'>
						被保险人经医院初次确诊因意外伤害事故导致罹患本合同所定义的重大疾病（无论一种
		或多种），或者在等待期后经医院初次确诊非因意外伤害事故导致罹患本合同所定义的重大
		疾病（无论一种或多种），并在医院经具有相应资质的医生诊断必须住院治疗的，保险人对
		被保险人在住院期间发生的应当由被保险人支付的必需且合理的<span className='bold'>重大疾病住院医疗费用，按
		照本合同约定的赔偿比例进行赔偿。</span>
						</div>
						<div className='indent'>
						2.重大疾病特殊门诊医疗费用
						</div>
						<div className='indent'>
						被保险人经医院初次确诊因意外伤害事故导致罹患本合同所定义的重大疾病（无论一种
		或多种），或者在等待期后经医院初次确诊非因意外伤害事故导致罹患本合同所定义的重大
		疾病（无论一种或多种），并在医院经具有相应资质的医生诊断必须接受特殊门诊治疗的，
		保险人对被保险人在接受特殊门诊治疗期间发生的应当由被保险人支付的必需且合理的<span className='bold'>重
		大疾病特殊门诊医疗费用，按照本合同约定的赔偿比例进行赔偿。</span>
						</div>
						<div className='indent bold'>
						特殊门诊治疗包括：
						</div>
						<div className='indent'>
						（1）门诊肾透析；
						</div>
						<div className='indent'>
						（2）门诊恶性肿瘤治疗，包括化学疗法、放射疗法、肿瘤免疫疗法、肿瘤内分泌疗法、
			肿瘤靶向疗法；
						</div>
						<div className='indent'>
						（3）器官移植后的门诊抗排异治疗。
						</div>
						<div className='indent'>
						3.重大疾病门诊手术医疗费用
						</div>
						<div className='indent'>
						被保险人经医院初次确诊因意外伤害事故导致罹患本合同所定义的重大疾病（无论一种
		或多种），或者在等待期后经医院初次确诊非因意外伤害事故导致罹患本合同所定义的重大
		疾病（无论一种或多种），并在医院经具有相应资质的医生诊断必须接受门诊手术治疗的，
		保险人对被保险人在接受门诊手术治疗期间发生的应当由被保险人支付的必需且合理的重
		大疾病门诊手术医疗费用，<span className='bold'>按照本合同约定的赔偿比例进行赔偿。</span>
						</div>
						<div className='indent'>
						4.重大疾病住院前后门急诊医疗费用
						</div>
						<div className='indent'>
						被保险人经医院初次确诊因意外伤害事故导致罹患本合同所定义的重大疾病（无论一种
		或多种），或者在等待期后经医院初次确诊非因意外伤害事故导致罹患本合同所定义的重大
		疾病（无论一种或多种），并在医院经具有相应资质的医生诊断必须接受住院治疗，在住院
		前（含住院当日）7日（含）和出院后（含出院当日）30日（含）内，因与该次住院相同原
		因而接受门急诊治疗的，保险人对被保险人在接受门急诊治疗期间发生的应当由被保险人支
		付的必需且合理的<span className='bold'>重大疾病门急诊医疗费用（不包括本条第（二）款第2项、第3项约定的
		重大疾病特殊门诊医疗费用和重大疾病门诊手术医疗费用），按照本合同约定的赔偿比例
		进行赔偿。</span>
						</div>
						<div className='indent bold'>
						保险人对于以上四类医疗费用的累计赔偿金额之和以本合同约定的重大疾病医疗保险
			金的保险金额为限，保险人一次或累计赔偿金额达到本合同约定的重大疾病医疗保险金额
			时，保险人对被保险人在重大疾病医疗保险金项下的保险责任终止。
						</div>
						<div className='indent bold'>
						<span className='bold' >第七条</span> <span className='space'></span> 补偿原则和赔偿标准
					</div>
						<div className='indent bold'>
						（一）本合同为费用补偿型保险合同，适用医疗费用补偿原则。若被保险人已从其他
			途径（包括但不限于社会基本医疗保险、公费医疗、工作单位、侵权人或侵权责任承担方、
			保险人在内的任何商业保险机构等）获得医疗费用补偿，则保险人仅对被保险人实际发生
			的医疗费用扣除其从其他途径所获医疗费用补偿后的余额按照本合同的约定进行赔偿。被
			保险人的社保卡个人账户部分支出视为个人支付，不属于已获得的医疗费用补偿，不进行
			前述扣除；
						</div>
						<div className='indent bold'>
						（二）本合同中所指免赔额指年免赔额。被保险人已获得的医疗费用补偿（指除社会
			基本医疗保险和公费医疗保险以外被保险人从其他途径获得的医疗费用补偿）可抵扣年免
			4 / 30
			赔额；
						</div>
						<div className='indent bold'>
						（三）若被保险人经医院初次确诊因意外伤害事故导致罹患本合同所定义的重大疾病
			（无论一种或多种），或者在等待期后经医院初次确诊非因意外伤害事故导致罹患本合同
			所定义的重大疾病（无论一种或多种），且无论前述哪一种情形，在医院经具有相应资质
			的医生诊断必需接受相关治疗的，对于被保险人因该重大疾病所产生的符合本合同约定的
			医疗费用，保险人在赔偿保险金时，不再扣除免赔额；
						</div>
						<div className='indent bold'>
						（四）被保险人以参加社会基本医疗保险或者公费医疗身份投保，并以参加社会基本
			医疗保险或者公费医疗身份就诊并结算的，赔偿比例为100%；被保险人以参加社会基本医
			疗保险或者公费医疗身份投保，但未以参加社会基本医疗保险或者公费医疗身份就诊并结
			算的，赔偿比例为60%；被保险人以未参加社会基本医疗保险或者公费医疗身份投保，赔偿
			比例为100%；
						</div>
						<div className='indent bold'>
						（五）被保险人在本合同保险期间届满前180天内（含 ）经医院初次确诊罹患本合同
		所定义的重大疾病（无论一种或者多种），并在医院接受住院治疗，如果在本合同保险期
		间届满之日该次住院治疗仍未结束的，保险人对于被保险人自该次住院开始之日起（含住
		院当日）180天内（含第180天）所发生的同一次住院的医疗费用，且符合本合同第六条载
		明的第（二）项保险责任范围内的，保险人按照本合同的约定承担保险责任，同时本合同
		终止。
						</div>
					</div>
		
		
					
		
		
		
		
					<span className='licenceSection center bold' >责任免除</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第八条</span> <span className='space'></span> 对于被保险人因以下任何情形而发生的任何费用，保险人不承担赔偿保险金
		的责任：
					</div>
						<div className='indent bold'>
						（一）投保人对被保险人的故意杀害、故意伤害；
						</div>
						<div className='indent bold'>
						（二）被保险人故意自伤、故意犯罪或者抗拒依法采取的刑事强制措施,或被国家机关
		依法拘禁、采取刑事强制措施或服刑期间伤病；
						</div>
						<div className='indent bold'>
						（三）被保险人酗酒、殴斗、主动吸食或注射毒品，违反规定使用麻醉或精神药品；
						</div>
						<div className='indent bold'>
						（四）被保险人酒后驾驶、无合法有效驾驶证驾驶或驾驶无有效行驶证照的机动车；
						</div>
						<div className='indent bold'>
						（五）战争、军事冲突、暴乱或武装叛乱；
						</div>
						<div className='indent bold'>
						（六）核爆炸、核辐射或核污染；
						</div>
						<div className='indent bold'>
						（七）被保险人从事潜水、滑水、滑雪、滑冰、滑翔翼、热气球、跳伞、攀岩、探险
		活动、武术比赛、摔跤比赛、柔道、空手道、跆拳道、拳击、特技表演、蹦极、赛马、赛
		车、各种车辆表演及车辆竞赛等高风险运动；
						</div>
						<div className='indent bold'>
						（八）被保险人未遵医嘱私自服用、涂用、注射药物；
						</div>
						<div className='indent bold'>
						（九）被保险人分娩（含剖腹产）、流产、宫外孕、不孕不育治疗、人工受精、怀孕、
		堕胎、节育（含绝育）、产前产后检查以及由以上原因引起的并发症；
						</div>
						<div className='indent bold'>
						（十）被保险人患精神和行为障碍（以世界卫生组织颁布的《疾病和有关健康问题的
			国际统计分类（ICD－10）》为准）；
						</div>
						<div className='indent bold'>
						（十一）被保险人接受矫形、视力矫正手术、美容、变性手术、牙科保健及非意外伤
		害事故所致的整容手术；
						</div>
						<div className='indent bold'>
						（十二）被保险人在投保前罹患的、投保时尚未治愈的疾病；被保险人在投保时未如
		实告知的既往疾病；
						</div>
						<div className='indent bold'>
						（十三）被保险人在非本合同约定的医院就诊发生的医疗费用；
						</div>
						<div className='indent bold'>
						（十四）被保险人患遗传性疾病，先天性畸形、变形或染色体异常（依据世界卫生组
		织《疾病和有关健康问题的国际统计分类》（ICD10））；
						</div>
						<div className='indent bold'>
						（十五）被保险人感染艾滋病病毒或患艾滋病（但符合本合同“输血原因致人类免疫
		缺陷病毒（HIV）感染”、 “职业原因致人类免疫缺陷病毒（HIV）感染”及“器官移植原
		因导致 HIV 感染”定义的不在此限，但上述职业类型应当符合中华人民共和国相关法律法
		规及公序良俗要求）；
						</div>
						<div className='indent bold'>
						（十六）康复治疗或训练、修养或疗养、健康体检、隔离治疗、保健食品及用品、体
		外或植入的医疗辅助装置或用具（义肢、轮椅、拐杖、助听器、眼镜或隐形眼镜、义眼等
		等）及其安装费用；
						</div>
						<div className='indent bold'>
						（十七）未经医生处方自行购买的药品或非医院药房购买的药品、医生开具单次处方
		超过 30 天部分的药品费用；
						</div>
						<div className='indent bold'>
						（十八）预防性治疗、实验性或试验性治疗。
						</div>
						</div>
		
					
						<span className='licenceSection center bold' >保险金额和保险费</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第九条</span> <span className='space'></span> <span  className='bold'>保险金额是保险人承担赔偿保险金责任的最高限额。</span>本合同的一般医疗保险
		金的保险金额为人民币叁佰万元；本合同的重大疾病医疗保险金的保险金额为<span className='bold'>人民币陆佰万
		元；</span>本合同的年度累计保险金额为<span className='bold'>人民币陆佰万元。保险人对于被保险人在保险期间内及
		保险期间届满后的所有赔偿金额之和不超过本合同保险单载明的年度累计保险金额。</span>
					</div>
					<div className='indent '>
						<span className='bold' >第十条</span> <span className='space'></span> 保险费依据保险金额与保险费率计收，在保险单中载明。保险费支付方式由投
		保人在投保时与保险人约定，并在保险单上载明。
					</div>
						</div>
		
					
		
		
						<span className='licenceSection center bold' >免赔额</span>
					<div className='licenceContent'>
						<div className='indent bold'>
						<span className='bold' >第十一条</span> <span className='space'></span> 本合同一般医疗保险金的免赔额为人民币壹万元；本合同重大疾病医疗
		保险金无免赔额。
					</div>
						
					</div>
					
					<span className='licenceSection center bold' >犹豫期</span>
					<div className='licenceContent'>
						<div className='indent bold'>
						<span className='bold' >第十二条</span> <span className='space'></span> 自本合同生效之日起15日（含第15日）为犹豫期。投保人在此期间提出
			解除本合同，保险人将无息退还投保人所支付的全部保险费。
			保险金无免赔额。
					</div>
					<div className='indent bold'>
					自保险人收到投保人解除合同的申请时起，本合同即被解除，保险人自始不承担保险
				责任。
					</div>
					<div className='indent bold'>
					犹豫期后申请解除合同会遭受一定经济损失。
					</div>
						
					</div>
					
					<span className='licenceSection center bold' >保险期限</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第十三条</span> <span className='space'></span> 本合同的保险期间为1年，自保险单或其他保险凭证上载明的保险期间起
		始日零时起至保险期间期满日的24时止。
					</div>
						</div>
		
					
						<span className='licenceSection center bold' >续保</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第十四条</span> <span className='space'></span> <span className='bold'>本合同为非保证续保合同。</span>本合同保险期间为一年，保险期间届满前三十
		日内（含第三十日），投保人需要重新向保险人申请投保本保险，经保险人审核同意后，投
		保人交纳保险费，并获得新的保险合同。
					</div>
					</div>
					
		
					<span className='licenceSection center bold' >保险人义务</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第十五条</span> <span className='space'></span> 本合同成立后，保险人应当及时向投保人签发保险单或其他保险凭证。
					</div>
					<div className='indent '>
						<span className='bold' >第十六条</span> <span className='space'></span> 订立本合同时，保险人应当向投保人说明本合同的内容。对本合同中免除
			保险人责任的条款，保险人在订立合同时应当在投保单、保险单或其他保险凭证上作出足以
			引起投保人注意的提示，并对该条款的内容以书面或口头形式向投保人作出明确说明；未作
			提示或者明确说明的，该条款不产生效力。
					</div>
					<div className='indent '>
					保险人认为被保险人或受益人提供的有关索赔的证明和资料不完整的，应当及时一次性
				通知投保人、被保险人或受益人补充提供。
					</div>
					<div className='indent '>
						<span className='bold' >第十七条</span> <span className='space'></span> 保险人收到被保险人或受益人的赔偿保险金请求后，应当及时作出是否属
			于保险责任的核定；情形复杂的，应当在30日内作出核定，但本合同另有约定的除外。
					</div>
					<div className='indent '>
					保险人应当将核定结果通知被保险人或受益人；对属于保险责任的，在与被保险人或受
				益人达成赔偿保险金的协议后 10 日内，履行赔偿保险金义务。本合同对赔偿保险金的期限
				有约定的，保险人应当按照约定履行赔偿保险金的义务。
					</div>
					<div className='indent '>
					保险人依照前款约定作出核定后，对不属于保险责任的，应当自作出核定之日起 3 日内
				向被保险人或受益人发出拒绝赔偿保险金通知书，并说明理由。
					</div>
					<div className='indent '>
						<span className='bold' >第十八条</span> <span className='space'></span> 保险人自收到赔偿保险金的请求和有关证明、资料之日起60日内，对其赔
		偿保险金的数额不能确定的，应当根据已有证明和资料可以确定的数额先予赔偿；保险人最
		终确定赔偿保险金的数额后，应当支付相应的差额。
					</div>
						</div>
		
					
		
		
		
						<span className='licenceSection center bold' >投保人、被保险人义务</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第十九条</span> <span className='space'></span> 投保人应该按照本合同约定向保险人交纳保险费。
					</div>
						<div className='indent'>
						投保人选择一次性交纳保险费的，应当在本合同成立时一次性交清保险费。<span className='bold'>投保人未按
		本款约定交清保险费的,本合同不生效,对本合同生效前发生的保险事故，保险人不承担保
		险责任。</span>
						</div>
						<div className='indent'>
						投保人选择分期交纳保险费的，应当在本合同成立时交清首期保险费。<span className='bold'>投保人未按本款
		约定交清首期保险费的,本合同不生效,对本合同生效前发生的保险事故,保险人不承担保
		险责任。</span>
						</div>
						<div className='indent'>
						投保人选择分期交纳保险费的，在交纳首期保险费后，投保人应当在每个<span className='bold'>保险费约定支
		付日</span>交纳其余各期的保险费。若投保人未按约定交纳保险费，保险人允许投保人在保险人催
		告之日起30日内（含第30日）补交保险费，如果被保险人在此期限内发生保险事故，<span className='bold'>保险人
		将扣减投保人欠交的保险费后按照本合同约定赔偿保险金。</span>
						</div>
						<div className='indent bold'>
						若投保人在保险人催告之日起30日内（含第30日）未补交保险费，本合同自上述期限
			届满之日的24时起效力中止，如果被保险人在合同效力中止期间发生保险事故的，保险人
			不承担保险责任。
						</div>
						<div className='indent'>
						本合同效力依照前款约定中止的，<span className='bold'>经投保人申请并经保险人审核同意，同时经投保人
		补交欠交保险费后，合同效力恢复。</span>
						</div>
						<div className='indent '>
						<span className='bold' >第二十条</span> <span className='space'></span> 订立本合同，保险人就被保险人的有关情况提出询问的，投保人应当如实
			告知。
					</div>
					<div className='indent bold'>
					投保人故意或者因重大过失未履行前款规定的义务，足以影响保险人决定是否同意承
				保或者提高保险费率的，保险人有权解除本合同。
						</div>
						<div className='indent'>
						前款规定的合同解除权，自保险人知道有解除事由之日起，超过 30 日不行使而消灭。
						</div>
						<div className='indent bold'>
						投保人故意不履行如实告知义务的，保险人对于合同解除前发生的保险事故，不承担
			赔偿保险金责任，并不退还保险费。
						</div>
						<div className='indent bold'>
						投保人因重大过失未履行如实告知义务，对保险事故的发生有严重影响的，保险人对
			于合同解除前发生的保险事故，不承担赔偿保险金责任，但应当退还保险费。
						</div>
						<div className='indent'>
						保险人在本合同订立时已经知道投保人未如实告知的情况的，保险人不得解除合同；发
			生保险事故的，保险人应当承担赔偿保险金的责任。
						</div>
						<div className='indent '>
						<span className='bold' >第二十一条</span> <span className='space'></span> 投保人住所或通讯地址变更时，应及时以书面形式通知保险人。投保人未
			通知的，保险人按本合同所载的最后住所或通讯地址发送的有关通知，均视为已发送给投保
			人。
					</div>
					<div className='indent '>
						<span className='bold' >第二十二条</span> <span className='space'></span>年龄的计算及年龄、性别错误的处理
					</div>
					<div className='indent'>
					（一）被保险人的投保年龄按周岁计算；
						</div>
						<div className='indent'>
						（二）投保人在投保时应将被保险人的真实年龄与性别在投保单上填明，若发生错误，
			保险人按照下列规定办理：
						</div>
						<div className='indent bold'>
						（1）投保人申报的被保险人年龄不真实，且真实年龄不符合本合同约定的年龄限制，
			保险人对该被保险人不负保险责任，保险人可以解除本合同，并向投保人退还剩余部分保
			险费；
						</div>
						<div className='indent bold'>
						（2）投保人申报的被保险人年龄或性别不真实，导致投保人实付保险费少于应付保险
			费的，保险人有权更正并要求投保人补交保险费，或在赔偿保险金时按照实付保险费与应
			付保险费的比例赔偿；
						</div>
						<div className='indent'>
						（3）投保人申报的被保险人年龄或性别不真实，导致投保人实付保险费多于应付保险
			费，保险人应将多收的保险费无息退还投保人。
						</div>
						<div className='indent '>
						<span className='bold' >第二十三条</span> <span className='space'></span>保险事故发生后，投保人、被保险人或受益人应当及时通知保险人。故意
			或者因重大过失未及时通知，致使保险事故的性质、原因、损失程度等难以确定的，保险
			人对无法确定的部分，不承担赔偿保险金责任，但保险人通过其他途径已经及时知道或者
			应当及时知道保险事故发生的除外。
					</div>
					<div className='indent'>
					上述约定，不包括因不可抗力而导致的迟延。
						</div>
						</div>
		
					
		
						<span className='licenceSection center bold' >保险金申请与赔偿</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第二十四条</span> <span className='space'></span> <span className='bold'>保险金申请人</span>向保险人申请赔偿保险金时，应提交以下材料。保险金申请
		人因特殊原因不能提供以下材料的，应提供其他合法有效的材料。<span className='bold'>保险金申请人未能提供有
		关材料，导致保险人无法核实该申请的真实性的，保险人对无法核实部分不承担赔偿保险
		金的责任。</span>
					</div>
						<div className='indent'>
						（1）保险金赔偿申请书；
						</div>
						<div className='indent'>
						（2）保险合同或其他保险凭证；
						</div>
						<div className='indent'>
						（3）保险金申请人的有效身份证件；
						</div>
						<div className='indent'>
						（4）医院出具的完整病历资料(包括门急诊病历、住院病历或出院小结以及相关的检查
		报告)；
						</div>
						<div className='indent'>
						（5）医院出具的附有病历、必要病理检验、血液检验、影像学报告及其他科学方法检
		验报告的疾病诊断证明书；
						</div>
						<div className='indent'>
						（6）医疗费用原始收据、医疗费用明细清单及医疗费用分割单（若发生手术费用，还
		需提供手术费用的原始凭证，被保险人享有社会基本医疗保险或公费医疗保障的，需包含社
		会基本医疗保险或公费医疗有关规定取得医疗费用补偿的证明）；
						</div>
						<div className='indent'>
						（7）被保险人罹患本合同所定义的重大疾病的，还应提供由医院具有相应资质的医生
		出具的对被保险人的疾病诊断证明书以及由医院出具的与该疾病诊断证明书相关的病理显
		微镜检查、血液检验及其他科学方法检验报告；
						</div>
						<div className='indent'>
						（8）保险金申请人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他
		证明和资料；
						</div>
						<div className='indent'>
						（9）若保险金申请人委托他人申请的，还应提供授权委托书原件、委托人和受托人的
		身份证明等相关证明文件。受益人为无民事行为能力人或者限制民事行为能力人的，由其监
		护人代为申领保险金，并需要提供监护人的身份证明等资料。
						</div>
						</div>
		
						<span className='licenceSection center bold' >诉讼时效期间</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第二十五条</span> <span className='space'></span> 保险金申请人向保险人请求赔偿保险金的诉讼时效期间为二年，自其知道
		或者应当知道保险事故发生之日起计算。
					</div>
						</div>
					
						<span className='licenceSection center bold' >争议处理和法律适用</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第二十六条</span> <span className='space'></span>因履行本合同发生的争议，由当事人协商解决。协商不成的，提交保险单
			载明的仲裁机构仲裁；保险单未载明仲裁机构且争议发生后未达成仲裁协议的，依法向中华
			人民共和国（不含港、澳、台地区）有管辖权的人民法院起诉。
					</div>
					<div className='indent '>
						<span className='bold' >第二十七条</span> <span className='space'></span>与本合同有关的以及履行本合同产生的一切争议处理适用中华人民共和
		国法律（不包括港、澳、台地区法律）。
					</div>
						</div>
		
					
						<span className='licenceSection center bold' >其他事项</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第二十八条</span> <span className='space'></span>本合同成立后将持续有效，直至保险单约定的保险期间届满或达到本合同
		约定的终止条件。
					</div>
					<div className='indent '>
					保险责任开始前，投保人要求解除本合同的，保险人应当无息全额退还投保人已交纳的
				保险费。
					</div>
					<div className='indent '>
					保险责任开始后，投保人要求解除本合同的，自保险人接到解除合同申请书之时起，本
		合同解除，<span className='bold'>保险人自收到解除合同申请之日起 30 日内向投保人退还本合同的剩余部分保险
		费。若本合同已发生保险金赔偿，剩余部分保险费为零。</span>
						</div>
						<div className='indent '>
						<span className='bold' >第二十九条</span> <span className='space'></span>投保人解除本合同时，应提供下列证明文件和资料：
					</div>
					<div className='indent '>
					（一）保险合同解除申请书；
					</div>
					<div className='indent '>
					（二）保险合同凭据；
					</div>
					<div className='indent '>
					（三）保险费交付凭证；
					</div>
					<div className='indent '>
					（四）投保人身份证明。
					</div>
						</div>
		
						<span className='licenceSection center bold' >释义</span>
					<div className='licenceContent'>
						<div className='indent '>
						<span className='bold' >第三十条</span> <span className='space'></span> 除另有约定外，本合同中的下列词语具有如下含义：
					</div>
						<div className='indent'>
						<span className='bold'>【合法有效】</span>本合同所指合法有效均以中华人民共和国法律、行政法规、地方性法规、
			行政规章及有关规范性法律文件的规定为判定依据。
						</div>
						<div className='indent'>
						<span className='bold'>【保险人】</span>本合同所指的保险人指泰康在线财产保险股份有限公司
						</div>
						<div className='indent'>
						<span className='bold'>【周岁】</span>指按有效身份证件中记载的出生日期计算的年龄，自出生之日起为零周岁，每
			经过一年增加一岁，不足一年的不计。例如，出生日期为 2000 年 9 月 1 日，2000 年 9 月 1
			日至 2001 年 8 月 31 日期间为 0 周岁，2001 年 9 月 1 日至 2002 年 8 月 31 日期间为 1 周岁，
			以此类推。
						</div>
						<div className='indent'>
						<span className='bold'>【续保】</span>指投保人为被保险人续保同一险种，且续保保单的生效日为原保单到期日的次
			日。
						</div>
						<div className='indent'>
						<span className='bold'>【重大疾病】</span>本合同所定义的重大疾病共有99种，是指被保险人在本合同的责任有效期
		10 / 30
		间内经医院专科医生明确诊断初次患下列疾病或初次达到下列疾病状态或在医院初次接受
		下列手术，其中第1至25种重大疾病为中国保险行业协会颁布的《重大疾病保险的疾病定义
		使用规范》（以下简称“规范”）规定的疾病，且疾病名称和疾病定义与“规范”一致，第
		26至99种重大疾病为“规范”规定范围之外的疾病。重大疾病的名称及定义如下：
						</div>
						<div className='indent'>
						<span className='bold'>（一）恶性肿瘤：</span>指恶性细胞不受控制的进行性增长和扩散，浸润和破坏周围正常组织，
			可以经血管、淋巴管和体腔扩散转移到身体其它部位的疾病。经病理学检查结果明确诊断，
			临床诊断属于世界卫生组织《疾病和有关健康问题的国际统计分类》（ICD-10）的恶性肿瘤
			范畴。
						</div>
						<div className='indent bold'>
						下列疾病不在保障范围内：
						</div>
						<div className='indent bold'>
						（1）原位癌；
						</div>
						<div className='indent bold'>
						（2）相当于 Binet 分期方案 A 期程度的慢性淋巴细胞白血病；
						</div>
						<div className='indent bold'>
						（3）相当于 Ann Arbor 分期方案 I 期程度的何杰金氏病；
						</div>
						<div className='indent bold'>
						（4）皮肤癌（不包括恶性黑色素瘤及已发生转移的皮肤癌）；
						</div>
						<div className='indent bold'>
						（5） TNM 分期为 T1N0M0 期或更轻分期的前列腺癌；
						</div>
						<div className='indent bold'>
						（6）感染艾滋病病毒或患艾滋病期间所患恶性肿瘤。
						</div>
						<div className='indent'>
						<span className='bold'>（二）急性心肌梗塞：</span>指因冠状动脉阻塞导致的相应区域供血不足造成部分心肌坏死。
		<span className='bold'>须满足下列至少三项条件：</span>
						</div>
						<div className='indent '>
						（1）典型临床表现，例如急性胸痛等；
						</div>
						<div className='indent '>
						（2）新近的心电图改变提示急性心肌梗塞；
						</div>
						<div className='indent '>
						（3）心肌酶或肌钙蛋白有诊断意义的升高，或呈符合急性心肌梗塞的动态性变化；
						</div>
						<div className='indent '>
						（4）发病 90 天后，经检查证实左心室功能降低，如左心室射血分数低于 50%。
						</div>
						<div className='indent'>
						<span className='bold'>（三）脑中风后遗症：</span>指因脑血管的突发病变引起脑血管出血、栓塞或梗塞，并导致神
			经系统永久性的功能障碍。神经系统永久性的功能障碍，指疾病确诊 180 天后，仍遗留下列
			一种或一种以上障碍：
						</div>
						<div className='indent '>
						（1）一肢或一肢以上<span className='bold'>肢体机能完全丧失；</span>
						</div>
						<div className='indent '>
						（2）<span className='bold'>语言能力或咀嚼吞咽能力完全丧失；</span>
						</div>
						<div className='indent '>
						（3）自主生活能力完全丧失，无法独立完成<span className='bold'>六项基本日常生活活动</span>中的三项或三项以
			上。
						</div>
						<div className='indent bold'>
						（四）重大器官移植术或造血干细胞移植术：
						</div>
						<div className='indent '>
						（1）重大器官移植术，指因相应器官功能衰竭，已经实施了肾脏、肝脏、心脏或肺脏
			的异体移植手术。
						</div>
						<div className='indent '>
						（2）造血干细胞移植术，指因造血功能损害或造血系统恶性肿瘤，已经实施了造血干
			细胞（包括骨髓造血干细胞、外周血造血干细胞和脐血造血干细胞）的异体移植手术。
						</div>
						<div className='indent'>
						<span className='bold'>（五）冠状动脉搭桥术（或称冠状动脉旁路移植术）：</span>指为治疗严重的冠心病，实际
			11 / 30
			实施了开胸进行的冠状动脉血管旁路移植的手术。
						</div>
						<div className='indent bold'>
						冠状动脉支架植入术、心导管球囊扩张术、激光射频技术及其它非开胸的介入手术、
			腔镜手术不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（六）终末期肾病（或称慢性肾功能衰竭尿毒症期）：</span>指双肾功能慢性不可逆性衰竭，
			达到尿毒症期，经诊断后已经进行了至少 90 天的规律性透析治疗或实施了肾脏移植手术。
						</div>
						<div className='indent'>
						<span className='bold'>（七）多个肢体缺失：</span>指因疾病或意外伤害导致两个或两个以上肢体自腕关节或踝关节
			近端（靠近躯干端）以上完全性断离。
						</div>
						<div className='indent'>
						<span className='bold'>（八）急性或亚急性重症肝炎：</span>指因肝炎病毒感染引起肝脏组织弥漫性坏死，导致急性
		肝功能衰竭，且经血清学或病毒学检查证实，<span className='bold'>并须满足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）重度黄疸或黄疸迅速加重；
						</div>
						<div className='indent'>
						（2）肝性脑病；
						</div>
						<div className='indent'>
						（3）B 超或其它影像学检查显示肝脏体积急速萎缩；
						</div>
						<div className='indent'>
						（4）肝功能指标进行性恶化。
						</div>
						<div className='indent'>
						<span className='bold'>（九）良性脑肿瘤：</span>指脑的良性肿瘤，已经引起颅内压增高，临床表现为视神经乳头水
		肿、精神症状、癫痫及运动感觉障碍等，并危及生命。须由头颅断层扫描（CT）、核磁共振
		检查（MRI）或正电子发射断层扫描（PET）等影像学检查证实，<span className='bold'>并须满足下列至少一项条件：</span>
						</div>
						<div className='indent'>
						（1）实际实施了开颅进行的脑肿瘤完全切除或部分切除的手术；
						</div>
						<div className='indent'>
						（2）实际实施了对脑肿瘤进行的放射治疗。
						</div>
		
						<div className='indent bold'>
						脑垂体瘤、脑囊肿、脑血管性疾病不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（十）慢性肝功能衰竭失代偿期：</span>指因慢性肝脏疾病导致肝功能衰竭。<span className='bold'>须满足下列所
		有条件：</span>
						</div>
						<div className='indent'>
						（1）持续性黄疸；
						</div>
						<div className='indent'>
						（2）腹水；
						</div>
						<div className='indent'>
						（3）肝性脑病；
						</div>
						<div className='indent'>
						（4）充血性脾肿大伴脾功能亢进或食管胃底静脉曲张。
						</div>
						<div className='indent bold'>
						因酗酒或药物滥用导致的肝功能衰竭不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（十一）脑炎后遗症或脑膜炎后遗症：</span>指因患脑炎或脑膜炎导致的神经系统永久性的
			功能障碍。神经系统永久性的功能障碍，指疾病确诊 180 天后，仍遗留下列一种或一种以上
			障碍：
						</div>
						<div className='indent'>
						（1）一肢或一肢以上肢体机能完全丧失；
						</div>
						<div className='indent'>
						（2）语言能力或咀嚼吞咽能力完全丧失；
						</div>
						<div className='indent'>
						（3）自主生活能力完全丧失，无法独立完成六项基本日常生活活动中的三项或三项以
			上。
						</div>
						<div className='indent'>
						<span className='bold'>（十二）深度昏迷：</span>指因疾病或意外伤害导致意识丧失，对外界刺激和体内需求均无反
			应，昏迷程度按照格拉斯哥昏迷分级（Glasgow coma scale）结果为 5 分或 5 分以下，且已
			经持续使用呼吸机及其它生命维持系统 96 小时以上。
						</div>
						<div className='indent bold'>
						因酗酒或药物滥用导致的深度昏迷不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（十三）双耳失聪：</span>指因疾病或意外伤害导致双耳听力<span className='bold'>永久不可逆</span>性丧失，在 500 赫兹、
			1000 赫兹和 2000 赫兹语音频率下，平均听阈大于 90 分贝，且经纯音听力测试、声导抗检
			测或听觉诱发电位检测等证实。
						</div>
						<div className='indent bold'>
						被保险人在3周岁之前因疾病导致的双耳失聪不在保障范围内。先天性疾病所致的听力
				丧失不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（十四）双目失明：</span>指因疾病或意外伤害导致双眼视力永久不可逆性丧失，<span className='bold'>双眼中较好
		眼须满足下列至少一项条件：</span>
						</div>
						<div className='indent '>
						（1）眼球缺失或摘除；
						</div>
						<div className='indent '>
						（2）矫正视力低于 0.02（采用国际标准视力表，如果使用其它视力表应进行换算）；
						</div>
						<div className='indent '>
						（3）视野半径小于 5 度。
						</div>
						<div className='indent bold'>
						被保险人在3周岁之前因疾病导致的双目失明不在保障范围内。先天性疾病所致的视力
			丧失不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（十五）瘫痪：</span>指因疾病或意外伤害导致两肢或两肢以上肢体机能永久完全丧失。肢体
			机能永久完全丧失，指疾病确诊 180 天后或意外伤害发生 180 天后，每肢三大关节中的两大
			关节仍然完全僵硬，或不能随意识活动。
						</div>
						<div className='indent'>
						<span className='bold'>（十六）心脏瓣膜手术：</span>指为治疗心脏瓣膜疾病，实际实施了开胸进行的心脏瓣膜置换
			或修复的手术。
						</div>
						<div className='indent'>
						<span className='bold'>（十七）严重阿尔茨海默病：</span>指因大脑进行性、不可逆性改变导致智能严重衰退或丧失，
			临床表现为明显的认知能力障碍、行为异常和社交能力减退，其日常生活必须持续受到他人
			监护。须由头颅断层扫描（CT）、核磁共振检查（MRI）或正电子发射断层扫描（PET）等影
			像学检查证实，且自主生活能力完全丧失，无法独立完成六项基本日常生活活动中的三项或
			三项以上。
						</div>
						<div className='indent bold'>
						神经官能症和精神疾病不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（十八）严重脑损伤：</span>指因头部遭受机械性外力，引起脑重要部位损伤，导致神经系统
			永久性的功能障碍。须由头颅断层扫描（CT）、核磁共振检查（MRI）或正电子发射断层扫
			描（PET）等影像学检查证实。神经系统永久性的功能障碍，指脑损伤 180 天后，仍遗留下
			列一项或一项以上障碍：
						</div>
						<div className='indent '>
						（1）一肢或一肢以上肢体机能完全丧失；
						</div>
						<div className='indent '>
						（2）语言能力或咀嚼吞咽能力完全丧失；
						</div>
						<div className='indent '>
						（3）自主生活能力完全丧失，无法独立完成六项基本日常生活活动中的三项或三项以
			上。
						</div>
						<div className='indent'>
						<span className='bold'>（十九）严重帕金森病：</span>是一种中枢神经系统的退行性疾病，临床表现为震颤麻痹、共
		13 / 30
		济失调等。<span className='bold'>须满足下列所有条件：</span>
						</div>
						<div className='indent '>
						（1）药物治疗无法控制病情；
						</div>
						<div className='indent '>
						（2）自主生活能力完全丧失，无法独立完成六项基本日常生活活动中的三项或三项以
			上。
						</div>
						<div className='indent bold'>
						继发性帕金森综合征不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（二十）严重Ⅲ度烧伤：</span>指烧伤程度为Ⅲ度，且Ⅲ度烧伤的面积达到全身体表面积的
			20％或 20％以上。体表面积根据《中国新九分法》计算。
						</div>
						<div className='indent'>
						<span className='bold'>（二十一）严重原发性肺动脉高压：</span>指不明原因的肺动脉压力持续性增高，进行性发
			展而导致的慢性疾病，已经造成永久不可逆性的体力活动能力受限，达到美国纽约心脏病学
			会心功能状态分级 IV 级，且静息状态下肺动脉平均压超过 30mmHg。
						</div>
						<div className='indent'>
						<span className='bold'>（二十二）严重运动神经元病：</span>是一组中枢神经系统运动神经元的进行性变性疾病，包
			括进行性脊肌萎缩症、进行性延髓麻痹症、原发性侧索硬化症、肌萎缩性侧索硬化症。须满
			足自主生活能力完全丧失，无法独立完成六项基本日常生活活动中的三项或三项以上的条
			件。
						</div>
						<div className='indent'>
						<span className='bold'>（二十三）语言能力丧失：</span>：指因疾病或意外伤害导致完全丧失语言能力，经过积极治疗
			至少 12 个月（声带完全切除不受此时间限制），仍无法通过现有医疗手段恢复。
						</div>
						<div className='indent bold'>
						精神心理因素所致的语言能力丧失不在本保障范围内。
						</div>
						<div className='indent bold'>
						被保险人在3周岁之前因疾病导致的语言能力丧失不在保障范围内。先天性失聪导致的
			语言能力丧失不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（二十四）重型再生障碍性贫血：</span>：指因骨髓造血功能慢性持续性衰竭导致的贫血、中
		性粒细胞减少及血小板减少。<span className='bold'>须满足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）骨髓穿刺检查或骨髓活检结果支持诊断；
						</div>
		
						<div className='indent'>
						（2）外周血象须具备以下三项条件：
						</div>
		
						<div className='indent'>
						①中性粒细胞绝对值≤0.5×10<span className='upper'>9</span>/L；
						</div>
						<div className='indent'>
						②网织红细胞＜1%；
						</div>
						<div className='indent'>
						③血小板绝对值≤20×10<span className='upper'>9</span>/L
						</div>
						<div className='indent'>
						<span className='bold'>（二十五）主动脉手术：</span>指为治疗主动脉疾病，实际实施了开胸或开腹进行的切除、置
		换、修补病损主动脉血管的手术。主动脉指胸主动脉和腹主动脉，<span className='bold'>不包括胸主动脉和腹主动
		脉的分支血管。</span>
						</div>
						<div className='indent bold'>
						动脉内血管成形术不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（二十六）严重心肌病：</span>指被保人因心肌病导致慢性心功能损害造成永久不可逆性的心
		功能衰竭。<span className='bold'>心功能衰竭程度达到纽约心脏病学会的心功能分级标准之心功能 IV 级。</span>被保险
							人永久不可逆性的体力活动能力受限，不能从事任何体力活动。
						</div>
						<div className='indent'>
						<span className='bold'>（二十七）慢性呼吸功能衰竭：</span>指慢性肺部疾病导致永久不可逆性的慢性呼吸功能衰竭。
		<span className='bold'>须满足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）休息时出现呼吸困难；
						</div>
						<div className='indent'>
						（2）动脉血氧分压（PaO2 ）&lt;50mmHg；
						</div>
						<div className='indent'>
						（3）动脉血氧饱和度（SaO2）&lt;80％；
						</div>
						<div className='indent'>
						（4）因缺氧必须接受持续的输氧治疗。
						</div>
						<div className='indent'>
						<span className='bold'>（二十八）严重多发性硬化：</span>多发性硬化为中枢神经系统白质多灶性脱髓鞘病变，病变
		有时累及灰质。<span className='bold'>多发性硬化必须明确诊断，并且已经造成永久不可逆的神经系统功能损害。
		永久不可逆的神经系统损害指被保险人持续 180 天无法独立完成下列基本日常生活活动之
		一：</span>
						</div>
						<div className='indent'>
						（1）移动：自己从一个房间到另一个房间；
						</div>
						<div className='indent'>
						（2）进食：自己从已准备好的碗或碟中取食物放入口中。
						</div>
						<div className='indent'>
						<span className='bold'>（二十九）职业原因致人类免疫缺陷病毒（HIV）感染：</span>被保险人的职业归属于下列职
		业列表内的职业，在其常规职业工作过程中遭遇外伤，或者职业需要处理血液或者其他体液
		时感染上人类免疫缺陷病毒（HIV）。<span className='bold'>必须满足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）感染必须是在被保险人正在从事其职业工作中发生；
						</div>
						<div className='indent'>
						（2）血清转化必须出现在事故发生后的 6 个月以内；
						</div>
						<div className='indent'>
						（3）必须提供被保险人在所报事故发生后的 5 天以内进行的检查报告，该报告必须显
		示被保险人血液 HIV 病毒阴性和/或 HIV 抗体阴性；
						</div>
						<div className='indent'>
						（4）必须在事故发生后的 12 个月内证实被保险人体内存在 HIV 病毒或者 HIV 抗体。
						</div>
						<div className='indent bold'>
						职业列表：
						</div>
				<table className='table'>
					<tbody>
					<tr>
								<td className='tableItem'>医生（包括牙医）</td>
								<td className='tableItem'>护士</td>
							</tr>
							<tr>
								<td className='tableItem'>医院化验室工作人员</td>
								<td className='tableItem'>医院护工</td>
							</tr>
							<tr>
								<td className='tableItem'>救护车工作人员</td>
								<td className='tableItem'>助产士</td>
							</tr>
							<tr>
								<td className='tableItem'>警察（包括狱警）</td>
								<td className='tableItem'>消防人员</td>
							</tr>
					</tbody>
							
						</table>
						<div className='indent bold'>
						任何因其他传播方式（包括：输血、性传播或静脉注射毒品）导致的 HIV 感染不在本
			保障范围内。保险人拥有获得使用被保险人的所有血液样本的权利和能够对这些样本进行
			独立检验的权利。
						</div>
						<div className='indent'>
						<span className='bold'>（三十）严重溃疡性结肠炎：</span>指伴有致命性电解质紊乱的急性暴发性溃疡性结肠炎，病
		变累及全结肠，表现为严重的血便和系统性症状体征，治疗通常采取全结肠切除和回肠造瘘
		术。<span className='bold'>溃疡性结肠炎必须根据组织病理学特点诊断，并且被保险人已经接受了结肠切除和/或
		回肠造瘘术。</span>
						</div>
						<div className='indent'>
						<span className='bold'>（三十一）严重重症肌无力：</span>是一种神经肌肉接头传递障碍所致的疾病，表现为局部或
		全身骨骼肌（特别是眼外肌）极易疲劳。疾病可以累及呼吸肌、上肢或下肢的近端肌群或全
		身肌肉。<span className='bold'>必须满足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）经药物治疗和胸腺切除治疗一年以上仍无法控制病情；
						</div>
						<div className='indent'>
						（2）自主生活能力完全丧失，无法独立完成六项基本日常生活活动中的三项或三项以
			上。
						</div>
						<div className='indent'>
						<span className='bold'>（三十二）严重类风湿性关节炎：</span>类风湿性关节炎为广泛分布的慢性进行性多关节病
		变，表现为关节严重变形，侵犯至少三个主要关节或关节组[如：双手（多手指）关节、双
		足（多足趾）关节、双腕关节、双膝关节和双髋关节]。<span className='bold'>类风湿性关节炎必须明确诊断并且
		已经达到类风湿性关节炎功能分类 IV 级的永久不可逆性关节功能障碍（生活不能自理，且
		丧失活动能力）。</span>
						</div>
						<div className='indent'>
						美国风湿病学会类风湿性关节炎分级
						</div>
						<div className='indent'>
						I 级：关节能自由活动，能完成平常的任务而无妨碍。
						</div>
						<div className='indent'>
						II 级：关节活动中度限制，一个或几个关节疼痛不适，但能料理日常生活。
						</div>
						<div className='indent'>
						III 级：关节活动显著限制，不能胜任工作，料理生活也有困难。
						</div>
						<div className='indent'>
						IV 级：大部分或完全失去活动能力，病人长期卧床或依赖轮椅，生活不能自理。
						</div>
						<div className='indent'>
						<span className='bold'>（三十三）脊髓灰质炎后遗症：</span>脊髓灰质炎是由于脊髓灰质炎病毒感染所致的瘫痪性疾
		病，临床表现为运动功能损害或呼吸无力。脊髓灰质炎必须明确诊断。<span className='bold'>本保单仅对脊髓灰质
		炎造成的神经系统功能损害导致被保险人一肢或一肢以上肢体机能永久完全丧失的情况予
		以理赔。</span>肢体机能永久完全丧失，指疾病确诊 180 天后，每肢三大关节中的两大关节仍然完
							全僵硬，或不能随意识活动。
						</div>
						<div className='indent'>
						<span className='bold'>（三十四）输血原因致人类免疫缺陷病毒（HIV）感染：</span>被保险人因输血感染上人类免
			疫缺陷病毒（HIV）并且满足下列所有条件：
						</div>
						<div className='indent'>
						（1）在保障起始日之后，被保险人因输血而感染 HIV
						</div>
						<div className='indent'>
						（2）提供输血治疗的输血中心或医院出具该项输血感染属医疗责任事故的报告，或者
		法院终审裁定为医疗责任；
						</div>
						<div className='indent'>
						（3）受感染的被保险人不是血友病患者。
						</div>
						<div className='indent bold'>
						任何因其他传播方式（包括：性传播或静脉注射毒品）导致的 HIV 感染不在本保障范
		围内。
						</div>
		
						<div className='indent bold'>
						保险人拥有获得使用被保险人的所有血液样本的权利和能够对这些样本进行独立检验
			的权利。
						</div>
						<div className='indent'>
						<span className='bold'>（三十五）严重克隆病：</span>克隆病是一种慢性肉芽肿性肠炎，具有特征性的克隆病病理组
		织学变化。诊断必须由病理检查结果证实。<span className='bold'>被保险人所患的克隆病必须已经造成瘘管形成
		并伴有肠梗阻或肠穿孔。</span>
						</div>
						<div className='indent'>
						<span className='bold'>(三十六)严重肌营养不良症：</span>肌营养不良症是一组遗传性肌肉变性性病变，临床特征为
		与神经系统无关的肌肉无力和肌肉萎缩。<span className='bold'>须满足自主生活能力完全丧失，无法独立完成六
		项基本日常生活活动中的三项或三项以上。</span>
						</div>
						<div className='indent'>
						<span className='bold'>（三十七）破裂脑动脉瘤夹闭手术：</span>指因脑动脉瘤破裂造成蛛网膜下腔出血，被保险
			人实际接受了在全麻下进行的开颅脑动脉瘤夹闭手术。
						</div>
						<div className='indent bold'>
						脑动脉瘤（未破裂）预防性手术、颅骨打孔手术、动脉瘤栓塞手术、血管内手术及其
			他颅脑手术不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（三十八）严重弥漫性系统性硬皮病：</span>一种系统性胶原血管病引起进行性的皮肤/血管
		和内脏器官的弥漫性纤维化。<span className='bold'>诊断必须明确并由活检和血清学证据支持并且疾病已经影响
		到心脏、肺或肾脏等内脏器官并且达到下列标准之一：</span>
						</div>
						<div className='indent '>
						（1）肺纤维化，已经出现肺动脉高压、肺心病；
						</div>
						<div className='indent '>
						（2）心脏损害，心脏功能衰竭程度达到纽约心脏病学会的心功能分级标准之心功能 IV
		级；
						</div>
						<div className='indent '>
						（3）肾脏损害，已经出现肾功能衰竭。
						</div>
						<div className='indent bold'>
						下列疾病不在本保障范围内：
						</div>
		
						<div className='indent bold'>
						（1）局限硬皮病；
						</div>
						<div className='indent bold'>
						（2）嗜酸细胞筋膜炎；
						</div>
						<div className='indent bold'>
						（3）CREST 综合征。
						</div>
		
						<div className='indent'>
						<span className='bold'>（三十九）严重冠心病：</span>指经冠状动脉造影检查结果明确诊断为三支主要血管严重狭窄
			性病变（至少一支血管管腔直径减少 75％以上和其他两支血管管腔直径减少 60％以上）。
			冠状动脉的主要血管指左冠状动脉主干、前降支、左旋支及右冠状动脉。
						</div>
						<div className='indent bold'>
						前降支、左旋支及右冠状动脉的分支血管的狭窄不作为本保障的衡量指标。
						</div>
						<div className='indent'>
						<span className='bold'>（四十）严重慢性复发性胰腺炎：</span>指慢性反复发作的胰腺炎症导致胰腺的广泛纤维化、
		坏死、弥漫性钙化及假性囊肿形成，造成胰腺功能障碍出现严重糖尿病和营养不良。<span className='bold'>必须满
		足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）CT 显示胰腺广泛钙化或逆行胰胆管造影（ERCP）显示胰管扭曲、扩张和狭窄；
						</div>
						<div className='indent'>
						（2）持续接受胰岛素替代治疗和酶替代治疗 180 天以上。
						</div>
						<div className='indent bold'>
						酒精导致的慢性复发性胰腺炎不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（四十一）植物人状态：</span>指由于严重颅脑外伤造成大脑和/或脑干严重损害导致完全永
		久性的对自身和环境的意识丧失和中枢神经系统功能丧失，仅残存植物神经功能的疾病状
		态。诊断必须明确并且具有严重颅脑外伤和脑损害的证据。<span className='bold'>植物人状态必须持续 30 天以上。</span>
						</div>
						<div className='indent'>
						<span className='bold'>（四十二）重症急性坏死性筋膜炎截肢：</span>是一种由细菌侵入皮下组织和筋膜引起的急
		性坏死性软组织感染，可伴有毒血症、败血症、中毒性休克、弥漫性血管内凝血及多器官衰
		竭。<span className='bold'>必须满足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）细菌培养和临床表现符合坏死性筋膜炎诊断标准；
						</div>
						<div className='indent'>
						（2）病情迅速恶化，有脓毒血症表现；
						</div>
						<div className='indent'>
						（3）受感染肢体被截肢（自腕关节或踝关节近端）。
						</div>
						<div className='indent'>
						<span className='bold'>（四十三）嗜铬细胞瘤：</span>源于肾上腺髓质、交感神经节或其他部位的嗜铬组织发生的肿
		瘤，分泌过多的儿茶酚胺，<span className='bold'>并且已经实施了嗜铬细胞瘤切除手术。</span>
						</div>
						<div className='indent'>
						<span className='bold'>（四十四）丝虫感染所致严重象皮病：</span>指因丝虫感染导致淋巴阻塞性出现严重淋巴水
			肿，达到国际淋巴学会淋巴肿分期第 III 期，临床表现为肢体象皮肿，患肢较健肢增粗 30%
			以上，日常生活不能自理。
						</div>
						<div className='indent'>
						<span className='bold'>（四十五）胰腺移植术：</span>胰腺移植术指因胰腺功能衰竭，已经实际接受了胰腺的同种（人
			类）异体器官移植手术。
						</div>
						<div className='indent bold'>
						单纯胰岛移植、部分胰腺组织或细胞移植不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（四十六）慢性肾上腺皮质功能衰竭：</span>指自身免疫性肾上腺炎（既往称：特发性肾上
		腺皮质萎缩）导致肾上腺萎缩和慢性肾上腺皮质功能减退。<span className='bold'>必须满足所有以下条件：</span>
						</div>
						<div className='indent'>
						（1）明确诊断，符合所有以下诊断标准：
						</div>
						<div className='indent'>
						①血浆促肾上腺皮质激素（ACTH）水平测定，>100pg/ml；
						</div>
						<div className='indent'>
						②血浆肾素活性、血管紧张素 II 和醛固酮测定，显示为原发性肾上腺皮质功能减退症；
						</div>
						<div className='indent'>
						③促肾上腺皮质激素（ACTH）刺激试验显示为原发性肾上腺皮质功能减退症。
						</div>
						<div className='indent'>
						（2）已经采用皮质类固醇替代治疗 180 天以上。
						</div>
						<div className='indent bold'>
						肾上腺结核、HIV 感染或艾滋病、感染、肿瘤所致的原发性肾上腺皮质功能减退和继发
			性肾上腺皮质功能减退不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（四十七）严重心肌炎：</span>指被保人因严重心肌炎症性病变导致心功能损害造成持续的永
		久不可逆性的心功能衰竭。<span className='bold'>必须满足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）心功能衰竭程度达到纽约心脏病学会的心功能分级标准之心功能 IV 级，或左室射
		血分数低于 30%；
						</div>
		
						<div className='indent'>
						（2）持续不间断 180 天以上；
						</div>
						<div className='indent'>
						（3）被保险人永久不可逆性的体力活动能力受限，不能从事任何体力活动。
						</div>
						<div className='indent'>
						<span className='bold'>（四十八）肺源性心脏病：</span>指被保人因慢性肺部疾病导致慢性肺源性心脏病并且引起慢
			性心功能损害造成永久不可逆性的心功能衰竭。心功能衰竭程度达到纽约心脏病学会的心功
			能分级标准之心功能 IV 级。被保险人永久不可逆性的体力活动能力受限，不能从事任何体
			力活动。
						</div>
						<div className='indent'>
						<span className='bold'>（四十九）严重原发性硬化性胆管炎：</span>指一种胆汁淤积综合征，其特征是肝内、肝外
		胆道因纤维化性炎症逐渐狭窄，并最终导致完全阻塞而发展为肝硬化。<span className='bold'>必须满足下列所有条
		件：</span>
						</div>
						<div className='indent'>
						（1）诊断由逆行胰胆管造影（ERCP）或经皮胆管造影（PTC）确认；
						</div>
						<div className='indent'>
						（2）持续性黄疸伴碱性磷酸酶（ALP）显著升高，血清 ALP>200U/L；
						</div>
						<div className='indent'>
						（3）出现继发性胆汁性肝硬化和门静脉高压。
						</div>
						<div className='indent bold'>
						因肿瘤或胆管损伤等继发性的硬化性胆管炎不在保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（五十）严重慢性自身免疫性肝炎：</span><span className='bold'>自身免疫性肝炎</span>是一种原因不明的慢性肝脏的坏
		死性炎性疾病，机体免疫机制被破坏，产生针对肝脏自身抗原的抗体导致自身免疫反应，从
		而破坏肝细胞造成肝脏炎症坏死，进而发展为肝硬化。<span className='bold'>必须满足下列所有条件：</span>
						</div>
						<div className='indent '>
						（1）高γ球蛋白血症；
						</div>
						<div className='indent '>
						（2）血液中存在高水平的自身免疫抗体，如 ANA（抗核抗体）、SMA（抗平滑肌抗体）、
		抗 LKM1 抗体或抗-SLA/LP 抗体；
						</div>
						<div className='indent '>
						（3）肝脏活检确诊免疫性肝炎；
						</div>
						<div className='indent '>
						（4）临床已经出现腹水、食道静脉曲张和脾肿大等肝硬化表现。
						</div>
						<div className='indent'>
						<span className='bold'>（五十一）原发性骨髓纤维化：</span>以骨髓纤维增生和髓外造血为特点，表现为进行性贫血、
			脾肿大、外周血幼稚细胞等等。
						</div>
						<div className='indent bold'>
						被保险人须经由骨髓活检明确诊断为原发性骨髓纤维化，并满足下列所有条件且持续
			180 天：
						</div>
						<div className='indent '>
						（1）血红蛋白&lt;100g/l；
						</div>
						<div className='indent '>
						（2）白细胞计数>25×10<span className='upper'>9</span>/l；
						</div>
						<div className='indent '>
						（3）外周血原始细胞≥1%；
						</div>
						<div className='indent '>
						（4）血小板计数&lt;100×10<span className='upper'>9</span>/l。
						</div>
						<div className='indent bold'>
						恶性肿瘤、中毒、放射线和感染所致的继发性骨髓纤维化不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（五十二）严重骨髓增生异常综合征：</span><span className='bold'>骨髓增生异常综合征</span>是起源于造血干细胞的一
			组骨髓克隆异常的疾病，表现为无效造血、难治性血细胞减少，有转化为急性髓系白血病的
			风险。
						</div>
						<div className='indent'>
						被保险人须经由外周血和骨髓活检明确诊断为骨髓增生异常综合征，<span className='bold'>并且满足下列所有
		条件：</span>
						</div>
						<div className='indent'>
						（1）根据 WHO 分型，分型为难治性贫血伴原始细胞增多（RAEB）；
						</div>
						<div className='indent'>
						（2）根据“骨髓增生异常综合征修订国际预后积分系统（IPSS-R）”积分≥3，属于中
			危及以上组。
						</div>
						<div className='indent'>
						<span className='bold'>（五十三）自体造血干细胞移植：</span>指为治疗造血功能损害或为达到治疗肿瘤的目的，
			已经实施了造血干细胞（包括骨髓造血干细胞、外周血造血干细胞和脐血造血干细胞）的自
			体移植手术。该治疗须由医院专科医生认为是医学必需的。
						</div>
						<div className='indent'>
						<span className='bold'>（五十四）严重感染性心内膜炎：</span>感染性心内膜炎是指因细菌或其他致病菌感染造成
			心脏内膜感染，瓣膜为最常受累部位，引起心脏瓣膜关闭不全。必须满足下列至少三项条件：
						</div>
						<div className='indent'>
						(1) 急性或亚急性感染性心内膜炎临床表现；
						</div>
						<div className='indent'>
						(2) 血培养病原体阳性；
						</div>
						<div className='indent'>
						(3) 心功能衰竭并实际接受了心脏瓣膜置换手术
						</div>
						<div className='indent'>
						(4) 并发动脉栓塞导致脑梗塞、肾梗塞或心肌梗塞。
						</div>
						<div className='indent bold'>
						药物滥用者所患感染性心内膜炎不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（五十五）主动脉夹层动脉瘤：</span>指主动脉的内膜破裂导致血液流入主动脉壁中形成夹层
			动脉瘤。主动脉指胸主动脉及腹主动脉而非其旁支。诊断必须由专科医生通过检验结果证实，
			检查包括超声心动图、数字减影血管造影，磁共振扫描及磁共振血管造影或心导管检查的证
			明，并有必要进行紧急修补手术。
						</div>
						<div className='indent'>
						<span className='bold'>（五十六）严重慢性缩窄性心包炎：</span>由于慢性心包炎症导致心包脏层和壁层广泛瘢痕
		粘连、增厚和钙化，心包腔闭塞，形成一个纤维瘢痕外壳，使心脏和大血管根部受压，阻碍
		心脏的舒张。慢性缩窄性心包炎<span className='bold'>必须被明确诊断并且满足下列所有条件：</span>
						</div>
						<div className='indent '>
						（1）心功能衰竭达到美国纽约心脏病学会心功能状态分级 IV 级，并持续 180 天以上；
						</div>
						<div className='indent '>
						（2）已经实施了开胸进行的心包剥脱或心包切除手术。
						</div>
						<div className='indent bold'>
						经胸腔镜、胸壁打孔进行的手术、心包粘连松解手术不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（五十七）心脏粘液瘤切除手术：</span>指为了治疗心脏粘液瘤，实际实施了开胸心脏粘液
			瘤切除手术。
						</div>
						<div className='indent bold'>
						经导管介入手术治疗不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（五十八）严重心脏衰竭 CRT 心脏再同步治疗：</span>指因缺血性心脏病或扩张性心肌病导
		致慢性严重心脏衰竭，被保险人实际接受了 CRT 治疗，以矫正心室收缩不协调和改善心脏功
		能。<span className='bold'>接受治疗之前必须满足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）心功能衰竭程度达到纽约心脏病学会的心功能分级标准之心功能 III 级或 IV 级；
						</div>
						<div className='indent'>
						（2）左室射血分数低于 35%；
						</div>
						<div className='indent'>
						（3）左室舒张末期内径≥55mm；
						</div>
						<div className='indent'>
						（4）QRS 时间≥130msec；
						</div>
						<div className='indent'>
						（5）药物治疗效果不佳，仍有症状。
						</div>
						<div className='indent'>
						<span className='bold'>（五十九）完全性房室传导阻滞：</span>指心房激动在房室交界区、房室束及其分支内发生
			阻滞，不能正常地传到心室的心脏传导性疾病，须明确诊断并且满足下列所有条件：
						</div>
						<div className='indent'>
						1. 心电图显示房室搏动彼此独立，心室率&lt;50 次/分钟；
						</div>
						<div className='indent'>
						2. 出现阿-斯综合征或心力衰竭的表现；
						</div>
						<div className='indent'>
						3. 必须持续性依赖心脏起搏器维持心脏正常功能，且已经放置心脏起搏器。
						</div>
						<div className='indent'>
						<span className='bold'>（六十）多处臂丛神经根性撕脱：</span>指由于疾病或意外导致至少两根臂丛神经根性撕脱，
			所造成的手臂感觉功能与运动功能完全永久性丧失，经手术治疗后无法恢复。
						</div>
						<div className='indent bold'>
						本疾病必须有电生理检查结果和手术证实。
						</div>
						<div className='indent'>
						<span className='bold'>（六十一）头臂动脉型多发性大动脉炎旁路移植手术：</span>多发性大动脉炎（高安氏动脉
			炎）是一种发生在主动脉和其主要分支的慢性炎症性动脉疾病，表现为受累动脉狭窄或闭塞。
			头臂动脉型多发性大动脉炎是指多发性大动脉炎头臂动脉型（I 型），又称为无脉症。被保
			险人被明确诊断为头臂动脉型多发性大动脉炎并且实际接受了经胸部切开进行的无名动脉
			（头臂干）、颈总动脉、锁骨下动脉旁路移植手术。
						</div>
						<div className='indent bold'>
						非开胸的血管旁路移植手术、因其他病因而进行的旁路移植手术，对其他动脉进行的
			旁路移植手术，经皮经导管进行的血管内手术不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（六十二）肺淋巴管肌瘤：</span>肺淋巴管肌瘤病是一种弥漫性肺部疾病，其特征性病理表现
		为囊性病变及不成熟的平滑肌细胞和血管周围上皮细胞异常增生形成多发结节。<span className='bold'>必须满足下
		列所有条件：</span>
						</div>
						<div className='indent '>
						（1）经组织病理学检查明确诊断；
						</div>
						<div className='indent '>
						（2） CT 显示双肺弥漫性囊性改变；
						</div>
						<div className='indent '>
						（3）动脉血气分析显示低氧血症。
						</div>
						<div className='indent bold'>
						疑似肺淋巴管肌瘤病除外。
						</div>
						<div className='indent'>
						<span className='bold'>（六十三）严重肺结节病：</span>结节病是一种原因未明的慢性肉芽肿病，可侵犯全身多个器
		官，以肺和淋巴结受累最为常见。严重肺结节病表现为肺的广泛纤维化导致慢性呼吸功能衰
		竭。<span className='bold'>必须满足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）肺结节病的 X 线分期为 IV 期，即广泛肺纤维化；
						</div>
						<div className='indent'>
						（2）永久不可逆性的慢性呼吸功能衰竭，临床持续 180 天动脉血氧分压（PaO<span className='lower'>2</span>）&lt;50mmHg
		和动脉血氧饱和度（SaO<span className='lower'>2</span>）&lt;80%。
						</div>
						<div className='indent'>
						<span className='bold'>（六十四）非阿尔茨海默病致严重痴呆：</span>指因阿尔茨海默病以外的脑的器质性疾病造
		成脑功能衰竭导致永久不可逆性的严重痴呆，临床表现为明显的认知能力障碍、行为异常和
		社交能力减退。<span className='bold'>被保险人自主生活能力完全丧失，无法独立完成六项基本日常生活活动中
		的三项或三项以上，日常生活必须持续受到他人监护。</span>导致痴呆的疾病必须明确诊断，并
							且由完整的临床、实验室和影像学检查结果证实。
						</div>
						<div className='indent bold'>
						神经官能症,精神疾病及酒精中毒所致脑损害不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（六十五）进行性核上性麻痹：</span>进行性核上性麻痹是中枢神经系统变性性疾病，临床表
			现为眼球运动障碍、假性球麻痹、帕金森综合征等。须满足自主生活能力完全丧失，无法独
			立完成六项基本日常生活活动中的三项或三项以上。
						</div>
						<div className='indent'>
						<span className='bold'>（六十六）克-雅氏病（CJD，人类疯牛病）：</span><span className='bold'>CJD </span>是一种传染性海绵状脑病，临床表现
			为快速进行性痴呆、肌阵挛和特征性脑电图变化。本病须经三级医院的专科医师根据 WHO
			诊断标准明确诊断，并且被保险人永久不可逆丧失自主生活能力，无法独立完成六项基本日
			常生活活动中的三项或三项以上。
						</div>
						<div className='indent'>
						<span className='bold'>（六十七）亚急性硬化性全脑炎：</span>是麻疹或麻疹样病毒所致的一种中枢神经系统慢病
		毒感染。中枢神经系统呈现灰质和白质破坏为特征的慢性和急性混合存在的炎症。<span className='bold'>必须满足
		下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）必须由三级医院诊断，临床检查证实脑电图存在周期性复合波、脑脊液Ƴ-球蛋白
			升高、脑脊液和血清中麻疹抗体滴定度升高；
						</div>
						<div className='indent'>
						（2）被保险人出现运动障碍，永久不可逆丧失自主生活能力，无法独立完成六项基本
			日常生活活动中的三项或三项以上。
						</div>
						<div className='indent'>
						<span className='bold'>（六十八）进行性多灶性白质脑病：</span>是一种亚急性脱髓鞘脑病，常常发生于免疫缺陷
		病人。<span className='bold'>必须满足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）根据脑组织活检确诊；
						</div>
						<div className='indent'>
						（2）永久不可逆丧失自主生活能力，无法独立完成六项基本日常生活活动中的三项或
			三项以上。
						</div>
						<div className='indent'>
						<span className='bold'>（六十九）肺泡蛋白质沉积症：</span>指肺泡和细支气管腔内充满不可溶性富磷脂蛋白的疾病，
			胸部 X 线呈双肺弥漫性肺部磨玻璃影，病理学检查肺泡内充满有过碘酸雪夫（PAS）染色阳
			性的蛋白样物质，并且接受了肺灌洗治疗。
						</div>
						<div className='indent'>
						<span className='bold'>（七十）脊髓小脑变性症：</span>脊髓小脑变性症为一组以小脑萎缩和共济失调为主要临床特
		点的疾病。<span className='bold'>必须满足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）脊髓小脑变性症必须由三级医院诊断，并有下列所有证据支持：
						</div>
						<div className='indent'>
						①影像学检查证实存在小脑萎缩；
						</div>
						<div className='indent'>
						②临床表现存在共济失调、语言障碍和肌张力异常；
						</div>
						<div className='indent'>
						（2）被保险人运动功能严重障碍，自主生活能力完全丧失，无法独立完成六项基本日
			常生活活动中的三项或三项以上。
						</div>
						<div className='indent'>
						<span className='bold'>（七十一）神经白塞病：</span>是一种慢性全身性血管炎症性疾病，主要表现为复发性口腔溃
		疡、生殖器溃疡、眼炎及皮肤损害，并可累及大血管、神经系统、消化道、肺、肾等等。<span className='bold'>累
		及神经系统损害的白塞病称为神经白塞病。神经白塞病必须明确诊断，并且已经造成永久
		不可逆的神经系统功能损害。</span>永久不可逆的神经系统损害指被保险人持续 180 天无法独立完
							成下列基本日常生活活动之一：
						</div>
						<div className='indent'>
						（1）移动：自己从一个房间到另一个房间；
						</div>
						<div className='indent'>
						（2）进食：自己从已准备好的碗或碟中取食物放入口中。
						</div>
						<div className='indent'>
						<span className='bold'>（七十二）脊髓内肿瘤：</span>指脊髓内良性或恶性肿瘤。肿瘤造成脊髓损害导致瘫痪。<span className='bold'>须满
		足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）被保险人实际接受了肿瘤切除的手术治疗；
						</div>
						<div className='indent'>
						（2）手术 180 天后遗留下列神经系统损害，无法独立完成下列基本日常生活活动之一：
						</div>
						<div className='indent'>
						①移动：自己从一个房间到另一个房间；
						</div>
						<div className='indent'>
						②进食：自己从已准备好的碗或碟中取食物放入口中。
						</div>
						<div className='indent bold'>
						非脊髓内的其他椎管内肿瘤、脊柱肿瘤、神经纤维瘤不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（七十三）横贯性脊髓炎后遗症：</span>脊髓炎是一种炎症性脊髓疾病。横贯性脊髓炎是指
			炎症扩展横贯整个脊髓，表现为运动障碍、感觉障碍和自主神经功能障碍。横断性脊髓炎必
			须明确诊断，并且已经造成永久不可逆的神经系统功能损害。永久不可逆的神经系统损害指
			被保险人持续 180 天无法独立完成下列任何一项基本日常生活活动：
						</div>
						<div className='indent'>
						(1) 移动：自己从一个房间到另一个房间；
						</div>
						<div className='indent'>
						(2) 进食：自己从已准备好的碗或碟中取食物放入口中。
						</div>
						<div className='indent'>
						<span className='bold'>（七十四）脊髓空洞症：</span>脊髓空洞症为慢性进行性的脊髓变性性疾病，其特征为脊髓内
			空洞形成。表现为感觉异常、肌萎缩及神经营养障碍。脊髓空洞症累及延髓的称为延髓空洞
			症，表现为延髓麻痹。脊髓空洞症必须明确诊断并且造成永久不可逆的神经系统功能障碍，
			存在持续至少 180 天以上的神经系统功能缺失并满足下列任一条件：
						</div>
						<div className='indent'>
						（1）延髓麻痹呈现显著舌肌萎缩、构音困难和吞咽困难；
						</div>
						<div className='indent'>
						（2）双手萎缩呈“爪形手”，肌力 2 级或以下。
						</div>
						<div className='indent'>
						<span className='bold'>（七十五）脊髓血管病后遗症：</span>指脊髓血管的突发病变引起脊髓梗塞或脊髓出血，导致
		永久性不可逆的神经系统功能损害，表现为截瘫或四肢瘫。<span className='bold'>神经系统永久性的功能障碍指疾
		病确诊 180 天后，仍然遗留后遗症并且无法独立完成下列基本日常生活活动之一：</span>
						</div>
						<div className='indent'>
						(1) 移动：自己从一个房间到另一个房间；
						</div>
						<div className='indent'>
						(2) 进食：自己从已准备好的碗或碟中取食物放入口中。
						</div>
						<div className='indent'>
						<span className='bold'>（七十六）开颅手术：</span>指因外伤、颅内肿瘤或脑动脉瘤破裂，被保险人实际接受了在全
			麻下进行的颅骨切开手术，以清除脑内血肿、切除肿瘤或夹闭破裂动脉瘤的手术治疗。
						</div>
						<div className='indent bold'>
						颅骨打孔手术、硬膜下血肿清除术、未破裂动脉瘤预防性手术、动脉瘤栓塞手术、血
			管内手术、颅骨切开或切除减压术、脑积水脑脊液分流手术、经蝶骨肿瘤切除术及其他原
			因的开颅手术不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（七十七）严重系统性红斑狼疮性肾炎：</span>系统性红斑狼疮是由多种因素引起，累及多系
			统的自身免疫性疾病。其特点是生成自身抗体对抗多种自身抗原。多见于育龄妇女。
						</div>
						<div className='indent'>
						本保单所指的系统性红斑狼疮性肾炎须满足下列条件之一：
						</div>
						<div className='indent'>
						（1）经肾脏活检确认符合 WHO 诊断标准定义 III 型或 III 型以上狼疮性肾炎；
						</div>
						<div className='indent'>
						（2）临床出现肾功能衰竭且达到尿毒症期。
						</div>
						<div className='indent'>
						其他类型的红斑性狼疮，如盘状狼疮、仅累及血液及关节的狼疮不在本保障范围內。
						</div>
						<div className='indent'>
						<span className='bold'>（七十八）I 型糖尿病严重并发症：</span>1 型糖尿病的特征为由于胰岛素分泌绝对不足引起
		严重胰岛素缺乏导致慢性血糖升高，需要依赖外源性胰岛素进行机体的葡萄糖代谢和维持生
		命。被保险人的 1 型糖尿病必须明确诊断，而且有血胰岛素测定及血 C 肽或尿 C 肽测定结果
		支持诊断，并且<span className='bold'>满足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）已经持续性地接受外源性胰岛素注射治疗连续 180 天以上；
						</div>
						<div className='indent'>
						（2）因需要已经接受了下列治疗：
						</div>
						<div className='indent'>
						①因严重心律失常植入了心脏起搏器；或
						</div>
						<div className='indent'>
						②因坏疽自跖趾关节或以上切除了一趾或多趾。
						</div>
						<div className='indent'>
						<span className='bold'>（七十九）席汉氏综合征：</span>指因产后大出血并发休克、全身循环衰竭、弥漫性血管内凝
		血导致脑垂体缺血坏死和垂体分泌激素不足，造成性腺、甲状腺、肾上腺皮质功能减退。<span className='bold'>必
		须满足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）产后大出血休克病史；
						</div>
						<div className='indent'>
						（2）严重腺垂体功能破坏，破坏程度>95%；
						</div>
						<div className='indent'>
						（3）影像学检查显示脑垂体严重萎缩或消失；
						</div>
						<div className='indent'>
						（4）实验室检查显示：
						</div>
						<div className='indent'>
						①垂体前叶激素全面低下（包括生长激素、促甲状腺素、促肾上腺皮质激素、卵泡刺激
		素和黄体生成素）；和
						</div>
						<div className='indent'>
						②性激素、甲状腺素、肾上腺皮质激素全面低下；
						</div>
						<div className='indent'>
						（5）需要终身激素替代治疗以维持身体功能，持续服用各种替代激素超过一年。
						</div>
						<div className='indent bold'>
						垂体功能部分低下及其他原因所致垂体功能低下不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（八十）严重强直性脊柱炎：</span>强直性脊柱炎是一种慢性全身性炎性疾病，主要侵犯脊柱
		导致脊柱畸形。强直性脊柱炎必须明确诊断并且<span className='bold'>满足下列所有条件：</span>
						</div>
						<div className='indent '>
						（1）严重脊柱畸形；
						</div>
						<div className='indent '>
						（2）自主生活能力完全丧失，无法独立完成六项基本日常生活活动中的三项或三项以
			上。
						</div>
						<div className='indent'>
						<span className='bold'>（八十一）肾髓质囊性病：</span>肾髓质囊性病是一种遗传性肾脏疾病，特点为肾髓质多发大
		小不等的囊肿并且伴有小管炎症和间质性肾炎。<span className='bold'>必须满足下列所有条件：</span>
						</div>
						<div className='indent '>
						（1）经肾组织活检明确诊断；
						</div>
						<div className='indent '>
						（2）临床有肾脏衰竭和肾小管功能障碍表现；
						</div>
						<div className='indent '>
						（3）影像学证据显示肾髓质多发囊肿。
						</div>
						<div className='indent bold'>
						其他肾脏囊性病变不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（八十二）肝豆状核变性：</span>肝豆状核变性是一种遗传性铜代谢障碍疾病。表现为体内的
		铜离子在肝、脑、肾、角膜等处沉积，引起进行性加重的肝硬化、锥体外系症状、精神症状、
		肾损害及角膜色素环。肝豆状核变性必须明确诊断并且<span className='bold'>满足以下条件之一：</span>
						</div>
						<div className='indent '>
						（1）帕金森综合征或其他神经系统功能损害，自主生活能力完全丧失，无法独立完成
		六项基本日常生活活动中的三项或三项以上；
						</div>
						<div className='indent '>
						（2）失代偿性肝硬化，临床已经出现腹水、食道静脉曲张和脾肿大等表现；
						</div>
						<div className='indent '>
						（3）慢性肾功能衰竭，已开始肾脏透析治疗；
						</div>
						<div className='indent '>
						（4）接受了肝移植或肾移植手术。
						</div>
						<div className='indent'>
						<span className='bold'>（八十三）重症急性出血坏死性胰腺炎开腹手术：</span>指被保险人被确诊为重症急性出血
			坏死性胰腺炎，并实际接受了外科剖腹直视手术治疗，进行坏死组织清除、坏死病灶切除或
			胰腺切除。
						</div>
						<div className='indent '>
						腹腔镜手术治疗、因酗酒或饮酒过量所致的急性坏死性胰腺炎不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（八十四）小肠移植术：</span>指被保险人因肠道疾病或外伤，为了维持生理功能已经实际接
			受了在全麻下进行的小肠的同种（人类）异体移植手术。
						</div>
						<div className='indent'>
						<span className='bold'>（八十五）意外导致的重度面部烧伤：</span>指面部Ⅲ度烧伤且烧伤面积达到面部表面积的
			2/3 或全身体表面积的 2%。
						</div>
						<div className='indent '>
						体表面积根据《中国新九分法》计算，面部总面积为全身体表面积的 3%。<span className='bold'>面部面积不
		包括发部和颈部。</span>
						</div>
						<div className='indent'>
						<span className='bold'>（八十六）失去一肢及一眼：</span>被保险人因同一次意外伤害事件或疾病导致一肢体丧失和
			一眼视力丧失。必须满足下列所有条件：
						</div>
						<div className='indent '>
						(1) 一肢体丧失指任何一肢体自腕关节或踝关节近端（靠近躯干端）以上完全性断离。
						</div>
						<div className='indent '>
						(2) 一眼视力丧失指一只眼视力永久不可逆性丧失，患眼须满足下列至少一项条件：
						</div>
						<div className='indent '>
						①眼球摘除；
						</div>
						<div className='indent '>
						②矫正视力低于 0.02（采用国际标准视力表，如果使用其它视力表应进行换算）；
						</div>
						<div className='indent '>
						③视野半径小于 5 度。
						</div>
						<div className='indent'>
						<span className='bold'>（八十七）溶血性链球菌感染引起的坏疽：</span>由于急性溶血性链球菌感染导致广泛的皮
		肤、皮下组织和深层组织的坏死。<span className='bold'>必须满足以下所有条件：</span>
						</div>
						<div className='indent '>
						（1）细菌培养证实致病菌为溶血性链球菌；
						</div>
						<div className='indent '>
						（2）受感染肢体被截肢（自腕关节或踝关节近端）。
						</div>
						<div className='indent'>
						<span className='bold'>（八十八）器官移植原因导致 HIV 感染：</span>被保险人因接受器官移植感染上人类免疫缺
		陷病毒（HIV）<span className='bold'>并且满足下列全部条件：</span>
						</div>
						<div className='indent '>
						（1）被保险人接受器官移植，并因此感染 HIV；
						</div>
						<div className='indent '>
						（2）实施器官移植的医院为三级医院;
						</div>
						<div className='indent '>
						（3）实施移植医院出具具有法律效力的证明确认移植器官来自 HIV 感染者；
						</div>
						<div className='indent'>
						<span className='bold'>（八十九）埃博拉病毒感染：</span>指埃博拉病毒感染导致的急性出血性传染病。埃博拉病毒
		病必须经国家认可的有合法资质的传染病专家确诊并且上报国家疾病控制中心接受了隔离
		和治疗，<span className='bold'>必须满足以下所有条件：</span>
						</div>
						<div className='indent '>
						（1）实验室检查证据证实存在埃博拉病毒感染；
						</div>
						<div className='indent '>
						（2）存在持续 30 天以上广泛出血的临床表现；
						</div>
						<div className='indent bold'>
						埃博拉病毒感染疑似病例不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（九十）Brugada 综合征：</span>被保险人必须由三级医院心脏专科医生明确诊断为 Brugada
			综合征，并且满足下列所有条件：
						</div>
						<div className='indent'>
						(1) 有晕厥或心脏骤停病史，并提供完整的诊疗记录；
						</div>
						<div className='indent'>
						(2)心电图有典型的 I 型 Brugada 波；
						</div>
						<div className='indent'>
						(3)已经安装了永久性心脏起搏器。
						</div>
						<div className='indent'>
						<span className='bold'>（九十一）川崎病冠状动脉瘤手术：</span>川崎病为一种病因不明的系统性血管炎。
						</div>
						<div className='indent bold'>
						本保险仅对诊断性检查证实川崎病并发冠状动脉瘤或其他心血管异常并且实际接受了
			手术治疗的情况予以理赔。
						</div>
						<div className='indent'>
						<span className='bold'>（九十二）严重幼年型类风湿关节炎：</span>幼年型类风湿关节炎是一种儿童期发病的慢性
			关节炎，其特点为在高热和系统性病征出现数月后发生关节炎。
						</div>
						<div className='indent bold'>
						本保险仅对诊断性检查证实川崎病并发冠状动脉瘤或其他心血管异常并且实际接受了
				手术治疗的情况予以理赔。
						</div>
						<div className='indent'>
						<span className='bold'>（九十三）因疾病或外伤导致智力缺陷：</span>因严重头部创伤或疾病造成被保险人智力低
		常（智力低于常态）或智力残疾。根据智商（IQ）智力低常分为轻度（IQ50-70）；中度（IQ35-50）；
		重度（IQ20-35）和极重度（IQ&lt;20）。<span className='bold'>智商 70-85 为智力临界低常，不在保障范围内。</span>根
							据被保险人年龄采用对应的智力量表如韦克斯勒智力量表（儿童智力量表或成人智力量表）
							检测证实，智商的检测必须由保险人认可的医院的儿童或成人精神卫生科、心理科或神经科
							专科医生进行。并须满足下列全部条件：
						</div>
						<div className='indent'>
						（1）造成被保险人智力低常（智力低于常态）的严重头部创伤或疾病（以入院日期为
			准）发生在被保险人 6 周岁以后；
						</div>
						<div className='indent'>
						（2）保险人认可的医院的专科医师确诊被保险人由于严重头部创伤或疾病造成智力低
		常；
						</div>
						<div className='indent'>
						（3）保险人认可的医院的儿童或成人精神卫生科、心理科或神经科专科医生证实被保
			险人智力低常 IQ＜50）（中度、重度或极重度）；
						</div>
						<div className='indent'>
						<span className='bold'>（九十四）严重肠道疾病并发症：</span>严重肠道疾病或外伤导致小肠损害并发症，<span className='bold'>必须满
		足以下所有条件：</span>
						</div>
						<div className='indent'>
						（1）至少切除了三分之二小肠；
						</div>
						<div className='indent'>
						（2）完全肠外营养支持 3 个月以上。
						</div>
						<div className='indent'>
						<span className='bold'>（九十五）严重瑞氏综合征：</span>瑞氏综合征（Reye 综合征）是线粒体功能障碍性疾病。
		导致脂肪代谢障碍，引起短链脂肪酸、血氨升高，造成脑水肿。主要临床表现为急性发热、
		反复呕吐、惊厥及意识障碍等等。肝脏活检是确诊的重要手段。瑞氏综合征需由三级医院的
		专科医生确诊，<span className='bold'>并满足下列所有条件：</span>
						</div>
						<div className='indent'>
						（1）有脑水肿和颅内压升高的脑脊液检查和影像学检查证据；
						</div>
						<div className='indent'>
						（2）血氨超过正常值的 3 倍；
						</div>
						<div className='indent'>
						（3）临床出现昏迷，病程至少达到疾病分期第 3 期。
						</div>
						<div className='indent'>
						<span className='bold'>（九十六）急性肺损伤（ALI）或急性呼吸窘迫综合征（ARDS）：</span>一种表现为无心脏衰
			竭的肺水肿，为创伤、脓毒血症等临床多种疾病的并发症，造成多器官衰竭，死亡率高。
						</div>
						<div className='indent'>
						急性肺损伤或急性呼吸窘迫综合征必须由呼吸系统专科医师诊断，被保险人理赔时年龄
		在 25 周岁以下，并有所有以下临床证据支持：
						</div>
						<div className='indent'>
						（1）急性发作（原发疾病起病后 6 至 72 小时）；
						</div>
						<div className='indent'>
						（2）急性发作的临床症状体征包括呼吸急促、呼吸困难、心动过速、大汗、面色苍白
		及辅助呼吸肌活动加强（点头呼吸、提肩呼吸）；
						</div>
						<div className='indent'>
						（3）双肺浸润影；
						</div>
						<div className='indent'>
						（4）PaO<span className='lower'>2</span>/FiO<span className='lower'>2</span>（动脉血压分压/吸入气氧分压）低于 200mmHg；
						</div>
						<div className='indent'>
						（5）肺动脉嵌入压低于 18mmHg，
						</div>
						<div className='indent'>
						（6）临床无左房高压表现。
						</div>
						<div className='indent'>
						<span className='bold'>（九十七）溶血性尿毒综合征：</span>一种由于感染导致的急性综合征，引起红细胞溶血，肾
		功能衰竭及尿毒症。溶血尿毒综合征必须由血液和肾脏专科医师诊断，<span className='bold'>并且满足下列所有条
		件：</span>
						</div>
						<div className='indent'>
						（1）实验室检查确认有溶血性贫血、血尿、尿毒症、血小板减少性紫癜；
						</div>
						<div className='indent'>
						（2）因肾脏功能衰竭实施了肾脏透析治疗。
						</div>
						<div className='indent bold'>
						任何非因感染导致的溶血性贫血，如：自身免疫性溶血性贫血、与红细胞膜缺陷有关
			的溶血性贫血、红细胞酶病、血红蛋白病等，不在本保障范围内。
						</div>
						<div className='indent'>
						<span className='bold'>（九十八）亚历山大病：</span>亚历山大病（Alexander’s Disease）是一种遗传性中枢神经
			系统退行性病变，特点为脑白质营养不良性。临床表现为惊厥发作、智力下降、球麻痹、共
			济失调、痉挛性瘫痪。亚历山大病必须被明确诊断，并且造成永久性神经系统功能障碍。被
			保险人自主生活能力完全丧失，无法独立完成六项基本日常生活活动中的三项或三项以上，
			日常生活必须持续接受他人护理。
						</div>
						<div className='indent bold'>
						未明确诊断的疑似病例不在本保障范围之内。
						</div>
						<div className='indent'>
						<span className='bold'>（九十九）室壁瘤切除手术：</span>被保险人被明确诊断为左室室壁瘤，并且实际接受了开胸
			进行的室壁瘤切除手术治疗。
						</div>
						<div className='indent bold'>
						经导管心室内成型手术治疗不在本保障范围内。
						</div>
		
						
						<div className='indent'>
						<span className='bold'>【住院】</span>指被保险人因意外伤害或疾病而入住医院的正式病房接受全日 24 小时监护治
		疗的过程，并正式办理入住院手续。<span className='bold'>但不包括下列情况：</span>
						</div>
						<div className='indent bold'>
						（一）被保险人在医院的门（急）诊观察室，家庭病床（房）入住；
						</div>
						<div className='indent bold'>
						（二）被保险人在特需病房、外宾病房或其他不属于社会医疗保险范畴的高等级病房
		入住；
						</div>
						<div className='indent bold'>
						（三）被保险人入住康复科、康复病床或接受康复治疗；
						</div>
						<div className='indent bold'>
						（四）被保险人住院期间一天内未接受与入院诊断相关的检查和治疗或一天内住院不
		满二十四小时，但遵医嘱到外院接受临时治疗的除外；
						</div>
						<div className='indent bold'>
						（五）被保险人住院体检；
						</div>
						<div className='indent '>
						（六）<span className='bold'>挂床住院及其他不合理的住院。</span>挂床住院指办理正式住院手续的被保险人，在住
			院期间每日非 24 小时在院。具体表现包括在住院期间连续若干日无任何治疗，只发生护理
			费、诊疗费、床位费等情况。
						</div>
						
						<div className='indent'>
						<span className='bold'>【医院】</span>指国家卫生部医院等级分类中的二级或二级以上医院普通部，<span className='bold'>但不包括观察室、
		特需医疗、国际医疗、联合病房、康复病房和干部病房以及附属于前述医院或单独作为诊
		所、康复、护理、疗养、戒酒、戒毒等或相类似的医疗机构。</span>同时该医院必须具有符合有
		关医院管理规定设置标准的医疗设备，并且全日二十四小时有合格医师及护士驻院提供医疗
		和护理等服务。
						</div>
						<div className='indent'>
						<span className='bold'>【初次确诊】</span>指自被保险人出生之日起第一次经医院确诊患有某种疾病，<span className='bold'>而不是指自本
		合同生效之后第一次经医院确诊患有某种疾病。</span>
						</div>
						<div className='indent'>
						<span className='bold'>【意外伤害事故】</span>指外来的、非本意的、突然的、非疾病的使被保险人身体受到伤害的
		客观事件。<span className='bold'>猝死不属于意外伤害事故。</span>
						</div>
						<div className='indent'>
						其中，猝死指表面健康的人因潜在疾病、机能障碍或其他原因在出现症状后 24 小时内
			发生的非暴力性突然死亡。猝死的认定以医院的诊断和公安部门的鉴定为准。
						</div>
						<div className='indent'>
						<span className='bold'>【医生】</span>医生应当同时满足以下三项资格条件:
						</div>
						<div className='indent'>
						（1）具有有效的中华人民共和国《医师资格证书》；
						</div>
						<div className='indent'>
						（2）具有有效的中华人民共和国《医师执业证书》,并按期到相关部门登记注册；
						</div>
						<div className='indent'>
						（3）具有有效的中华人民共和国主治医师或主治医师以上职称的《医师职称证书》。
						</div>
						<div className='indent'>
						<span className='bold'>【必需且合理】</span>必需且合理指：
						</div>
						<div className='indent'>
						（1）符合通常惯例：指与接受医疗服务所在地通行治疗规范、通行治疗方法、平均医
		疗费用价格水平一致的费用。
						</div>
						<div className='indent'>
						（1）符合通常惯例：指与接受医疗服务所在地通行治疗规范、通行治疗方法、平均医
			疗费用价格水平一致的费用。
						</div>
						<div className='indent'>
						对是否符合通常惯例由保险人根据客观、审慎、合理的原则进行审核；如果被保险人对
		审核结果有不同意见，可由双方认同的权威医学机构或者权威医学专家进行审核鉴定。
						</div>
						<div className='indent'>
						（2）医学必需：指医疗费用符合下列所有条件：
						</div>
						<div className='indent'>
						1) 治疗意外伤害或者疾病所必需的项目；
						</div>
						<div className='indent'>
						2) 不超过安全、足量治疗原则的项目；
						</div>
						<div className='indent'>
						3) 由医生开具的处方药；
						</div>
						<div className='indent'>
						4) 非试验性的、非研究性的项目；
						</div>
						<div className='indent'>
						5) 与接受治疗当地普遍接受的医疗专业实践标准一致的项目。
						</div>
						<div className='indent'>
						对是否医学必需由保险人根据客观、审慎、合理的原则进行审核；如果被保险人对审核
		结果有不同意见，可由双方认同的权威医学机构或者权威医学专家进行审核鉴定。
						</div>
		
						<div className='indent'>
						<span className='bold'>【住院医疗费用】</span>指被保险人住院期间发生的医疗费用，包括：
						</div>
						<div className='indent'>
						（一）床位费
						</div>
						<div className='indent'>
						指被保险人在住院期间发生的医院床位的费用，包括普通床位费和重症监护室床位费，
		<span className='bold'>不包括陪人床、观察病床位和家庭病床的费用。</span>
						</div>
						<div className='indent'>
						（二）药品费
						</div>
						<div className='indent'>
						指住院期间实际发生的合理且必要的由医生开具的具有国家药品监督管理部门核发的
		药品批准文号或者进口药品注册证书、医药产品注册证书的国产或进口药品的费用。<span className='bold'>但不包
		括营养补充类药品，免疫功能调节类药品，美容及减肥类药品，预防类药品，以及下列中
		药类药品：（1）主要起营养滋补作用的单方、复方中药或中成药品，如花旗参，冬虫草，
		白糖参，朝鲜红参，玳瑁，蛤蚧，珊瑚，狗宝，红参，琥珀，灵芝，羚羊角尖粉，马宝，
		玛瑙，牛黄，麝香，西红花，血竭，燕窝，野山参，移山参，珍珠（粉），紫河车，阿胶，
		阿胶珠，血宝胶囊，红桃K口服液，十全大补丸，十全大补膏等； （2）部分可以入药的动
		物及动物脏器，如鹿茸，海马，胎盘，鞭，尾，筋，骨等； （3）用中药材和中药饮片炮
		制的各类酒制剂等。</span>
						</div>
						<div className='indent'>
						（三）治疗费
						</div>
						<div className='indent'>
						指住院期间以治疗疾病为目的，提供必要的医学手段而发生的合理的治疗者的技术劳务
			费和医疗器械使用费，以及消耗品的费用，包括注射费、机疗费、理疗费（中式理疗费：顺
			势疗法、正骨治疗、针灸治疗费；西式理疗费：物理治疗、美式脊椎矫正、职业疗法、语音
			治疗费）、输血费、输氧费、体外反搏费等。
						</div>
						<div className='indent'>
						（四）护理费
						</div>
						<div className='indent'>
						指住院期间根据医嘱所示的护理等级确定的护理费用。
						</div>
						<div className='indent'>
						（五）检查检验费
						</div>
						<div className='indent'>
						指住院期间实际发生的、以诊断疾病为目的，采取必要的医学手段进行检查及检验而发
		生的合理的医疗费用，包括X光费、心电图费、B超费、脑电图费、内窥镜费、肺功能仪费、
		分子生化检验费和血、尿、便常规检验费等。
						</div>
						<div className='indent'>
						（六）手术费
						</div>
						<div className='indent'>
						指当地卫生行政部门规定的手术项目的费用。包括手术费、麻醉费、手术监测费、手术
		材料费、术中用药费、手术设备费；<span className='bold'>若因器官移植而发生的手术费用，不包括器官本身的
		费用和获取器官过程中的费用。</span>
						</div>
		
						<div className='indent'>
						<span className='bold'>【化学疗法】</span>指针对于恶性肿瘤的化学治疗。化疗是使用医学界公认的化疗药物以杀死
			癌细胞、抑制癌细胞生长繁殖为目的而进行的治疗。本合同所指的化疗为被保险人根据医嘱，
			在医院进行的静脉注射化疗。
						</div>
						<div className='indent'>
						<span className='bold'>【放射疗法】</span>指针对恶性肿瘤的放射治疗。放疗是使用各种不同能量的射线照射肿瘤组
			织，以抑制和杀灭癌细胞为目的而进行的治疗。本合同所指的放疗为被保险人根据医嘱，在
			医院的专门科室进行的放疗。
						</div>
						<div className='indent'>
						<span className='bold'>【肿瘤免疫疗法】</span>指应用免疫学原理和方法，使用肿瘤免疫治疗药物提高肿瘤细胞的免
			疫原性和对效应细胞杀伤的敏感性，激发和增强机体抗肿瘤免疫应答，并应用免疫细胞和效
			应分子输注宿主体内，协同机体免疫系统杀伤肿瘤、抑制肿瘤生长。本合同所指的肿瘤免疫
			疗法使用的药物需符合法律、法规要求并经过国家食品药品监督管理总局批准用于临床治
			疗。
						</div>
						<div className='indent'>
						<span className='bold'>【肿瘤内分泌疗法】</span>指针对于恶性肿瘤的内分泌疗法，用药物抑制激素生成和激素反应，
			杀死癌细胞或抑制癌细胞的生长。本合同所指的内分泌疗法使用的药物需符合法律、法规要
			求并经过国家食品药品监督管理总局批准用于临床治疗。
						</div>
						<div className='indent'>
						<span className='bold'>【肿瘤靶向疗法】</span>指在细胞分子水平上，针对已经明确的致癌点来设计相应的靶向治疗
			药物，利用具有一定特异性的载体，将药物或其他杀伤肿瘤细胞的活性物质选择性地运送到
			肿瘤部位攻击癌细胞的疗法。本合同所指的靶向疗法使用的药物需符合法律、法规要求并经
			过国家食品药品监督管理总局批准用于临床治疗。
						</div>
						<div className='indent'>
						<span className='bold'>【社会基本医疗保险】</span>指《社会保险法》第三章规定的基本医疗保险，包括职工基本医
			疗保险、城镇居民基本医疗保险、新型农村合作医疗等政府举办的基本医疗保险。
						</div>
						<div className='indent'>
						<span className='bold'>【同一次住院】</span>与前次住院原因相同，且前次出院与下次入院间隔未超过 30 天的再次
			住院视为同一次住院。
						</div>
						<div className='indent'>
						<span className='bold'>【酗酒】</span>指没有节制地喝酒，以医疗机构或司法部门出具的酒精中毒或酒精摄入过量的
			相关证明为依据。
						</div>
						<div className='indent'>
						<span className='bold'>【毒品】</span>指中华人民共和国刑法规定的鸦片、海洛因、甲基苯丙胺（冰毒）、吗啡、大
			麻、可卡因以及国家规定管制的其他能够使人形成瘾癖的麻醉药品和精神药品，但不包括由
			医生开具并遵医嘱使用的用于治疗疾病但含有毒品成分的处方药品。
						</div>
						<div className='indent'>
						<span className='bold'>【酒后驾驶】</span>指经检测或鉴定，发生事故时车辆驾驶人员每百毫升血液中的酒精含量达
			到或超过一定的标准，公安机关交通管理部门依据《道路交通安全法》的规定认定为饮酒后
			驾驶或醉酒后驾驶。
						</div>
						<div className='indent'>
						<span className='bold'>【无合法有效驾驶证驾驶】</span>指下列情形之一：
						</div>
						<div className='indent'>
						（1）没有取得驾驶资格：
						</div>
						<div className='indent'>
						（2）驾驶与驾驶证准驾车型不相符合的车辆；
						</div>
						<div className='indent'>
						（3）持审验不合格的驾驶证驾驶；
						</div>
						<div className='indent'>
						（4）持学习驾驶证学习驾车时，无教练员随车指导，或不按指定时间、路线学习驾车。
						</div>
						<div className='indent'>
						<span className='bold'>【无有效行驶证照】</span>指下列情形之一：
						</div>
						<div className='indent'>
						（1）发生保险事故时无公安机关交通管理部门核发的合法有效的行驶证、号牌，或临
			时号牌或临时移动号牌；
						</div>
						<div className='indent'>
						（2）未依法按时进行或通过机动车安全技术检验。
						</div>
						<div className='indent'>
						<span className='bold'>【机动车】</span>指以动力装置驱动或者牵引，上道路行驶的供人员乘用或者用于运送物品以
			及进行工程专项作业的轮式车辆。
						</div>
						<div className='indent'>
						<span className='bold'>【潜水】</span>指以辅助呼吸器材在江、河、湖、海、水库、运河等水域进行的水下运动。
						</div>
						<div className='indent'>
						<span className='bold'>【攀岩】</span>指攀登悬崖、建筑物外墙、人造悬崖、冰崖、冰山和雪山等运动。
						</div>
						<div className='indent'>
						<span className='bold'>【探险活动】</span>指在某种特定的自然条件下有失去生命或使身体受到伤害的危险，而使自
				己置身于其中的行为，如江河漂流、徒步穿越沙漠或原始森林等活动。
						</div>
						<div className='indent'>
						<span className='bold'>【武术比赛】</span>指两人或两人以上对抗柔道、空手道、跆拳道、散打、拳击等各种拳术及
			各种使用器械的对抗性比赛。
						</div>
						<div className='indent'>
						<span className='bold'>【特技表演】</span>指从事马术、杂技、驯兽、飞车等特殊技能训练或比赛。
						</div>
						<div className='indent'>
						<span className='bold'>【遗传性疾病】</span>指生殖细胞或受精卵的遗传物质（染色体和基因）发生突变（或畸变）
			所引起的疾病，通常具有由亲代传至后代的垂直传递的特征。
						</div>
						<div className='indent'>
						<span className='bold'>【先天性畸形、变形或染色体异常】</span>指被保险人出生时就具有的畸形、变形或染色体异
			常。先天性畸形、变形和染色体异常依照世界卫生组织《疾病和有关健康问题的国际统计分
			类》（ICD-10）确定。
						</div>
						<div className='indent'>
						<span className='bold'>【感染艾滋病病毒或患艾滋病】</span>艾滋病病毒指人类免疫缺陷病毒，英文缩写为 HIV。艾
			滋病指人类免疫缺陷病毒引起的获得性免疫缺陷综合症，英文缩写为 AIDS。
						</div>
						<div className='indent'>
						在人体血液或其它样本中检测到艾滋病病毒或其抗体呈阳性，没有出现临床症状或体征
			的，为感染艾滋病病毒；如果同时出现了明显临床症状或体征的，为患艾滋病。
						</div>
						<div className='indent'>
						<span className='bold'>【保险费约定支付日】</span>指保险合同生效日在每月的对应日。如果当月无对应的同一日，
			则以该月最后一日为对应日。如保险合同生效日为2019年6月8日，则次月的保险费约定支付
			日为2019年7月8日，以此类推，则最后一个月的保险费约定支付日为2020年5月8日。
						</div>
						<div className='indent'>
						<span className='bold'>【剩余部分保险费】</span>指本合同所具有的价值，通常体现为解除合同时，根据精算原理计
			算的，由保险人退还的那部分金额。
						</div>
						<div className='indent'>
						保险费为一次性支付的：
						</div>
						<div className='indent'>
						剩余部分保险费=保险费*[1-(保险单已经过天数/保险期间天数)]
						</div>
						<div className='indent'>
						保险费为分期支付的：
						</div>
						<div className='indent'>
						剩余部分保险费=当期保险费*[1-(当期已经过天数/当期总天数)]
						</div>
						<div className='indent bold'>
						经过天数不足一天的按一天计算。若本合同已发生保险金赔偿，剩余部分保险费为零。
						</div>
						
		
						<div className='indent'>
						<span className='bold'>【不可抗力】</span>指不能预见、不能避免并不能克服的客观情况。
						</div>
						<div className='indent'>
						<span className='bold'>【保险金申请人】</span>指被保险人、受益人，被保险人、受益人的继承人或其他依法享有保
		险金请求权的自然人。
						</div>
						<div className='indent'>
						<span className='bold'>【有效身份证件】</span>指由中华人民共和国政府主管部门规定的能够证明其身份的证件，如：
			居民身份证、军官证、警官证、士兵证、户口簿以及中华人民共和国政府主管部门颁发或者
			认可的有效护照或者其他身份证明文件。
						</div>
						<div className='indent'>
						<span className='bold'>【肢体机能完全丧失】</span>指肢体的三大关节中的两大关节僵硬，或不能随意识活动。肢体
			是指包括肩关节的整个上肢或包括髋关节的整个下肢。
						</div>
						<div className='indent'>
						<span className='bold'>【语言能力或咀嚼吞咽能力完全丧失】</span>语言能力完全丧失是指无法发出四种语音（包括
			口唇音、齿舌音、口盖音和喉头音）中的任何三种、或声带全部切除，或因大脑语言中枢受
			伤害而患失语症。
						</div>
						<div className='indent'>
						咀嚼吞咽能力完全丧失是指因牙齿以外的原因导致器质障碍或机能障碍，以致不能作咀
			嚼吞咽运动，除流质食物外不能摄取或吞咽的状态。
						</div>
						<div className='indent'>
						<span className='bold'>【六项基本日常生活活动】</span>指（1）穿衣：自己能够穿衣及脱衣；（2）移动：自己从一
		个房间到另一个房间；（3）行动：自己上下床或者上下轮椅；（4）如厕：自己控制进行大
		小便；（5）进食：自己从已准备好的碗或者碟中取食物放入口中；（6）洗澡：自己进行淋
		浴或者盆浴。
						</div>
		
						<div className='indent'>
						<span className='bold'>【永久不可逆】</span>指自疾病确诊或意外伤害发生之日起，经过积极治疗 180 天后，仍无法
		通过现有医疗手段恢复。
						</div>
						</div>
		
				</div>
	}

// 	const scrollHandle = useCallback((e:any) => {
// 		let sTop = getScrollTop(); // 滚动条的y值
// 		let nav = document.getElementById('nav')
// 		let navTitle = document.getElementById('title')
// 		if (sTop > ( isIphoneX() ? 360-88 : 360-40)) {
// 			nav.style.backgroundColor = '#fff'
// 			navTitle.style.color = '#000'
// 		} else {
// 			nav.style.backgroundColor = '#0000'
// 			navTitle.style.color = '#0000'
// 		}
		
// 	}, [])

// 	useEffect(() => {
// 		let navTitle = document.getElementById('title')
// 		let nav = document.getElementById('nav')
// 		nav.style.backgroundColor = '#0000'
// 		navTitle.style.color = '#0000'

// 		window.addEventListener('scroll',scrollHandle,false)
// 		return () => {
// 			window.removeEventListener('scroll',scrollHandle,false)
// 		}
// },[])


	return (
		<div style={{ flex: 1, position: 'relative' }}>
			<div style={{position:'fixed',padding:0,height:!!isIphoneX()?'0.88rem':'0.4rem',backgroundColor:'#fff',zIndex:99, top:0,width:'100%'}}></div>
			<Nav title='' style={{border:'none',position:'fixed',padding:0,...{paddingTop:!!isIphoneX()?'0.88rem':'0.4rem'}}} ></Nav>
			{detail()}
		</div>
  )
}
// InsuranceSecurePlan.options = { disableHeader: false, wrapperClassName: '' }
export default InsuranceLicenceC
