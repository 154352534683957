import ShopBrandStory from './ShopBrandStory'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncShopBrandStoryPage = Loadable({
	loader: () => import('./ShopBrandStory'),
	loading: MyLoadingComponent
})
const LiveStream: Module = {
	name: 'shopbranstory',
	routes: [
		{
			name: 'e店品牌故事',
			path: '/shopBrandStory/share/:kid',
			component: asyncShopBrandStoryPage,
		},
	],
}
export default LiveStream
