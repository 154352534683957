import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncRenheyiyao = Loadable({
	loader: () => import('./Renheyiyao'),
	loading: MyLoadingComponent
})

const Renheyiyao: Module = {
	name: 'renheyiyao',
	routes: [
		{
			name: '仁鹤医药',
			path: '/mall/activepage/renheyiyao/:kid',
			component: asyncRenheyiyao,
		},
	],
}
export default Renheyiyao
