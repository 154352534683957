import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'
const asyncChildrenFood = Loadable({
	loader: () => import('./ChildrenFood'),
	loading: MyLoadingComponent
})
const ChildrenFood: Module = {
	name: 'branstory',
	routes: [
		{
			name: '儿童餐活动',
			path: '/childrenFoodActivity',
			component: asyncChildrenFood,
		},
	],
}
export default ChildrenFood
