import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncInterventionSchemeDetail = Loadable({
  loader: () => import('./InterventionSchemeDetailScreen'),
  loading: MyLoadingComponent,
})

const InterventionSchemeDetail: Module = {
  name: 'thyroid',
  routes: [
    {
      name: '甲状腺功能减退症营养治疗方案',
      path: '/interventionSchemeDetail/:type',
      component: asyncInterventionSchemeDetail,
    },
  ],
}

export default InterventionSchemeDetail
