import {httpPost,httpGet,httpPut,httpDelete} from "services/http"
type int = number;
		type List<T> = Array<T>
		type Collection<T> = Array<T>
		
type JsonNode = any
type long = number
interface KeyValueDTO<T,T1>{key:T,value:T1}
export interface IospayNotifyVO {
  /** 支付单号 */
  payNo?: string
  /** 凭据 */
  receipt?: string
  /** 交易ID */
  transactionId?: string
}

export interface PayVO {
  /** 支付单号 */
  payNo?: string
  /** 支付密码 */
  payPassword?: string
}

export interface WithdrawRspVO {
  /** 提现扩展信息 */
  withdrawExt?: string
  /** 提现单号 */
  withdrawNo?: string
  /** 提现状态：1-处理中 2-成功 3-失败 */
  withdrawStatus?: number
}

export interface WithdrawVO {
  /** 账户号 */
  accountNum?: string
  /** 账户类型 1-支付宝账户 2-微信账户 3-银行卡 */
  accountType?: number
  /** 账户用户名 */
  accountUserName?: string
  /** 提现金额(分) */
  amount?: number
  /** 业务系统回调队列名称 */
  callbackQueue?: string
  /** 提现订单描述 */
  orderDesc?: string
  /** 提现订单类型 */
  orderType?: string
  /** 随机数 */
  rand?: number
  /** 签名 */
  sign?: string
  /** 时间戳 */
  timestamp?: number
  /** 提现渠道：1-支付宝，2-微信，3-苹果，4-银联，5-中国银联（提现） */
  withdrawChannel?: number
  /** 提现扩展信息 */
  withdrawExt?: string
  /** 提现来源：1-IOS 2-ANDROID 3-WEB 4-MINI_PROGRAM */
  withdrawSource?: number
}
export default {
  healths: {
    /**
    * 健康检查
    */
    check(): Promise<boolean> {
      return httpGet(`/platform-pay/v5.0/pb/healths/action/check`).then((res:any) => res.data.data)
    },
  },
  notifys: {
    /**
    * 苹果支付回调
    */
    iospay(iospayNotifyVO: IospayNotifyVO): Promise<boolean> {
      return httpPost(`/platform-pay/v5.0/pt/notifys/action/iospay`, iospayNotifyVO).then((res:any) => res.data.data)
    },
  },
  payInfos: {
    /**
    * 余额支付接口
    */
    pay(payVO: PayVO): Promise<boolean> {
      return httpPost(`/platform-pay/v5.0/pt/pay-infos/action/pay`, payVO).then((res:any) => res.data.data)
    },
    /**
    * 查询支付订单状态：0-非法查询 1-处理中 2-成功 3-失败
    */
    payStatus(params: { payNo: string }): Promise<number> {
      return httpGet(`/platform-pay/v5.0/pt/pay-infos/action/pay-status`,  {params} ).then((res:any) => res.data.data)
    },
  },
  withdrawInfos: {
    /**
    * 提现
    */
    withdraw(withdrawVO: WithdrawVO): Promise<WithdrawRspVO> {
      return httpPost(`/platform-pay/v5.0/withdraw-infos/action/withdraw`, withdrawVO).then((res:any) => res.data.data)
    },
  },
}