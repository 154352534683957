import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'
const asyncTeamIndexPage = Loadable({
    loader: () => import('./TeamIndex'),
    loading: MyLoadingComponent
})
const asyncCircleMainPage = Loadable({
    loader: () => import('./CircleIndex'),
    loading: MyLoadingComponent
})
// component: TeamIndexPage,
const LiveStream: Module = {
    name: 'teamindex',
    routes: [
        {
            name: '小组首页',
            path: '/teamindex/share/:kid',
            component: asyncTeamIndexPage,
        },
        {
            name: '圈子首页',
            path: '/circle/share/:kid',
            component: asyncCircleMainPage,
        },
    ],
}
export default LiveStream
