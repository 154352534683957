import React, { useState, useEffect } from 'react'
// import nutrition from 'api/nutrition'
import { Button, Icon } from 'antd-mobile'
import './insu-secu.css'
import Nav from 'components/nav/Nav';
import isIphoneX from 'utils/isIphoneX';


const InsuranceLicenceList: PageComponent = props => {
  // const [result, setSesult] = useState({})
  useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
		// console.log('result - :',result)
		window.scrollTo(0, 0)
		// document.body.scrollTo({ top: 0, left: 0 })
	}, [])
	/**
	 */
	const licences = [
		{name:'《泰康在线财产保险股份有限公司个人住院医疗保险（C款）条款》',link:'/licencesC'},
		{name:'《泰康在线财产保险股份有限公司附加质子重离子医疗保险条款》', link:'/licencesA'},
		{name:'《附加扩展被保险人年龄保险（A款）条款》',link:'/licencesE'}
	]

	const openPage = (link:string) => {
		props.history.push(link)
	}

	const renderItem = (item:{name:string,link:string},index:number) => {
		return <div className='licence-li' key={'sec' + index} onClick={() => {
			openPage(item.link)
		}}>
			<div className='licence-li-head'>{item.name}</div>
			<div style={{width:'0.4rem',height:'0.4rem',display:'flex',alignItems:'center',justifyContent:'center'}}>
			<Icon type="right" size="lg" color="#B0B4BA" />
			</div>
			{/* <div className='section-title' style={{ fontWeight: 500 }}>
				<span>{item.title}</span>
				<span className='section-title-mount'>{item.payment}</span></div>
			<div className='section-list'>
				<ol>
					{item.options.map((it, iex) => {
						return <li key={index+'li'+iex} className='section-list-item' style={{ textAlign: 'justify' }}>
							<span>{iex+1}、</span>{it}
						</li>
					})}
				</ol>
			</div> */}
		</div>
	}

	return (
		<div style={{flex:1,position:'relative'}}>
			<Nav title='保险条款' style={{position:'fixed', backgroundColor:'#fff',padding:0,...{paddingTop:!!isIphoneX()?'0.88rem':'0.4rem'}}}></Nav>
		<div className='insu-wrap'>		
			<div className='nav-padding'></div>
			
			{licences.map(renderItem)}

		</div>
		</div>
		
    // <div className="demo-wrap">
    //   <div className="title">demo1</div>
    //   {/* <div>{JSON.stringify(result)}</div> */}
    //   <Button type="primary" size="small" inline className="btn">
    //     demo1
    //   </Button>
    //   <Button type="primary" size="large" className="btn">
    //     demo2
    //   </Button>
    // </div>
  )
}
// InsuranceSecurePlan.options = { disableHeader: false, wrapperClassName: '' }
export default InsuranceLicenceList
