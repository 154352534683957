/**
 * 收货人Item
 */
import React, { useState, ReactNode, Fragment } from 'react'
import { fuzzyNumber } from 'utils'
import { OrderReceivingAddressVO } from 'api/order'
import { Icon } from 'antd-mobile'
import mallStyles from '../../mallStyle'
import './ReceiverItem.css'
import assets from 'assets'
import { defaultProps } from 'antd-mobile/lib/search-bar/PropsType'
interface ReceiverItemProps {
  itemData?: OrderReceivingAddressVO
  onPickReceiver?: Function
  right?: ReactNode
  onEdit?: Function
	custClass?: string
	renderIcon?: ReactNode
}
const ReceiverItem: React.FC<ReceiverItemProps> = ({
  itemData = {},
  onPickReceiver,
  right,
  onEdit,
  custClass,
  ...props
}) => {
  let { defaultFlag, receivingName, receivingPhone, receivingAddresDetail } = itemData
  let editReveicer = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onEdit && onEdit(itemData)
  }
  return (
    <div className={`receiver-item  ${custClass ? custClass : ''}`}>
			<div className="receiver-item-left" onClick={() => onPickReceiver(itemData)}>
				{props.renderIcon ? props.renderIcon : null }
        {props.children ? (
          props.children
        ) : (
          <div style={{ justifyContent: 'center' }}>
            <div className="flexCen">
              {defaultFlag === 0 ? <span style={styles.defaultTxt}>默认</span> : null}
              <span className="smallGrayTxt">
                {itemData.receivingProvinceText} {itemData.receivingCityText} {itemData.receivingCountyText}
              </span>
            </div>
            <div className="receiverAddrTxt">{receivingAddresDetail}</div>
            <div className="flexCen">
              <span className="assistText" style={{ maxWidth: '3.7rem' }}>
                {receivingName}
              </span>
              <span className="assistText">{fuzzyNumber(receivingPhone)}</span>
            </div>
          </div>
        )}
      </div>
      {right ? (
        right
      ) : (
        <div className="editWrap" onClick={editReveicer}>
          <img src={assets.mall.edit_icon} alt="" className="editIcon" />
        </div>
      )}
      <div className="separateLine"></div>
    </div>
  )
}
export default ReceiverItem
const styles = {
  defaultTxt: {
    fontSize: '0.2rem',
    color: '#FE5500',
    justifyContent: 'center',
    borderRadius: '0.2rem',
    borderWidth: '1px',
    borderColor: '#FE5500',
    borderStyle: 'solid',
    paddingLeft: '0.16rem',
    paddingRight: '0.16rem',
    paddingTop: '0.04rem',
    paddingBottom: '0.04rem',
    marginRight: '0.1rem',
  },
  receiverAddrTxt: {
    fontSize: '0.36rem',
    color: '#1F1F1F',
    lineHeight: '0.50rem',
    marginLeft: '0.2rem',
    display: 'inline-block',
  },
}
