import React, { useState, useEffect } from 'react'
// import nutrition from 'api/nutrition'
import { Button, Icon } from 'antd-mobile'
import './insu-licence.css'
import Nav from 'components/nav/Nav';
import isIphoneX from 'utils/isIphoneX';


const InsuranceLicenceAppend: PageComponent = props => {
  // const [result, setSesult] = useState({})
  
	useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
		// console.log('result - :',result)
		window.scrollTo(0, 0)
		// document.body.scrollTo({top:0,left:0})
	}, [])

	const detail = () => {
		return <div className='insulic-wrap' style={{backgroundColor:'#fff'}}>

		<span className='licenceTitle center bold'>泰康在线财产保险股份有限公司</span>
		<span className='licenceTitle center bold'>附加质子重离子医疗保险条款</span>

		<span className='licenceSection center bold' >总则</span>
		<div className='licenceContent'>
			<div className='indent '>
			<span className='bold' >第一条</span> <span className='space'></span> 本附加保险合同（以下简称为“本附加合同”）须附加于医疗保险合同（以下简 称“主险合同”）。主险合同所附条款、投保单、保险单、保险凭证以及批单等，凡与本附加合 同相关者，均为本附加合同的构成部分。凡涉及本附加合同的约定，均应采用书面或电子形式。
		</div>
			<div className='indent bold'>
			若主险合同与本附加合同的条款互有冲突，则以本附加合同的条款为准。本附加合同未尽 事宜，以主险合同的条款规定为准。
			</div>
			<div className='indent'>
			<span className='bold' >第二条</span> <span className='space'></span> 本附加合同的投保人、被保险人、受益人均与主险合同一致。
			</div>
		</div>

		<span className='licenceSection center bold' >保险责任</span>
		<div className='licenceContent'>
			<div className='indent '>
			<span className='bold' >第三条</span> <span className='space'></span>  在本附加合同保险期间内，被保险人在主险合同约定的等待期后经医院初次确 诊罹患主险合同所定义的恶性肿瘤，在载明于保险单上的特定医疗机构接受<span className='bold'>质子、重离子放射 治疗</span>的，则对被保险人在特定医疗机构支付的合理的<span className='bold'>质子重离子医疗费，保险人在扣除免赔额 后，按照约定的赔偿比例向被保险人赔偿质子重离子医疗保险金，但累计赔偿金额最高不超过 本附加合同约定的质子重离子医疗保险金的保险金额。</span>
		</div>
			<div className='indent'>
			<span className='bold' >第四条</span> <span className='space'></span> 特定医疗机构以在保险单中载明的名单为准。<span className='bold'>保险期间内保险人可以调整特定 医疗机构名单，但以保险人在官方正式渠道（包括但不限于官网、官微）的通知为准。</span>
			</div>
			<div className='indent bold'>
			<span className='bold' >第五条</span> <span className='space'></span> 本附加合同适用医疗费用补偿原则，补偿原则和赔付标准与主险合同一致。
			</div>
			<div className='indent bold'>
			<span className='bold' >第六条</span> <span className='space'></span>  除另有约定外，被保险人在本附加合同保险期间届满前 180 天内（含）经医院 初次确诊罹患主险合同所定义的恶性肿瘤，并在医院接受住院治疗，如果在本附加合同保险期 间届满之日该次住院治疗仍未结束的，保险人对于被保险人自该次住院开始之日起（含住院当 日）的 180 天内（含 180 天）内所发生的同一次住院的质子重离子医疗费用，保险人按照本附 加合同的约定承担保险责任，同时本附加合同终止。
			</div>
		</div>

		<span className='licenceSection center bold' >责任免除</span>
		<div className='licenceContent'>
			<div className='indent '>
			<span className='bold' >第七条</span> <span className='space'></span>  除另有约定外，本附加合同的责任免除事项与主险合同一致。
		</div>
		</div>

		<span className='licenceSection center bold' >保险期间</span>
		<div className='licenceContent'>
			<div className='indent '>
			<span className='bold' >第八条</span> <span className='space'></span>  除另有约定外，本附加合同的保险期间与主险合同一致。
		</div>
		</div>

		<span className='licenceSection center bold' >保险金额、保险费与免赔额（率）</span>
		<div className='licenceContent'>
			<div className='indent bold'>
			<span className='bold' >第九条</span> <span className='space'></span>  本附加合同的保险金额包含于主险合同的保险金额之内，若保险人在主险合同
及本附加合同下累计赔偿保险金的金额达到主险合同约定的保险金额，则主险合同和本附加合
同同时终止，保险人对被保险人不再承担赔偿保险金的责任。
		</div>
		<div className='indent'>
			<span className='bold' >第十条</span> <span className='space'></span>  保险费依据保险金额与保险费率计收，在保险单中载明。除另有约定外，本附
加合同保险费支付方式与主险合同一致。
		</div>
		<div className='indent bold'>
			<span className='bold' >第十一条</span> <span className='space'></span>  本附加合同所称免赔额均指年免赔额，本附加合同与主险合同共用免赔额，
若免赔额在主险合同下赔付时已完全扣除，则本附加合同不再扣除免赔额；若免赔额在本附加
合同赔付时已经完全扣除，则主险合同不再扣除免赔额。
		</div>
		</div>

		<span className='licenceSection center bold' >释义</span>
		<div className='licenceContent'>
			<div className='indent '>
			<span className='bold' >第十二条</span> <span className='space'></span>  本附加合同涉及下列术语时，适用以下释义:
		</div>
		<div className='indent'>
			<span className='bold'>【质子、重离子放射治疗】</span>指利用质子和重离子对肿瘤进行放射治疗的技术。本合同所指
的质子、重离子放射治疗是指被保险人根据医嘱，在保单载明的特定医疗机构的专门治疗室内
接受的质子和重离子放射治疗。
			</div>
			<div className='indent'>
			<span className='bold'>【质子重离子医疗费】</span>指被保险人因接受质子、重离子放射治疗而发生的相关费用，包括
床位费、膳食费、护理费、诊疗费、检查检验费、治疗费、药品费等，但<span className='bold'>不包括化学疗法、肿
瘤免疫疗法、肿瘤内分泌疗法和肿瘤靶向疗法所产生的药品费。</span>
			</div>
			<div className='indent bold'>
			本附加合同的未释义名词，以本附加合同所附属主险合同中的释义为准。
		</div>
		</div>

	</div>
	}


	return (
		<div style={{ flex: 1, position: 'relative' }}>
			<div style={{position:'fixed',padding:0,height:!!isIphoneX()?'0.88rem':'0.4rem',backgroundColor:'#fff',zIndex:99, top:0,width:'100%'}}></div>
			<Nav title='' style={{border:'none',position:"fixed",padding:0,...{paddingTop:!!isIphoneX()?'0.88rem':'0.4rem'}}} ></Nav>
			{detail()}
		</div>
  )
}
// InsuranceSecurePlan.options = { disableHeader: false, wrapperClassName: '' }
export default InsuranceLicenceAppend
