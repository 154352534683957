import StoreDetail from "./StoreDetail"
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const Store: Module = {
	name: 'store',
	routes: [
			{
					name: '店铺详情页',
					path: '/storedetail/share/:kid',
					component: Loadable({
						loader: ()=> import('./StoreDetail'),
						loading: MyLoadingComponent
				}),
		},

	],
}

export default Store