import PrivacyPolicyPage from './PrivacyPolicy'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncPrivacyPolicy = Loadable({
    loader: () => import('./PrivacyPolicy'),
    loading: MyLoadingComponent
})
const asyncDieticianPolicy = Loadable({
    loader: () => import('./DieticianPolicy'),
    loading: MyLoadingComponent
})
const asyncAssignmentPolicy = Loadable({
    loader: () => import('./Assignment'),
    loading: MyLoadingComponent
})
const asyncConsultationPolicy = Loadable({
    loader: () => import('./Consultation'),
    loading: MyLoadingComponent
})
const asyncCopyrightPolicy = Loadable({
    loader: () => import('./Copyright'),
    loading: MyLoadingComponent
})
const asyncCopyrightNoticePolicy = Loadable({
    loader: () => import('./CopyrightNotice'),
    loading: MyLoadingComponent
})
const asyncOrderRulePolicy = Loadable({
    loader: () => import('./OrderRule'),
    loading: MyLoadingComponent
})

const asyncOnetoOneService = Loadable({
	loader: () => import('./OnetoOneService'),
	loading: MyLoadingComponent
})

const asyncDisclaimer = Loadable({
	loader: () => import('./Disclaimer'),
	loading: MyLoadingComponent
})

const asyncVideoDirection = Loadable({
	loader: () => import('./VideoDirection'),
	loading: MyLoadingComponent
})
const asyncCopyrightCustomerService = Loadable({
    loader: () => import('./CopyrightCustomerService'),
    loading: MyLoadingComponent
})
const asyncUserCustomerService = Loadable({
    loader: () => import('./UserCustomerService'),
    loading: MyLoadingComponent
})
const asyncPrivacyCustomerService = Loadable({
    loader: () => import('./PrivacyCustomerService'),
    loading: MyLoadingComponent
})
const asyncServiceCopyright = Loadable({
    loader: () => import('./serviceCopyright'),
    loading: MyLoadingComponent
})
const asyncServiceUserAgreement = Loadable({
    loader: () => import('./serviceUserAgreement'),
    loading: MyLoadingComponent
})
const asyncServiceUserPrivacy = Loadable({
    loader: () => import('./serviceUserPrivacy'),
    loading: MyLoadingComponent
})
const PrivacyPolicy: Module = {
    name: 'groupdetail',
    routes: [
        {
            name: '“营养e生”用户隐私政策',
            path: '/privacypolicy',
            component: asyncPrivacyPolicy,
        },
        {
            name: '营养师入驻协议',
            path: '/dietician/policy',
            component: asyncDieticianPolicy,
        },
        {
            name: '“营养e生”用户服务协议',
            path: '/assignment/policy',
            component: asyncAssignmentPolicy,
        },
        {
            name: '营养家政咨询服务条款',
            path: '/consultation/policy',
            component: asyncConsultationPolicy,
        },
        // {
        //     name: '营养e生软件许可协议',
        //     path: '/copyright/policy',
        //     component: asyncCopyrightPolicy,
        // },
        {
            name: '营养e生版权声明',
            path: '/copyright/policy',
            component: asyncCopyrightNoticePolicy
        },
        {
            name: '预约规则',
            path: '/orderRule/policy',
            component: asyncOrderRulePolicy,
				},
				{
					name: '一对一服务规则',
					path: '/onetoOneService',
					component: asyncOnetoOneService,
			},
			{
				name: '“营养e生”免责声明',
				path: '/disclaimer',
				component: asyncDisclaimer,
			},
			{
				name: '“专家视频指导”服务规则说明',
				path: '/videodirection',
				component: asyncVideoDirection,
			},
        {
            name: '“营养e生-客服端”软件许可协议',
            path: '/copyrightCustomerService',
            component: asyncCopyrightCustomerService,
        },
        {
            name: '营养e生-客服端”用户服务协议',
            path: '/userCustomerService',
            component: asyncUserCustomerService,
        },
        {
            name: '“营养e生-客服端”用户隐私政策',
            path: '/privacyCustomerService',
            component: asyncPrivacyCustomerService,
        },
        {
            name: '"营养e生-服务商版”版权声明',
            path: "/serviceCopyright",
            component: asyncServiceCopyright,
        },
        {
            name: '“营养e生-服务商版”用户服务协议',
            path: "/serviceUserAgreement",
            component: asyncServiceUserAgreement,
        },
        {
            name: '“营养e生-服务商版”用户隐私政策',
            path: "/serviceUserPrivacy",
            component: asyncServiceUserPrivacy,
        },
    ],
}
export default PrivacyPolicy