import React, { useState, useEffect } from 'react'
import { Toast } from 'antd-mobile';
import './downloadapp.css'

const QrcodeDownLoad: PageComponent =  props => {
    return (
        <div className="QrcodeDownLoad">
            <div className="cont">
                <img className="logo-img" src={require('./images/qrcode-download.png')} alt=""/>
            </div>
        </div>
    )
}
export default QrcodeDownLoad