import {httpPost,httpGet,httpPut,httpDelete} from "services/http"
type int = number;
		type List<T> = Array<T>
		type Collection<T> = Array<T>
		
type JsonNode = any
type long = number
interface KeyValueDTO<T,T1>{key:T,value:T1}
export interface BrandStoryEvaluatesAndTagsVO {
  evaluates?: BrandStoryEvaluateVO[]
  tags?: BrandStoryTagVO[]
}

export interface BrandStoryEvaluateVO {
  /** 头像 */
  avartar?: string
  brandStoryId?: number
  /** 内容 */
  content?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 商品图片 */
  goodsPic?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  products?: Product[]
  /** 推荐商品id */
  recommodGoodsIds?: string
  /** 用户昵称 */
  userName?: string
}

export interface Product {
  /** 审核状态 1.待审 2.审核中 3.通过 4.驳回 5.撤回 */
  auditStatus?: number
  /** 商品品牌ID */
  brandId?: number
  /** 平台抽成费率 百分制 */
  commissionRate?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 详情网页内容 */
  detailHtml?: string
  /** 展示图片 */
  detailShowPic?: string
  /** 展示视频 */
  detailShowVideo?: string
  /** 商品运费模板ID */
  feightTemplateId?: number
  /** 注册商标 */
  icon?: string
  /** 分布式唯一ID */
  kid?: number
  /** 是否专家推荐 */
  kol?: boolean
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 封面图片 */
  mainPic?: string
  /** 商户ID */
  merchantId?: number
  /** 商户店铺商品分类 */
  merchantProductCategoryId?: number
  /** 原价【冗余SKU最低价】 */
  originalPrice?: number
  /** 出产检验报告 */
  produceCheckReportPic?: string
  /** 商品所属二级分类ID */
  productCategoryId?: number
  /** 商品所属一级分类ID */
  productCategoryIdParent?: number
  /** 商品认证，冒号分隔  0 无公害 1 绿色 2 有机 3地理 */
  productCerts?: string
  /** 商品种类  0 本地商品 1 线上 2 课程  3 线下 */
  productClass?: number
  /** 商品课程ID */
  productCourseId?: number
  /** 商品食材ID */
  productFoodId?: string
  /** 商品名 */
  productName?: string
  /** 商品识别码 */
  productNo?: string
  /** 商品营养师 */
  productNutritionistId?: number
  /** 推荐排序 */
  recommandSort?: number
  /** 促销角标  1折扣 2 特卖 3 满减 */
  saleIcon?: number
  /** 促销价格【冗余SKU最低价】 */
  salePrice?: number
  /** 销量 */
  sales?: number
  /** 上架时间 */
  shelveDate?: string
  /** 上架状态：0->上架；1->下架 */
  shelveFlag?: number
  /** 店铺推荐  0 推荐 1 不推荐 */
  shopRecommend?: number
  /** 是否商城展示  0 正常 1 不展示 */
  showOut?: number
  /** 简介 */
  subTitle?: string
  /** 有效类型  0 一直有效 1 按时间段 */
  usageState?: number
  /** 有效时间-结束 */
  usageTimeEnd?: string
  /** 有效时间-开始 */
  usageTimeStart?: string
  /** 会员价格 */
  vipPrice?: number
  /** 资质预警状态： 0 正常 1 预警 */
  warningStatusCert?: number
  /** 库存预警状态： 0 正常 1 预警 */
  warningStatusStock?: number
}

export interface BrandStoryTagVO {
  behavior?: UserBehaviorResult
  /** 品牌故事id */
  brandStoryId?: number
  /** 创建时间 */
  createDate?: string
  /** 点赞数 */
  favoriteCount?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 标签名 */
  tagName?: string
}

export interface UserBehaviorResult {
  /** 是否评论（0否，1是） */
  commentFlag?: number
  /** 自定义（0否，1是） */
  customMapper?: any
  /** 是否收藏（0否，1是） */
  favoriteFlag?: number
  /** 是否完成（0否，1是） */
  finishFlag?: number
  /** 是否关注（0否，1是） */
  followFlag?: number
  /** 是否加入（0否，1是） */
  joinFlag?: number
  /** 是否点赞（0否，1是） */
  likeFlag?: number
  /** 是否分享（0否，1是） */
  shareFlag?: number
  /** 目标资源唯一标识 */
  targetKid?: string
  /** 是否阅读（0否，1是） */
  viewFlag?: number
}

export interface BrandStoryAppVO {
  behavior?: UserBehaviorResult
  /** 品牌简介 */
  brandIntroduce?: string
  /** 品牌logo */
  brandLogo?: string
  /** 品牌名称 */
  brandName?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识1删除0未删除 */
  delFlag?: number
  /** 品牌故事详情 */
  detailHtml?: string
  evaluates?: BrandStoryEvaluate[]
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  products?: Product[]
  /** 推荐商品 */
  recommendGoodsIds?: string
  /** 关联店铺 */
  shopId?: number
  shopScore?: number
  /** slogan */
  slogan?: string
}

export interface BrandStoryEvaluate {
  /** 头像 */
  avartar?: string
  brandStoryId?: number
  /** 内容 */
  content?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 商品图片 */
  goodsPic?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 推荐商品id */
  recommodGoodsIds?: string
  /** 用户昵称 */
  userName?: string
}

export interface BrandStory {
  /** 品牌简介 */
  brandIntroduce?: string
  /** 品牌logo */
  brandLogo?: string
  /** 品牌名称 */
  brandName?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识1删除0未删除 */
  delFlag?: number
  /** 品牌故事详情 */
  detailHtml?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 推荐商品 */
  recommendGoodsIds?: string
  /** 关联店铺 */
  shopId?: number
  /** slogan */
  slogan?: string
}

export interface PageList<T> {
  /** 数据总条数，前端接口可忽略改字段 */
  count?: number
  /** 数据集合 */
  entities?: T[]
  /** 当前页码 */
  pageNo?: number
  /** 每页条数 */
  pageSize?: number
}

export interface CouponRecordsAppBaseVO {
  /** 可领取/使用券数量 */
  canReceiveNum?: number
  /** 优惠券列表 */
  list?: CouponRecordsAppVO[]
}

export interface CouponRecordsAppVO {
  /** 门槛条件：大于多少元 */
  conditionPrice?: number
  /** 三方id */
  cooperationId?: number
  /** 三方名称 */
  cooperationName?: string
  /** 优惠券id */
  couponId?: number
  /** 优惠券名称 */
  couponName?: string
  /** 优惠券类型：0:平台优惠券；1:商户优惠券 */
  couponType?: number
  /** 备注 */
  description?: string
  /** 优惠券面额 */
  faceValue?: number
  /** kid */
  kid?: number
  /** 使用范围：0:全部商品可用；1:指定商品可用 */
  useArea?: number
  /** 优惠券可用商品范围  逗号隔开的商品ID集 */
  useAreaProducts?: string
  /** 使用门槛：0:无门槛；1:有门槛 */
  useCondition?: number
  /** 有效期结束时间 */
  validPeriodEndTime?: string
  /** 有效期开始时间 */
  validPeriodStartTime?: string
  /** 是否已领取,0 未领取,1已领取 */
  wasReceive?: number
}

export interface HealthExaminationMealVO {
  /** 驳回原因 */
  description?: string
  /** 套餐性别编码(0男 1女 2通用) */
  gender?: number
  /** 套餐所属体检中心编号 */
  hospitalId?: number
  /** 体检项目 */
  items?: HealthExaminationMealItem[]
  /** 套餐婚否信息（0：通用 1：已婚（有性经历）） */
  marriageId?: number
  /** 套餐名称 */
  name?: string
  /** 套餐价格 */
  price?: number
  /** 套餐标签 如：”糖尿病”, “三高”, “贵宾服务” */
  tags?: string
  /** 套餐类型编码(1:单位套餐，2：收藏套餐，3：通用套餐/官方套餐 4:基础推荐套餐，5：平台套餐) */
  type?: number
}

export interface HealthExaminationMealItem {
  /** 创建时间 */
  createDate?: string
  /** 单项描述 */
  description?: string
  /** 套餐婚否信息（0：通用 1：已婚（有性经历）） */
  detail?: string
  /** 项目适宜人群 */
  fitPeople?: string
  /** 项目类型 */
  itemType?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 单项编号 */
  mealId?: number
  /** 套餐名称 */
  name?: string
  /** 项目不适宜人群 */
  unfitPeople?: string
}

export interface HealthExaminationMeal {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 驳回原因 */
  description?: string
  /** 套餐性别编码(0男 1女 2通用) */
  gender?: number
  /** 套餐所属体检中心编号 */
  hospitalId?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 套餐婚否信息（0：通用 1：已婚（有性经历）） */
  marriageId?: number
  /** 套餐名称 */
  name?: string
  /** 套餐价格 */
  price?: number
  /** 套餐标签 如：”糖尿病”, “三高”, “贵宾服务” */
  tags?: string
  /** 套餐类型编码(1:单位套餐，2：收藏套餐，3：通用套餐/官方套餐 4:基础推荐套餐，5：平台套餐) */
  type?: number
}

export interface ProductBrand {
  /** 品牌log */
  brandLog?: string
  /** 品牌名称 */
  brandName?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 描述 */
  describeText?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** sort */
  sortNum?: number
  /** 专题图片 */
  subjectPic?: string
}

export interface ProductCategoryVO {
  /** 类目名称 */
  categoryName?: string
  /** 商品品类类型 0 实物商品 1 课程 */
  categoryType?: number
  childrenVO?: KeyValueDTO<string,ProductCategoryVO>
  /** 图标url */
  icon?: string
  /** kid */
  kid?: number
  /** 标签KID */
  labelKid?: number
  /** 标签名称 */
  labelName?: string
  /** 父级kid */
  parentKid?: number
  /** 排序编号 */
  sortNum?: number
  /** 百科简介 */
  wikiIntro?: string
}

export interface ProductCategoryGradeVO {
  /** 标签归类vo */
  categoryLabelVO?: CategoryLabelVO[]
  /** 类目名称 */
  categoryName?: string
  /** 图标url */
  icon?: string
  /** kid */
  kid?: number
  /** 标签KID */
  labelKid?: number
  /** 标签名称 */
  labelName?: string
  /** 父级kid */
  parentKid?: number
  /** 排序编号 */
  sortNum?: number
  /** 百科简介 */
  wikiIntro?: string
}

export interface CategoryLabelVO {
  labelChildren?: ProductCategoryGradeVO[]
  labelName?: string
}

export interface ProductCategory {
  /** 类目名称 */
  categoryName?: string
  /** 商品品类类型 0 实物商品 1 课程 */
  categoryType?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 是否删除 */
  delFlag?: number
  /** 图标url */
  icon?: string
  /** 分布式唯一ID */
  kid?: number
  /** 标签kid */
  labelKid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 父级kid */
  parentKid?: number
  /** 排序编号 */
  sortNum?: number
  /** 百科详情(KV形式的json数组) */
  wikiDetails?: string
  /** 百科简介 */
  wikiIntro?: string
}

export interface ProductCategoryWikiVO {
  kid?: number
  /** 百科详情 k:v对象数组 k表示标题 v表示内容 */
  wikiDetail?: string
  /** 百科简介 */
  wikiIntro?: string
}

export interface ProductEvaluationListVO {
  /** 评价时间 */
  addTime?: string
  /** 1 匿名 0不匿名 */
  anonymous?: number
  /** 评论内容 */
  content?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 1删除0正常 */
  delFlag?: number
  /** 1.好评 2.中评 3差评 */
  grade?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 商户id */
  merchantId?: number
  /** 商户名称 */
  merchantName?: string
  /** 订单kid */
  orderKid?: number
  /** 评论图片 */
  pics?: string
  /** 商品id */
  productId?: number
  /** 商品名称 */
  productName?: string
  /** 店铺名称 */
  shopName?: string
  /** skukid */
  skuKid?: number
  /** sku图片 */
  skuPic?: string
  /** sku规格 */
  skuSpec?: string
  /** sku规格属性 */
  skuSpecAttr?: string
  /** 用户信息 */
  user?: UserSimpleVO
  /** 评价人 */
  userId?: number
}

export interface UserSimpleVO {
  /** 星座 */
  constellation?: string
  /** 现居地 */
  livingPlace?: string
  /** 用户角色集合 */
  roles?: UserRole[]
  /** 用户背景图片 */
  userBgImg?: string
  /** 出生年月日 */
  userBirthday?: string
  /** 用户性别 0女/1男 */
  userGenders?: number
  /** 用户账户id */
  userId?: number
  /** 头像 */
  userImg?: string
  /** 姓名 */
  userName?: string
  /** 昵称 */
  userNickName?: string
  /** 用户签名 */
  userSignature?: string
  /** 马甲状态 0-普通用户 1-马甲 */
  vestFlag?: number
}

export interface UserRole {
  /** 角色 */
  role?: string
  /** 角色头衔 */
  roleTitle?: string
}

export interface ProductEvaluationVO {
  /** 评价列表 */
  entities?: ProductEvaluationListVO[]
  /** 好评数 */
  goodNum?: number
  /** 有图数量 */
  hasPicNum?: number
  /** 中评数 */
  mediumNum?: number
  /** 差评数 */
  negativeNum?: number
  /** 总评数 */
  totalNum?: number
}

export interface ProductEvaluationQueryDTO {
  /** 评论table类型 0全部 1好评 2中评 3差评 4有图 */
  evaluateType?: number
  /** 商户id */
  merchantId?: number
  /** 商户名称 */
  merchantName?: string
  /** 页码 */
  pageNo?: number
  /** 页面大小 */
  pageSize?: number
  /** 商品id */
  productId?: number
  /** 商品名称 */
  productName?: string
  /** 店铺名称 */
  shopName?: string
}

export interface ProductSubjectListVO {
  /** 品牌专题 */
  brandSubjects?: ProductSubjectVO[]
  /** H5专题 */
  h5Subjects?: ProductSubjectVO[]
  /** 热销精选 */
  hotSubjects?: ProductSubjectVO[]
}

export interface ProductSubjectVO {
  h5Url?: string
  pic?: string
  products?: ProductSubjectProductVO[]
  subjectId?: number
  subjectName?: string
  subjectSort?: number
}

export interface ProductSubjectProductVO {
  /** 会员权益（是否展示会员价） */
  joinVip?: boolean
  /** 封面图片 */
  kid?: number
  /** 封面图片 */
  mainPic?: string
  /** 原价 */
  originalPrice?: number
  /** 商品种类  0 本地商品 1 线上 2 课程  3 线下 */
  productClass?: number
  /** 商品名 */
  productName?: string
  /** 推荐排序 */
  recommandSort?: number
  /** 促销角标  1折扣 2 特卖 3 满减 */
  saleIcon?: number
  /** 销售价格 */
  salePrice?: number
  /** 销量 */
  sales?: number
  /** 会员价格 */
  vipPrice?: number
}

export interface ShopDetailVO {
  entities?: ProductVO[]
  /** 商户店铺id */
  merchantId?: number
  /** 商品数量 */
  productCount?: number
  /** 商户店铺图标 */
  shopLogo?: string
  /** 店铺名称 */
  shopName?: string
}

export interface ProductVO {
  /** 三品 */
  grades?: string
  /** 会员权益（是否展示会员价） */
  joinVip?: boolean
  /** 商品业务id */
  kid?: number
  /** 缩略图 */
  mainPic?: string
  /** 商户店铺id */
  merchantId?: number
  /** 原价 */
  originalPrice?: number
  /** 商品名称 */
  productName?: string
  /** 售价 */
  salePrice?: number
  /** 一标 */
  standard?: string
  /** 会员价格 */
  vipPrice?: number
}

export interface ProductAppVO {
  /** 三品 */
  grades?: string
  /** 会员权益（是否展示会员价） */
  joinVip?: boolean
  /** 分布式唯一ID */
  kid?: number
  /** 封面图片 */
  mainPic?: string
  /** 原价【冗余SKU最低价】 */
  originalPrice?: number
  /** 预售结束时间 */
  preSaleEndTime?: string
  /** 预售开始时间 */
  preSaleStartTime?: string
  /** 状态  1.预售中 2.已取消 3.已结束 */
  preSaleState?: number
  /** 商品名 */
  productName?: string
  /** 促销价格【冗余SKU最低价】 */
  salePrice?: number
  /** 一标 */
  standard?: string
  /** 会员价格 */
  vipPrice?: number
}

export interface ProductDetailForAuditTempVO {
  /** 商品发货地 */
  address?: ProductAddressDetaiVO[]
  /** 商品参数 */
  attributes?: ProductAttributeValueVO[]
  /** 审核状态 1.待审 2.审核中 3.通过 4.驳回 5.撤回 */
  auditStatus?: number
  /** 商品品牌ID */
  brandId?: number
  /** 认证类别 0 无公害 1 绿色 2 有机 3地理 4 其他 5 无 */
  certs?: ProductCertificationVO[]
  /** 平台抽成费率 百分制 */
  commissionRate?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 详情网页内容 */
  detailHtml?: string
  /** 展示图片 */
  detailShowPic?: string
  /** 展示视频 */
  detailShowVideo?: string
  /** 商品运费模板ID */
  feightTemplateId?: number
  /** 运费模板名称 */
  feightTemplateName?: string
  /** 注册商标 */
  icon?: string
  /** 分布式唯一ID */
  kid?: number
  /** 是否专家推荐 */
  kol?: boolean
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 封面图片 */
  mainPic?: string
  /** 商户ID */
  merchantId?: number
  /** 商户名 */
  merchantName?: string
  /** 商户店铺商品分类 */
  merchantProductCategoryId?: number
  /** 商户分类名称 */
  merchantProductCategoryName?: string
  /** 原价【冗余SKU最低价】 */
  originalPrice?: number
  /** 出产检验报告 */
  produceCheckReportPic?: string
  /** 商品所属二级分类ID */
  productCategoryId?: number
  /** 商品所属一级分类ID */
  productCategoryIdParent?: number
  /** 分类名称 */
  productCategoryName?: string
  /** 商品认证，冒号分隔  0 无公害 1 绿色 2 有机 3地理 */
  productCerts?: string
  /** 商品种类  0 本地商品 1 线上 2 课程  3 线下 */
  productClass?: number
  /** 商品课程ID */
  productCourseId?: number
  /** 分类名称 */
  productCourseName?: string
  /** 商品食材ID */
  productFoodId?: string
  /** 食材名称 */
  productFoodName?: string
  /** 商户ID */
  productId?: number
  /** 商品名 */
  productName?: string
  /** 商品识别码 */
  productNo?: string
  /** 商品营养师 */
  productNutritionistId?: number
  /** 商品营养师 */
  productNutritionistName?: string
  /** 展示视频 */
  productVideo?: ProductVideo
  /** 推荐排序 */
  recommandSort?: number
  /** 促销角标  1折扣 2 特卖 3 满减 */
  saleIcon?: number
  /** 促销价格【冗余SKU最低价】 */
  salePrice?: number
  /** 销量 */
  sales?: number
  /** 上架时间 */
  shelveDate?: string
  /** 上架状态：0->上架；1->下架 */
  shelveFlag?: number
  /** 店铺推荐  0 推荐 1 不推荐 */
  shopRecommend?: number
  /** 是否商城展示  0 正常 1 不展示 */
  showOut?: number
  /** sku规格列表 */
  skuDetail?: ProductSkuAuditVO[]
  /** 简介 */
  subTitle?: string
  /** 有效类型  0 一直有效 1 按时间段 */
  usageState?: number
  /** 有效时间 */
  usageTime?: string
  /** 有效时间-结束 */
  usageTimeEnd?: string
  /** 有效时间-开始 */
  usageTimeStart?: string
  /** 会员价格 */
  vipPrice?: number
  /** 资质预警状态： 0 正常 1 预警 */
  warningStatusCert?: number
  /** 库存预警状态： 0 正常 1 预警 */
  warningStatusStock?: number
}

export interface ProductAddressDetaiVO {
  /** 具体街道地址 */
  addrDetail?: string
  /** 区编码 */
  areaCode?: string
  areaName?: string
  /** 市编码 */
  cityCode?: string
  cityName?: string
  /** 省编码 */
  provinceCode?: string
  provinceName?: string
  /** 支持运送里程数【生鲜类】 */
  shippingRange?: number
  /** 支持运送区域编码集合，逗号隔开 */
  shippingRegion?: string
  /** 区域名称 */
  shippingRegionArea?: Area[]
  /** 运费模板别名 */
  shippingTemplateDesc?: string
  /** 排序 */
  sort?: number
}

export interface Area {
  /** 行政代码 */
  areaCode?: number
  /** 下一级地区集合 */
  childArea?: Area[]
  /** 区号 */
  cityCode?: string
  /** 创建时间 */
  createDate?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 纬度 */
  lat?: number
  /** 层级 */
  level?: number
  /** 经度 */
  lng?: number
  /** 组合名 */
  mergerName?: string
  /** 名称 */
  name?: string
  /** 父级行政代码 */
  parentCode?: number
  /** 拼音 */
  pinyin?: string
  /** 大区编码  0华东 1华北 2华中 3华南 4东北 5西北 6西南 7港澳台 */
  regionCode?: number
  /** 简称 */
  shortName?: string
  /** 邮政编码 */
  zipCode?: number
}

export interface ProductAttributeValueVO {
  /** 商品属性ID */
  attributeId?: number
  /** 属性名 */
  attributeName?: string
  /** 属性规则 */
  attributeRule?: string
  /** 属性类型  0 规格属性 1 服务说明 2 商品参数 3 适宜人群 4 禁忌人群 */
  attributeType?: number
  /** 属性类型  0 规格属性 1 服务说明 2 商品参数 3 适宜人群 4 禁忌人群 */
  attributeTypeName?: string
  /** 是否必填 0 必填 1 非必填 */
  attributeValue?: string
  /** 手动添加规格或参数的值，参数单值，规格有多个时以逗号隔开 */
  value?: string
}

export interface ProductCertificationVO {
  /** 批准量产/年 */
  allowYield?: number
  /** 认证有效截止时间 */
  certEndDate?: string
  /** 认证资质名【其他认证类别】 */
  certName?: string
  /** 证书 & 检测报告 */
  certPic?: string
  /** 认证类别 0 无公害 1 绿色 2 有机 3地理 4 其他 5 无 */
  certType?: number
  /** 标志编号 */
  markCode?: string
}

export interface ProductVideo {
  /** 封面 */
  coverImgUrl?: string
  /** 时长 */
  duration?: number
  /** 视频URL */
  video?: string
}

export interface ProductSkuAuditVO {
  /** 会员权益（是否展示会员价） */
  joinVip?: boolean
  /** 商户id */
  merchantId?: number
  /** 原价 */
  originalPrice?: number
  /** 规格图片 */
  pic?: string
  /** 限购数量 */
  purchaseMax?: number
  /** 起购数量 */
  purchaseMin?: number
  /** 销售价格 */
  salePrice?: number
  /** 结算价 */
  settlePrice?: number
  /** sku编码 */
  skuCode?: string
  /** 销售规格 */
  spec?: string
  /** 销售规格属性ID */
  specAttrId?: string
  /** 销售规格属性名称 */
  specAttrName?: string
  /** 库存 */
  stock?: number
  /** 库存 */
  stockOld?: number
  /** 会员价格 */
  vipPrice?: number
  /** 体积 */
  volume?: number
  /** 重量 */
  weight?: number
}

export interface ProductPageDetailVO {
  /** 商品发货地 */
  address?: ProductAddress[]
  /** 商品参数 */
  attributes?: ProductAttributeValueVO[]
  /** 审核状态 1.待审 2.审核中 3.通过 4.驳回 5.撤回 */
  auditStatus?: number
  /** 商品品牌ID */
  brandId?: number
  /** 认证类别 0 无公害 1 绿色 2 有机 3地理 4 其他 5 无 */
  certs?: ProductCertification[]
  /** 平台抽成费率 百分制 */
  commissionRate?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 详情网页内容 */
  detailHtml?: string
  /** 展示图片 */
  detailShowPic?: string
  /** 展示视频 */
  detailShowVideo?: string
  /** 商品运费模板ID */
  feightTemplateId?: number
  /** 注册商标 */
  icon?: string
  /** 分布式唯一ID */
  kid?: number
  /** 是否专家推荐 */
  kol?: boolean
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 封面图片 */
  mainPic?: string
  /** 商户ID */
  merchantId?: number
  /** 商户名 */
  merchantName?: string
  /** 商户店铺商品分类 */
  merchantProductCategoryId?: number
  /** 禁忌人群 */
  noSuit?: string
  /** 原价【冗余SKU最低价】 */
  originalPrice?: number
  /** 出产检验报告 */
  produceCheckReportPic?: string
  /** 商品所属二级分类ID */
  productCategoryId?: number
  /** 商品所属一级分类ID */
  productCategoryIdParent?: number
  /** 商品认证，冒号分隔  0 无公害 1 绿色 2 有机 3地理 */
  productCerts?: string
  /** 商品种类  0 本地商品 1 线上 2 课程  3 线下 */
  productClass?: number
  /** 商品课程ID */
  productCourseId?: number
  /** 商品食材ID */
  productFoodId?: string
  /** 商品名 */
  productName?: string
  /** 商品识别码 */
  productNo?: string
  /** 商品营养师 */
  productNutritionistId?: number
  /** 展示视频 */
  productVideo?: ProductVideo
  /** 推荐排序 */
  recommandSort?: number
  /** 促销角标  1折扣 2 特卖 3 满减 */
  saleIcon?: number
  /** 促销价格【冗余SKU最低价】 */
  salePrice?: number
  /** 销量 */
  sales?: number
  /** 上架时间 */
  shelveDate?: string
  /** 上架状态：0->上架；1->下架 */
  shelveFlag?: number
  /** 店铺推荐  0 推荐 1 不推荐 */
  shopRecommend?: number
  /** 是否商城展示  0 正常 1 不展示 */
  showOut?: number
  /** sku规格列表 */
  skuDetail?: ProductSkuTemp[]
  /** 简介 */
  subTitle?: string
  /** 适宜人群 */
  suit?: string
  /** 有效类型  0 一直有效 1 按时间段 */
  usageState?: number
  /** 有效时间-结束 */
  usageTimeEnd?: string
  /** 有效时间-开始 */
  usageTimeStart?: string
  /** 会员价格 */
  vipPrice?: number
  /** 资质预警状态： 0 正常 1 预警 */
  warningStatusCert?: number
  /** 库存预警状态： 0 正常 1 预警 */
  warningStatusStock?: number
}

export interface ProductAddress {
  /** 具体街道地址 */
  addrDetail?: string
  /** 区编码 */
  areaCode?: string
  areaName?: string
  /** 市编码 */
  cityCode?: string
  cityName?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 商品ID */
  productId?: number
  /** 省编码 */
  provinceCode?: string
  provinceName?: string
  /** 支持运送里程数【生鲜类】 */
  shippingRange?: number
  /** 支持运送区域编码集合，逗号隔开 */
  shippingRegion?: string
  /** 运费模板别名 */
  shippingTemplateDesc?: string
  /** 排序 */
  sort?: number
}

export interface ProductCertification {
  /** 批准量产/年 */
  allowYield?: number
  /** 认证有效截止时间 */
  certEndDate?: string
  /** 认证资质名【其他认证类别】 */
  certName?: string
  /** 证书 & 检测报告 */
  certPic?: string
  /** 认证类别 0 无公害 1 绿色 2 有机 3地理 4 其他 5 无 */
  certType?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 标志编号 */
  markCode?: string
  /** 商品ID */
  productId?: number
}

export interface ProductSkuTemp {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 商户id */
  merchantId?: number
  /** 原价 */
  originalPrice?: number
  /** 规格图片 */
  pic?: string
  /** 商品ID */
  productId?: number
  /** 限购数量 */
  purchaseMax?: number
  /** 起购数量 */
  purchaseMin?: number
  /** 销售价格 */
  salePrice?: number
  /** 销量 */
  sales?: number
  /** 结算价 */
  settlePrice?: number
  /** sku编码 */
  skuCode?: string
  /** 销售规格 */
  spec?: string
  /** 销售规格属性ID */
  specAttrId?: string
  /** 销售规格属性名称 */
  specAttrName?: string
  /** 库存 */
  stock?: number
  /** 库存 */
  stockOld?: number
  /** 会员价格 */
  vipPrice?: number
  /** 体积 */
  volume?: number
  /** 重量 */
  weight?: number
}

export interface ProductDetailVO {
  /** 发货地 */
  address?: ProductAddressVO[]
  /** 商品属性 */
  attributes?: ProductAttributeValueVO[]
  /** 用户行为数据 */
  behavior?: UserBehaviorResult
  /** 认证类别 0 无公害 1 绿色 2 有机 3地理 4 其他 5 无 */
  certs?: CertVO[]
  /** 课程信息 */
  course?: CourseVO
  /** 详情网页内容 */
  detailHtml?: string
  /** 展示图片 */
  detailShowPic?: string
  /** 查询一个评价和总数 */
  evaluate?: ProductEvaluationSingletonVO
  /** 运费 首件 */
  freightFee?: number
  /** 注册商标 */
  icon?: string
  /** 会员权益（是否展示会员价） */
  joinVip?: boolean
  /** 分布式唯一ID */
  kid?: number
  /** 封面图片 */
  mainPic?: string
  /** 商户店铺商品分类 */
  merchantProductCategoryId?: number
  /** 禁忌人群 */
  noSuit?: string
  /** 原价【冗余SKU最低价】 */
  originalPrice?: number
  /** 出产检验报告 */
  produceCheckReportPic?: string
  /** 商品种类  0 本地商品 1 线上 2 课程  3 线下 */
  productClass?: number
  /** 商品课程ID */
  productCourseId?: number
  /** 商品食材ID */
  productFoodId?: string
  /** 商品名 */
  productName?: string
  /** 商品营养师 */
  productNutritionistId?: number
  /** 商品预售信息 */
  productPrepareSimpleAppVO?: ProductPrepareSimpleAppVO
  /** 展示视频 */
  productVideo?: ProductVideo
  /** 促销价格【冗余SKU最低价】 */
  salePrice?: number
  /** 上架状态：0->上架；1->下架 */
  shelveFlag?: number
  /** 店铺信息 */
  shop?: ProductDetailShopVO
  /** sku规格列表 */
  skus?: ProductSkuVO[]
  /** 简介 */
  subTitle?: string
  /** 适宜人群 */
  suit?: string
  /** 有效类型  0 一直有效 1 按时间段 */
  usageState?: number
  /** 有效时间-结束 */
  usageTimeEnd?: string
  /** 有效时间-开始 */
  usageTimeStart?: string
  /** 会员价格 */
  vipPrice?: number
}

export interface ProductAddressVO {
  /** 具体街道地址 */
  addrDetail?: string
  /** 市编码 */
  cityCode?: string
  /** 市名字 */
  cityName?: string
  /** 省编码 */
  provinceCode?: string
  /** 省名字 */
  provinceName?: string
  /** 排序 */
  sort?: number
}

export interface CertVO {
  /** 认证类别名 */
  certName?: string
  /** 证书 & 检测报告 */
  certPic?: string[]
  /** 认证类别  */
  certType?: number
}

export interface CourseVO {
  /** 底色 */
  baseColor?: string
  /** 课程分类 */
  category?: string
  /** 是否完结0前端不显示1更新中2完结 */
  completed?: number
  /** 商户id */
  cooperationId?: number
  /** 课程标签 */
  courseTag?: string
  /** 课程类型1视频2音频 */
  courseType?: number
  /** 课程封面 */
  coverPhoto?: string
  /** 是否删除 */
  delFlag?: number
  /** 结束时间 */
  endTime?: string
  /** 是否热门 */
  hotFlag?: number
  /** 课程亮点 */
  introduction?: string
  /** 分布式唯一ID */
  kid?: number
  /** 宣传封面图 */
  publicityCover?: string
  /** 是否精选 0:不推荐 1:推荐 */
  recommandFlag?: number
  /** 讲数 */
  sectionCount?: number
  /** 状态 */
  shelveFlag?: number
  /** 排序从大到小 */
  sortNo?: number
  /** 开始时间 */
  startTime?: string
  /** 状态 */
  status?: number
  /** 副主题 */
  subTheme?: string
  /** 主题 */
  theme?: string
}

export interface ProductEvaluationSingletonVO {
  /** 评价时间 */
  addTime?: string
  /** 1 匿名 0不匿名 */
  anonymous?: number
  /** 评论内容 */
  content?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 1删除0正常 */
  delFlag?: number
  /** 1.好评 2.中评 3差评 */
  grade?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 商户id */
  merchantId?: number
  /** 商户名称 */
  merchantName?: string
  /** 订单kid */
  orderKid?: number
  /** 评论图片 */
  pics?: string
  /** 商品id */
  productId?: number
  /** 商品名称 */
  productName?: string
  /** 店铺名称 */
  shopName?: string
  /** skukid */
  skuKid?: number
  /** sku图片 */
  skuPic?: string
  /** sku规格 */
  skuSpec?: string
  /** sku规格属性 */
  skuSpecAttr?: string
  /** 总评价数 */
  totalNum?: number
  /** 用户信息 */
  user?: UserSimpleVO
  /** 评价人 */
  userId?: number
}

export interface ProductPrepareSimpleAppVO {
  /** 审核状态 1.待审核 3.审核通过 4.审核驳回 */
  auditStatus?: number
  /** 服务器当前时间 */
  currentTime?: string
  /** 发货开始时间 */
  deliveryStartTime?: string
  /** 预售id */
  kid?: number
  /** 预售结束时间 */
  preSaleEndTime?: string
  /** 预售开始时间 */
  preSaleStartTime?: string
  /** 状态  1.预售中 2.已取消 3.已结束 */
  state?: number
}

export interface ProductDetailShopVO {
  /** 资质 */
  auths?: string[]
  /** 商户id */
  kid?: number
  /** 商品数量 */
  productCount?: number
  /** 恢复发货时间 */
  resumeShippingTime?: string
  /** 停止发货文案 */
  shippingContent?: string
  /** 店铺logo */
  shopLogo?: string
  /** 店铺名 */
  shopName?: string
  /** 店铺状态 1启用0禁用 */
  shopStatus?: number
  /** 停止发货时间 */
  stopShippingTime?: string
}

export interface ProductSkuVO {
  /** 会员权益（是否展示会员价） */
  joinVip?: boolean
  /** 原价 */
  originalPrice?: number
  /** 规格图片 */
  pic?: string
  /** 限购数量 */
  purchaseMax?: number
  /** 起购数量 */
  purchaseMin?: number
  /** 销售价格 */
  salePrice?: number
  /** 销量 */
  sales?: number
  /** 结算价 */
  settlePrice?: number
  /** sku编码 */
  skuCode?: string
  /** skuKid */
  skuKid?: number
  /** 销售规格 */
  spec?: string
  /** 库存 */
  stock?: number
  /** 会员价格 */
  vipPrice?: number
  /** 体积 */
  volume?: number
  /** 重量 */
  weight?: number
}

export interface CouponRecordsMyVO {
  /** 门槛条件：大于多少元 */
  conditionPrice?: number
  /** 三方id */
  cooperationId?: number
  /** 三方名称 */
  cooperationName?: string
  /** 优惠券id */
  couponId?: number
  /** 优惠券名称 */
  couponName?: string
  /** 优惠券类型：0:平台优惠券；1:商户优惠券 */
  couponType?: number
  /** 备注 */
  description?: string
  /** 优惠券面额 */
  faceValue?: number
  /** kid */
  kid?: number
  /** 使用范围：0:全部商品可用；1:指定商品可用 */
  useArea?: number
  /** 优惠券可用商品范围  逗号隔开的商品ID集 */
  useAreaProducts?: string
  /** 使用门槛：0:无门槛；1:有门槛 */
  useCondition?: number
  /** 有效期结束时间 */
  validPeriodEndTime?: string
  /** 有效期开始时间 */
  validPeriodStartTime?: string
}

export interface CouponRecordsBackVO {
  /** 优惠券状态  1 未使用 2 已使用 3 已过期 */
  couponStatus?: number
  /** kid */
  kid?: number
  /** 订单编号 */
  orderNo?: string
  /** 支付金额 */
  payAmount?: number
  /** 领取时间 */
  receiveDate?: string
  /** 使用时间 */
  useDate?: string
  /** 姓名 */
  userName?: string
  /** 手机号 */
  userPhone?: string
}

export interface CouponRecords {
  /** 三方id */
  cooperationId?: number
  /** 优惠券id */
  couponId?: number
  /** 优惠券状态  1 未使用 2 已使用 3 已过期 */
  couponStatus?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 使用过期时间 */
  expirationDate?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 订单编号 */
  orderNo?: string
  /** 支付金额 */
  payAmount?: number
  /** 领取时间 */
  receiveDate?: string
  /** 使用时间 */
  useDate?: string
  /** 所属用户id */
  userId?: number
}

export interface PageRequest<T> {
  /** 截止时间 */
  endTime?: string
  /** 页码 */
  pageNo?: number
  /** 每页条数 */
  pageSize?: number
  /** 参数对象 */
  param?: T
  /** 开始时间 */
  startTime?: string
  /** 时间范围 */
  timeRange?: string
}

export interface CouponRecordsAppDTO {
  /** 三方id */
  cooperationId?: number
  /** 商品sku信息集合 */
  proInfos?: ProInfo[]
  /** 商品id */
  productId?: number
}

export interface ProInfo {
  /** 商品购买数量 */
  productNum?: number
  /** 商品skuId */
  productSkuId?: number
}

export interface CouponVO {
  /** 活动状态：0:已发放  1:已结束：优惠券时间已到期/优惠券已全部领取完成 2：未发放（默认状态） */
  activeStatus?: number
  /** 门槛条件：大于多少元 */
  conditionPrice?: number
  conditionStr?: string
  /** 三方商户id */
  cooperationId?: number
  /** 三方用户管理员操作id */
  cooperationManagerUserId?: number
  /** 优惠券名称 */
  couponName?: string
  /** 优惠券类型：0:平台优惠券；1:商户优惠券 */
  couponType?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 备注 */
  description?: string
  /** 优惠券面额 */
  faceValue?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 最后领取时间 */
  lastValidTime?: string
  merchantName?: string
  /** 发放范围 ：0:其他  1:月卡会员  2:年卡会员 */
  offerArea?: number
  /** 优惠券发放数量 */
  offerNumber?: number
  operator?: string
  /** 推广方式：0:领取方式；1:发放方式 */
  promoteModel?: number
  /** 优惠券领取量 */
  receiveNumber?: number
  /** 上架状态：0->上架；1->下架 */
  shelveFlag?: number
  /** 使用范围：0:全部商品可用；1:指定商品可用 */
  useArea?: number
  /** 优惠券可用商品 */
  useAreaProducts?: string
  /** 使用门槛：0:无门槛；1:有门槛 */
  useCondition?: number
  /** 有效天数 */
  validDateNumber?: number
  /** 指定有效期结束时间 */
  validPeriodEndTime?: string
  /** 指定有效期开始时间 */
  validPeriodStartTime?: string
  /** 有效期限类型：0:指定有效期(包括有效期开始时间,有效期结束时间)；1:领券后多少天 (包括有效天数,最后领券时间) */
  validPeriodType?: number
  validTimeStr?: string
  validType?: number
}

export interface Coupon {
  /** 活动状态：0:已发放  1:已结束：优惠券时间已到期/优惠券已全部领取完成 2：未发放（默认状态） */
  activeStatus?: number
  /** 门槛条件：大于多少元 */
  conditionPrice?: number
  /** 三方商户id */
  cooperationId?: number
  /** 三方用户管理员操作id */
  cooperationManagerUserId?: number
  /** 优惠券名称 */
  couponName?: string
  /** 优惠券类型：0:平台优惠券；1:商户优惠券 */
  couponType?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 备注 */
  description?: string
  /** 优惠券面额 */
  faceValue?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 最后领取时间 */
  lastValidTime?: string
  /** 发放范围 ：0:其他  1:月卡会员  2:年卡会员 */
  offerArea?: number
  /** 优惠券发放数量 */
  offerNumber?: number
  /** 推广方式：0:领取方式；1:发放方式 */
  promoteModel?: number
  /** 优惠券领取量 */
  receiveNumber?: number
  /** 上架状态：0->上架；1->下架 */
  shelveFlag?: number
  /** 使用范围：0:全部商品可用；1:指定商品可用 */
  useArea?: number
  /** 优惠券可用商品 */
  useAreaProducts?: string
  /** 使用门槛：0:无门槛；1:有门槛 */
  useCondition?: number
  /** 有效天数 */
  validDateNumber?: number
  /** 指定有效期结束时间 */
  validPeriodEndTime?: string
  /** 指定有效期开始时间 */
  validPeriodStartTime?: string
  /** 有效期限类型：0:指定有效期(包括有效期开始时间,有效期结束时间)；1:领券后多少天 (包括有效天数,最后领券时间) */
  validPeriodType?: number
}

export interface CouponProductVO {
  coupon?: Coupon
  flag?: number
  productVOS?: ProductsVO[]
}

export interface ProductsVO {
  /** 封面图片 */
  mainPic?: string
  /** 原价【冗余SKU最低价】 */
  originalPrice?: number
  /** 商品名 */
  productName?: string
  /** 促销价格【冗余SKU最低价】 */
  salePrice?: number
}

export interface ExcelExportRecord {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 导出Excel类型 */
  excelType?: string
  /** 导出码 */
  exportCode?: string
  /** 导出描述 */
  exportDesc?: string
  /** 导出参数 */
  exportParams?: string
  /** 导出状态：0未执行 1导出中 2全部导出 3部分导出 4导出失败 */
  exportStatus?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 结果excel文件地址 */
  resultExcel?: string
  /** 无标题模板导出 */
  withoutTitle?: boolean
}

export interface ProductAuditForMerchantVO {
  /** 审核申请时间 */
  applyTime?: string
  /** 认证审核状态 */
  authStatus?: number
  /** 撤销时间 */
  cancelTime?: string
  /** 类目id */
  categoryId?: number
  /** 内容审核状态 */
  contentStatus?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 数据类型 1.新增审核 2.编辑审核 */
  dataType?: number
  /** 是否删除 */
  delFlag?: number
  /** 图片审核状态 */
  imgStatus?: number
  /** 分布式唯一ID */
  kid?: number
  /** 终审状态 */
  lastStatus?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 供应商kid */
  merchantKid?: number
  /** 通过时间 */
  passTime?: string
  /** 商品种类  0 本地商品 1 线上 2 课程  3 线下 */
  productClass?: number
  /** 审核商品kid */
  productKid?: number
  productName?: string
  /** 商品识别码 */
  productNo?: string
  /** 驳回原因 */
  rebutReason?: string
  /** 驳回时间 */
  rebutTime?: string
  /** 驳回人 */
  rebutUserId?: number
  /** 审核状态 1.待审 2.审核中 3.通过 4.驳回 5.撤回 */
  status?: number
}

export interface ProductAuditForMerchantDTO {
  endTime?: string
  pageNo?: number
  pageSize?: number
  /** 商品id */
  productKid?: number
  startTime?: string
  /** 审核状态 1.待审 2.审核中 3.通过 4.驳回 5.撤回 */
  status?: number
}

export interface ProductAuditVO {
  /** 审核申请时间 */
  applyTime?: string
  authItem?: ProductAuditItem
  /** 认证审核状态 */
  authStatus?: number
  /** 撤销时间 */
  cancelTime?: string
  /** 类目id */
  categoryId?: number
  contentItem?: ProductAuditItem
  /** 内容审核状态 */
  contentStatus?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 数据类型 1.新增审核 2.编辑审核 */
  dataType?: number
  /** 是否删除 */
  delFlag?: number
  imagesItem?: ProductAuditItem
  /** 图片审核状态 */
  imgStatus?: number
  /** 分布式唯一ID */
  kid?: number
  lastItem?: ProductAuditItem
  /** 终审状态 */
  lastStatus?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 供应商kid */
  merchantKid?: number
  merchantName?: string
  /** 通过时间 */
  passTime?: string
  /** 商品种类  0 本地商品 1 线上 2 课程  3 线下 */
  productClass?: number
  productKid?: number
  productName?: string
  /** 商品识别码 */
  productNo?: string
  /** 驳回原因 */
  rebutReason?: string
  /** 驳回时间 */
  rebutTime?: string
  /** 驳回人 */
  rebutUserId?: number
  /** 审核状态 1.待审 2.审核中 3.通过 4.驳回 5.撤回 */
  status?: number
}

export interface ProductAuditItem {
  /** 审核类型 1.内容 2.图片 3.认证 4.终审 */
  auditType?: number
  /** 创建时间 */
  createDate?: string
  /** 是否删除 */
  delFlag?: number
  /** 审核完成时间 */
  finishTime?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 审核id */
  productAuditKid?: number
  /** 审核开始时间 */
  startTime?: string
  /** 审核状态 1.待审 2.审核中 3.通过 4.驳回 */
  status?: number
  /** 审核人 */
  userId?: number
}

export interface ProductSubject {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** H5专题跳转页 */
  h5Url?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 专题图片 */
  pic?: string
  /** 上架状态：0->上架；1->下架 */
  shelveFlag?: number
  /** 专题名称 */
  subjectName?: string
  /** 专题排序 */
  subjectSort?: number
  /** 专题类型： 1 H5品牌 2 推荐 3 热销 */
  subjectType?: number
}
export default {
  brandStorys: {
    /**
    * APP端查询品牌故事她眼中的店
    */
    getBrandStoryTagAndEvaluates(params: { kid: number }): Promise<BrandStoryEvaluatesAndTagsVO> {
      return httpGet(`/mall/v5.0/pb/brand-storys/action/getBrandStoryTagAndEvaluates`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询详情数据
    */
    getDetail(params: { kid: number }): Promise<BrandStoryAppVO> {
      return httpGet(`/mall/v5.0/pb/brand-storys/action/getDetail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据(首页接口)
    */
    listIndex(): Promise<PageList<BrandStory>> {
      return httpGet(`/mall/v5.0/pb/brand-storys/action/list-index`).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<BrandStory>> {
      return httpGet(`/mall/v5.0/pb/brand-storys/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  couponRecordss: {
    /**
    * APP商品详情可领取优惠券列表
    */
    productReceiveCouponList(params: { cooperationId: number, productId: number }): Promise<CouponRecordsAppBaseVO> {
      return httpGet(`/mall/v5.0/pb/coupon-recordss/action/product-receive-coupon-list`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询我的优惠券分页数据
    */
    listPage(params: { couponStatus: number, pageNo?: number, pageSize?: number }): Promise<PageList<CouponRecordsMyVO>> {
      return httpGet(`/mall/v5.0/pt/coupon-recordss/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 商户后台查询分页数据
    */
    listPageCooperation(pageRequest: PageRequest<CouponRecords>): Promise<PageList<CouponRecordsBackVO>> {
      return httpPost(`/mall/v5.0/pt/coupon-recordss/action/list-page-cooperation`, pageRequest).then((res:any) => res.data.data)
    },
    /**
    * APP领取优惠券
    */
    receive(params: { couponId: number }): Promise<boolean> {
      return httpGet(`/mall/v5.0/pt/coupon-recordss/action/receive`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP查询所有可领取优惠券列表
    */
    receiveCouponAllList(params: { pageNo: number, pageSize: number }): Promise<PageList<CouponRecordsAppVO>> {
      return httpGet(`/mall/v5.0/pt/coupon-recordss/action/receive-coupon-all-list`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP优惠券详情
    */
    receiveCouponDetail(params: { couponId: number }): Promise<CouponRecordsAppVO> {
      return httpGet(`/mall/v5.0/pt/coupon-recordss/action/receive-coupon-detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP可领取优惠券列表
    */
    receiveCouponList(params: { cooperationId: number }): Promise<CouponRecordsAppBaseVO> {
      return httpGet(`/mall/v5.0/pt/coupon-recordss/action/receive-coupon-list`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP可使用优惠券列表
    */
    useCouponList(couponRecordsAppDTO: CouponRecordsAppDTO[]): Promise<CouponRecordsAppBaseVO> {
      return httpPost(`/mall/v5.0/pt/coupon-recordss/action/use-coupon-list`, couponRecordsAppDTO).then((res:any) => res.data.data)
    },
  },
  coupons: {
    /**
    * APP查询存在发店铺满减券的商户id集合
    */
    getCooperationIds(cooperationIds: number[]): Promise<List<long>> {
      return httpPost(`/mall/v5.0/pb/coupons/action/get-cooperationIds`, cooperationIds).then((res:any) => res.data.data)
    },
    /**
    * 商户后台查询分页数据 cooperationId为商户id
    */
    merchantBackListPage(pageRequest: PageRequest<Coupon>): Promise<PageList<CouponVO>> {
      return httpPost(`/mall/v5.0/pt/coupons/action/merchant-back-list-page`, pageRequest).then((res:any) => res.data.data)
    },
    /**
    * 商户后台新增
    */
    merchantCreate(coupon: Coupon): Promise<boolean> {
      return httpPost(`/mall/v5.0/pt/coupons/action/merchant-create`, coupon).then((res:any) => res.data.data)
    },
    /**
    * 商户后台查询详情
    */
    merchantDetail(params: { kid: number }): Promise<CouponProductVO> {
      return httpGet(`/mall/v5.0/pt/coupons/action/merchant-detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 商户后台编辑
    */
    merchantUpdate(coupon: Coupon): Promise<boolean> {
      return httpPut(`/mall/v5.0/pt/coupons/action/merchant-update`, coupon).then((res:any) => res.data.data)
    },
  },
  healthExaminationMeals: {
    /**
    * 管理后台查询单个详情
    */
    detail(params: { kid: number }): Promise<HealthExaminationMealVO> {
      return httpGet(`/mall/v5.0/pb/health-examination-meals/action/detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { gender?: string, hospitalId: number, pageNo?: number, pageSize?: number, priceEnd?: number, priceStart?: number, type?: string }): Promise<PageList<HealthExaminationMeal>> {
      return httpGet(`/mall/v5.0/pb/health-examination-meals/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  healths: {
    /**
    * 健康检查
    */
    check(): Promise<boolean> {
      return httpGet(`/mall/v5.0/pb/healths/action/check`).then((res:any) => res.data.data)
    },
  },
  productBrands: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<ProductBrand>> {
      return httpGet(`/mall/v5.0/pb/product-brands/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 合作方管理后台查询单个ProductBrand
    */
    get(params: { kid: number }): Promise<ProductBrand> {
      return httpGet(`/mall/v5.0/pt/product-brands/${params.kid}`,  {params} ).then((res:any) => res.data.data)
    },
  },
  productCategorys: {
    /**
    * 客户端查询类目列表(平级且已排序) parentKid=0时只查询一级类目，传其他则查询相应的，不传表示查询所有
    */
    listClient(params: { parentKid?: number }): Promise<List<ProductCategoryVO>> {
      return httpGet(`/mall/v5.0/pb/product-categorys/action/list-client`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * listClientALl
    */
    listClientAll(params: { parentKid?: number }): Promise<List<ProductCategoryVO>> {
      return httpGet(`/mall/v5.0/pb/product-categorys/action/list-client-all`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 客户端查询类目列表(对类目分层后再进行标签分组且已排序)
    */
    listGradeLabel(): Promise<List<ProductCategoryGradeVO>> {
      return httpGet(`/mall/v5.0/pb/product-categorys/action/list-grade-label`).then((res:any) => res.data.data)
    },
    /**
    * APP 课程分类
    */
    listCourse(): Promise<List<ProductCategory>> {
      return httpGet(`/mall/v5.0/pb/product-categorys/action/listCourse`).then((res:any) => res.data.data)
    },
    /**
    * App查询类目百科详情
    */
    queryWikiDetail(params: { kid: number }): Promise<ProductCategoryWikiVO> {
      return httpGet(`/mall/v5.0/pb/product-categorys/action/queryWikiDetail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 管理后台查询分页
    */
    listAdmin(params: { categoryName?: string, categoryType?: number, createDate?: string, createUserId?: number, delFlag?: number, icon?: string, kid?: number, labelKid?: number, lastUpdateDate?: string, lastUpdateUserId?: number, pageNo?: number, pageSize?: number, parentKid?: number, sortNum?: number, wikiDetails?: string, wikiIntro?: string }): Promise<PageList<ProductCategoryVO>> {
      return httpGet(`/mall/v5.0/pt/product-categorys/action/list-admin`,  {params} ).then((res:any) => res.data.data)
    },
  },
  productEvaluations: {
    /**
    * 商品详情页查询评价详情
    */
    detailApp(params: { kid: number }): Promise<ProductEvaluationListVO> {
      return httpGet(`/mall/v5.0/pb/product-evaluations/action/detail-app`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 商品详情页查询评价列表
    */
    listApp(productEvaluationQueryDTO: ProductEvaluationQueryDTO): Promise<ProductEvaluationVO> {
      return httpPost(`/mall/v5.0/pb/product-evaluations/action/list-app`, productEvaluationQueryDTO).then((res:any) => res.data.data)
    },
    /**
    * 商户后台查询评价列表
    */
    listMerchant(productEvaluationQueryDTO: ProductEvaluationQueryDTO): Promise<PageList<ProductEvaluationListVO>> {
      return httpPost(`/mall/v5.0/pt/product-evaluations/action/list-merchant`, productEvaluationQueryDTO).then((res:any) => res.data.data)
    },
  },
  productSubjects: {
    /**
    * APP 商城首页 专题
    */
    all(): Promise<ProductSubjectListVO> {
      return httpGet(`/mall/v5.0/pb/product-subjects/action/all`).then((res:any) => res.data.data)
    },
    /**
    * APP H5专题详情
    */
    getSubjectProductList(params: { kid: number }): Promise<ProductSubjectVO> {
      return httpGet(`/mall/v5.0/pb/product-subjects/action/getSubjectProductList`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP 商城首页 热销精选
    */
    hot(): Promise<List<ProductSubjectVO>> {
      return httpGet(`/mall/v5.0/pb/product-subjects/action/hot`).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<ProductSubject>> {
      return httpGet(`/mall/v5.0/pt/product-subjects/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  products: {
    convertFoodIdRef(): Promise<boolean> {
      return httpGet(`/mall/v5.0/pb/products/action/convertFoodIdRef`).then((res:any) => res.data.data)
    },
    /**
    * app查询店铺商品及店铺基本信息
    */
    getShopDetailInfo(params: { keyWord?: string, merchantId?: number, pageNo?: number, pageSize?: number, sortRise?: number, sortType?: number }): Promise<ShopDetailVO> {
      return httpGet(`/mall/v5.0/pb/products/action/getShopDetailInfo`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app首页商品列表查询分页
    */
    listApp(params: { pageNo?: number, pageSize?: number }): Promise<PageList<ProductAppVO>> {
      return httpGet(`/mall/v5.0/pb/products/action/list-app`,  {params} ).then((res:any) => res.data.data)
    },
    show(params: { kid: number }): Promise<ProductDetailForAuditTempVO> {
      return httpGet(`/mall/v5.0/pb/products/action/show`,  {params} ).then((res:any) => res.data.data)
    },
    showRelease(params: { kid: number }): Promise<ProductPageDetailVO> {
      return httpGet(`/mall/v5.0/pb/products/action/showRelease`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP商品详情
    */
    get(params: { kid: number }): Promise<ProductDetailVO> {
      return httpGet(`/mall/v5.0/pb/products/${params.kid}`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询商户下的商品数量
    */
    countByMerchantId(params: { merchantId: number }): Promise<number> {
      return httpGet(`/mall/v5.0/pt/products/action/countByMerchantId`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 商户管理后台优惠券选取指定商品列表
    */
    merchantCouponListPage(pageRequest: PageRequest<Product>): Promise<PageList<Product>> {
      return httpPost(`/mall/v5.0/pt/products/action/merchant-coupon-list-page`, pageRequest).then((res:any) => res.data.data)
    },
  },
  areas: {
    /**
    * 合作方根据父code查询省市
    */
    getAreaByParentCode(params: { level?: number, parentCode: number }): Promise<List<Area>> {
      return httpGet(`/mall/v5.0/pt/areas/action/getAreaByParentCode`,  {params} ).then((res:any) => res.data.data)
    },
  },
  brandStoryEvaluates: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<BrandStoryEvaluate>> {
      return httpGet(`/mall/v5.0/pt/brand-story-evaluates/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  excelExports: {
    /**
    * 合作方导出数据
    */
    export(excelExportRecord: ExcelExportRecord): Promise<boolean> {
      return httpPost(`/mall/v5.0/pt/excel-exports/action/export`, excelExportRecord).then((res:any) => res.data.data)
    },
  },
  excelImports: {
    /**
    * 合作方导入数据
    */
    import(params: { createDate?: string, createUserId?: number, importCode?: string, importDesc?: string, importExcel?: string, importParams?: string, importStatus?: number, kid?: number, lastUpdateDate?: string, lastUpdateUserId?: number, resultExcel?: string, withoutTitle?: boolean }): Promise<boolean> {
      return httpPost(`/mall/v5.0/pt/excel-imports/action/import`,  {params} ).then((res:any) => res.data.data)
    },
  },
  productAudits: {
    /**
    * 商户后台撤销商品审核
    */
    cancelAudit(params: { kid: number }): Promise<boolean> {
      return httpGet(`/mall/v5.0/pt/product-audits/action/cancelAudit`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 商户后台审核列表查询分页
    */
    listMerchant(productAuditMerchantDTO: ProductAuditForMerchantDTO): Promise<PageList<ProductAuditForMerchantVO>> {
      return httpPost(`/mall/v5.0/pt/product-audits/action/list-merchant`, productAuditMerchantDTO).then((res:any) => res.data.data)
    },
    /**
    * 商户后台审核列表查询分页(一个商品只展示一条审核数据)
    */
    listMerchantGroupbyProduct(productAuditMerchantDTO: ProductAuditForMerchantDTO): Promise<PageList<ProductAuditForMerchantVO>> {
      return httpPost(`/mall/v5.0/pt/product-audits/action/list-merchant-groupby-product`, productAuditMerchantDTO).then((res:any) => res.data.data)
    },
    /**
    * 查询单个审核详情
    */
    get(params: { kid: number }): Promise<ProductAuditVO> {
      return httpGet(`/mall/v5.0/pt/product-audits/${params.kid}`,  {params} ).then((res:any) => res.data.data)
    },
  },
}