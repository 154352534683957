import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react'
import { __RouterContext } from 'react-router'
import Nav from 'components/nav/Nav'
import { Icon, Toast, ActivityIndicator,Modal } from 'antd-mobile'
import assets from 'assets'
import order, { OrderReceivingAddressVO, CalcExpressDTO, OrderSubmitDTO, OrderSubmitVO } from 'api/order'
import { useDefaultReceiver } from './components/defaultReceiver'
import ReceiverItem from './components/ReceiverItem'
import ProductItem, { CartItem } from './components/ProductItem'
import { getIntPrice, getDecimalPrice,formPrice } from 'utils/price'
import { withInvoiceResult } from './components/invoiceData'
import { useCheckStatus } from 'utils/payment'
import { useThirdLoginData } from './components/thirdLoginData'
import { wxShare, wxPay } from 'utils/wxShare'
import { withPickedSku } from 'modules/goods/components/pickedSku'
import { withShowSpec } from './ReceiverList'
import { withIgnoreState } from './PayResult'
import { CartProductVO } from "../../api/order";
import evaluateApi from 'api/evaluate'
import { getStateWithRights } from 'modules/activepage/epidemic/userLoginInfo'
import mallApi, { CouponRecordsAppDTO, CouponRecordsAppBaseVO, ProInfo, CouponRecordsAppVO } from 'api/mall'
import CouponModal from './components/CouponModal'

const OrderConfirm: PageComponent = (props) => {
  const params = props.location.state
	const { settleData,merchantKid, selectedProducts, isFromCart, pickedReceiver,productClass } = params  // merchantKid商家kid
	const [showCouponModal, setShowCouponModal] = useState(false)
  let [settleGoods, setSettleGoods] = useState<CartItem[]>(settleData)
  let [invoiceList, setInvoiceList] = useState<any[]>([])
  let [isVip, setIsVip] = useState<boolean>(false)
	let [canReceiveNum, setCanReceiveNum] = useState(0) //可用的优惠券数量
  let [selectCouponObj, setSelectCouponObj] = useState({} as CouponRecordsAppVO) // 选中的优惠券信息obj
  let defaultReceiver = useDefaultReceiver()
  const [payChecked, setPayChecked] = useState(true)

  const [payNo, setPayNo] = useState('')
	const [orderInfo, setOrderInfo] = useState<OrderSubmitVO>()
	
  const thirdLoginData = useThirdLoginData()

  let totalGood = useMemo(() => {
    let count = 0
    let price = 0
    let totalShip = 0
    for (let merchant of settleGoods) {
      let ship = (merchant && merchant.shipFee) || 0
      totalShip += ship
      for (let p of merchant.cartProductVOList) {
        let tmpNum = (p && p.num) || 0
				// let skuP = (p && p.skuSalePrice) || 0
        let skuP = 0
        if (isVip) {
          skuP = p && p.skuVipPrice
        } else {
          skuP = p && p.skuSalePrice
        }
        count += tmpNum
        price += skuP * tmpNum
      }
    }

		price = price + totalShip
		// 如果选中了优惠券 总价减去优惠券的价格
    if (selectCouponObj && selectCouponObj.faceValue) {
      price = price - selectCouponObj.faceValue
    }
		// console.warn("totalGood",[count, price])
    return [count, price]
	}, [selectCouponObj,settleGoods,isVip])
	
	useEffect(() => {
    // 会员状态 -2.用户未开通会员  -1.用户会员已过期 0.用户开通了会员但无此项权益  1.用户开通了会员且有此项权益"
    evaluateApi.vipThroughTrain
      .getStateWithRights({ type: 1 })
      .then(res => {
        setIsVip(res === 1 ? true : false)
      })
		  .catch(() => {})
		// getStateWithRights({ type: 1 }).then(res => {
		// 		console.warn('getStateWithRights',res)
		// 	  setIsVip(res === 1 ? true : false)
		// 	  })
		// 	.catch((err) => {
		// 		console.warn('getStateWithRights',err)
		// 		})
	}, [])

	 // 确认订单页查询可用优惠券参数couponRecordsParams
	 const couponRecordsParams: CouponRecordsAppDTO[] = useMemo(() => {
    // console.warn('selectedProducts', selectedProducts)
		return [
			{
				cooperationId: settleData[0].merchantKid,
				productId: selectedProducts[0] && selectedProducts[0].productKid,
				proInfos: [
					{
						productNum: selectedProducts[0] && selectedProducts[0].num,
						productSkuId: selectedProducts[0] && selectedProducts[0].skuKid,
					},
				],
			},
		]
  }, [selectedProducts, isFromCart])
	useEffect(() => {
    // console.warn('查询优惠券接口参数==', couponRecordsParams, JSON.stringify(couponRecordsParams))
    mallApi.couponRecordss
      .useCouponList(couponRecordsParams)
      .then((res: CouponRecordsAppBaseVO) => {
				// console.warn('查询优惠券接口res', res)
				// 先不展示优惠券信息
        setCanReceiveNum(res.canReceiveNum || 0)
      })
      .catch(err => {
        if (err.code >= 1000) {
          let errRes = err.userInfo || {}
          Toast.fail(errRes.msg || '网络出小差啦')
        }
      })
	}, [couponRecordsParams])
// 弹出选中优惠券
	let choseCoupon = useCallback(() => {
		setShowCouponModal(true)
},[])
  const couponTxt = useMemo(() => {
    if (Object.keys(selectCouponObj).length === 0) return '不使用优惠券'
    return selectCouponObj.useCondition === 0
      ? `立减${selectCouponObj.faceValue / 100}`
      : `满${selectCouponObj.conditionPrice / 100}减${selectCouponObj.faceValue / 100}`
	}, [selectCouponObj])
	// 优惠券信息等
  let disCountInfo = useMemo(() => {
    if (!canReceiveNum) return null
    return (
			<div style={{
				backgroundColor: '#fff',
				padding: '0 0.2rem',
				margin: '0.2rem 0.2rem 0',
				borderRadius: '0.08rem',}}>
				<div
					onClick={choseCoupon}
					style={{
						display:'flex',
					justifyContent: 'space-between',
					padding: '0.15rem 0',
					marginBottom: '0.05rem'
				}}>
          <div style={{display:'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
						<span style={{
							fontSize: '0.28rem',
							fontWeight: 400,
							color: '#1F1F1F'
						}}>选择优惠券</span>
            <span
              style={{
                borderRadius: '0.06rem',
                color: '#FE5500',
                borderColor: '#FE5500',
                borderStyle: 'solid',
                borderWidth: '0.01rem',
                fontSize: '0.2rem',
                padding: '0.04rem 0.05rem',
                marginLeft: '0.1rem',
              }}>
              {canReceiveNum} 张可用
            </span>
          </div>

          <div style={{justifyContent:'center',alignItems:"center",display: 'flex'}}>
            <span style={{ color: '#8B8F97',fontSize: '0.28rem', }}>{couponTxt}</span>
						<Icon type="right" size="lg" color="#CACACE" style={{fontSize: '0.28rem',color: '#CACACE',marginRight: 0,}} />
          </div>
        </div>
        <div style={{display: 'flex',justifyContent: 'space-between',padding: '0.15rem 0',marginBottom: '0.05rem', marginTop: '0.2rem' }}>
          <span style={ {fontSize: '0.28rem',
    fontWeight: 400, color: '#1F1F1F' }}>已优惠</span>
          <span style={{fontSize: '0.24rem',
    lineHeight: '0.4rem',
    marginRight: '0.06rem',}}>
            ¥{formPrice(selectCouponObj.faceValue)}
          </span>
        </div>
        <div style={{ marginTop: '0.2rem',display: 'flex', justifyContent: 'space-between',alignItems:"center",padding: ' 0.15rem 0',marginBottom: '0.05rem', }}>
          <span style={ { color: '#1F1F1F',fontSize: '0.28rem',fontWeight: 400, }}>合计</span>
          <span style={{fontSize: '0.24rem',
    lineHeight: '0.4rem',
    marginRight: '0.06rem',}}>¥{formPrice(totalGood[1])}</span>
        </div>
      </div>
    )
  }, [canReceiveNum, choseCoupon, couponTxt, selectCouponObj.faceValue, totalGood])



  const addrRight = useMemo(() => {
    return (
      <div className="centerWrap right-arrow">
        <Icon type="right" size="lg" color="#999" />
      </div>
    )
  }, [])
  const toChoseReceiver = (itemData: OrderReceivingAddressVO) => {
    props.history.push('/mall/receiver-list')
  }
  // useEffect(() => {
  //   let ignoreState = withIgnoreState.getShareState()
  //   if (ignoreState.orderConfirm) {
  //     props.history.goBack()
  //     withIgnoreState.setShareState({ orderConfirm: false })
  //   }
  // }, [props.history])
  const [loading, setLoading] = useState(false)
  let locking = useRef(false)
  const submitOrder = useCallback(async () => {
    if (locking.current) return
    locking.current = true
    setLoading(true)
    let productList = selectedProducts.map((p: any) => {
      return { key: p.skuKid, value: p.num }
    })

    let orderParam: OrderSubmitDTO = {
      addressKid: productClass === 0 && defaultReceiver.kid ? defaultReceiver.kid : null,
      productList,
      isFromCart,
			invoiceList,
			notesList: [],
      merchantCouponIdList:selectCouponObj.kid ? [{key:merchantKid , value: selectCouponObj.kid}]:[], // 没有优惠券时传[]
    }
		console.warn('submitOrder-orderParam:',selectCouponObj,orderParam)
    try {
      let res = await order.orders.submitOrder(orderParam)
      if (!res) {
        Toast.fail('订单提交失败，请稍候再试')
        setLoading(false)
        locking.current = false
        return
      }
      res && setOrderInfo(res)
      //清空invoiceData
      withInvoiceResult.setShareState({})
      withPickedSku.setShareState({})
      //调起支付

      let payParams = await order.orders.getPayParams({
        openid: thirdLoginData.openId,
        orderNoList: res.orderNoList,
        payPlatform: 2,
      })
			console.log('payParams.ext111--', payParams.ext)
      if (!payParams || !payParams.ext) return
      payParams && setPayNo(payParams.payNo)
      let { appId, nonceStr, signType, paySign, timeStamp } = payParams.ext
      console.log('payParams.ext--', payParams.ext)
      wxPay({
        appId,
        timestamp: timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr, // 支付签名随机串，不长于 32 位
        package: payParams.ext.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign, // 支付签名
        success: function(result: any) {
          // setLoading(false)
          // 支付成功后的回调函数
          // Toast.show(`支付成功${JSON.stringify(result)}`)
          // props.history.push('/mall/pay-result', { payResult: 2, orderResult: orderInfo })
        },
        cancel: function() {
          setLoading(false)
          locking.current = false
        },
				fail: function() {
          setLoading(false)
          locking.current = false
        },
      })
    } catch (ex) {
			setLoading(false)
      console.warn('ex',ex);
      Toast.fail(ex.message || '订单提交失败，请稍后再试')
      locking.current = false
    }
    // 商品埋点
    //  settleGoods[0]   api/order -> CartProductVO
    // if (settleGoods && settleGoods[0] && settleGoods[0].cartProductVOList && settleGoods[0].cartProductVOList[0]) {
    //     (window as any).gio('track', 'product_name_pri_id',
    //         { product_out_ID: settleGoods[0].cartProductVOList[0].productKid || '',
    //             product_out_price: settleGoods[0].cartProductVOList[0].skuPic || '',
    //             product_out_name: settleGoods[0].cartProductVOList[0].productName || ''
    //         })
    // }
  }, [selectedProducts, defaultReceiver, isFromCart, invoiceList, thirdLoginData,selectCouponObj.kid])
  useCheckStatus(payNo, (res: number) => {
    setLoading(false)
    locking.current = false
    props.history.push('/mall/pay-result', { payResult: res, orderResult: orderInfo })
  })
  useEffect(() => {
    if (!defaultReceiver || !defaultReceiver.kid|| productClass !== 0) return
    let skuWithNumList: any[] = []
    params.settleData.forEach((settle: any) => {
      settle.cartProductVOList.forEach((p: any) => {
        skuWithNumList.push({ key: p.skuKid, value: p.num })
      })
    })
    let calcExpressDTO: CalcExpressDTO = {
      addressKid: defaultReceiver.kid,
      skuWithNumList,
    }
    order.orders.preSubmitCalc(calcExpressDTO).then(res => {
      let feeList = res
      let setData: CartItem[] = [...params.settleData]

      setData.map(merchant => {
        let merchantShipFee = feeList && feeList.find(fee => fee.key === merchant.merchantKid)
        if (!merchantShipFee) return null
        merchant.shipFee = merchantShipFee.value && merchantShipFee.value.expressFee
        merchant.invoiceTypeList = merchantShipFee.value && merchantShipFee.value.invoiceTypeList
        return merchant
      })
      setSettleGoods(setData)
    })
  }, [defaultReceiver, params.settleData])
  const onChoseInvoice = useCallback(res => {
    setInvoiceList(invoiceList => {
      let copy = [...invoiceList]
      let index = -1
      for (let i = 0; i < copy.length; i++) {
        if (copy[i].key === res.key) {
          index = i
        }
      }
      if (index > -1) {
        copy.splice(index, 1, res)
        return copy
      }
      return [...invoiceList, res]
    })
  }, [])
  const handleBack = () => {
    withShowSpec.setShareState({ showSpec: true })
    props.history.goBack()
	}
	
	const renderIcon = () => { 
		return (
			<div className="addr-wrap">
				<img src={require('../../assets/image/mall/addr-icon.png')} className="addr-img"/>
			</div>
		)
	}
	const dismissCouponModal = () => {
		setShowCouponModal(false)
	}
	const renderReceiver = useMemo(() => {
		if (productClass !== 0) {
      return null
    }
		return !defaultReceiver.receivingName ? (
			<ReceiverItem right={addrRight} onPickReceiver={toChoseReceiver} custClass="hideBtmBorder">
				<div className="centerWrap">
					<img className="order-loc" src={assets.goods.loc_icon} alt="" />
					<div className="addReceiverTxt"> 请填写您的收货人信息</div>
				</div>
			</ReceiverItem>
		) : (
				<ReceiverItem
				renderIcon={renderIcon()}
				right={addrRight}
				itemData={defaultReceiver}
				custClass="hideBtmBorder"
				onPickReceiver={toChoseReceiver}
			/>
		)
	},[])
  return (
    <div className="order-confirm container">
			<Nav title="确认订单" onBack={handleBack}></Nav>
			<Modal visible={showCouponModal} popup transparent animationType="slide-up" onClose={dismissCouponModal} maskClosable={true} wrapClassName="coupon-wrap">
				<CouponModal
          couponRecordsParams={couponRecordsParams}
          selectId={selectCouponObj.kid}
          onItemPress={(item: any) => {
            setSelectCouponObj(item || {})
          }}
          onDismiss={dismissCouponModal}
        />
			</Modal>
			<div className="body"> 
				{renderReceiver}
        {/* <img src={assets.mall.address_line} alt="" style={{ width: '100%', height: '0.08rem' }} /> */}
        {/* <div className="borderBottom"></div> */}
      </div>
			<ProductItem isVip={isVip} goodData={settleGoods[0]} onChoseInvoice={onChoseInvoice} />
			{disCountInfo}
      <div className="borderBottom"></div>
      <div
        className="pay-wrap"
        onClick={() => {
          // setPayChecked(!payChecked)
        }}>
        <div className="pay-left">
          <img src={assets.mall.wechat_pay} alt="" className="wechat-pay-logo" />
          <span>微信支付</span>
        </div>

        {/* {payChecked ? <Icon type="check-circle" color="#41d282" size="md" /> : <div className="pay-check"></div>} */}
      </div>

      <div className="btm-note">请在60分钟内完成支付，如未支付完成该订单将自动取消</div>
      <div className="btm-bar">
        <div className="btm-left">
          共 <span>{totalGood[0]}</span> 件
        </div>
        <div className="btm-center">
          应付： <span className="price-int">¥{getIntPrice(totalGood[1])}</span>
          <span className="price-decimal">.{getDecimalPrice(totalGood[1])}</span>
        </div>
        <div className="btm-right centerWrap" onClick={submitOrder}>
          {loading ? (
            <div className="order-loading">
              正在交易...
              {/* <ActivityIndicator toast={false} text="正在交易..." animating={loading} /> */}
            </div>
          ) : (
            '立即支付'
          )}
        </div>
      </div>
    </div>
  )
}
export default OrderConfirm
