import React, { useState, useEffect } from 'react'
// import nutrition from 'api/nutrition'
import { Button } from 'antd-mobile'
import './insu-secu.css'
import Nav from 'components/nav/Nav';
import isIphoneX from 'utils/isIphoneX';


/**
 * type ：taikang、renbao, 
 * @param props 
 */

const InsuranceClaimsPay: PageComponent = props => {
  useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
		// console.log('result - :',result)
		window.scrollTo(0, 0)
		// document.body.scrollTo({top:0,left:0})
	}, [])
	
	/**
	 */
	const info = [
		'1 保险金给付申请书；',
		'2 保险单；',
		'3 保险金申请人的身份证明；',
		'4 中华人民共和国境内（不包括港、澳、台地区）二级（含）以上公立医院或保险人认可的医疗机构出具的医疗费用收据及明细清单/账、诊断证明、病历、出院小结等；',
		'5 对于已经从社会医疗保险、公费医疗或任何第三方包括任何商业医疗保险获得相关医疗费用补偿的，应提供社会医疗保险机构、商业保险机构或其他第三方的医疗费用分割单或医疗费用结算证明；',
		'6 被保险人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料。'
	]
	

	const renderItem = (point:string, index:number) =>{
		return <div key={'sec' + index} className='section-title' style={{textAlign:'justify'}}>
			{point}
		</div>
	}

	
	return (
		<div style={{flex:1,position:'relative'}}>
			<Nav title={'理赔攻略'} style={{position:'fixed',backgroundColor:'#fff',padding:0,...{paddingTop:!!isIphoneX()?'0.88rem':'0.4rem'}}}></Nav>
		<div className='insu-wrap'>		
				<div className='nav-padding'></div>
				{info.map(renderItem)}
		</div>
		</div>
    // <div className="demo-wrap">
    //   <div className="title">demo1</div>
    //   {/* <div>{JSON.stringify(result)}</div> */}
    //   <Button type="primary" size="small" inline className="btn">
    //     demo1
    //   </Button>
    //   <Button type="primary" size="large" className="btn">
    //     demo2
    //   </Button>
    // </div>
  )
}
// InsuranceSecurePlan.options = { disableHeader: false, wrapperClassName: '' }
export default InsuranceClaimsPay
