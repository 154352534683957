import NewsPage from './News'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncNews = Loadable({
    loader: () => import('./News'),
    loading: MyLoadingComponent
})
const newsModule: Module = {
    name: 'messagepreview',
    routes: [
        {
            name: '资讯预览',
            path: '/news/info',
            component: asyncNews,
        }
    ],
}
export default newsModule