/*
非微信浏览器的商品详情分享及商户后台商品详情预览页面
*/
import React, { useState, useEffect, useMemo } from 'react'
import { Carousel, WingBlank, Toast } from 'antd-mobile';
import Header from 'components/Header'
import Footer from 'components/Footer'
import mall, { ProductDetailVO, ProductAttributeValueVO, ProductEvaluationSingletonVO } from 'api/mall'
import nutrition, { FoodInfoVO, BannerInfoVO, FoodHotVO, FoodNewsCookVO, CookingHomeVO, NewsInfoVO } from 'api/nutrition'
import { Icon } from 'antd-mobile'
import { CSSTransition } from 'react-transition-group'  // 引入动画库
import { openApp } from "../../utils/helper";             // 打开app
import DownPop from 'components/DownPop'                // 指引下载弹窗
import assets from 'assets'
import './good.css'
import { ProductVideo } from "../../api/mall";
import '../../styles/swiper.min.css';
import Swiper from 'react-id-swiper';
import { isWeixinBrowser } from 'utils';
import { httpPost, httpGet, httpPut, httpDelete } from 'services/http'
import { wxPay } from 'utils/wxShare';
import { getDate } from 'utils/dateUtils';
import { isWeiXin, wxShare, isMobileQQ, qqShare } from 'utils/wxShare'
import URI from 'urijs'
import { UserScore } from 'api/platform-behavior';


interface GoodSharePageProps {
	kid?: number
}
export enum AttributeType {
	Spec = 0,    // 规格属性
	Service = 1, // 服务说明
	Params = 2,  // 商品参数
	Adapt = 3,   // 适宜人群
	Taboo = 4,   // 禁忌人群
}
// 认证类别 0 无公害 1 绿色 2 有机 3地理 4 其他 5 无
const certLogos: { [key: number]: string } = {
	0: assets.goods.harmless_logo,
	1: assets.goods.green_logo,
	2: assets.goods.organic_logo,
	3: assets.goods.geography_logo,
	4: assets.goods.others_logo,
	5: assets.goods.others_logo,
	19: assets.goods.inspection_logo,
	20: assets.goods.trademark_logo,
}

const GoodSharePage: PageComponent = props => {                        //  dev   424273987272704
	let query: any = URI(window.location.href).query(true);
	const isPreview = Number(query.isPreview);
	const [goodData, setGoodData] = useState({} as ProductDetailVO)
	const [productVideo, setProductVideo] = useState({} as ProductVideo)   //  产品视频数据
	const [shop, setShop] = useState()
	const [evaluate, setEvaluate] = useState({} as UserScore & ProductEvaluationSingletonVO)              //   评价数据
	const [certs, setCerts] = useState([])                                //
	const [evaluatePics, setEvaluatePics] = useState([])    //   第一条评价图片的列表
	const [detailHtml, setDetailHtml] = useState([])        //   正文部分
	const [openAppParams, setOpenAppParams] = useState({})       // 打开app 指定页面 要传给app的参数
	const [showVideo, setShowVideo] = useState(false)

	useEffect(() => {
		if (isWeixinBrowser()) {
			window.location.href = window.location.href.replace(/share/, 'pay')
		}
		if (props.match.params.kid) {

			// 获取商品基本信息
			if (window.location.search.indexOf('type=1') > -1) {
				mall.products.show({ kid: props.match.params.kid }).then(res => {
					handleGoodsData(res, 1);
				})
			} else if (window.location.search.indexOf('type=2') > -1) {
				mall.products.showRelease({ kid: props.match.params.kid }).then(res => {
					handleGoodsData(res, 2);
				})
			} else {
				mall.products.get({ kid: props.match.params.kid }).then(res => {
					handleGoodsData(res, 0);
				})
			}
			var openParam = {
				passprops: 'kid',
				id: props.match.params.kid,
				router: 'GoodInfo'
			}
			setOpenAppParams(openParam)
		}

	}, [])
	const handleGoodsData = (res: any, type: number) => {
		const link = `${window.location.origin}/good/share/` + props.match.params.kid
		if (!res) {
			Toast.fail('商品不存在!', 1);
			return
		}
		if (type == 1 || type == 2) {
			res.skus = res.skuDetail;
		}
		setGoodData(res)
		if (res.attributes && res.attributes.length > 0) {
			disposeAttributeData(res.attributes)
		}
		const { productName, mainPic } = res
		if (isWeiXin()) {
			wxShare({
				title: productName,
				desc: '我在营养e生app发现了一个不错的商品......',
				link: link,
				imgUrl: mainPic
			})
		}
		if (isMobileQQ()) {
			qqShare({
				title: productName,
				desc: '我在营养e生app发现了一个不错的商品......',
				link: link,
				imgUrl: mainPic
			})
		}

		if (res.productVideo && res.productVideo.video && res.productVideo.video.length > 0) {
			setProductVideo(res.productVideo)
			setShowVideo(true)
		}

		if (res.evaluate && res.evaluate.pics) {            //  商品评价图片
			setEvaluatePics(res.evaluate.pics.split(','))
		}
		console.log('res - 数据 - :', res)
		if (res.detailShowPic) {                //  商品轮播数据
			var arr = []
			if (res.productVideo.video && res.productVideo.video.length > 0) {
				arr = [res.productVideo, ...res.detailShowPic.split(',')]
			} else {
				arr = [...res.detailShowPic.split(',')]
			}

			console.log('轮播图 数据 - - : ', arr)
			setBannerlist(arr)
		}
		if (res.certs) {
			setCerts(res.certs)
		}
		if (res.detailHtml) {
			const detailHtmlData = JSON.parse(res.detailHtml)
			setDetailHtml(detailHtmlData)
		}
		if (res.evaluate) {
			setEvaluate(res.evaluate)
		}
		if (res.shop) {
			setShop(res.shop)
		}
		// 获取食材  获取烹饪列表
		if (res.productFoodId) {
			getNewsCookieDataList(parseInt(res.productFoodId))   //   获取食材,获取烹饪列表,资讯
		}
		if (res.evaluate) {
			setEvaluate(res.evaluate)
		}
		if (res.shop) {
			setShop(res.shop)
		}
	}

	/**
	 * 根据食材Kid获取相关咨询和烹饪信息  /{version}/pb/food-infos/action/newscookie
	 */
	const [foodMaterial, setFoodMaterial] = useState([] as FoodHotVO[])             //   获取食材数据
	const [cookList, setCookList] = useState([] as CookingHomeVO[])                //   获取烹饪列表
	const [newsList, setNewsList] = useState([] as NewsInfoVO[])                  //   获取资讯列表
	const [foodNewsCook, setFoodNewsCook] = useState({} as FoodNewsCookVO)
	const getNewsCookieDataList = (kid: number) => {
		// nutrition.foodInfos.newscookie({id: kid}).then((res) => {
		nutrition.foodInfos.relatedResources({ foodKids: kid + '' }).then((res) => {
			console.log('- - 获取烹饪信息 - - : ', res)
			if (!res) {
				return
			}
			setFoodNewsCook(res)
			if (res.foods) {
				setFoodMaterial(res.foods)
				console.log(' - - 食材 - - :', res.foods)
			}
			if (res.cooks) {
				setCookList(res.cooks)
			}
			if (res.news) {
				setNewsList(res.news)
			}
		})
	}

	// 显示轮播
	const [showBanner, setShowBanner] = useState(false);                  // 是否展示烹饪详情数据
	const [bannerlist, setBannerlist] = useState([]);                     // 商品详情顶部轮播滚动数据
	const [currentSlideIndex, setCurrentSlideIndex] = useState(0)          // currentSlideIndex
	useEffect(() => {
		if (bannerlist.length > 0) {
			setShowBanner(true)
		}
		// console.log('轮播数据 - - :',bannerlist)
	}, [bannerlist]);

	// 监听滚动节点的变化
	useEffect(() => {
		if (currentSlideIndex == 0) {
			setShowProductState(1)
		} else {
			setShowProductState(2)
		}
	}, [currentSlideIndex]);

	// 安选滚动到指定位置
	const [navTop, setNavTop] = useState(false);                          //  是否吸顶
	const [tipScrollIndex, setTipScrollIndex] = useState(0)               //  要滚动的锚点索引
	const tipparams = {
		// direction: 'horizontal',
		slidesPerView: 3.8,
		spaceBetween: 20,
		freeMode: true
		// activeSlideKey:cur
	}
	const lenceparams = {
		// direction: 'horizontal',
		slidesPerView: 1.8,
		spaceBetween: 20,
		freeMode: true
	}
	// 跳转到指定锚点
	const [tipAnchor, setTipAnchor] = useState(0);
	const scrollToAnchor = (anchorName: string, index: number) => {
		if (anchorName) {
			let anchorElement = document.getElementById('lence-tip')
			setTipAnchor(index)
			console.log('点击了 - - :', anchorName, index)
			if (anchorElement) {
				var currentOffsetTop = anchorElement.offsetTop
				// anchorElement.scrollIntoView(
				//     // 缓慢滚动动画
				//     {
				//         behavior: 'smooth'
				//         }
				// );
				if (navTop) {
					console.log('111-222:', navTop, currentOffsetTop, currentOffsetTop - 120)
					window.scrollTo({ left: 0, top: currentOffsetTop - 120, behavior: "smooth" })
				} else {
					console.log('111-333:', navTop, currentOffsetTop - 110)
					window.scrollTo({ left: 0, top: currentOffsetTop - 120, behavior: "smooth" })
				}
			}
		}
	}

	/**
	* 获取安选标准图片相对父元素的位置
	*/
	let [certlayoutArr, setCertlayoutArr] = useState([])
	const getCertLayout = (item: any) => {
		setCertlayoutArr(certlayoutArr.concat(item))
	}
  /**
  *  打开-关闭弹窗
  */
	const [appropriateUserShow, setAppropriateUserShow] = useState(false)          // 适宜人群
	const closeAppropriatePop = () => {
		setAppropriateUserShow(false)
	}
	const openAppropriatePop = () => {
		setAppropriateUserShow(true)
	}

	const [tabooShow, setTabooShow] = useState(false)                             // 禁忌人群
	const closeTabooPop = () => {
		setTabooShow(false)
	}
	const openTabooPop = () => {
		setTabooShow(true)
	}

	const [productParamShow, setProductParamShow] = useState(false)           // 商品参数
	const closeParamPop = () => {
		setProductParamShow(false)
	}
	const openParamPop = () => {
		setProductParamShow(true)
	}

	const [productStandardsShow, setProductStandardsShow] = useState(false)   // 产品规格属性
	const closeStandardsPop = () => {
		setProductStandardsShow(false)
	}
	const openStandardsPop = () => {
		setProductStandardsShow(true)
	}
	const [serviceShow, setServiceShow] = useState(false)                     // 服务说明
	const closeServicePop = () => {
		setServiceShow(false)
	}
	const openServicePop = () => {
		setServiceShow(true)
	}
	const [adaptShow, setAdaptShow] = useState(false)                     // 服务说明
	const closeAdaptPop = () => {
		setAdaptShow(false)
	}
	const openAdaptPop = () => {
		setAdaptShow(true)
	}
  /**
   * 处理attributeType 数据
   */
	const [attributeSpec, setAttributeSpec] = useState([])             // Spec = 0,    // 规格属性
	const [attributeService, setAttributeService] = useState([])       // Service = 1, // 服务说明
	const [attributeParams, setAttributeParams] = useState([])         // Params = 2,  // 商品参数
	const disposeAttributeData = (attributes: ProductAttributeValueVO[]) => {

		let Spec = attributes.filter(item => item.attributeType === 0)
		setAttributeSpec(Spec)

		let Service = attributes.filter(item => item.attributeType === 1)
		setAttributeService(Service)

		let Params = attributes.filter(item => item.attributeType === 2)
		setAttributeParams(Params)

	}

  /**
   * 商品数量的加减
   */
	const [productNumber, setProductNumber] = useState(1)
	const addProduct = () => {
		if (productNumber < 99) {
			setProductNumber(productNumber + 1)
		}
	}
	const delProduct = () => {
		if (productNumber > 1) {
			setProductNumber(productNumber - 1)
		}
	}

  /**
    *  顶部视频图片切换
    *  @param {number} type
    */
	const [showProductState, setShowProductState] = useState(1)
	useEffect(() => {
		setShowBanner(false)
		if (showProductState == 1) {
			setCurrentSlideIndex(0)
			setShowBanner(true)
		} else {
			setCurrentSlideIndex(1)
			setShowBanner(true)
		}
	}, [showProductState]);
  /**
   * 页面点击操作   打开app指定页面 或者 引导下载
   */
	// oppen app

	const [downShowPop, setDownShowPop] = useState(false)       // 显示引导弹窗
	const showDownPopHandle = (isShow: boolean) => {
		setDownShowPop(isShow)
	}
	const callback = (val: boolean) => {
		setDownShowPop(val)
	}
	const clickHandle = () => {
		console.log('打开app 页面的参数 - — — :', openAppParams)
		if (isPreview === 1) {
			return;
		}
		const result = openApp(openAppParams)
		console.log(' - - clickHandle - - : ', result)
		if (result) {
			setDownShowPop(true)
		} else {
			setDownShowPop(false)
		}
	}
	/**
	 *   食材横向滚动
	 **/
	const foodSwiperParam = {
		// direction: 'horizontal',
		slidesPerView: 2.3,
		spaceBetween: 0,
		freeMode: true
		// activeSlideKey:cur
	}
	const preBuyLabel = useMemo(() => {
		if (!goodData.productPrepareSimpleAppVO) return null
		if ([2, 3].includes(goodData.productPrepareSimpleAppVO.state)) {
			return (
				<div className='preBuyWrap preBuyEnd' style={{ background: '#ccc' }}>
					预购结束
				</div>
			)
		}
		if (goodData.productPrepareSimpleAppVO.state === 1) {
			return (
				<div className='preBuyWrap'>
					预购
				</div>
			)
		}
		return null
	}, [goodData.productPrepareSimpleAppVO])
	let { usageState = '', usageTimeEnd = '', usageTimeStart = '' } = goodData || {}
	const bottomBar = useMemo(() => {
		if (!goodData.productPrepareSimpleAppVO) {
			return (<div className="shop-cart-foot" onClick={clickHandle}>
				<div className="left-cont">
					<div className="item- kefu">
						<span className="anticon icon-customerservice"></span>
						<span className="txt">客服</span>
					</div>
					<div className="item- kefu">
						<span className="anticon icon-staro"></span>
						<span className="txt">收藏</span>
					</div>
					<div className="item- kefu">
						<span className="anticon icon-shoppingcart"></span>
						<span className="txt">购物车</span>
					</div>
				</div>
				<div className="right-cont">
					<div className="right-cont-item- add-cart">加入购物车</div>
					<div className="right-cont-item- buy-new">{goodData.productClass === 2 ? '去听课' : '立即购买'}</div>
				</div>
			</div>)
		}
		if ([2, 3].includes(goodData.productPrepareSimpleAppVO.state)) {
			return <div className='preSaleBar preSaleEndBar' style={{ background: '#ccc' }}>
				预购结束
			</div>
		}
		return <div className='preSaleBar' onClick={() => setProductStandardsShow(true)}>
			参加预购
		</div>

	}, [goodData.productPrepareSimpleAppVO, clickHandle])

	const validAllAuth = useMemo(() => {
		let productAuth: string[] = [];
		let shopAuth = goodData.shop ? goodData.shop.auths : [];
		goodData.certs && goodData.certs.forEach((pCert) => {
			if (!pCert || !pCert.certPic) return
			productAuth = productAuth.concat(pCert.certPic)
		})
		let finalAuth = [...productAuth, ...shopAuth];

		return finalAuth.filter(auth => !!auth)
	}, [goodData])

	let resumeShippingTime =
		goodData && goodData.shop && goodData.shop.resumeShippingTime && goodData.shop.resumeShippingTime.split(' ')[0]
	let stopShippingTime =
		goodData && goodData.shop && goodData.shop.stopShippingTime && goodData.shop.stopShippingTime.split(' ')[0]

	let timeDiff = new Date(resumeShippingTime).getTime() - new Date().getTime()

	const userAvatar = useMemo(() => {
		return evaluate.userAvatar || (evaluate.user && evaluate.user.userImg)
	}, [evaluate])
	const userName = useMemo(() => {
		return evaluate.userName || (evaluate.user && evaluate.user.userName)
	}, [evaluate])
	const content = useMemo(() => {
		return evaluate.scoreContent || evaluate.content
	}, [evaluate])
	const pics = useMemo(() => {
		let imageStr = evaluate.scoreImgs || evaluate.pics || ''
		return imageStr.indexOf(';') > 0 ? imageStr.split(';') : imageStr.split(',')
	}, [evaluate])

	return (
		<div className="good-wrap" style={{ paddingTop: isPreview ? 0 : '1.06rem' }}>
			{openAppParams && openAppParams.hasOwnProperty('router') && !isPreview ? <Header openAppParam={openAppParams} handleData={showDownPopHandle} /> : ''}
			{<DownPop isShow={downShowPop} callback={callback}></DownPop>}
			{/*<GoodSummary data={goodData} />*/}
			{/*轮播*/}
			<section className="swiper-cont">
				<div className="top-video-swiper">
					{
						!showBanner ? '' :
							<div className={`topSwiper ${bannerlist.length == 1 ? 'topSwiper_one' : ''}`}>

								<WingBlank>
									<Carousel
										// autoplay={true}
										// infinite
										dots={false}
										selectedIndex={currentSlideIndex}
										// beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
										afterChange={index => {
											// if (showProductState == 1) {
											//     setCurrentSlideIndex(0)
											// }

											setCurrentSlideIndex(index)
										}}
									>
										{bannerlist.map((val: any, index: number) => (
											<a
												key={index}
												href="javascript:;"
												style={{ touchAction: 'none', display: 'inline-block', width: '100%', height: 620 }}>
												{
													val.video ? <video poster={val && val.coverImgUrl} controls className="video-play" src={val.video}></video>
														:
														<img
															src={val}
															alt=""
															style={{ width: '100%', display: 'inline-block', height: '100%', verticalAlign: 'top', objectFit: 'fill' }}
														// onLoad={() => {
														//     // fire window resize event to change height
														//     window.dispatchEvent(new Event('resize'));
														//     // this.setState({ imgHeight: 'auto' });
														// }}
														/>
												}
											</a>
										))}
									</Carousel>
								</WingBlank>
								{!showVideo || currentSlideIndex != 0 ?
									<div className="collect">
										{
											!showVideo ?
												<span className="collect-number">{currentSlideIndex + 1 || 0}/{bannerlist.length || 0}</span> :
												<span className="collect-number">{currentSlideIndex || 0}/{bannerlist.length - 1 || 0}</span>
										}

									</div>
									: ''}
							</div>
					}
					{showVideo && productVideo ?
						<div className="check-show-state">
							<span className={showProductState == 1 ? 'item active' : 'item '} onClick={() => setShowProductState(1)}>
								{showProductState == 1 ?
									<img className="item-img" src={require('../../assets/image/goods/play-icon-cur.png')} alt="" />
									: <img className="item-img" src={require('../../assets/image/goods/play-icon-pause.png')} alt="" />
								}
                    视频
                </span>
							<span className={showProductState == 2 ? 'item active' : 'item '} onClick={() => setShowProductState(2)}>图片</span>
						</div>
						: ''}
				</div>
				{stopShippingTime && resumeShippingTime && timeDiff > 0 && (<div className="stopwrap">
					<span className="iconfont icontrumpet" />
					<div className="stopText">从{stopShippingTime}到{resumeShippingTime}期间该商品会停止发货，给您带来不便，敬请您谅解!</div>
				</div>)}
				<div style={{ backgroundColor: '#fff' }}>
					{/* {preBuyLabel} */}
					{!(goodData && goodData.salePrice) ? '' :
						<div className="price-cont">
							{goodData.productPrepareSimpleAppVO && [1, 2, 3].includes(goodData.productPrepareSimpleAppVO.state) ? <div className='preSaleLabel'>预购</div> : null}
							<div className="newprice"><div className="yang">{goodData.productClass !== 2 ? "¥" : ''}</div><div className="pricenumber">{goodData.skus[0] && parseInt((goodData.skus[0].salePrice / 100).toFixed(2))}.</div><div className="dots">{goodData.skus[0] && (goodData.skus[0].salePrice / 100).toFixed(2).split('.')[1]}</div><span style={{ fontSize: '0.3rem', lineHeight: '0.3rem', marginLeft: '0.08rem' }}>{goodData.productClass === 2 ? "营养贝" : ''}</span></div>
							{goodData.joinVip ?
								<div className="vipprice">
									<p>¥{goodData && (goodData.skus[0].vipPrice / 100).toFixed(2)}</p>
									<div className="vipIcon">会员价</div>
								</div> : null
							}
						</div>
					}
					<div className="product-name">{goodData && goodData.productName}</div>
					{
						goodData.productPrepareSimpleAppVO && [1, 2, 3].includes(goodData.productPrepareSimpleAppVO.state) ?
							<div>
								<div className={`preSaleTime ${goodData.productPrepareSimpleAppVO.state === 3 ? 'preSaleTime-g' : ''} `}>预购起始时间</div>
								<div className={`preSaleTime-time ${goodData.productPrepareSimpleAppVO.state === 3 ? 'preSaleTime-g' : ''}`}>{getDate(goodData.productPrepareSimpleAppVO.preSaleStartTime)} - {getDate(goodData.productPrepareSimpleAppVO.preSaleEndTime)}</div>
							</div>
							: null
					}
				</div>
			</section>

			{/* {goodData && goodData.certs && goodData.certs.length> 0 ?
        <section>
          <div className="safe-top-cont">
              <img className="safe-top-icon-" src={require('../../assets/image/goods/safe.png')} alt=""/>
              <div className="txt">质量保障</div>
          </div>
          <div className="safe-linear-scroll">
              <Swiper {...tipparams}>
                  {goodData.certs.map((item,index)=> (
                      <div key={index} className="listItem"><img src={certLogos[item.certType]} onClick={() => scrollToAnchor('screen'+item.certType,0)} className="tipTxt" alt=""/></div>
                  ))}
              </Swiper>
          </div>
      </section>
			: ''} */}

			<section className="product-Information">
				{attributeSpec && attributeSpec.length > 0 ?
					attributeSpec.map((item, index) => (
						<div key={index} className="infor-line specif-wrap">
							<div className="line-tip">{item.attributeName}</div>
							<div className="skus-item-list">
								{item.value.split(',').map((ite: string, i: number) => (
									i == 0 ? <span key={i} onClick={clickHandle} className="skus-item-i active">{ite}</span> :
										<span key={i} onClick={clickHandle} className="skus-item-i">{ite}</span>
								))}
							</div>
						</div>
					)) : ''}
			</section>

			{/* 实物商品才显示发货 参数 */}
			{goodData.productClass !== 0 ? null :
				<section className="product-Information">
					{
						goodData && goodData.address ?
							<div className="infor-line align-items-start">
								<div className="line-tip">发货</div>
								<div className="first-line">
									<div className="line-right-cont" style={{ width: '100%' }}>
										<div className="r-txt b-txt">
											{/*+ goodData.address[0].addrDetail*/}
											<span className="local-t" >{goodData && goodData.address && goodData.address[0] && (goodData.address[0].provinceName + goodData.address[0].cityName)}</span>
											<span className='ship-fee'>快递：¥{goodData && goodData.freightFee ? (goodData.freightFee / 100).toFixed(2) : 0}</span>
										</div>
									</div>
									{goodData.productPrepareSimpleAppVO && [1, 2, 3].includes(goodData.productPrepareSimpleAppVO.state) && goodData.productPrepareSimpleAppVO.deliveryStartTime ? (
										<div className='summaryFirstLine'>
											预计{getDate(goodData.productPrepareSimpleAppVO.deliveryStartTime)}日开始发货
										</div>) : null}
								</div>
							</div>
							: ''}



					{attributeParams && attributeParams.length > 0 ?
						<div className="infor-line" onClick={openParamPop}>
							<div className="line-tip">参数</div>
							<div className="line-right-cont">
								<div className="r-txt">
									{
										attributeParams.slice(0, 3).map((item, index) => (
											<span key={index} className="param-tip">{item.attributeName} </span>
										))
									}
								</div>
								<div className="r-arrow"><Icon type="right" size="lg" color="#CACACE" /></div>
							</div>
						</div>
						: ''}

					{/* {goodData.suit ?
          <div className="infor-line" onClick={openAdaptPop}>
              <div className="line-tip">适宜人群</div>
              <div className="line-right-cont">
                  <div className="r-txt"></div>
                  <div className="r-arrow"><Icon type="right" size="lg" color="#CACACE" /></div>
              </div>
          </div>
          : ''} */}
					{/* {goodData.noSuit ?
              <div className="infor-line" onClick={openTabooPop}>
                  <div className="line-tip">禁忌人群</div>
                  <div className="line-right-cont">
                      <div className="r-txt"></div>
                      <div className="r-arrow"><Icon type="right" size="lg" color="#CACACE" /></div>
                  </div>
              </div>
          : ''} */}
				</section>

			}
			{attributeService && attributeService.length > 0 && goodData.productClass === 0 ?
				<section className="product-Information service-wrap"  >
					<div className="infor-line service-cent-wrap" onClick={openServicePop}>
						<div className="line-tip service-left">保障</div>
						<div className="line-right-cont service-right">
							{
								attributeService.slice(0, 2).map((item, index) => (
									<span className="icon-txt" key={index}>
										{/* <img className="r-txt-icon" src={require('../../assets/image/goods/Shapecopy@2x.png')} alt="" /> */}
										<span>{item.attributeName}</span>
									</span>
								))
							}
							{/*<img className="r-txt-icon" src={require('../../assets/image/goods/Shapecopy@2x.png')} alt=""/><span>48小时快速退款</span>*/}
							<span className="iconfont iconarrow-r-full" />
						</div>
					</div></section>
				: ''}
			{/* 课程 显示类型：课程 */}
			{goodData.productClass === 2 ?
				<section className="product-Information">
					<div className="infor-line align-items-start">
						<div className="line-tip">类型</div>
						<div className="first-line">
							<div className="line-right-cont" style={{ width: '100%' }}>
								<div className="r-txt b-txt">
									课程
								</div>
							</div>
						</div>
					</div>
				</section>
				: null
			}
			{/* 线下显示 有效时间 */}
			{goodData.productClass === 3 ?
				<section className="product-Information">
					<div className="infor-line align-items-start">
						<div className="line-tip">有效时间</div>
						<div className="first-line">
							<div className="line-right-cont" style={{ width: '100%' }}>
								<div className="r-txt b-txt">
									{usageState === 0 ? '一直有效' : `${usageTimeStart}至${usageTimeEnd}`}
								</div>
							</div>
						</div>
					</div>
				</section> : null
			}

			{/*显示商品的sku 信息  */}
			<section>
				{/* 服务说明 pop */}
				{serviceShow ?
					<div className="sercve- sercve-explain">
						<CSSTransition
							in={serviceShow} // 如果productStandardsShow从false变为true，则动画入场，反之out出场
							timeout={1200}            //动画执行1秒
							classNames='hideBg' //自定义的class名
							unmountOnExit //可选，当动画出场后在页面上移除包裹的dom节点
							onEntered={(el) => {
								// el.style.color='blue'   //可选，动画入场之后的回调，el指被包裹的dom，让div内的字体颜色等于蓝色
							}}
							onExited={() => {         //同理，动画出场之后的回调，也可以在这里来个setState啥的操作
							}}
						>
							<div className="bg" onClick={closeServicePop}></div>
						</CSSTransition>
						<CSSTransition
							in={serviceShow} // 如果productStandardsShow从false变为true，则动画入场，反之out出场
							timeout={1000} //动画执行1秒
							classNames='fade' //自定义的class名
							unmountOnExit //可选，当动画出场后在页面上移除包裹的dom节点
							onEntered={(el) => {
								// el.style.color='blue'   //可选，动画入场之后的回调，el指被包裹的dom，让div内的字体颜色等于蓝色
							}}
							onExited={() => {         //同理，动画出场之后的回调，也可以在这里来个setState啥的操作
							}}
						>
							<div className="cont-">
								<div className="sercve-cont-top">
									<div className="top-left service-top-left">保障</div>
									<div className="top-right" onClick={closeServicePop}><img className='close-icon' src={require('../../assets/image/goods/close-icon.png')} alt="" /></div>
								</div>
								{
									attributeService.map((item, index) => (
										<div className="txt-tip" key={index}>
											<div className="s-top">{item.attributeName}</div>
											<div className="s-txt">{item.value}</div>
										</div>
									))
								}
								{/*<div className="txt-tip">*/}
								{/*<div className="s-top">30天无忧退货</div>*/}
								{/*<div className="s-txt">自收到商品之日起30天内，可在线申请无忧退货服务(内裤、食品等特殊商品除外)。</div>*/}
								{/*</div>*/}
								{/*<div className="txt-tip">*/}
								{/*<div className="s-top">48小时快速退款</div>*/}
								{/*<div className="s-txt">收到退货包裹并确认无误后，将在48小时内办理退款，退款将原路返回，不同银行处理时间不同，预计1-5个工作日到账。</div>*/}
								{/*</div>*/}
								{/*<div className="txt-tip">*/}
								{/*<div className="s-top">满88元免邮费</div>*/}
								{/*<div className="s-txt">单笔订单金额(不含运费)满88元可免邮费，不满88元，单笔订单收取10元邮费。</div>*/}
								{/*</div>*/}
							</div>
						</CSSTransition>
					</div>

					: ''}

				{/* 商品参数 pop */}
				{productParamShow ?
					<div className="sercve- sercve-parameters">
						<CSSTransition
							in={productParamShow} // 如果productStandardsShow从false变为true，则动画入场，反之out出场
							timeout={1200}            //动画执行1秒
							classNames='hideBg' //自定义的class名
							unmountOnExit //可选，当动画出场后在页面上移除包裹的dom节点
							onEntered={(el) => {
								// el.style.color='blue'   //可选，动画入场之后的回调，el指被包裹的dom，让div内的字体颜色等于蓝色
							}}
							onExited={() => {         //同理，动画出场之后的回调，也可以在这里来个setState啥的操作
							}}
						>
							<div className="bg" onClick={closeParamPop}></div>
						</CSSTransition>

						<CSSTransition
							in={productParamShow} // 如果productStandardsShow从false变为true，则动画入场，反之out出场
							timeout={1000} //动画执行1秒
							classNames='fade' //自定义的class名
							unmountOnExit //可选，当动画出场后在页面上移除包裹的dom节点
							onEntered={(el) => {
								// el.style.color='blue'   //可选，动画入场之后的回调，el指被包裹的dom，让div内的字体颜色等于蓝色
							}}
							onExited={() => {         //同理，动画出场之后的回调，也可以在这里来个setState啥的操作
							}}
						>
							<div className="cont-">
								<div className="sercve-cont-top">
									<div className="top-left">商品参数</div>
									<div className="top-right" onClick={closeParamPop}><img className='close-icon' src={require('../../assets/image/goods/close-icon.png')} alt="" /></div>
								</div>
								<div className="lines-">
									{
										attributeParams.map((item, index) => (
											<div className="apram-line" key={index}>
												<div className="param-tip">{item.attributeName}</div>
												<div className="patam-txt">{item.value}</div>
											</div>
										))
									}
									{/*<div className="apram-line">*/}
									{/*<div className="param-tip">生产日期</div>*/}
									{/*<div className="patam-txt">2019年5月21日 至 2019年5月30日</div>*/}
									{/*</div>*/}
								</div>
							</div>
						</CSSTransition>
					</div>
					: ''}
				{/*商品详情规格选择  pop  */}

				{productStandardsShow ? <div className="sercve- sercve-product-sku">
					<CSSTransition
						in={productStandardsShow} // 如果productStandardsShow从false变为true，则动画入场，反之out出场
						timeout={1200}            //动画执行1秒
						classNames='hideBg' //自定义的class名
						unmountOnExit //可选，当动画出场后在页面上移除包裹的dom节点
						onEntered={(el) => {
							// el.style.color='blue'   //可选，动画入场之后的回调，el指被包裹的dom，让div内的字体颜色等于蓝色
						}}
						onExited={() => {         //同理，动画出场之后的回调，也可以在这里来个setState啥的操作
						}}
					>
						<div className="bg" onClick={closeStandardsPop}></div>
					</CSSTransition>
					<CSSTransition
						in={productStandardsShow} // 如果productStandardsShow从false变为true，则动画入场，反之out出场
						timeout={1000} //动画执行1秒
						classNames='fade' //自定义的class名
						unmountOnExit //可选，当动画出场后在页面上移除包裹的dom节点
						onEntered={(el) => {
							// el.style.color='blue'   //可选，动画入场之后的回调，el指被包裹的dom，让div内的字体颜色等于蓝色
						}}
						onExited={() => {         //同理，动画出场之后的回调，也可以在这里来个setState啥的操作
						}}
					>
						<div className="cont-">
							<div className="sercve-cont-top">
								<div className="top-left"></div>
								<div className="top-right" onClick={closeStandardsPop}><img className='close-icon' src={require('../../assets/image/goods/close-icon.png')} alt="" /></div>
							</div>
							<div className="pro-cont">
								<div className="product-cont">
									<div className="img-cont"><img className="img-i" src={goodData.mainPic} alt="" /></div>
									<div className="pro-txt-info-">
										<div className="pro-name">{goodData && goodData.productName}</div>
										<div className="pro-price"><span className="yang">¥</span><span className="intNum">{goodData && parseInt((goodData.salePrice / 100).toFixed(2))}.</span><span className="decimals">{goodData && (goodData.salePrice / 100).toFixed(2).split('.')[1]}</span></div>
										{/*<div className="newprice"><div className="yang">¥</div><div className="pricenumber">.</div><div className="dots"></div></div>*/}
									</div>
								</div>
								<div className="decimals-cont">
									<div className="decimals-tip">(配送地可能会影响库存，请正确选择)</div>
									<div className="line-p" onClick={clickHandle}>
										<div className="leftp-p">
											<img className='location-icon' src={require('../../assets/image/goods/localtion-icon.png')} alt="" />
											<span className="address-"></span>
										</div>
										<Icon type="right" size="lg" color="#CACACE" /></div>
								</div>
								<div className="divide-cont"></div>
								<div className="sku-cont-check">
									{attributeSpec && attributeSpec.length > 0 ?
										attributeSpec.map((item, index) => (
											<div key={index} className="item-cont">
												<div className="tip-item-cont">{item.attributeName}</div>
												<div className="skus-item-list">
													{item.value.split(',').map((ite: string, i: number) => (
														i == 0 ? <span key={i} onClick={clickHandle} className="skus-item-i active">{ite}</span> :
															<span key={i} onClick={clickHandle} className="skus-item-i">{ite}</span>
													))}
												</div>
											</div>
										))

										: ''}
									{/*<div className="item-cont">*/}
									{/*<div className="tip-item-cont">颜色</div>*/}
									{/*<div className="skus-item-list">*/}
									{/*<span className="skus-item-i active">红色</span>*/}
									{/*</div>*/}
									{/*</div>*/}
									<div className="item-cont item-check-number">
										<div className="tip-item-cont">商品数量</div>
										<div className="change-number">
											<img className="btn-number" onClick={delProduct} src={require('../../assets/image/goods/decrease_icon.png')} alt="" />
											<span className="number">{productNumber}</span>
											<img className="btn-number" onClick={addProduct} src={require('../../assets/image/goods/increase_icon.png')} alt="" />
										</div>
									</div>
								</div>

								<div className="foot-add-cart-cont">
									<div className="item sku-add-cart">加入购物车</div>
									<div className="item buy-limit">立即购买</div>
								</div>

							</div>
						</div>
					</CSSTransition>
				</div> : ''
				}

				{/* 适宜人群 pop*/}
				{adaptShow ?
					<div className="sercve- sercve-explain sercve-Adapt">
						<CSSTransition
							in={adaptShow} // 如果productStandardsShow从false变为true，则动画入场，反之out出场
							timeout={1200}            //动画执行1秒
							classNames='hideBg' //自定义的class名
							unmountOnExit //可选，当动画出场后在页面上移除包裹的dom节点
							onEntered={(el) => {
								// el.style.color='blue'   //可选，动画入场之后的回调，el指被包裹的dom，让div内的字体颜色等于蓝色
							}}
							onExited={() => {         //同理，动画出场之后的回调，也可以在这里来个setState啥的操作
							}}
						>
							<div className="bg" onClick={closeAdaptPop}></div>
						</CSSTransition>
						<CSSTransition
							in={adaptShow} // 如果productStandardsShow从false变为true，则动画入场，反之out出场
							timeout={1000} //动画执行1秒
							classNames='fade' //自定义的class名
							unmountOnExit //可选，当动画出场后在页面上移除包裹的dom节点
							onEntered={(el) => {
								// el.style.color='blue'   //可选，动画入场之后的回调，el指被包裹的dom，让div内的字体颜色等于蓝色
							}}
							onExited={() => {         //同理，动画出场之后的回调，也可以在这里来个setState啥的操作
							}}
						>
							<div className="cont-">
								<div className="sercve-cont-top">
									<div className="top-left">适宜人群</div>
									<div className="top-right" onClick={closeAdaptPop}><img className='close-icon' src={require('../../assets/image/goods/close-icon.png')} alt="" /></div>
								</div>
								<div className="s-txt">{goodData.suit && goodData.suit.split(',').join('、')}</div>
							</div>
						</CSSTransition>
					</div>
					: ''}


				{/* 禁忌人群 pop*/}
				{tabooShow ?
					<div className="sercve- sercve-explain sercve-taboo">
						<CSSTransition
							in={tabooShow} // 如果productStandardsShow从false变为true，则动画入场，反之out出场
							timeout={1200}            //动画执行1秒
							classNames='hideBg' //自定义的class名
							unmountOnExit //可选，当动画出场后在页面上移除包裹的dom节点
							onEntered={(el) => {
								// el.style.color='blue'   //可选，动画入场之后的回调，el指被包裹的dom，让div内的字体颜色等于蓝色
							}}
							onExited={() => {         //同理，动画出场之后的回调，也可以在这里来个setState啥的操作
							}}
						>
							<div className="bg" onClick={closeTabooPop}></div>
						</CSSTransition>
						<CSSTransition
							in={tabooShow} // 如果productStandardsShow从false变为true，则动画入场，反之out出场
							timeout={1000} //动画执行1秒
							classNames='fade' //自定义的class名
							unmountOnExit //可选，当动画出场后在页面上移除包裹的dom节点
							onEntered={(el) => {
								// el.style.color='blue'   //可选，动画入场之后的回调，el指被包裹的dom，让div内的字体颜色等于蓝色
							}}
							onExited={() => {         //同理，动画出场之后的回调，也可以在这里来个setState啥的操作
							}}
						>
							<div className="cont-">
								<div className="sercve-cont-top">
									<div className="top-left">禁忌人群</div>
									<div className="top-right" onClick={closeTabooPop}><img className='close-icon' src={require('../../assets/image/goods/close-icon.png')} alt="" /></div>
								</div>
								<div className="s-txt">{goodData.noSuit && goodData.noSuit.split(',').join('、')}</div>

							</div>
						</CSSTransition>
					</div>
					: ''}


			</section>

			{/* {goodData && goodData.subTitle ?  <section>
          <div className="product-abstract">
              <div className="abstract-tip">商品简介</div>
              <div className="abstract-info">{goodData.subTitle}</div>
          </div>

      </section>
			: ''} */}

			{shop ? <section className="shop-cont">
				{/* <div className="shop-title">店铺详情</div> */}
				<div className="shop-line">
					<div className="left-shop-info">
						{shop && shop.shopLogo ? <img className="shop-icon" src={shop.shopLogo} alt="" /> : ''}
						<div className="shop-txt">
							<div className="shop-name">{shop && shop.shopName}</div>
							<div className="product-number">{shop && shop.productCount}件商品</div>
						</div>
					</div>
					<div className="enterShop" onClick={clickHandle}>进店逛逛</div>
				</div>
			</section> : ''}

			{evaluate ?
				<section className="section-comment">
					<div className="section-title">
						<span className="comment-title">评价 <span> {evaluate && evaluate.totalNum || 0}</span></span>
						<span className="comment-entry" onClick={clickHandle}>
							<span>查看全部评价</span> <Icon type="right" size="lg" color="#ccc" />
						</span>
					</div>
					<div className="CommentItem-cont">
						<div className="item-c">
							<div className="user-c">
								{userAvatar ? <div className="user-avatar"><img src={userAvatar} alt="" /> </div> : ''}
								<div className="user-txt">
									<div className="user-name">{userName}</div>
									<div className="publish-time">{evaluate && evaluate.createDate}</div>
								</div>
							</div>
							<div className="user-comment">{content}</div>
							{(pics && pics.length > 0) ?
								<div className="list-img">
									{
										pics.slice(0, 3).map((item, index) => (
											<img key={index} src={item} alt="" />
										))
									}
									{
										pics.length >= 3 ?
											<div className="total-number">{pics.length}P</div>
											: ''}
								</div>
								: ''}
						</div>
					</div>

					{/*<div className="section-content">{evaluate ? <CommentItem data={evaluate} /> : null}</div>*/}
				</section>
				: ''}



			{/* 
			{
            validAllAuth && validAllAuth.length>0 ?
         <section className="goods-aptitude">
          <div className="aptitude-top" id="lence-tip">
             <img src={require('../../assets/image/goods/line_left.png')} alt=""/>
             <div className="aptitude-top-">商品资质</div>
             <img src={require('../../assets/image/goods/line_right.png')} alt=""/>
         </div>
         {
             validAllAuth.length>1?
						 <Swiper  {...lenceparams}>
								{validAllAuth.map((item,index) => (
                 <div key={index} id={'screen'+item} className="lenceparams- aptitude-cont">
                     <img src={item} alt="" className="more-imgs-"/>
                 </div>
						 ))}
         </Swiper>: <div className="one-item aptitude-cont">
                     <span className="after-"></span>
								 <img src={validAllAuth[0]} alt="" />
                     <span className="before-"></span>
                 </div>
         }
        </section>
					: ''} */}

			<section>
				<div className="good-info-top"><span /> 商品详情<span /></div>
				{detailHtml && detailHtml.length > 0 ?
					<div className="goodCert">
						{detailHtml.map((item, index) => (
							<div key={index}>
								{item.hasOwnProperty('text') ? <div className="goot-txt-info">{item.text}</div> : ''}
								{item.hasOwnProperty('image') ?
									<div className="pro-detail-img">
										<img src={item.image} alt="" />
									</div>
									: ''}
							</div>
						))
						}
					</div>
					: ''}
			</section>


			{/* <section className="food-material">
          <div className="food-material-top">食材</div>
          <Swiper {...foodSwiperParam}>
              { foodMaterial.map((item:any,index:number) => (
                  <div  key={index} className="food-material-cont">
                      <img src={item.coverImgUrl} alt=""/>
                      <div className="foot-mater">
                          <span className="leftp">{item.chName}</span>
                      </div>
                  </div>
                ))
              }
          </Swiper>
      </section> */}

			{/* {
				foodNewsCook && foodNewsCook.courses && foodNewsCook.courses.length > 0 ?
					<section>
						<div className="list-cont-item">
							<div className="list-cont-item-top">专家讲堂</div>
							<div className="list-two">
								{
									foodNewsCook && foodNewsCook.courses && foodNewsCook.courses.slice(0, 2).map((item, index) => (
										<div className="item" key={index} onClick={clickHandle}>
											<div className="img-">
												<img src={item.coverPhoto} alt="" />
												<img className="play-btn" src={require('../../assets/image/goods/play-icon.png')} alt="" />
											</div>
											<div className="txt">{item.theme}</div>
										</div>
									))
								}
							</div>
						</div>
					</section>: null
			} */}

			{/* {
         newsList && newsList.length>0 ?
            <section>
                <div className="list-cont-item">
                    <div className="list-cont-item-top">资讯</div>
                    <div className="list-two">
                        {
                            newsList.slice(0,2).map((item,index) => (
                                <div key={index} className="item" onClick={clickHandle}>
                                    {item.type ==0 ? '': <img className="play-btn" src={require('../../assets/image/goods/play-icon.png')} alt=""/> }
                                    <img src={item.coverImgUrl} alt=""/>
                                    <div className="txt">{item.title}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        : ''
        } */}

			{/* { cookList && cookList.length>0 ?
         <section>
          <div className="list-cont-item">
            <div className="list-cont-item-top">烹饪</div>
            <div className="list-two">
                {
                    cookList.slice(0,2).map((item,index) => (
                        <div key={index} className="item" onClick={clickHandle}>
                            {item.type ==0 ? '': <img className="play-btn" src={require('../../assets/image/goods/play-icon.png')} alt=""/>}
                            <img src={item.coverImg} alt=""/>
                            <div className="txt">{item.title}</div>
                        </div>
                    ))
                }

            </div>
          </div>
      </section>
				: ''} */}

			<section>
				<div>
					{/*<GoodRelated foodId={goodData.productFoodId} />*/}
					<div className="bottomNoteWrap">
						<span className="bottomNote">- 已经到底了哦 -</span>
					</div>
				</div>
			</section>
			{bottomBar}
			{
				!isPreview && <div className="footer" onClick={clickHandle}>
					<Footer />
				</div>
			}

		</div>
	)
}
GoodSharePage.options = { disableHeader: false, wrapperClassName: '' }
export default GoodSharePage
