import demo from './demo'
import fooddetailshare from './fooddetailshare' // 食材分享
import messagedetailshare from './messagedetailshare' // 资讯分享
import cookdetailshare from './cookdetailshare' // 烹饪详情
import goods from './goods' // 商品详情
import lecturerarticle from './lecturerarticle' // 讲师文章详情
import livestream from './livestream' // 直播详情
import brandstory from './brandstory' // 品牌故事
import brandstorypreview from './brandstorypreview' // 品牌故事后台预览
import voiceband from './voiceband' // 音频播放详情页
import videoplay from './videoplay' // 视频播放详情页
import videodetailplay from './videodetailplay' // 视频播放详情页，目前有贴子视频和资讯视频
import teamindex from './teamindex' // 小组首页、圈子首页
import teaminfo from './teaminfo' // 小组详情、帖子详情
import lecturerdetails from './lecturerdetails' // 讲师个人主页详情
import grouphome from './grouphome/groupmain' //
import groupdetail from './grouphome/groupdetail' //
import messagepreview from './messagepreview' // 后台发布资讯预览
import shopdetail from './shopdetail' // 店铺详情分享
import coursedetail from './coursedetail' // 课程详情分享
import coursedetailpreview from './coursedetailpreview' // 课程详情分享后台预览
import threereduce from './threereducethreehealthy' // 三减三健
import questionnaire from './questionnaire' // 问卷详情
import wechartlogin from './wechartlogin' // 微信登录 demo
import privacypolicy from './privacypolicy' // 免责声明
import downloadapp from './downloadapp' // app 下载页
import newsInformation from './newsInformation' // 显示图的资讯页
//import brandstory from './brandstory'
import mall from './mall'
import store from './store'
import mallActivity from './activepage/mall' //商城活动
import topicActivity from './activepage/socialcontact' //社交活动
import conferenceActivity from './activepage/conference' //会场活动
import originActivity from './activepage/originActivity' //会场活动
import childrenFoodActivity from './activepage/childrenfood' //儿童车活动
import vipfreeActivity from './activepage/vipfree' //会场活动
import dinnertablenutrition from './dinnertablenutrition' //会场活动
import nutrition from './nutrition' //报告样例
import mallhot from './mallhot' //商城热门产品
import vipexclusive from './activepage/vipexclusive' // vip专属送会员 12.10
import epidemic from './activepage/epidemic' // 抗击疫情活动
import dietician from './dietician' //  营养师详情页

import renheyiyao from './mall/activepage/renheyiyao' // 商城仁鹤医药活动页
import huize from './mall/activepage/huize' // 商城仁鹤医药活动页
import haishuidao from './mall/activepage/haishuidao' // 商城仁鹤医药活动页
import YongKang from './mall/activepage/yongkang' // 商城永康详情活动页
import Tuntunmai from './mall/activepage/tuntunmai' // 商城屯屯麦活动页
import Publicbenefit from './mall/activepage/publicbenefit' // 公益活动页
import insurance from './insurance' // 保险
import PrefectureInfo from './prefectureinfo' //专区详情
import storetrends from './storetrends' // 动态详情
import attract from './activepage/attract818' // 招商详情
import shopBrandStory from './shopbrandstory' // e店品牌故事详情
import shopexpertdetails from './shopexpertdetails' // e店专家详情页

import interventionScheme from './interventionScheme' // 微医

export default [
  demo,
  mall,
  store,
  fooddetailshare,
  messagedetailshare,
  cookdetailshare,
  goods,
  lecturerarticle,
  livestream,
  voiceband,
  videoplay,
  lecturerdetails,
  grouphome,
  groupdetail,
  messagepreview,
  videoplay,
  videodetailplay,
  brandstory,
  shopdetail,
  teamindex,
  teaminfo,
  coursedetail,
  brandstorypreview,
  coursedetailpreview,
  threereduce,
  questionnaire,
  wechartlogin,
  privacypolicy,
  downloadapp,
  newsInformation,
  mallActivity,
  topicActivity,
  conferenceActivity,
  originActivity,
  childrenFoodActivity,
  vipfreeActivity,
  dinnertablenutrition,
  nutrition,
  mallhot,
  vipexclusive,
  epidemic,
  dietician,
  renheyiyao,
  huize,
  haishuidao,
  insurance,
  YongKang,
  Tuntunmai,
  Publicbenefit,
  PrefectureInfo,
  storetrends,
  attract,
  shopBrandStory,
  shopexpertdetails,
  interventionScheme,
]
