import React, { useState, useEffect } from 'react'
import { Button, Icon } from 'antd-mobile'
import './insu-licence.css'
import Nav from 'components/nav/Nav';
import isIphoneX from 'utils/isIphoneX';


const PersonalClause: PageComponent = props => {
  useEffect(() => {
		window.scrollTo(0, 0)
	}, [])


	const detail = () => {
		return <div className='insulic-wrap' style={{backgroundColor:'#fff'}}>

		<span className='licenceTitle center bold'>中国人民财产保险股份有限公司</span>
			<span className='licenceTitle center bold'>人人安康医疗保险条款</span>
			<span className='licenceSubtitle center'>注册号：C00000232512018053104441</span>
		
					<span className='licenceSection center bold' >一、总则</span>
			<div className='licenceContent'>
			<div className='indent bold'>1.1、合同构成 </div>
						<div className='indent '>
						本保险合同由保险条款、投保单、保险单、健康问卷、声明、批单以及经投保
人与保险人认可的、与保险合同有关的其它书面协议组成。凡涉及本保险合同的约
定，均应采用书面形式。
					</div>
				
						
				
					<div className='indent bold'>1.2、被保险人  </div>
						<div className='indent '>
						年龄在 0 至 80 周岁（释义见 8.1）（含）、首次投保时身体健康、能正常工作、
生活的自然人可作为本保险合同的被保险人。投保时被保险人为 0 周岁的，应当为
出生满 28 日且已健康出院的婴儿。除另有约定外，被保险人为 60 周岁（不含）以
上的，保险人不接受首次投保，只接受续保。
					</div>
				
					<div className='indent bold'>1.3、投保人  </div>
						<div className='indent '>
						对被保险人有保险利益的团体或自然人可作为投保人。
					</div>

				
				
			</div>
			
			<span className='licenceSection center bold' >二、保障内容 </span>
			<div className='licenceContent'>
			<div className='indent bold'>2.1、保险责任 </div>
						<div className='indent '>
						经保险人同意，投保人可在投保 2.1.1 一般医疗保险责任的基础上选择投保
2.1.2 恶性肿瘤医疗保险责任，并在保险单中载明。
					</div>
					<div className='indent '>
					无论被保险人是否选择投保 2.1.2 保险责任，<span className='bold'>被保险人自获得被保资格（释义
见 8.2）之日起，在等待期（释义见 8.3）内首次发病（释义见 8.4）并被专科医
生（释义见 8.5）确诊为恶性肿瘤（释义见 8.6）的，本保险合同对该被保险人的
保险责任终止，保险人向投保人退还该被保险人的保险费。</span>
					</div>
				
						
				
					<div className='indent bold'>2.1.1、一般医疗保险责任  </div>
						<div className='indent '>
						在保险期间内，被保险人遭受意外伤害（释义见 8.7）或自获得被保资格之日
起经过本保险合同约定的等待期后罹患疾病在中华人民共和国境内（不包括香港、
澳门和台湾地区）二级或二级以上公立医院或保险人认可的医疗机构（释义见 8.8）
的普通部接受治疗的，对于其累计支出的 2.1.1.1-2.1.1.4 中必需且合理（释义见
8.9）的医疗费用，保险人按照 2.1.3 保险金给付标准的约定，<span className='bold'>在扣除约定的免赔
额（释义见 8.10）后，</span>在一般医疗保险金额范围内计算并给付保险金。
					</div>
					<div className='indent bold'>
					除另有约定外，保险人承担保险责任的范围不包括上述医院或医疗机构的家
庭病床（房）、特需医疗、外宾医疗、国际部、干部病房、联合病房、国际医疗
中心、VIP 病房，以及其他不属于社会医疗保险（释义见 8.11）范畴的门诊、急
诊、病房、住院部。 
					</div>
					<div className='indent bold'>2.1.1.1、住院医疗费用   </div>
						<div className='indent '>
						被保险人接受符合本保险合同约定的住院（释义见 8.12）治疗时，发生的必
需且合理的住院医疗费用，包括床位费（释义见 8.13）、膳食费（释义见 8.14）、
护理费（释义见 8.15）、重症监护室床位费、诊疗费、检查检验费（释义见 8.16）、
治疗费（释义见 8.17）、药品费（释义见 8.18）、手术费（释义见 8.19）、救护车
使用费（释义见 8.20）。

					</div>
					<div className='indent '>
					保险期间届满被保险人仍在住院治疗的，保险人承担给付保险金的期限可自保
险期间届满次日起延长至出院之日止，但最长以 30 日为限。

					</div>

				
				
					<div className='indent bold'>2.1.1.2、特殊门诊医疗费用  </div>
						<div className='indent'>
						被保险人接受符合本保险合同约定的特殊门诊治疗时，发生的必需且合理的如
下特殊门诊治疗费用，包括：
						</div>
				
						<div className='indent'>
						（1）门诊肾透析费；
						</div>
						<div className='indent'>
						（2）门诊恶性肿瘤治疗费，包括化学疗法（释义见 8.21）、放射疗法（释义
见 8.22）、肿瘤免疫疗法（释义见 8.23）、肿瘤内分泌疗法（释义见 8.24）、肿瘤
靶向疗法（释义见 8.25）的治疗费用；
						</div>
						<div className='indent'>
						（3）器官移植后的门诊抗排异治疗费。
						</div>
				
						<div className='indent bold'>2.1.1.3、 门诊手术费用  </div>
						<div className='indent'>
						被保险人接受符合本保险合同约定的门诊治疗且必须接受门诊手术时，发生的
必需且合理的手术费。
						</div>
				
						<div className='indent bold'>2.1.1.4、 住院前后门急诊医疗费用  </div>
						<div className='indent'>
						被保险人接受符合本保险合同约定的住院的，在住院前 7 日（含住院当日）和
出院后 30 日（含出院当日）内，因与该次住院相同原因而接受符合本保险合同约
定的门急诊治疗时，发生的必需且合理的门急诊医疗费用<span className='bold'>（但不包括特殊门诊医疗
费用和门诊手术费用）。 </span>
				</div>
				
				<div className='indent bold'>2.1.2、 恶性肿瘤医疗保险责任  </div>
						<div className='indent'>
						在保险期间内，被保险人自获得被保资格之日起经过本保险合同约定的等待期
后首次发病并被专科医生确诊为恶性肿瘤，在中华人民共和国境内（不包括香港、
澳门和台湾地区）二级或二级以上公立医院或保险人认可的医疗机构的普通部接受
治疗的，保险人先按照 2.1.1 的约定给付一般医疗保险金，当保险人累计给付的保
险金达到一般医疗保险金的保险金额后，保险人在本保险责任项下，对于被保险人
支出的 2.1.2.1-2.1.2.4 中必需且合理的、未在 2.1.1 一般医疗保险责任项下获得
赔偿的医疗费用，按照 2.1.3 保险金给付标准的约定，在恶性肿瘤医疗保险金额范
围内计算并给付保险金。
						</div>
						<div className='indent bold'>
						除另有约定外，保险人承担保险责任的范围不包括上述医院或医疗机构的家
庭病床（房）、特需医疗、外宾医疗、国际部、干部病房、联合病房、国际医疗
中心、VIP 病房，以及其他不属于社会医疗保险范畴的门诊、急诊、病房、住院部。
						</div>
				

						<div className='indent bold'>2.1.2.1、  恶性肿瘤住院医疗费用   </div>
						<div className='indent'>
						被保险人因恶性肿瘤接受符合本保险合同约定的住院治疗时，发生的必需且合
理的恶性肿瘤住院医疗费用，包括床位费、膳食费、护理费、重症监护室床位费、
诊疗费、检查检验费、治疗费、药品费、手术费、救护车使用费。
						</div>
						<div className='indent'>
						保险期间届满被保险人仍在住院治疗的，保险人承担给付保险金的期限可自保
险期间届满次日起延长至出院之日止，但最长以 30 日为限。

						</div>
				

						<div className='indent bold'>2.1.2.2、  恶性肿瘤特殊门诊医疗费用   </div>
						<div className='indent'>
						被保险人因恶性肿瘤接受符合本保险合同约定的门诊治疗时，发生的必需且合
理的门诊恶性肿瘤治疗费，包括化学疗法、放射疗法、肿瘤免疫疗法、肿瘤内分泌
疗法、肿瘤靶向疗法的治疗费用。
						</div>


						<div className='indent bold'>2.1.2.3、   恶性肿瘤门诊手术费用    </div>
						<div className='indent'>
						被保险人因恶性肿瘤接受符合本保险合同约定的门诊治疗且必须接受恶性肿
瘤门诊手术时，发生的必需且合理的手术费。
						</div>
				
						<div className='indent bold'>2.1.2.4、   恶性肿瘤住院前后门急诊医疗费用    </div>
						<div className='indent'>
						被保险人因恶性肿瘤接受符合本保险合同约定的住院的，在住院前 7 日（含住
院当日）和出院后 30 日（含出院当日）内，因与该次住院相同原因而接受符合本
保险合同约定的门急诊治疗时，发生的必需且合理的恶性肿瘤门急诊医疗费用<span className='bold'>（但
不包括恶性肿瘤特殊门诊医疗费用和恶性肿瘤门诊手术费用）。 </span>
				</div>
				
				<div className='indent bold'>2.1.3、   保险金给付标准    </div>
						<div className='indent'>
						对于每一被保险人在保险期间内累计支出的符合本保险合同约定的医疗费用
（以下公式中简称为“累计合理医疗费用”），保险人按照下列公式计算保险金：
						</div>
						<div className='indent'>
						（1）若被保险人从其他途径已获得的医疗费用补偿金额（不含社会医疗保险
和公费医疗已补偿金额）超过免赔额的，保险金计算公式为：
						</div>
						<div className='indent bold'>
						保险金=（累计合理医疗费用-社会医疗保险、公费医疗已补偿金额以及其他
途径已获得的医疗费用补偿金额）×给付比例 
						</div>
						<div className='indent'>
						（2）若被保险人从其他途径已获得的医疗费用补偿金额（不含社会医疗保险
和公费医疗已补偿金额）不超过免赔额的，保险金计算公式为：

						</div>
						<div className='indent bold'>
						保险金=（累计合理医疗费用-社会医疗保险、公费医疗已补偿金额-免赔额）
×给付比例
						</div>
						<div className='indent'>
						（3）若被保险人选择投保恶性肿瘤医疗保险责任，且被保险人因罹患恶性肿
瘤接受本保险合同约定的治疗，对于确诊恶性肿瘤之前发生的医疗费用，保险人仍
按照上述（1）（2）中的公式计算保险金，但对于自确诊恶性肿瘤之日起所发生的
符合本合同约定的全部医疗费用（以下公式中简称为“合理医疗费用”），保险人在
计算保险金时，不设置免赔额，按如下公式计算保险金：

						</div>
						<div className='indent bold'>
						保险金=（合理医疗费用-社会医疗保险、公费医疗已补偿金额以及其他途径
已获得的医疗费用补偿金额）×给付比例 
						</div>
						<div className='indent'>
						若本保险合同为续保合同，且包含恶性肿瘤医疗保险责任，保险人对续保前已
确诊恶性肿瘤的被保险人不设置免赔额，也按照该公式计算保险金。
						</div>
						<div className='indent bold'>
						前述免赔额、给付比例由投保人、保险人双方约定，并在保险单中载明。若
被保险人以参加社会医疗保险身份投保，但未以参加社会医疗保险身份就诊并结
算的，则保险人根据另行约定的给付比例及上述保险金计算公式计算保险金，该
给付比例由投保人和保险人双方约定，并在保险单中载明。
						</div>

				
						<div className='indent bold'>2.1.4、   补偿原则    </div>
						<div className='indent'>
						本保险合同适用补偿原则。被保险人通过任何途径所获得的医疗费用补偿金额
总和以其实际支出的医疗费用金额为限。
						</div>

						<div className='indent bold'>2.2、   责任免除     </div>
						<div className='indent bold'>2.2.1、
						被保险人因下列原因而支出医疗费用的，保险人不承担给付保险金责任： 
						</div>
						<div className='indent bold'>
						（1）投保人对被保险人的故意杀害、故意伤害； 
						</div>
						<div className='indent bold'>
						（2）故意自伤或自杀，但被保险人自杀时为无民事行为能力人的除外；
						</div>
						<div className='indent bold'>
						（3）挑衅或故意行为而导致的打斗、被袭击或被谋杀； 
						</div>
						<div className='indent bold'>
						（4）受酒精、毒品、管制药物的影响，或未遵医嘱服用、涂用、注射药物； 
						</div>
						<div className='indent bold'>
						（5）在获得被保资格前所患既往症（释义见 8.26）及保险单中特别约定的除
外疾病； 
						</div>
						<div className='indent bold'>
						（6）在获得被保资格之日起 120 天内接受扁桃体腺、甲状腺、疝气、女性生
殖系统疾病的检查与治疗； 
						</div>
						<div className='indent bold'>
						（7）遗传性疾病，先天性畸形、变形或染色体异常（以世界卫生组织颁布的
《疾病和有关健康问题的国际统计分类（ICD-10）》为准）； 
						</div>
						<div className='indent bold'>
						（8）精神和行为障碍（以世界卫生组织颁布的《疾病和有关健康问题的国际
统计分类（ICD-10）》为准）； 
						</div>
						<div className='indent bold'>
						（9）故意犯罪或抗拒依法采取的刑事强制措施； 
						</div>
						<div className='indent bold'>
						（10）接受以保健（释义见 8.27）为目的的疗养、特别护理、静养，康复性
治疗、物理治疗、心理治疗或预防性治疗； 
						</div>
						<div className='indent bold'>
						（11）包皮环切术、包皮剥离术、包皮气囊扩张术、性功能障碍治疗；
						</div>
						<div className='indent bold'>
						（12）进行器官捐赠； 
						</div>
						<div className='indent bold'>
						（13）接受美容手术、矫形手术、变性手术或非因意外伤害而进行的整形手
术； 
						</div>
						<div className='indent bold'>
						（14）接受试验性药物或治疗；
						</div>
						<div className='indent bold'>
						（15）健康检查、看护、保健或任何与疾病、意外伤害无直接关系的咨询、
检查和治疗； 
						</div>
						<div className='indent bold'>
						（16）战争、军事行动、暴乱或武装叛乱及政府强制行为； 
						</div>
						<div className='indent bold'>
						（17）核爆炸、核辐射或核污染； 
						</div>
						<div className='indent bold'>
						（18）酒后驾驶、无有效驾驶证（释义见 8.28）驾驶，或驾驶无有效行驶证
（释义见 8.29）的机动车； 
						</div>
						<div className='indent bold'>
						（19）从事职业运动或可获得报酬的运动或竞技，在训练或比赛中受伤；从
事或参加高风险运动（释义见 8.30）； 
						</div>
						<div className='indent bold'>
						（20）感染艾滋病病毒（HIV 呈阳性）或患艾滋病（AIDS，释义见 8.31）； 
						</div>
						<div className='indent bold'>
						（21）非因意外伤害而进行的牙科治疗或手术、视力矫正、因矫正视力而作
的眼科验光检查，以及任何原因导致的牙齿修复或牙齿整形、安装及购买残疾用
具（如轮椅、假肢、假眼、假牙或者助听器等）； 
						</div>
						<div className='indent bold'>
						（22）分娩、流产、宫外孕、不孕不育治疗、人工受精、怀孕、堕胎、节育
（含绝育）、产前产后检查、变性以及由以上原因引起的并发症；  
						</div>
						<div className='indent bold'>
						（23）职业病（释义见 8.32）、医疗事故（释义见 8.33）；
						</div>
						<div className='indent bold'>
						（24）不符合入院标准、挂床住院或住院病人应当出院而拒不出院（从医院
确定出院之日起发生的一切医疗费用）。 
						</div>
				
						<div className='indent bold'>2.2.2、   对于以下医疗费用，保险人不承担给付保险金责任：    </div>
						<div className='indent bold'>
						（1）等待期内被保险人发生的医疗费用；
						</div>
						<div className='indent bold'>
						（2）等待期内被保险人已经发生的疾病、症状或病理改变且延续到等待期以
后进行的任何诊断或治疗所发生的医疗费用； 
						</div>
						<div className='indent bold'>
						（3）各类医疗鉴定，包括但不限于医疗事故鉴定、精神病鉴定、孕妇胎儿性
别鉴定、验伤鉴定、亲子鉴定、遗传基因鉴定费用。 
						</div>
				
						<div className='indent bold'>2.3、   保险金额     </div>
						<div className='indent '>
						每一被保险人的保险金额是保险人承担给付该被保险人保险金责任的最高限
额。

						</div>
						<div className='indent '>
						保险金额由投保人、保险人双方约定，并在保险单中载明。 
						</div>
						<div className='indent '>
						每一被保险人的保险金额一经确定，在保险期间内不得变更。
						</div>
				

						<div className='indent bold'>2.4、   保险期间     </div>
						<div className='indent '>
						除另有约定外，保险期间为 1 年，以保险单载明的起讫时间为准。
						</div>
				

						<div className='indent bold'>2.5、   续保      </div>
						<div className='indent '>
						本保险合同期满前，投保人可向保险人申请续保。<span className='bold'>续保合同保险期间的起始日
期与续保对应上一保险合同保险期间的终止日期相连不间断。</span>
				</div>
				<div className='indent '>
				续保费率根据被保险人申请续保时的年龄确定。保险人不会因为某一被保险人
的健康状况变化或历史理赔情况而单独调整该被保险人的续保费率。
						</div>
						<div className='indent '>
						保险人有权根据医疗费用水平变化、本保险产品整体经营状况等调整被保险人
在续保时的费率。费率调整适用于本保险产品的所有被保险人或同一投保年龄段的
所有被保险人，在投保人接受费率调整的前提下，保险人方可为投保人办理续保手
续。
						</div>
						<div className='indent '>
						本保险合同为非保证续保合同，若发生下列情形之一的，本保险合同不再接受
续保：
						</div>
						<div className='indent '>
						（1）本产品已停售；
						</div>
						<div className='indent '>
						（2）续保时被保险人的年龄超过 80 周岁；
						</div>
						<div className='indent '>
						（3）被保险人身故；
						</div>
						<div className='indent '>
						（4）投保人对于本公司就投保人或者被保险人的有关情况提出的询问未履行
如实告知义务，足以影响本公司决定是否同意承保，本公司已经解除保险合同的；
						</div>
						<div className='indent '>
						（5）本保险合同因其他条款所列情况而导致效力终止。
						</div>

			</div>
			
			<span className='licenceSection center bold' >三、投保人、被保险人义务 </span>
			<div className='licenceContent'>
			<div className='indent bold'>3.1、交费义务 </div>
						<div className='indent '>
						保险费根据投保人选择的保障计划以及被保险人的风险状况（如健康状况、年
龄等）而定，并在保险单中载明。
						</div>
						<div className='indent '>
						除另有约定外，投保人应当在保险合同成立时交清保险费。
						</div>
				
						<div className='indent bold'>3.2、如实告知义务  </div>
						<div className='indent '>
						投保人应如实填写投保单并回答保险人提出的询问，履行如实告知义务。
						</div>
				

						<div className='indent bold'>3.3、住址或通讯地址变更通知义务   </div>
						<div className='indent '>
						投保人住所或通讯地址变更时，应及时以书面形式通知保险人。
						</div>
				

						<div className='indent bold'>3.4、变更批注    </div>
						<div className='indent '>
						在保险期间内，投保人需变更合同内容的，应以书面形式向保险人提出申请。
保险人同意后出具批单，并在本保险合同中批注。若被保险人已身故，则保险人不
接受本保险合同中有关该被保险人的任何内容的变更申请。
						</div>
				

						<div className='indent bold'>3.5、保险事故通知义务    </div>
						<div className='indent '>
						发生保险责任范围内的事故后，投保人、被保险人或受益人应及时通知保险人，
并书面说明事故发生的原因、经过和损失情况。
						</div>
			


			</div>
		
			<span className='licenceSection center bold' >四、保险金申请与给付  </span>
			<div className='licenceContent'>
			<div className='indent bold'>4.1、保险金申请    </div>
						<div className='indent '>
						保险金申请人（释义见 8.34）向保险人申请给付保险金时，应填写保险金给
付申请书，并提交以下材料。保险人按照本保险合同的约定，认为有关的证明和资
料不完整的，应当及时一次性通知保险金申请人补充提供。
						</div>
				
						<div className='indent '>
						（1）保险金给付申请书；
						</div>
						<div className='indent '>
						（2）保险单；
						</div>
						<div className='indent '>
						（3）保险金申请人的身份证明；
						</div>
						<div className='indent '>
						（4）中华人民共和国境内（不包括港、澳、台地区）二级（含）以上公立医
院或保险人认可的医疗机构出具的医疗费用收据及明细清单/帐、诊断证明、病历、
出院小结等；
						</div>
						<div className='indent '>
						（5）对于已经从社会医疗保险、公费医疗或任何第三方包括任何商业医疗保
险获得相关医疗费用补偿的，应提供社会医疗保险机构、商业保险机构或其他第三
方的医疗费用分割单或医疗费用结算证明；
						</div>
						<div className='indent '>
						（6）被保险人所能提供的与确认保险事故的性质、原因、损失程度等有关的
其他证明和资料。
						</div>
						<div className='indent '>
						在保险人的理赔审核过程中，保险人有权在合理的范围内对索赔的被保险人进
行医疗检查。
						</div>
				



						<div className='indent bold'>4.2、保险金的给付    </div>
						<div className='indent '>
						保险人在收到保险金申请人提交的本保险条款所列的材料后，应及时就是否属
于保险责任做出核定，并将核定结果通知被保险人或受益人。
						</div>
				
						
			</div>

			<span className='licenceSection center bold' >五、保险合同解除  </span>
			<div className='licenceContent'>
			<div className='indent'>
			被保险人已经出险理赔的，投保人不得解除保险合同。
					</div>
					<div className='indent'>
					投保人解除合同的，保险人应当自收到解除合同通知之日起三十日内，按照合
同约定退还保险单的未满期净保险费（释义见 8.35）。
					</div>
			</div>



			<span className='licenceSection center bold' >六、争议处理和法律适用  </span>
			<div className='licenceContent'>
			<div className='indent bold'>6.1、争议处理</div>
					<div className='indent'>
					合同争议解决方式由当事人在合同约定从下列两种方式中选择一种：
					</div>
					<div className='indent'>
					（一）因履行本合同发生的争议，由当事人协商解决，协商不成的，提交保险
单载明的仲裁委员会仲裁；
					</div>
					<div className='indent'>
					（二）因履行本合同发生的争议，由当事人协商解决，协商不成的，依法向人
民法院起诉。
					</div>
				
					<div className='indent bold'>6.2、法律适用及合法性保证</div>
					<div className='indent'>
					与本保险合同有关的以及履行本保险合同产生的一切争议处理适用中华人民
共和国法律（不包括港澳台地区法律）。
					</div>
			</div>


			<span className='licenceSection center bold' >七、合法性保证  </span>
			<div className='licenceContent'>
					<div className='indent'>
					本保险合同约定与《中华人民共和国保险法》等法律规定相悖之处，以法律规
定为准。本保险合同未尽事宜，以法律规定为准。
					</div>
					
			</div>


			<span className='licenceSection center bold' >八、释义  </span>
			<div className='licenceContent'>
				<div className='indent bold'>8.1、周岁 </div>
				<div className='indent'> 以法定身份证明文件中记载的出生日期为基础计算的实足年龄。</div>
					
				

				<div className='indent bold'>8.2、获得被保资格 </div>
				<div className='indent'> 经保险人审核同意并出具保险单或批单，符合 1.2 中约定的人员即可获得被保
资格，成为被保险人。</div>
<div className='indent'> 本保险合同为首次投保或非续保的，被保险人获得被保资格的日期以以下两者
中较晚的日期为准：（1）本保险合同的保险期间起始日；（2）批单所载生效日，有
多张批单增加该被保险人的，以最晚批单所载生效日为准。
				</div>
				<div className='indent'> 本保险合同为续保的，被保险人获得被保资格的日期以以下两者中较晚的日期
为准：（1）续保对应首张保险合同的保险期间起始日；（2）续保对应首张保险合同
的保险期间起始日之后，批单所载生效日，有多张批单增加该被保险人的，以最晚
批单所载生效日为准。
				</div>
				

				<div className='indent bold'>8.3、等待期 </div>
				<div className='indent'> 是指自被保险人获得被保资格之日起计算的一段时间，经过该段时间后，保险
人才对被保险人因疾病而发生的医疗费用承担保险责任。<span className='bold'>在此期间，尽管保险合同
已经生效，被保险人已获得被保资格，但保险人并不承担给付保险金义务。</span> </div>

				
<div className='indent bold'>8.4、发病及首次发病</div>
				<div className='indent'> 发病，是指被保险人出现本保险合同 8.5 条所约定的恶性肿瘤的前兆、
症状或异常的身体状况，或已经显现足以促使一般普通谨慎人士引起注意并
寻求诊断、治疗或护理的病症。</div>
<div className='indent'> 首次发病，是指被保险人第一次发生并首次被确诊患上本合同约定的恶
性肿瘤，并且该疾病在该被保险人获得被保资格前并未发病或有任何症状。</div>

				
<div className='indent bold'>8.5、专科医生 </div>
				<div className='indent'> 专科医生应当同时满足以下四项资格条件：</div>
				<div className='indent'> 
					（1）具有有效的中华人民共和国《医师资格证书》；</div>
					<div className='indent'> 
					（2）具有有效的中华人民共和国《医师执业证书》，并按期到相关部门登记注
册；
</div>
					<div className='indent'> 
					（3）具有有效的中华人民共和国主治医师或主治医师以上职称的《医师职称
证书》；</div>
					<div className='indent'> 
					（4）在二级或二级以上医院的相应科室从事临床工作三年以上。</div>
				

					<div className='indent bold'>8.6、恶性肿瘤  </div>
				<div className='indent'> 指恶性细胞不受控制的进行性增长和扩散，浸润和破坏周围正常组织，可以经
血管、淋巴管和体腔扩散转移到身体其它部位的疾病。经病理学检查结果明确诊断，
临床诊断属于世界卫生组织《疾病和有关健康问题的国际统计分类》（ICD-10）的
恶性肿瘤范畴。<span className='bold'>但不包括下列疾病：</span></div>
				<div className='indent bold'> ①原位癌；</div>
				<div className='indent bold'> ②相当于 Binet 分期方案 A 期程度的慢性淋巴细胞白血病；</div>
				<div className='indent bold'> ③相当于 Ann Arbor 分期方案 I 期程度的何杰金氏病； </div>
				<div className='indent bold'> ④皮肤癌（不包括恶性黑色素瘤及已发生转移的皮肤癌）； </div>
				<div className='indent bold'> ⑤TNM 分期为 T1N0M0 期或更轻分期的前列腺癌；</div>
				<div className='indent bold'> ⑥感染艾滋病病毒或患艾滋病期间所患恶性肿瘤。</div>



				<div className='indent bold'>8.7、意外伤害  </div>
				<div className='indent'> 指以外来的、突发的、非本意的、非疾病的客观事件为直接且单独的原因致使
身体受到的伤害。<span className='bold'>以下情形属于疾病范畴，非本条款所指意外伤害：</span> </div>
<div className='indent bold'> （1）猝死：指由潜在疾病、身体机能障碍或其他非外来性原因所导致的、在
出现急性症状后发生的突然死亡，以医院的诊断或公安、司法机关的鉴定为准； </div>

				<div className='indent bold'> （2）过敏及由过敏引发的变态反应性疾病；  </div>
				<div className='indent bold'> （3）高原反应；  </div>
				<div className='indent bold'> （4）中暑；  </div>
				<div className='indent bold'> （5）细菌、病毒或其他病原体导致的感染性疾病。   </div>


				<div className='indent bold'>8.8、医院、医疗机构   </div>

				<div className='indent'>
				本保险合同约定的医院或医疗机构是指符合下列所有条件的机构：
				</div>

				<div className='indent'>
				(1)拥有合法经营执照；
				</div>
				<div className='indent'>
				(2)设立的主要目的为向受伤者和患病者提供留院治疗和护理服务；
				</div>
				<div className='indent'>
				(3)有合格的医生和护士提供全日二十四小时的医疗和护理服务；
				</div>
				<div className='indent'>
				(4)非主要作为康复医院、诊所、护理、疗养、戒酒、戒毒或类似的医疗机构。
				</div>

				
				<div className='indent bold'>8.9、必需且合理    </div>
				<div className='indent'>
				指符合以下两个条件：
				</div>
				<div className='indent'>
				1.符合通常惯例：指与接受医疗服务所在地通行治疗规范、通行治疗方法、平
均医疗费用价格水平一致的费用。
				</div>
				<div className='indent'>
				对是否符合通常惯例由保险人根据客观、审慎、合理的原则进行审核；如果被
保险人对审核结果有不同意见，可由双方认同的权威医学机构或者权威医学专家进
行审核鉴定。
				</div>
				<div className='indent'>
				2.医学必需：指医疗费用符合下列所有条件：
				</div>

				<div className='indent'>
				（1) 治疗意外伤害或者疾病所必需的项目；
				</div>
				<div className='indent'>
				（2) 不超过安全、足量治疗原则的项目；

				</div>
				<div className='indent'>
				（3) 由医生开具的处方药；
				</div>
				<div className='indent'>
				（4) 非试验性的、非研究性的项目；
				</div>
				<div className='indent'>
				（5) 与接受治疗当地普遍接受的医疗专业实践标准一致的项目。
				</div>
				<div className='indent'>
				对是否医学必需由保险人根据客观、审慎、合理的原则进行审核；如果被保险
人对审核结果有不同意见，可由双方认同的权威医学机构或者权威医学专家进行审
核鉴定。
				</div>

				<div className='indent bold'>8.10、免赔额   </div>
				<div className='indent'>
				本保险合同中所指免赔额均指年免赔额，指在本合同保险期间内，应由每一被
保险人自行承担，本合同不予赔付的部分。被保险人从其他途径已获得的医疗费用
补偿可用于抵扣免赔额。但通过社会医疗保险和公费医疗保险获得的补偿，不可用
于抵扣免赔额。
				</div>


				<div className='indent bold'>8.11、社会医疗保险   </div>
				<div className='indent'>
				本保险合同所称社会医疗保险包括新农合、城镇职工基本医疗保险、城镇居民
	基本医疗保险、医疗救助等政府举办的基本医疗保障项目以及城镇职工大病保险、
	城乡居民大病保险等保障项目。
				</div>
				
				<div className='indent bold'>8.12、住院   </div>
				<div className='indent'>
				限于被保险人因疾病或遭到意外伤害，经医生诊断必须留院治疗，并办理正式
	的出入院手续；被保险人必须连续留院二十四小时以上，但住院并不包括门诊观察
	室、急诊观察室、其他非正式病房、联合病房或挂床住院。
				</div>
				<div className='indent'>
				挂床住院指办理正式住院手续的被保险人，在住院期间每日非 24 小时在床、
在院，包括在住院期间连续若干日无任何治疗，只发生护理费、诊疗费、床位费的
情况。
				</div>

				
				<div className='indent bold'>8.13、床位费   </div>
				<div className='indent'>
				指住院期间实际发生的、不高于双人病房的住院床位费（不包括单人病房、套
	房、家庭病床）。
				</div>
				
				<div className='indent bold'>8.14、膳食费   </div>
				<div className='indent'>
				指住院期间根据医生的医嘱，由作为医院内部专属部门的、为住院病人配餐的
	食堂配送的膳食费用。膳食费应包含在医疗账单内；根据各医疗机构的惯例，可以
	作为独立的款项、也可以合并在病房费等其他款项内。
				</div>
				
				<div className='indent bold'>8.15、护理费    </div>
				<div className='indent'>
				指住院期间根据医嘱所示的护理等级确定的护理费用。
				</div>


				<div className='indent bold'>8.16、检查检验费    </div>
				<div className='indent'>
				指实际发生的、以诊断疾病为目的，采取必要的医学手段进行检查及检验而发
	生的合理的医疗费用，包括诊查费、妇检费、X 光费、CT 费、磁共振费、心电图费、
	B 超费、脑电图费、内窥镜费、肺功能仪费、活检费、穿刺费、造影费、分子生化
	检验费和血、尿、便常规检验费等。
				</div>
				
				<div className='indent bold'>8.17、治疗费    </div>
				<div className='indent'>
				以治疗疾病为目的，提供医学手段而发生的技术劳务费、医疗器械使用费及消
	耗的费用，包括注射费、输血费、输氧费、体外反搏费等。
				</div>
				
				<div className='indent bold'>8.18、药品费     </div>
				<div className='indent'>
				指实际发生的合理且必要的、由医生开具的、具有国家药品监督管理部门核发
的药品批准文号或者进口药品注册证书、医药产品注册证书的国产或进口药品的费
用。<span className='bold'>但不包括营养补充类药品，免疫功能调节类药品，美容及减肥类药品，预防
类药品，以及下列中药类药品：</span> 
				</div>
				<div className='indent bold'>
				1.主要起营养滋补作用的单方、复方中药或中成药品，如花旗参，冬虫草，
白糖参，朝鲜红参，玳瑁，蛤蚧，珊瑚，狗宝，红参，琥珀，灵芝，羚羊角尖粉，
马宝，玛瑙，牛黄，麝香，西红花，血竭，燕窝，野山参，移山参，珍珠（粉），
紫河车，阿胶，阿胶珠，血宝胶囊，红桃K口服液，十全大补丸，十全大补膏等； 
				</div>
				<div className='indent bold'>
				2.部分可以入药的动物及动物脏器，如鹿茸，海马，胎盘，鞭，尾，筋，骨
	等； 
				</div>
				<div className='indent bold'>
				3.用中药材和中药饮片炮制的各类酒制剂等。 
				</div>

				
				<div className='indent bold'>8.19、手术费      </div>

				<div className='indent'>
				根据当地卫生行政部门规定的手术项目的费用，包括手术室费、麻醉费、手术
监测费、手术辅助费、材料费、一次性用品费、术中用药费和手术设备费；若因器
官移植而发生的手术费用，<span className='bold'>不包括器官本身的费用和获取器官过程中的费用。 </span>
				</div>
				<div className='indent bold'>
				其中手术限于被保险人因疾病或意外伤害住院后，为治疗疾病、挽救生命而
	施行的手术。 
				</div>
				
				<div className='indent bold'>8.20、救护车使用费       </div>
				<div className='indent bold'>
				指住院期间以抢救生命或治疗疾病为目的，根据医生建议，被保险人需医院转
诊过程中的医院用车费用，<span className='bold'>且救护车的使用仅限于同一城市中的医疗运送。</span>
				</div>

				<div className='indent bold'>8.21、化学疗法        </div>
				<div className='indent'>
				指针对于恶性肿瘤的化学治疗。化疗是使用医学界公认的化疗药物以杀死癌细
	胞、抑制癌细胞生长繁殖为目的而进行的治疗。本合同所指的化疗为被保险人根据
	医嘱，在医院进行的静脉注射化疗。
				</div>
				<div className='indent bold'>8.22、放射疗法        </div>
				<div className='indent'>
				指针对恶性肿瘤的放射治疗。放疗是使用各种不同能量的射线照射肿瘤组织，
	以抑制和杀灭癌细胞为目的而进行的治疗。本合同所指的放疗为被保险人根据医嘱，
	在医院的专门科室进行的放疗。
				</div>
				<div className='indent bold'>8.23、肿瘤免疫疗法        </div>
				<div className='indent'>指应用免疫学原理和方法，使用肿瘤免疫治疗药物提高肿瘤细胞的免疫原性和
对效应细胞杀伤的敏感性，激发和增强机体抗肿瘤免疫应答。本合同所指的肿瘤免
疫治疗药物需符合法律、法规要求并经过国家食品药品监督管理总局批准用于临床
治疗。</div>
				<div className='indent bold'>8.24、肿瘤内分泌疗法         </div>
				<div className='indent'>
				指针对于恶性肿瘤的内分泌疗法，用药物抑制激素生成和激素反应，杀死癌细
胞或抑制癌细胞的生长。本合同所指的内分泌治疗药物需符合法律、法规要求并经
过国家食品药品监督管理总局批准用于临床治疗。
				</div>


				<div className='indent bold'>8.25、肿瘤靶向疗法          </div>
				<div className='indent'>
				指在细胞分子水平上，针对已经明确的致癌点来设计相应的靶向治疗药物，利
	用具有一定特异性的载体，将药物或其他杀伤肿瘤细胞的活性物质选择性地运送到
	肿瘤部位攻击癌细胞的疗法。本合同所指的靶向治疗药物需符合法律、法规要求并
	经过国家食品药品监督管理总局批准用于临床治疗。
				</div>
				
				<div className='indent bold'>8.26、既往症           </div>
				<div className='indent'>
				指在被保险人获得被保资格之前罹患的被保险人已知或应该知道的有关疾病
	或症状。包括但不限于以下情况：
				</div>
				<div className='indent'>
				1.被保险人获得被保资格前，医生已有明确诊断，长期治疗未间断；
				</div>
				<div className='indent'>
				2.被保险人获得被保资格前，医生已有明确诊断，治疗后症状未完全消失，有
间断用药情况；
				</div>
				<div className='indent'>
				3.被保险人获得被保资格前发生，未经医生诊断和治疗，但症状已经显现足以
	促使一般普通谨慎人士引起注意并寻求诊断、治疗或护理的病症。
				</div>
				<div className='indent'>
				投保人在投保时告知保险人，经保险人审核同意并在保险单中单独载明不属于
	既往症的疾病或病症，不属于本保险合同约定的既往症。
				</div>
				

				<div className='indent bold'>8.27、保健           </div>
				<div className='indent'>
				指为保护和增进人体健康、防止发生疾病而所采取的综合性措施。
				</div>

				<div className='indent bold'>8.28、无有效驾驶证            </div>
				<div className='indent'>
				指驾驶人员有下列情形之一者：
				</div>
				<div className='indent'>
				（1）无驾驶证或驾驶证有效期已届满；
				</div>
				<div className='indent'>
				（2）驾驶的机动车与驾驶证载明的准驾车型不符；
				</div>
				<div className='indent'>
				（3）实习期内驾驶公共汽车、营运客车或者载有爆炸物品、易燃易爆化学物
品、剧毒或者放射性等危险物品的机动车，实习期内驾驶机动车牵引挂车；
				</div>
				<div className='indent'>
				（4）持未按约定审验的驾驶证，以及在暂扣、扣留、吊销、注销驾驶证期间
驾驶机动车；
				</div>
				<div className='indent'>
				（5）使用各种专用机械车、特种车的人员无国家有关部门核发的有效操作证，
驾驶营业性客车的驾驶人无国家有关部门核发的有效资格证书；
				</div>
				<div className='indent'>
				（6）依照法律法规或公安机关交通管理部门有关约定不允许驾驶机动车的其
他情况下驾车。
				</div>

				

				<div className='indent bold'>8.29、无有效行驶证            </div>
				<div className='indent'>
				发生保险事故时被保险人驾驶的机动车无公安机关交通管理部门、农机部门等
政府管理部门核发的行驶证或号牌，或行驶证不在有效期内，或该机动车未按规定
检验或检验不合格。 
				</div>

				<div className='indent bold'>8.30、高风险运动             </div>
				<div className='indent'>
				指潜水、滑水、冲浪、赛艇、漂流、跳伞、热气球运动、滑翔机、滑翔翼、滑
翔伞、动力伞或其他高空运动、蹦极、攀岩运动、探险活动或考察活动（洞穴、极
地、沙漠、火山、冰川等等）、武术比赛、摔跤比赛、特技表演及训练、替身表演
及训练、赛马、赛车及保险单载明的其他运动。
				</div>
				<div className='indent'>
				潜水：指以辅助呼吸器材在江、河、湖、海、水库、运河等水域进行的水下运
	动，但穿着救生衣在水面进行的浮潜活动除外。
				</div>
				<div className='indent'>
				热气球运动：指乘热气球升空飞行的体育活动。
				</div>
				<div className='indent'>
				攀岩活动：指攀登悬崖、楼宇外墙、人造悬崖、冰崖、冰山等运动。
				</div>
				<div className='indent'>
				探险活动：指明知在某种特定的自然条件下有失去生命或使身体受到伤害的危
险，而故意使自己置身其中的行为。如江河漂流、非固定路线徒步、徒步穿越沙漠
或人迹罕至的原始森林等活动。
				</div>
				<div className='indent'>
				武术比赛：指两人或两人以上对抗性柔道、空手道、跆拳道、散打、拳击等各
种拳术及各种使用器械的对抗性比赛。
				</div>
				<div className='indent'>
				特技：指从事马术、杂技、驯兽等特殊技能。
				</div>


				<div className='indent bold'>8.31、感染艾滋病病毒或患艾滋病              </div>
				<div className='indent'>
				（1）艾滋病病毒指人类免疫缺陷病毒，英文缩写为 HIV。艾滋病指人类免疫
缺陷病毒引起的获得性免疫缺陷综合征，英文缩写为 AIDS。
				</div>
				<div className='indent'>
				（2）在人体血液或其它样本中检测到艾滋病病毒或其抗体呈阳性，没有出现
临床症状或体征的，为感染艾滋病病毒；如果同时出现了明显临床症状或体征的，
为患艾滋病。
				</div>


				<div className='indent bold'>8.32、职业病              </div>
				<div className='indent'>
				指企业、事业单位和个体经济组织的劳动者在职业活动中，因接触粉尘、放射
性物质和其他有毒、有害物质等因素而引起的疾病。职业病的认定需遵循《中华人
民共和国职业病防治法》中的相关规定及鉴定程序。
				</div>

				<div className='indent bold'>8.33、医疗事故               </div>

				<div className='indent'>
				指医疗机构及其医务人员在医疗活动中，违反医疗卫生管理法律、行政法规、
	部门规章和诊疗护理规范及常规，过失造成患者人身损害的事故。
				</div>
				

				<div className='indent bold'>8.34、保险金申请人               </div>
				<div className='indent'>
				本保险保险金申请人是指被保险人本人。 
				</div>

				<div className='indent bold'>8.35、未满期净保险费               </div>
				<div className='indent'>
				除另有约定外，未满期净保险费=保险费×[1-(保险单已经过天数/保险期间天
数)] ×（1-25%）。经过天数不足一天的按一天计算。
				</div>

			</div>














			
		
				</div>
	}



	return (
		<div style={{ flex: 1, position: 'relative' }}>
			<div style={{position:'fixed',padding:0,height:!!isIphoneX()?'0.88rem':'0.4rem',backgroundColor:'#fff',zIndex:99, top:0,width:'100%'}}></div>
			<Nav title='' style={{border:'none',position:'fixed',padding:0,...{paddingTop:!!isIphoneX()?'0.88rem':'0.4rem'}}} ></Nav>
			{detail()}
		</div>

  )
}
PersonalClause.options = { disableHeader: false, wrapperClassName: '' }
export default PersonalClause
