import React, { useState, useEffect } from 'react'
// import nutrition from 'api/nutrition'
import { Button } from 'antd-mobile'
import './insu-licence.css'
import Nav from 'components/nav/Nav';
import isIphoneX from 'utils/isIphoneX';

/**
 * type ：taikang、renbao, 
 * @param props 
 */

const InsuranceDelegateLicence: PageComponent = props => {
	
	useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
		// console.log('result - :',result)
		window.scrollTo(0, 0)
		// document.body.scrollTo({top:0,left:0})
	}, [])
	
	return (
		<div style={{flex:1,position:'relative',padding:0,...{paddingTop:!!isIphoneX()?'0.88rem':'0.4rem'}}}>
			<Nav title='' style={{position:'fixed',border:'none'}}></Nav>
		<div className='insulic-wrap'>		
				<div className='licenceTitle center bold'>保险经纪委托协议</div>
				<div className='licenceSection'>
				本协议是用户(以下简称“用户”或“您”)与中元保险经纪有限公司（以下简称“中元”或“我们”） 订立的有效约定。我们依据本协议的规定向您提供保险经纪服务，用户必须完全同意和接受本协议，才能够享受我们提供的保险经纪服务。
				</div>
				<div className='licenceSection'>
				本协议条款与您的权益具有重大关系，请您在同意接受本服务前确认，您已经充分阅读、理解并接受本协议的全部内容，特别是免除或者限制责任的条款、管辖与法律适用条款。 您通过网络页面点击“同意”或以其他方式选择接受本协议，即表示您完全同意和接受本协议的全部条款。
				</div>
				<div className='licenceContent'>
					<div className='indent'>1.	您确认，在您使用本保险经纪服务（以下简称 “本服务”）时，您是具有中华人民共和国法律规定的完全民事权利能力和民事行为能力的，能够独立承担民事责任的自然人， 或者是在中国大陆地区合法开展经营活动或其他业务的法人或其他组织。不具备前述条件的，您应立即终止使用本服务。</div>
					<div className='indent'>2.	您同意，委托中元作为您的保险经纪人，授权我们向您提供保险经纪服务，根据您的需求拟定投保方案、选择保险公司、选择保险产品、办理投保手续、代收保险费、协助索赔、为您提供实时的保险咨询和建议等相关服务。</div>
					<div className='indent'>3.	您知晓，您选择使用本服务要求您提供个人信息，您需保证您所提供的所有信息是真实的、合法的、正确的、完整的。本协议所指个人信息是指您使用本服务时需要提供的个人信息， 包括但不限于个人姓名、身份证号码、年龄、性别、职业、家庭成员等。</div>
					<div className='indent'>4.	您确认，在您投保时，应使用您本人的账户进行保险费支付或者由您授权委托的其他人账户进行保险费的支付。</div>
					<div className='indent'>5.	您认可，当您所投保险险种发生退保时，所退保的保险费用将会原路退回保险费支付账户。</div>
					<div className='indent'>6.	您同意，为了提供本服务的技术需要，我们有权采集、获取或在系统内保存您提供的投保人、被保险人、受益人及紧急联系人的姓名、身份证号、健康状况等个人信息。当您提供投保人、被保险人、受益人及紧急联系人的个人信息时，应确保您已经将该行为告知前述信息主体，并取得其明确授权同意。我们将严格确保您提供的个人信息的安全。</div>
					<div className='indent'>7.	您同意，当您使用某些由我们的合作伙伴或关联方提供或我们与合作伙伴或关联方共同提供的服务和（或）产品时，我们会与其共享提供服务和（或）产品所必要的个人信息。我们将努力确保合作伙伴和关联方在使用您的个人信息时采取严格的保密和安全措施。</div>
					<div className='indent'>8.	您承诺，对使用本服务过程中您发出的所有指令的真实性、有效性、合法性承担全部责任，我们依照您的指令进行操作的一切风险由您自行承担。</div>
					<div className='indent'>9.	您认可，在我们有合理理由怀疑您提供的资料错误、不实、过时或不完整的情况下，我们有权暂停或终止向您提供部分或全部本服务，您将承担因此产生的一切责任，我们对此不承担任何责任。</div>
					<div className='indent'>10.	您认可，如果您违反本协议的约定；或违反本网站或我们合作方网站的条款、协议、规则、通告等相关规定，而被终止提供服务的，我们有权暂停或终止向您提供部分或全部本服务，您将承担因此产生的一切责任，我们对此不承担任何责任。</div>
					<div className='indent'>11.	您使用本服务时同意并认可，可能由于保险公司系统问题、相关作业网络连线问题或其他不可抗拒因素，造成本服务无法提供，对此我们不承担任何责任。</div>
					<div className='indent'>12.	您同意，基于运行和交易安全的需要，我们有权暂停提供或者限制本服务部分功能,或提供新的功能，在减少、增加或者变化任何功能时，只要您仍然使用本服务，表示您仍然同意本协议或者变更后的协议。</div>
					<div className='indent'>13.	您同意，根据保险业及互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，我们有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，我们将会直接在本网站上公布修改之后的协议内容，该公布行为视为中元已经通知用户修改内容。如果您不同意中元对本协议相关条款所做的修改，有权并应当停止使用本服务；若您在本协议内容公告变更七日后继续使用本服务的，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用本服务。</div>
					<div className='indent'>14.	您同意，应就您所知的与保险业务有关的事实和信息向保险公司如实告知，并就保险标的或者被保险人的有关情况如实回答保险公司提出的询问，以便保险公司做出是否接受该保险业务的决定。否则保险人有权依法解除合同，对合同解除前发生的保险事故不承担赔偿或者给付保险金的责任，并不退还保险费。</div>
					<div className='indent'>15.	本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例或行业惯例。</div>
					<div className='indent'>16.	本协议产生之争议，双方应当友好协商解决，无法达成一致的，以中元所在地法院为一审管辖法院。</div>
					<div className='indent'>17.	如果您对本协议或本服务有意见或建议，可与中元联系，我们会给予您必要的帮助。</div>
				</div>
				<div className='licenceContent right'>中元保险经纪有限公司</div>
		</div>
		</div>
    // <div className="demo-wrap">
    //   <div className="title">demo1</div>
    //   {/* <div>{JSON.stringify(result)}</div> */}
    //   <Button type="primary" size="small" inline className="btn">
    //     demo1
    //   </Button>
    //   <Button type="primary" size="large" className="btn">
    //     demo2
    //   </Button>
    // </div>
  )
}
// InsuranceSecurePlan.options = { disableHeader: false, wrapperClassName: '' }
export default InsuranceDelegateLicence
