import React, { useState, useEffect } from 'react'
import { Toast } from 'antd-mobile';
// import { openApp } from "../../utils/helper_conference";
import {openApp, openAppNoParam} from "../../utils/helper";
import DownPop from 'components/DownPop';
import { isWeiXin, wxShare, isMobileQQ, qqShare } from 'utils/wxShare'
import './downloadapp.css'
import URI from 'urijs'
const DownLoadAppGuidance: PageComponent =  props => {
    let query: any = URI(window.location.href).query(true);
    const { id } = query
    useEffect(() => {
        var link = ''
        if (id) {
            link = `${window.location.origin}/downloadguide?id=`+ id
        } else {
            link = `${window.location.origin}/downloadguide`
        }
       // 二次分享
        const shareParams = {
            title: '营养e生 您健康生命的呵护者！',
            desc: '针对400余种慢性疾病人群，量身定制居家营养健康管理计划。让您远离病痛折磨，健康长寿',
            link: link,
            imgUrl: ''
        }
        console.log(' - - : ',shareParams,' - : ',id)
        if (isWeiXin()) {
            wxShare(shareParams)
        }
        if (isMobileQQ()) {
            qqShare(shareParams)
        }
        var openParam = {
            router: 'shop',
            passprops: '',
            id: 'kid'
        }
        setOpenAppParams(openParam)
    },[])
    /**
     * 页面点击操作   打开app指定页面 或者 引导下载  oppen app
     */
    const [openAppParams,setOpenAppParams] = useState({})       //   打开app 指定页面 要传给app的参数
    const [downShowPop,setDownShowPop] = useState(false)        //   显示引导弹窗
    const showDownPopHandle = (isShow:boolean) => {
        setDownShowPop(isShow)
    }
    const callback = (val:boolean) => {
        setDownShowPop(val)
    }
    const clickHandle = () => {
        // 埋点
        if (id) {
            (window as any).gio('track', 'download_url', { download_id: id })
        }
        const result = openAppNoParam()
        // const result = openApp(openAppParams)
        if (result) {
            setDownShowPop(true)
        } else {
            setDownShowPop(false)
        }
    }
    return (
        <div className="DownLoadAppGuidance">
            {<DownPop isShow={downShowPop} callback={callback}></DownPop>}
            <div className="cont">
                <img className="bg-img" src={require('./images/bag_shang.png')} alt=""/>
                <img className="bg-img" src={require('./images/bag_zhong.png')} alt=""/>
                <div className="part-down">
                    <img className="bg-img" src={require('./images/bag_xia.png')} alt=""/>
                    <div className="down-btn">
                        <img onClick={clickHandle} src={require('./images/but_lijixiazai.png')} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}
DownLoadAppGuidance.options = { disableHeader: false, wrapperClassName: '' }
export default DownLoadAppGuidance