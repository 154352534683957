import React, { useState, useEffect } from 'react'
// import nutrition from 'api/nutrition'
import { Button } from 'antd-mobile'
import './insu-secu.css'
import Nav from 'components/nav/Nav';
import isIphoneX from 'utils/isIphoneX';

interface ClaimsItem {
	section: string,
	brife?: string[],
	extra?:{q:string,a:string}[]
}
/**
 * type ：taikang、renbao, 
 * @param props 
 */

const InsuranceClaimsToKnown: PageComponent = props => {
	
	useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
		// console.log('result - :',result)
		window.scrollTo(0, 0)
		// document.body.scrollTo({top:0,left:0})
	}, [])
	/**
	 */
	const info:ClaimsItem[] = [
		{ section: '一.24小时内报案（报案电话95518）' },
		{ section: '二.提交材料:', brife: ['1. 保险金给付申请书；', '2. 保险单；', '3. 保险金申请人的身份证明；', '4. 中华人民共和国境内（不包括港、澳、台地区）二级（含）以上公立医院或保险人认可的医疗机构出具的医疗费用收据及明细清单/账、诊断证明、病历、出院小结等；', '5. 对于已经从社会医疗保险、公费医疗或任何第三方包括任何商业医疗保险获得相关医疗费用补偿的，应提供社会医疗保险机构、商业保险机构或其他第三方的医疗费用分割单或医疗费用结算证明；', '6. 被保险人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料。'], }, {
			section:'三.领取理赔'
		}, {
			section: '常见问题：', extra: [{
				q:'•	本产品接受的投保年龄范围？',a:'0至60周岁（含）可投保本产品，60周岁以上只能续保。投保时为0周岁的，应当为出生满28日且已健康出院的婴儿。'
			}, { q: '•	我已经参加了社会医疗保险，是否还有必要购买本产品？', a: '社会医疗保险不仅报销范围受医保目录的限制，而且在赔付比例和赔付上限方面都有限制规定。一旦遇到重特大疾病（如癌症），将需要庞大的医疗费用，且很多是社会医疗保险不能覆盖的自费药和自费项目，患者仍需要自己承担高额医疗费用。而本产品赔付范围广、100%比例赔付，是专门针对社会医疗保险的最好补充。' }, {
				q:'•	社会医疗保险报销的金额是否可用于抵扣免赔额？',a:'不可以。通过社会医疗保险和公费医疗保险获得的补偿，不可用于抵扣免赔额，但通过其他途径已获得的医疗费用补偿可用于抵扣免赔额，如 通过企业为员工投保的企业补充医疗保险报销的金额可用于抵扣免赔额。'
				}, { q: '•	门诊医疗费用是否可以报销？', a: '以下三类门诊医疗费用属于报销范围：（一）特殊门诊医疗费用（门诊肾透析费，恶性肿瘤化学疗法、放射疗法、肿瘤免疫疗法、肿瘤内分泌 疗法、肿瘤靶向疗法的治疗费用，器官移植后的门诊抗排异治疗费）；（二）门诊手术费用；（三）住院前7日（含住院当日）和出院后30日 （含出院当日）内因与住院相同原因发生的门急诊治疗费用。' }, {
				q:'•	确诊恶性肿瘤后，是否不再计算免赔额？',a:'是的，对于自确诊恶性肿瘤之日起新发生的医疗费用，无论是用于治疗恶性肿瘤，还是其他普通疾病，免赔额均为0。'
				}, {
				q:'•	到三甲医院国际医疗部看病是否可以报销？',a:'不可以，本产品保障的范围仅限中华人民共和国境内（不包括香港、澳门和台湾地区）二级或二级以上公立医院或保险人认可的医疗机构的普通部。'
				}, {
				q:'•	是否所有的药品费都可以报销？',a:'虽然赔付的药品范围比较广，但并非所有的药品费都可以报销，例如营养补充类药品、免疫功能调节类药品、美容及减肥类药品、预防 类药品、一些特定的中药类药品以及实验性药物产生的费用均不属于报销的范围，具体范围请参见条款。'
				}, {
				q:'•	续保时的保费是否和首次投保时的相同？',a:'本保险产品的保险期间为一年，期满后投保人可申请续保，续保保费根据被保险人申请续保时的年龄确定。一般来说，由于年龄越小的未成年人及年龄越大的成年人医疗发生率越高，相应的保费也越高。'
				}, {
				q:'•	投保前已患有的疾病是否可以报销？',a:'不可以。本产品不接受带病投保，投保前已患有的疾病及症状均不属于保障范围。'
			}]
		}
	]
	

	const renderItem = (item:ClaimsItem, index:number) =>{
		return <div key={'sec' + index} className='section-title' style={{textAlign:'justify'}}>
			<div className='ahead-section gap-m'>
				{item.section}
			</div>
			{item.brife?item.brife.map((extra) => {
				return <div className='ahead-extra indent'>
					{extra}
				</div>
			}) : null}
			{item.extra?item.extra.map((extra) => {
				return <div >
					<div className='ahead-extra gap-s'>
					{extra.q}
					</div>
					<div className='ahead-subpoints'>
					{extra.a}
					</div>
				</div>
			}) : null}
		</div>
	}

	
	return (
		<div style={{flex:1,position:'relative'}}>
			<Nav title={'理赔须知'} style={{position:'fixed',backgroundColor:'#fff',padding:0,...{paddingTop:!!isIphoneX()?'0.88rem':'0.4rem'}}}></Nav>
		<div className='insu-wrap'>		
				<div className='nav-padding'></div>
				{info.map(renderItem)}
		</div>
		</div>
    // <div className="demo-wrap">
    //   <div className="title">demo1</div>
    //   {/* <div>{JSON.stringify(result)}</div> */}
    //   <Button type="primary" size="small" inline className="btn">
    //     demo1
    //   </Button>
    //   <Button type="primary" size="large" className="btn">
    //     demo2
    //   </Button>
    // </div>
  )
}
// InsuranceSecurePlan.options = { disableHeader: false, wrapperClassName: '' }
export default InsuranceClaimsToKnown
