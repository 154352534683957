import VoiceBandPage from './VoiceBand'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncVoiceBandPage = Loadable({
    loader: () => import('./VoiceBand'),
    loading: MyLoadingComponent
})
// component: VoiceBandPage,
const VoiceBand: Module = {
    name: 'voiceband',
    routes: [
        {
            name: '音频播放详情',
            path: '/voiceband/share/:kid',
            component: asyncVoiceBandPage,
        },
    ],
}
export default VoiceBand
