import DownLoadAppPage from './DownLoadApp';
import DownLoadAppGuidance from './DownLoadAppGuidance';
import ExpectApp from './ExpectApp';
import QrcodeDownLoad from './QrcodeDownLoad';
import YYYSDownloadApp from './YYYSDownloadApp';

const DownLoadApp: Module = {
    name: 'downloadapp',
    routes: [
        {
            name: 'app下载页',
            path: '/downloadapp',
            component: DownLoadAppPage,
        },
        {
            name: '拾恋App运营app下载页',
            path: '/expandapp',
            component: DownLoadAppGuidance,
        },
        {
            name: '2020春节App运营下载页',
            path: '/expectapp',
            component: ExpectApp,
        },
        {
            name: '扫码下载页',
            path: '/qrcodeDownload',
            component: QrcodeDownLoad,
        },
        {
            name: '营养一生介绍下载页',
            path: '/yyys/download',
            component: YYYSDownloadApp,
        },
    ],
}
export default DownLoadApp