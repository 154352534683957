import WechartLoginPage from './WechartLogin'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncWcLogin = Loadable({
  loader: () => import('./WechartLogin'),
  loading: MyLoadingComponent,
})

const WechartLogin: Module = {
  name: 'wechartlogin',
  routes: [
    {
      name: '微信网页登录',
      path: '/wechartlogin',
      component: asyncWcLogin,
    },
  ],
}
export default WechartLogin
