import React, { useState, useEffect } from 'react'
// import nutrition from 'api/nutrition'
import { Button } from 'antd-mobile'
import './insu-secu.css'
import Nav from 'modules/activepage/epidemic/components/nav/Nav';
import isIphoneX from 'utils/isIphoneX';

interface InfoBrif {
	title: string,
	payment: string,
	options:string[]
}
/**
 * type ：taikang、renbao, 
 * @param props 
 */
const pageTaikang = 'taikang'
const pageRenbao = 'renbao'
const InsuranceSecurePlan: PageComponent = props => {
  useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
		// console.log('result - :',result)
		window.scrollTo(0, 0)
		// document.body.scrollTo({top:0,left:0})
	}, [])
  const type = props.match.params.type || pageTaikang
	/**
	 */
	const info:InfoBrif[] = [
		{
			title: '一般医疗保险金',
			payment:'300万',
			options: [
				'因遭受意外伤害事故或在等待期后罹患疾病，在医院经具有相应资质的医生诊断必须接受治疗的，对于被保险人支付的必需且合理的医疗费用，在扣除本合同约定的免赔额后，依照本合同的约定赔偿一般医疗保险金。',
				'包括住院医疗费用、特殊门诊医疗费用、门诊手术医疗费用、住院前后门急诊费用。',
				'免赔额1万元。',
				'购买时有社保：社保赔付后的报销金额=（医疗费用-免赔额1万-已报销部分）*100%，未使用社保的报销金额=（医疗费用-免赔额1万）*60%，购买时无社保：报销金额=（医疗费用-免赔额1万）*100%。']
		}, {
			title: '重大疾病医疗保险金',
			payment: '600万',
			options: [
				'经医院初次确诊因意外伤害导致罹患本合同所定义的重大疾病（无论一种或多种），或者在等待期后经医院初次确诊非因意外伤害导致罹患本合同所定义的重大疾病（无论一种或多种），在医院经具有相应资质的医生诊断并接受相关治疗，对于被保险人支付的以下医疗费用，保险人依照本合同的约定赔偿重大疾病医疗保险金。',
				'包括重大疾病医疗费用、重大疾病特殊门诊医疗费用、重大疾病门诊手术费用、重大疾病住院前后门急诊费用。',
				'无免赔额。',
				'购买时有社保：社保赔付后的报销金额=（医疗费用-已报销部分）*100%，未使用社保的报销金额=医疗费用*60%，购买时无社保：报销金额=医疗费用*100%'
			]
		}, {
			title: '质子重离子医疗保险金',
			payment: '600万',
			options: [
				'等待期后经医院初次确诊罹患主险合同所定义的恶性肿瘤，在特定医疗机构接受质子、重离子放射治疗的，则对被保险人在特定医疗机构支付的合理的质子重离子医疗费，保险人在扣除免赔额后，按照约定的赔偿比例向被保险人赔偿质子重离子医疗保险金，但累计赔偿金额最高不超过本附加合同约定的质子重离子医疗保险金的保险金额。',
				'质子重离子医疗保险责任与重大疾病医疗保险金共用保额，赔付比例为100%。',
				'扩展质子重离子医疗保险责任的医疗机构限定为“上海质子重离子医院”。'
			]
		}, {
			title: '扩展门急诊医疗保险金',
			payment: '1万',
			options:[
				'因遭受意外伤害事故或在等待期后罹患约定的疾病并因该意外伤害事故或疾病在医院经具有相应资质的医生诊断必须接受门（急）诊治疗的，对被保险人在前述医院发生的必需且合理的门（急）诊医疗费用，在扣除约定的免赔额后，按照约定的赔偿比例进行赔付。',
				'如被保险人因合同约定的99种重疾出险，无免赔额；如被保险人因99种重疾以外的原因出险，本附加保险责任与主险的一般医疗保险金共用1万免赔额；本附加保险责任的赔偿比例同主险。'
			]
		}
	]

	const infoRR:string[] = [
		'（一）保障额度高，一般医疗200万元，恶性肿瘤医疗再加200万元，合计400万元；',
		'（二）保费价格低，最低每天只需0.37元（16-20岁有社保人群），即可尊享400万元保障；',
		'（三）报销范围广，住院医疗、特殊门诊、门诊手术、住院前后门急诊、恶性肿瘤、进口药、自费药和靶向药、救护车费用、护理费、重症监护室费用、住院诊疗费用、住院检查检验费用、床位费用、住院膳食费用均可报销；',
		'（四）续保能力强，不会因为产生理赔而拒绝续保或调整承保费率，公司整体实力强，中国人民保险公司成立于1949年10月20日，与共和国同龄，是国有保险企业, 国内第一，亚洲最大的财产保险公司；',
		'（五）投保手续简，新保无须体检，仅需填写健康问询表；智能续保，续保时无须健康问询；',
		'（六）附加服务优，购买此产品可享受网‎上​无限​次​数‎健​康咨​询，一次​国内专​家‎2次​诊断、一次‎导​医‎导‎诊‎️开通​住​院​费‎用垫‎付​功​能​、心理咨询服务、单‎次住院​费‎用50万以‎内均可​享​受‎垫​付服务‎。',
		'（七）服务再升级，针对新冠病毒引起的肺炎，取消30天的等待期和1万元的免赔额，与全国共同抗击疫情'
	]


	const renderRRItem = (point:string, index:number) =>{
		return <div key={'sec' + index} className='section-list-item' style={{textAlign:'justify'}}>
			{point}
		</div>
	}

	const renderItem = (item:InfoBrif,index:number) => {
		return <div key={'sec'+index}>
			<div className='section-title' style={{ fontWeight: 500 }}>
				<span>{item.title}</span>
				<span className='section-title-mount'>{item.payment}</span></div>
			<div className='section-list'>
				<ol>
					{item.options.map((it, iex) => {
						return <li key={index+'li'+iex} className='section-list-item' style={{ textAlign: 'justify' }}>
							<span>{iex+1}、</span>{it}
						</li>
					})}
				</ol>
			</div>
		</div>
	}


	const renderBody = () => {
		if (type == pageTaikang) {
			return info.map(renderItem)
		} else {
			return infoRR.map(renderRRItem)
		}
	}

	return (
		<div style={{flex:1,position:'relative',fontSize:'0.3rem'}}>
			<Nav title={type == pageTaikang?'保障计划':'保障详情'} style={{position:'fixed',border:'none',backgroundColor:'#fff',padding:0,...{paddingTop:!!isIphoneX()?'0.88rem':'0.4rem'}}}></Nav>
		<div className='insu-wrap'>		
				<div className='nav-padding'></div>
				{renderBody()}
			
		</div>
		</div>
    // <div className="demo-wrap">
    //   <div className="title">demo1</div>
    //   {/* <div>{JSON.stringify(result)}</div> */}
    //   <Button type="primary" size="small" inline className="btn">
    //     demo1
    //   </Button>
    //   <Button type="primary" size="large" className="btn">
    //     demo2
    //   </Button>
    // </div>
  )
}
// InsuranceSecurePlan.options = { disableHeader: false, wrapperClassName: '' }
export default InsuranceSecurePlan
