import perfectureInfoPage from './prefectureinfo'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncPrefectureInfoPage = Loadable({
    loader: () => import('./prefectureinfo'),
    loading: MyLoadingComponent
})
// component: CourseDetailPage,

const PrefectureInfo: Module = {
    name: 'perfectureInfo',
    routes: [
        {
            name: '专区详情',
            path: '/perfectureInfo/share/:kid/:sourceKid',
            component: asyncPrefectureInfoPage,
        },
    ],
}
export default PrefectureInfo