import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncHaishuidao = Loadable({
	loader: () => import('./Tuntunmai'),
	loading: MyLoadingComponent
})

const Tuntunmai: Module = {
	name: 'Haishuidao',
	routes: [
		{
			name: '屯屯麦',
			path: '/mall/activepage/tuntunmai/:kid',
			component: asyncHaishuidao,
		},
	],
}
export default Tuntunmai
