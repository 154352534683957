import TeamInfoPage from './TeamInfo'
import TopicPage from './topic'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncTeamInfoPage = Loadable({
	loader: () => import('./TeamInfo'),
	loading: MyLoadingComponent
})
const asyncTopicPage = Loadable({
	loader: () => import('./topic'),
	loading: MyLoadingComponent
})
// component: TeamInfoPage,
const LiveStream: Module = {
	name: 'teamindex',
	routes: [
		{
			name: '小组详情页',
			path: '/groupdetail/share/:kid',
			component: asyncTeamInfoPage,
		},
		{
			name: '话题列表',
			path: '/topic/share/:kid',
			component: asyncTopicPage,
		},
	],
}
export default LiveStream
