import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncHaishuidao = Loadable({
	loader: () => import('./YongKang'),
	loading: MyLoadingComponent
})

const YongKang: Module = {
	name: 'YongKang',
	routes: [
		{
			name: '永康',
			path: '/mall/activepage/yongkang/:kid',
			component: asyncHaishuidao,
		},
	],
}
export default YongKang
