import Share from './Share'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'
import ShareWe from './Share-we'
import ProductDetail from './ProductDetail'

const asyncShare = Loadable({
  loader: () => import('./Share'),
  loading: MyLoadingComponent,
})
const asyncShareWe = Loadable({
  loader: () => import('./Share-we'),
  loading: MyLoadingComponent,
})
const asyncShareWePreview = Loadable({
  loader: () => import('./Share-we-preview'),
  loading: MyLoadingComponent,
})
// component: Share,
const goodModule: Module = {
  name: 'good',
  routes: [
    {
      name: '商品分享',
      path: '/good/share/:kid',//app分享非微信打开
      component: asyncShare,
		},
		{
      name: '商品分享',//商户后台分享
      path: '/good/sharepreview/:kid',
      component: asyncShare,
		},
		{
      name: '商品分享',//暂未知
      path: '/good/sharepreviewadmin/:kid',
      component: asyncShare,
    },
    {
      name: '商品预售',//app分享微信打开
      path: '/good/pay/:kid',
      component: asyncShareWe,
    },
    {
      name: '商品预售预览',
      path: '/good/presell/:kid',
      component: asyncShareWePreview,
    },
    {
      name: '商品重构',
      path: '/product/:kid',
      component: ProductDetail,
    },
  ],
}
export default goodModule
