import MessagePreviewShare from './MessagePreview'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncMessagePreviewShare = Loadable({
    loader: () => import('./MessagePreview'),
    loading: MyLoadingComponent
})
// component: MessagePreviewShare,
const messageModule: Module = {
    name: 'messagepreview',
    routes: [
        {
            name: '资讯预览',
            path: '/messagepreview/component',
            component: asyncMessagePreviewShare,
        }
    ],
}
export default messageModule