import NutritionDemo from './NutritionDemo'
import Loadable from 'react-loadable'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'

const NutritionDemoPage = Loadable({
  loader: () => import('./NutritionDemo'),
  loading: MyLoadingComponent,
})
const nutritionModule: Module = {
  name: 'nutrition',
  routes: [
    {
      name: '示例',
      path: '/nutrition/nutritionDemo',
      component: NutritionDemoPage,
    },
  ],
}
export default nutritionModule
