import React from 'react'
import { Icon } from 'antd-mobile'
import InlineItem, { InlineItemProps } from 'modules/mall/components/InlineItem'

const InlineItemLink: React.FC<InlineItemProps & { title: string; rightStyle?: React.CSSProperties }> = ({
  rightStyle = { justifyContent: 'space-between', marginLeft: '0.2rem' },
  ...props
}) => {
  return (
    <InlineItem {...props} rightStyle={rightStyle} titleStyle={{ width: '1.2rem' }}>
      <div>{props.children}</div>
    </InlineItem>
  )
}
export default InlineItemLink
