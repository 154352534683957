import MessageDetailShare from './MessageDetailShare'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncMessageDetailShare = Loadable({
    loader: () => import('./MessageDetailShare'),
    loading: MyLoadingComponent
})
// component: MessageDetailShare,
const messageModule: Module = {
    name: 'message',
    routes: [
        {
            name: '资讯详情',
            path: '/messagedetailshare/component',
            component: asyncMessageDetailShare,
        }
    ],
}
export default messageModule