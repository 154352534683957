import ThreeReducePage from './ThreeReduce'

const ThreeReduce: Module = {
    name: 'threereduce',
    routes: [
        {
            name: '三减三健',
            path: '/threereduce/share',
            component: ThreeReducePage,
        },
    ],
}
export default ThreeReduce