import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'
const asyncEpidemic = Loadable({
    loader: () => import('./epidemic'),
    loading: MyLoadingComponent
})
const ReceiverList = Loadable({
    loader: () => import('./ReceiverList'),
    loading: MyLoadingComponent
})
const EditReceiver = Loadable({
    loader: () => import('./EditReceiver'),
    loading: MyLoadingComponent
})
const OrderConfirm = Loadable({
    loader: () => import('./orderConfirm'),
    loading: MyLoadingComponent
})
const LectureList = Loadable({
    loader: () => import('./lectureList'),
    loading: MyLoadingComponent
})
const ProductDetail = Loadable({
    loader: () => import('./ProductDetail'),
    loading: MyLoadingComponent
})
const ProductList = Loadable({
    loader: () => import('./productList'),
    loading: MyLoadingComponent
})
const FoodList = Loadable({
    loader: () => import('./foodList'),
    loading: MyLoadingComponent
})
const MessageList = Loadable({
    loader: () => import('./messageList'),
    loading: MyLoadingComponent
})
const testDataList = Loadable({
    loader: () => import('./testDataList'),
    loading: MyLoadingComponent
})
const Epidemic: Module = {
    name: 'epidemic',
    routes: [
        {
            name: '2020抗击疫情活动',
            path: '/epidemic',
            component: asyncEpidemic,
        },
        {
            name: '订单确认页',
            path: '/epidemic/order-confirm',
            component: OrderConfirm,
        },
        {
            name: '收货人列表',
            path: '/epidemic/receiver-list',
            component: ReceiverList,
        },
        {
            name: '编辑收货人',
            path: '/epidemic/edit-receiver',
            component: EditReceiver,
        },
        {
            name: '课程列表',
            path: '/epidemic/lecture-list',
            component: LectureList,
        },
        {
            name: '商品详情页',
            path: '/epidemic/product-detail',
            component: ProductDetail,
        },
        {
            name: '商品列表页',
            path: '/epidemic/product-list',
            component: ProductList,
        },
        {
            name: '烹饪列表页',
            path: '/epidemic/food-list',
            component: FoodList,
        },
        {
            name: '资讯列表页',
            path: '/epidemic/message-list',
            component: MessageList,
        },
        // {
        //     name: '测试列表页',
        //     path: '/epidemic/testData-list',
        //     component: testDataList,
        // },
    ],
}
export default Epidemic
