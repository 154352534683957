import React, { useState, useCallback, useEffect, useImperativeHandle, forwardRef } from 'react'
import './header.css'
import assets from 'assets'
import { openApp } from 'utils/helper'
import DownPop from 'components/DownPop'

interface HeaderProps { 
	openAppParam?: {}
	handleData?: (isShow?: boolean) => void
}

const Header: React.FC<HeaderProps> = (props: HeaderProps, ref) => {
  const [downShowPop,setDownShowPop] = useState(false)
  const [openAppParams,setOpenAppParam] = useState({})
    useEffect(() => {
        console.log('header get params - : ',props)
        setOpenAppParam(props.openAppParam)
		}, [])

	const clickHandle = (params?: {}) => {
		let result = openApp(params || openAppParams)
    // setDownShowPop(false)
      console.log( - - 'Header - :',result)
    	setDownShowPop(result)
    
  }
  const callback = (val:boolean) => {
    setDownShowPop(val)
	};
	
	useEffect(() => {
			// console.log('article - - downShowPop - : ',downShowPop)
			if (downShowPop) {
					document.body.style.overflow = 'hidden'
			} else {
					document.body.style.overflow = 'auto'
			}
	}, [downShowPop])

	useImperativeHandle(ref, () => { 
		return {
			openApp: clickHandle
		}
	})
	
  return (
    <div className="header-wrap">
        {downShowPop === true ? <DownPop isShow={downShowPop} callback={callback}></DownPop> : ''}
      <div className="leftName">
        <img className="logo" src={assets.common.np_logo} alt="" />
        <span className="appName">营养e生</span>
      </div>
      <div className="rightPart">
        <span className="openBtn" onClick={() => clickHandle()}>
          打开
        </span>
      </div>
		</div>
		
  )
}

export default forwardRef(Header)
