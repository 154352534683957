import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncDieticianPage = Loadable({
    loader: () => import('./Dietician'),
    loading: MyLoadingComponent
})
const asyncschemesHeadScreenPage = Loadable({
	loader: () => import('./SchemesHead'),
	loading: MyLoadingComponent
})
const asyncMemberCartScreenPage = Loadable({
	loader: () => import('./MemberCart'),
	loading: MyLoadingComponent
})
const Dietician: Module = {
    name: 'dietician',
    routes: [
        {
            name: '营养师首页',
            path: '/dietician/share/:kid',
            component: asyncDieticianPage,
			},
			{
				name: '前言',
				path: '/dietician/schemesHead/:kid',
				component: asyncschemesHeadScreenPage,
		},
			{
				name: '会员直通车',
				path: '/dietician/memberCart',
				component: asyncMemberCartScreenPage,
		}
    ],
}
export default Dietician
