import React, { useState, useEffect } from 'react'
// import nutrition from 'api/nutrition'
import { Button } from 'antd-mobile'
import './insu-fee.css'
import Nav from 'components/nav/Nav';
import isIphoneX from 'utils/isIphoneX';

interface InfoBrifFee {
	section: string,
	social: { pertime: number, total: number },
	noSocial:{pertime:number, total:number}
}
interface InfoRRBrifFee {
	section: string,
	socialPrice: number,
	noSocialPrice: number,
	index:number
}

const pageTaikang = 'taikang'
const pageRenbao = 'renbao'

const InsuranceFeeRate: PageComponent = props => {
  // const [result, setSesult] = useState({})
  // useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
    // console.log('result - :',result)
	// }, [])
	useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
		// console.log('result - :',result)
		window.scrollTo(0, 0)
		// document.body.scrollTo({top:0,left:0})
	}, [])

	const type = props.match.params.type || pageTaikang
	const index = props.match.params.index || '0'


	/**
	 */
	const info:InfoBrifFee[] = [
		{ section:'0-4', social: { pertime: 71.71, total: 788 }, noSocial: { pertime: 149.06, total: 1638 } },
		{ section:'5-10', social: { pertime: 27.03, total: 297 }, noSocial: { pertime: 54.05, total: 594 } },
		{ section:'11-15', social: { pertime: 13.20 , total: 145 }, noSocial: { pertime: 28.85, total: 317 } },
		{ section:'16-20', social: { pertime: 16.74, total: 184 }, noSocial: { pertime: 36.04 , total: 396 } },
		{ section:'21-25', social: { pertime: 22.20, total: 244 }, noSocial: { pertime: 49.32, total: 542 } },
		{ section:'26-30', social: { pertime: 27.66, total: 304 }, noSocial: { pertime: 63.70, total: 700 } },
		{ section:'31-35', social: { pertime: 35.85, total: 394 }, noSocial: { pertime:  84.54 , total: 929 } },
		{ section:'36-40', social: { pertime: 44.77, total: 492 }, noSocial: { pertime: 120.30 , total: 1322 } },
		{ section:'41-45', social: { pertime: 57.79, total: 635 }, noSocial: { pertime: 200.93   , total:2208 } },
		{ section:'46-50', social: { pertime: 89.82 , total: 987 }, noSocial: { pertime: 281.28, total: 3091 } },
		{ section:'51-55', social: { pertime: 111.11, total: 1221 }, noSocial: { pertime: 376.74, total: 4140 } },
		{ section:'56-60', social: {pertime:148.79, total:1635}, noSocial: {pertime: 501.05 ,total:5506}},
	]

	const infoRR = [
		{ section: '0-4', socialPrice: 778, noSocialPrice: 1716, index: 0 },
		{ section: '5-10', socialPrice: 388, noSocialPrice: 796, index: 0 },
		{ section: '11-15', socialPrice: 230, noSocialPrice: 426, index: 0 },
		{ section: '16-20', socialPrice: 136, noSocialPrice: 228, index: 0 },
		{ section: '21-25', socialPrice: 228, noSocialPrice: 478, index: 0 },
		{ section: '26-30', socialPrice: 290, noSocialPrice: 648, index: 0 },
		{ section:'31-35', socialPrice: 386, noSocialPrice:898,index:0  },
		{ section:'36-40', socialPrice: 480, noSocialPrice:1226,index:0  },
		{ section: '41-45', socialPrice: 578, noSocialPrice: 1738, index: 0 },
		{ section: '46-50', socialPrice: 898, noSocialPrice: 2528, index: 0 },
		{ section: '51-55', socialPrice: 1116, noSocialPrice: 3680, index: 0 },
		{ section: '56-60', socialPrice: 1490, noSocialPrice: 4618, index: 0 },
		{ section: '0-4', socialPrice: 796, noSocialPrice: 1780, index: 1 },
		{ section: '5-10', socialPrice: 396, noSocialPrice: 798, index: 1 },
		{ section: '11-15', socialPrice: 236, noSocialPrice: 436, index: 1 },
		{ section: '16-20', socialPrice: 136, noSocialPrice: 236, index: 1 },
		{ section: '21-25', socialPrice: 238, noSocialPrice: 496, index: 1 },
		{ section: '26-30', socialPrice: 298, noSocialPrice: 658, index: 1 },
		{ section:'31-35', socialPrice: 406, noSocialPrice:908,index:1  },
		{ section:'36-40', socialPrice: 506, noSocialPrice:1206,index:1  },
		{ section: '41-45', socialPrice: 636, noSocialPrice: 1836, index: 1 },
		{ section: '46-50', socialPrice: 988, noSocialPrice: 2618, index: 1 },
		{ section: '51-55', socialPrice: 1258, noSocialPrice: 3906, index: 1 },
		{ section: '56-60', socialPrice: 1608, noSocialPrice: 4990, index: 1 },
		{ section: '61-65', socialPrice: 2165, noSocialPrice: 6711, index: 1 },
		{ section: '0-4', socialPrice: 488, noSocialPrice: 1078, index: 2 },
		{ section: '5-10', socialPrice: 246, noSocialPrice: 498, index: 2 },
		{ section: '11-15', socialPrice: 148, noSocialPrice: 268, index: 2 },
		{ section: '16-20', socialPrice: 86, noSocialPrice: 146, index: 2 },
		{ section: '21-25', socialPrice: 146, noSocialPrice: 298, index: 2 },
		{ section: '26-30', socialPrice: 186, noSocialPrice: 406, index: 2 },
		{ section: '31-35', socialPrice: 256, noSocialPrice: 576, index: 2 },
		{ section:'36-40', socialPrice: 316, noSocialPrice:728,index:2  },
		{ section:'41-45', socialPrice: 406, noSocialPrice:1126,index:2  },
		{ section: '46-50', socialPrice: 598, noSocialPrice: 1586, index: 2 },
		{ section: '51-55', socialPrice: 766, noSocialPrice: 2366, index: 2 },
		{ section: '56-60', socialPrice: 976, noSocialPrice: 3018, index: 2 },
		{ section:'61-65', socialPrice: 1308, noSocialPrice:4058,index:2  },
	]

	const [hasSocial, setSocial] = useState(true)


	const renderTab = () => {
		return <div style={{display:'flex', flexDirection: "row" }}>
			<div className={hasSocial ? 'fee-tab fee-tab-selected' : 'fee-tab'}
				onClick={() => { setSocial(true) }}>有社保新业务保费（元）</div>
			<div className={!hasSocial ? 'fee-tab fee-tab-selected' : 'fee-tab'}
				onClick={() => { setSocial(false) }}>无社保新业务保费（元）</div>
		</div>
	}

	const renderRRItem = (item:InfoRRBrifFee, index:number) => {
		return <div className={index%2==0?'fee-row-l':''} key={'sec'+index} style={{display:'flex', flexDirection:"row"}}>
			<span className='fee-row'>{item.section}</span>
				<span className='fee-row'>{item.socialPrice}</span>
				<span className='fee-row'>{item.noSocialPrice}</span>
		</div>
	}

	const renderColumnHead = () => {
		// return <div className='fee-head'></div>
		return <div style={{display:'flex', flexDirection:"row"}}>
			<div className='fee-head'>
				<span>年龄</span>
			</div>
			<div className='fee-head-m'>
				<span>{type == pageTaikang?'分期缴费':'有社保'}</span>
				<span className='fee-head-sub'>（1-12期）</span>
			</div>
			<div className='fee-head'>
			<span>{type == pageTaikang?'年缴':'无社保'}</span>
			</div>
		</div>
	}

	const renderItem = (item:InfoBrifFee,index:number) => {
		return <div className={index%2==0?'fee-row-l':''} key={'sec'+index} style={{display:'flex', flexDirection:"row"}}>
			<span className='fee-row'>{item.section}</span>
				<span className='fee-row'>{hasSocial? item.social.pertime:item.noSocial.pertime}</span>
				<span className='fee-row'>{hasSocial? item.social.total:item.noSocial.total}</span>
		</div>
	}


	const renderBody = () => {
		if (type == pageTaikang) {
			return <div>
				{renderTab()}
			{renderColumnHead()}
			{info.map(renderItem)}
			</div>
		} else {
			return <div>
				{renderColumnHead()}
				{infoRR.filter(item=>item.index == index).map(renderRRItem)}
			</div>
		}
	}

	return (

		<div style={{flex:1,position:'relative',display:'flex',flexDirection:'column'}}>
			<Nav title='费率表' style={{position:"fixed",border:'none',backgroundColor:'#fff',padding:0,...{paddingTop:!!isIphoneX()?'0.88rem':'0.4rem'}}}></Nav>
			
			<div className='insufee-wrap'>
			<div className='nav-padding'></div>
				{renderBody()}
			</div>
			
		</div>

		
    // <div className="demo-wrap">
    //   <div className="title">demo1</div>
    //   {/* <div>{JSON.stringify(result)}</div> */}
    //   <Button type="primary" size="small" inline className="btn">
    //     demo1
    //   </Button>
    //   <Button type="primary" size="large" className="btn">
    //     demo2
    //   </Button>
    // </div>
  )
}
// InsuranceSecurePlan.options = { disableHeader: false, wrapperClassName: '' }
export default InsuranceFeeRate
