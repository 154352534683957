import Loadable from 'react-loadable'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import LiveStreamPage from './LiveStream'

const asyncLiveStreamPage = Loadable({
    loader: () => import('./LiveStream'),
    loading: MyLoadingComponent
})
// component: LiveStreamPage,
const LiveStream: Module = {
    name: 'livestream',
    routes: [
        {
            name: '直播详情',
            path: '/livestream/share/:kid',
            component: asyncLiveStreamPage,
        },
    ],
}
export default LiveStream
