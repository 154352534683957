import GroupDetailPage from './GroupDetail'
// teaminfo/share
const GroupDetail: Module = {
    name: 'groupdetail',
    routes: [
        {
            name: '直播详情',
            path: '/teaminfo/share/:kid',
            component: GroupDetailPage,
        },
    ],
}
export default GroupDetail