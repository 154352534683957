import React, { useState, useEffect } from 'react'
// import nutrition from 'api/nutrition'
import { Button, Icon } from 'antd-mobile'
import './insu-licence.css'
import Nav from 'components/nav/Nav';
import Pdf from '@mikecousins/react-pdf';


const InsurancePDFViewer: PageComponent = props => {
	// const [result, setSesult] = useState({})
	const [page, setPage] = useState(1);
  useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
    // console.log('result - :',result)
	}, [])

	const querys = props.location.search
	const getQuery = (key: string) => {
		if (querys.indexOf(key) < 0) {
			return ''
		}
		let resArr = querys.replace('?', '').split('&')
		let target = resArr.filter((res:string) => {
			let r = res.split("=")
			return r[0] == key
		}).map((res:string) => {
			let r = res.split("=")
			return r[1]
		})
		if (target.length <= 0) {
			return ''
		}
		return target[0]
	}
	
	return (
		<Pdf file={getQuery('file')} page={page} />
		// <Pdf file={getQuery('file')} page={page}>
    //   {({ pdfDocument, pdfPage, canvas }) => (
    //     <>
    //       {!pdfDocument && <span>Loading...</span>}
    //       {canvas}
    //       {Boolean(pdfDocument && pdfDocument.numPages) && (
    //         <nav>
    //           <ul className="pager">
    //             <li className="previous">
    //               <button
    //                 disabled={page === 1}
    //                 onClick={() => setPage(page - 1)}
    //               >
    //                 Previous
    //               </button>
    //             </li>
    //             <li className="next">
    //               <button
    //                 disabled={page === pdfDocument.numPages}
    //                 onClick={() => setPage(page + 1)}
    //               >
    //                 Next
    //               </button>
    //             </li>
    //           </ul>
    //         </nav>
    //       )}
    //     </>
    //   )}
    // </Pdf>
  )
}
// InsuranceSecurePlan.options = { disableHeader: false, wrapperClassName: '' }
export default InsurancePDFViewer
