/**
 * 根据以分为单位的价格，保留两位小数
 * 容错处理：假如服务的返回的以分为单位的价格大于0小于1分，例如0.8=> 8厘，处理成整数1分，支付宝微信最小支付单位是分
 * @param price
 */
export function formPrice(price: number) {
	if (!price && price !== 0) return 0
	let finalPrice = price >0 && price <1 ? 1 : price
  if (!finalPrice && finalPrice !== 0) return 0
  return (finalPrice / 100).toFixed(2)
}
export function getIntPrice(price: number) {
	if (!price) return 0
	let finalPrice = price >0 && price <1 ? 1 : price
  let priceArr = (finalPrice / 100).toFixed(2).split('.')
  return priceArr[0]
}
export function getDecimalPrice(price: number) {
	if (!price) return 0
	let finalPrice = price >0 && price <1 ? 1 : price
  let priceArr = (finalPrice / 100).toFixed(2).split('.')
  return priceArr[1]
}
