import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'
const asyncAttract = Loadable({
	loader: () => import('./Attract818'),
	loading: MyLoadingComponent
})
const ChildrenFood: Module = {
	name: 'attract',
	routes: [
		{
			name: '招商活动页818',
			path: '/attract',
			component: asyncAttract,
		},
	],
}
export default ChildrenFood
