import React, { useState, useEffect } from 'react'
// import nutrition from 'api/nutrition'
import { Button } from 'antd-mobile'
import './insu-secu.css'
import Nav from 'components/nav/Nav';
import isIphoneX from 'utils/isIphoneX';
import yryz from 'utils/reactNativeMessage';

interface InfoBrif {
	section: string,
	points:{brife:string, extra?:string, subPoints?:string[], append?:string}[]
}

interface InfoBrifRR {
	section: string,
	extra: string[],
	asFamilly?: boolean,
}

const pageTaikang = 'taikang'
const pageRenbao = 'renbao'
const InsuranceKnownAhead: PageComponent = props => {
	// const [result, setSesult] = useState({})
	const type = props.match.params.type || pageTaikang
  // useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
    // console.log('result - :',result)
	// }, [])
	/**
	 */
	useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
		// console.log('result - :',result)
		window.scrollTo(0, 0)
		// document.body.scrollTo({top:0,left:0})
	}, [])
  yryz.useBackHander(() => {
    return true
  })
	const infoRR:InfoBrifRR[] = [
		{
			section:'一.被保险人的投保年龄：',extra:['首次投保时年龄须为出生满28天至60周岁；61至80周岁的，只能申请续保。'],
		},
		{ section: '二.如实告知：', extra: ['投保时，投保人应如实填写投保信息，并就《健康问卷》中的问题据实告知,否则保险人有权根据《中华人民共和国保险法》第十六条的规定解除保险合同且不承担赔偿责任。', '续保时无需再次接受健康问卷问询，其续保对应首张保险合同的健康告知适用续保合同。'] }, {
			section:'三.等待期：', extra:['投保本保险合同,被保险人罹患疾病的等待期为30天，自获得被保资格之日起计算。', '投保本保险合同,因意外伤害导致的医疗无等待期。', '特别提示：在获得被保资格之日起120天内接受扁桃体腺、甲状腺、疝气、女性生殖系统疾病的检查与治疗，保险人不承担保险责任。']
		}, {
			section:'四. 免赔额：', extra:['本产品免赔额为1万元/年，被保险人通过社会医疗保险和公费医疗保险获得的补偿，不可用于抵扣免赔额，但从其他途径已获得的医疗费用补偿可用于抵扣免赔额。', '被保险人确诊恶性肿瘤且符合保险责任的，对于自确诊恶性肿瘤之日起所发生的医疗费用，保险人在计算保险金时免赔额调整为0。', '注：本保险所指社会医疗保险包括新农合、城镇职工基本医疗保险、城镇居民基本医疗保险、医疗救助等政府举办的基本医疗保障项目以及城镇职工大病保险、城乡居民大病保险等保障项目。']
		}, {
			section:'五. 赔付比例：',extra:['本保险的赔付比例为100%。', '若被保险人以参加社会医疗保险身份投保，但未以参加社会医疗保险身份就诊并结算的，赔付比例调整为60%。']
		}, {
			section:'六．就诊医院：',extra:['中华人民共和国境内（不包括香港、澳门和台湾地区）二级或二级以上公立医院的普通部，不包括上述医院或医疗机构的家庭病床（房）、特需医疗、外宾医疗、国际部、干部病房、联合病房、国际医疗中心、VIP病房，以及其他不属于社会医疗保险范畴的门诊、急诊、病房、住院部。']
		}, {
			section:'七．续保：',extra:['本保险合同为非保证续保合同，若发生下列情形之一的，不再接受续保申请：','本产品已停售；','续保时被保险人的年龄超过80周岁；','被保险人身故；','投保人对于本公司就投保人或者被保险人的有关情况提出的询问未履行如实告知义务，足以影响本公司决定是否同意承保，本公司已经解除保险合同的；','本保险合同因其他条款所列情况而导致效力终止。']
		}, {
			section:'八. 投保份数：',extra:['每一被保险人限投保一份，同一被保险人在同一保险期间内仅可有一张生效保单。']
		}, {
			section:'九. 解除合同：',extra:['在保险期间内，投保人可申请解除合同，但解除合同将会遭受一定的损失。解除合同时，保险人按如下公式计算退保金：', '退保金=保险费×[1-(保险单已经过天数/保险期间天数)] ×75%','若被保险人已经出险理赔的，投保人不得解除保险合同。']
		}, {
			section:'十. 本保险适用条款：',extra:['《中国人民财产保险股份有限公司人人安康医疗保险条款》（注册号：C00000232512018053104441）。请您投保前仔细阅读保险条款，并特别注意条款中的保险责任、责任免除、投保人被保险人义务、保险金申请与给付等内容。']
		}, {
			section:'一. 所有被保险人的投保人群范围：',extra:['本产品仅适用于所有被保险人均为新保客户或既往已投保个人版产品且无理赔记录的续保客户，保单仍在有效期且无理赔记录的续保客 户保单终止日期减当前日期需小于90天。'], asFamilly:true
		}, {
			section:'二. 被保险人的投保年龄：',extra:['首次投保时年龄须为出生满28天至60周岁；61至80周岁的，只能申请续保。'], asFamilly:true
		}, {
			section:'三. 如实告知：',extra:['投保时，投保人应如实填写投保信息，并就《健康问卷》中的问题据实告知,否则保险人有权根据《中华人民共和国保险法》第十六条的规定解除保险合同且不承担赔偿责任。被保险人续保时无需再次接受健康问卷问询，其续保对应首张保险合同的健康告知适用续保合同。'], asFamilly:true
		}, {
			section:'四. 等待期：',extra:['投保本保险合同,被保险人罹患疾病的等待期为30天，自获得被保资格之日起计算。','投保本保险合同,因意外伤害导致的医疗无等待期。','特别提示：在获得被保资格之日起120天内接受扁桃体腺、甲状腺、疝气、女性生殖系统疾病的检查与治疗，保险人不承担保险责任。'], asFamilly:true
		}, {
			section:'五. 免赔额：',extra:['本产品免赔额为家庭免赔额，根据所选方案分为1万元/年和2万元/年两种，被保险人通过社会基本医疗保险、公费医疗和大病保险获得的补偿，不可用于抵扣免赔额，但从其他途径已获得的医疗费用补偿可用于抵扣免赔额。被保险人确诊恶性肿瘤且符合保险责任的，对于该被保险人自确诊恶性肿瘤之日起所发生的医疗费用，保险人在计算保险金时免赔额调整为0。','注：本保险所指社会基本医疗保险指《社会保险法》第三章规定的基本医疗保险，包括职工基本医疗保险、城镇居民基本医疗保险、新型农村合作医疗和城乡居民基本医疗保险等政府举办的基本医疗保险。'], asFamilly:true
		}, {
			section:'六赔付比例：', extra:['本保险的赔付比例为100%。','若被保险人以参加社会医疗保险身份投保，但未以参加社会医疗保险身份就诊并结算的，赔付比例调整为60%。'], asFamilly:true
		}, {
			section:'七. 就诊医院：', extra:['中华人民共和国境内（不包括香港、澳门和台湾地区）二级或二级以上公立医院的普通部，不包括上述医院或医疗机构的家庭病床（房）、特需医疗、外宾医疗、国际部、干部病房、联合病房、国际医疗中心、VIP病房，以及其他不属于社会医疗保险范畴的门诊、急诊、病房、住院部。'], asFamilly:true
		}, {
			section:'八. 续保：',extra:['本保险合同为非保证续保合同，若发生下列情形之一的，不再接受续保申请：','本产品已停售；','续保时被保险人的年龄超过80周岁；','被保险人身故；','投保人对于本公司就投保人或者被保险人的有关情况提出的询问未履行如实告知义务，足以影响本公司决定是否同意承保，本公司已经解除保险合同的；','本保险合同因其他条款所列情况而导致效力终止。'], asFamilly:true
		}, {
			section:'九. 投保份数：',extra:['每一被保险人限投保一份，同一被保险人在同一保险期间内仅可有一张生效保单。'], asFamilly:true
		}, {
			section:'十. 解除合同：',extra:['在保险期间内，投保人可申请解除合同，但解除合同将会遭受一定的损失。解除合同时，保险人按如下公式计算退保金：','退保金=保险费×[1-(保险单已经过天数/保险期间天数)] ×75%','若被保险人已经出险理赔的，投保人不得解除保险合同。'], asFamilly:true
		}, {
			section:'十一. 保险适用条款：',extra:['《中国人民财产保险股份有限公司人人安康家庭医疗保险条款》（注册号：C00000232512018102200592）。请您投保前仔细阅读保险条款，并特别注意条款中的保险责任、责任免除、投保人被保险人义务、保险金申请与给付等内容。'],asFamilly:true
		}
	]
	
	
	const info: InfoBrif[] = [
		{
			section: '投保须知',
			points: [
				{ brife: '投保地区', extra: '本计划仅限在中国大陆有固定居住地（常住）的人士投保。' },
				{ brife: '保单形式', extra: '网上投保为您提供电子保单，根据《中华人民共和国合同法》第十一条规定，数据电文是合法的合同表现形式，电子保单与纸质保单具有同等法律效力。您可以登录www.tk.cn自助查询对电子保单的真实性进行验证。' },
				{
					brife: '如实告知', extra: '您应如实填写投保信息，并就我们提出的询问据实告知,否则我们有权根据《中华人民共和国保险法》第十六条的规定解除保险合同且不承担赔偿责任：', subPoints: [
						'订立保险合同时，保险公司就保险标的或者被保险人的有关情况提出询问的，投保人应当如实告知。',
						'投保人故意或者因重大过失未履行前款规定的如实告知义务，足以影响保险公司决定是否同意承保或者提高保险费率 的，保险公司有权解除合同。',
						'投保人故意不履行如实告知义务的，保险公司对于合同解除前发生的保险事故，不承担赔偿责任，并不退还保险费。 ',
						'投保人因重大过失未履行如实告知义务，对保险事故的发生有严重影响的，保险公司对于合同解除前发生的保险事故，不承担赔偿责任，但退还保险费。'
					]
				},
				{ brife: '信息变更', extra: '如果您的邮件地址、通信地址、邮编、联系电话发生变化，请与本公司客户服务电话95522-3联系，办理变更事宜。' },
				{ brife: '争议解决方式', extra: '因履行本合同发生的争议，由当事人协商解决，协商不成的，提交武汉仲裁委员会仲裁。' },
				{brife:'偿付能力告知及风险综合评价 ', extra:'我公司最新季度综合偿付能力充足率、风险综合评级评价结果等信息，请登录我公司偿付能力信息披露页面http://channel.tk.cn/page/notice/index.html进行查询。'}
			]
		}, {
			section: '产品说明',
			points: [
				{ brife: '本产品由泰康在线承保，适用条款为《泰康在线财产保险股份有限公司个人住院医疗保险（C款）》，备案注册号：C00019932512020033129431，《泰康在线财产保险股份有限公司附加质子重离子医疗保险》，备案注册号：(泰康在线)(备-医疗保险)【2019】(附)023号，《泰康在线财产保险股份有限公司附加扩展门（急）诊医疗保险》，注册号：C00019932522019070400212；' },
				{ brife: '本产品的交费方式为月交或者年交。选择年交保费的，应当在合同成立时一次性交清保险费。保险费未交清前，本合同不生效，对保险费交清前发生的保险事故，保险公司不承担保险责任；选择月交保险费的，应当在合同成立时交纳首期保险费，若未按约定交纳首期保险费，本合同不生效，保险人不承担保险责任。本人在交纳首期保险费后，应当在每个约定的交费日交纳其余各期保险费；' },
				{ brife: '选择月交保费的，约定交费日为保单生效日在各自然月的对应日期，若当月无对应日期，则为该自然月最后一日；' },
				{ brife: '若投保人未按约定交纳保险费，保险人允许投保人在在约定交费日起三十日（含第三十日）内）内补交保险费，如果被保险人在此三十日内发生保险事故，保险人将扣减投保人欠交的保险费后按主险合同约定承担赔偿保险金的责任；' },
				{ brife: '如果被保险人在约定交费日起三十日（含第三十日）内未补交保险费，本合同自上述期限届满之日二十四时起效力中止，本合同自保单效力中止日起发生的保险事故或医疗费用，保险公司不承担保险责任；' },
				{ brife: '因投保人未按约定交纳保险费导致合同效力终止，投保人要求保单复效的，投保人可重新申请投保新单，经保险人审核同意后，新保险合同生效，不重新计算等待期；' },
				{ brife: '本产品投保年龄范围是出生满30天（不含30天）-60周岁（含）。对于成年人（须满18周岁）投保，投保人可为被保险人父母、子女、配偶、本人；对于未成年人（不满18周岁）投保，投保人必须为其父母；' },
				{ brife: '社保医疗保险：是指包括新型农村合作医疗、城镇职工基本医疗保险、城镇居民基本疗保险等政府举办的基本医疗保障项目；' }, { brife: '本保单一般医疗保险金赔偿限额300万，重大疾病医疗保险金赔偿限额600万，附加扩展门（急）诊医疗费用保险金赔偿限额1万，保单累计赔偿限额600万；' }, {
					brife:'本产品一般医疗保险金及重大疾病医疗保险金的赔付比例如下：如被保险人以有社保身份购买，以社会医疗保险身份就诊并结算，赔付比例为100%；如被保险人以无社保身份购买，未以社会医疗保险身份就诊并结算，赔付比例为100%；如被保险人以有社会保险身份参保，但未以社会医疗保险身份就诊并结算的，保险公司按照应赔付金额的60%进行赔付；'
				}, { brife: '本产品附加质子重离子医疗保险责任与重大疾病医疗保险金共用保额，赔付比例为100%；' }, {
					brife:'本产品附加扩展门（急）诊医疗保险责任根据不同的出险疾病，对应不同的免赔规则，如被保险人因99种重疾出险，本附加保险责任无免赔额；如被保险人因99种重疾以外的原因出险，本附加保险责任与主险的一般医疗保险金共用1万免赔额；本附加保险责任的赔偿比例同主险；'
				},{brife:'同一保障期间内，本产品同一被保险人仅限购买一份，多购买部分保险公司不承担保险责任；'},{brife:'本产品自保单生效起15天为犹豫期，投保人在此期间提出解除本合同，保险人将无息退还投保人所支付的全部保险费。犹豫期后申请解除合同会遭受一定经济损失。续保保单无犹豫期；'},{brife:'本产品网上投保生效时间为T+1零时生效，T为投保申请日，产品等待期为生效后30天（含犹豫期）；'},{brife:'本产品医疗相关保障限定为中华人民共和国境内合法经营的二级以上（含二级）公立医院或保险公司认可的其他医疗机构（不含国际医疗及特需部)；扩展质子重离子医疗保险责任的医疗机构限定为“上海质子重离子医院”；'},{brife:'本产品年度免赔额为1万元（重大疾病医疗保险金无免赔额）；'},{brife:'本产品不保证续保。保险期满时，经投保人向保险人提出续保申请，并经保险人审核同意并收取保险费后，续保合同生效，续保合同具体的生效日以保险人另行签发的保险单的日期为准。上述为同一被保险人的续保合同无等待期。如本保险产品统一停售，则保险人不再接受投保人的投保申请'}
				
			]
		}, {
			section: '投保声明',
			points: [
				{ brife: '本人已完整阅读并了解以上投保须知、产品说明及投保险种的保险条款，尤其是对其中免除保险人责任的条款或约定（包括但不限于责任免除、投保人被保险人义务、保险金申请与给付等），本人已充分理解并接受上述内容，同意以此作为订立保险合同的依据；' },
				{ brife: '投保时，本投保人已就该产品的保障内容以及保险金额等向被保险人/被保人监护人进行了明确说明，并征得其同意；' },
				{ brife: '投保单中所填写的内容均属实，如有隐瞒或不实告知，你公司有权解除保险合同，对于合同解除前发生的任何事故，你公司可不承担任何责任；' }, {
					brife: '本人已知晓本保险生效后退保有损失，投保人要求解除保险合同的，保险人计收保险责任开始之日起至合同解除之日止期间的保险费，并退还剩余部分保险费:', subPoints: [
						'保险责任开始前和在犹豫期内，投保人要求解除本合同的，保险人应当退还投保人已交纳的保险费；',
						'保险责任过犹豫期后，投保人要求解除保险合同的，自通知保险人之日起，保险合同解除，保险人计收保险责任开始之日起至合同解除之日止期间的保险费，并退还剩余部分保险费。'
					], append: '经过天数不足一天的按一天计算。若本合同已发生保险金给付，剩余部分保险费为零。'
				},
				{
					brife: '本人同意贵公司为本保险及与本保险之相关服务的目的收集本人的个人资料，无论该资料是从本投保申请或其他地方所获取。本人理解贵公司为提升保险服务质量，可能会与第三方机构、组织或个人合作，此类合作可能需要使用本人的个人资料。在此理解下，本人同意并授权贵公司及与贵公司存在合作关系的机构、组织或个人将收集的本人的个人资料用于：', subPoints: [
					'该保险的投保审核；','该保险的理赔；','提供与该保险有关之服务；','与本人联络；'
				]},{brife:'本人同意你公司通过手机（包括手机短信）、E-mail适时提供保险信息服务。'}
				
			]
		}
	]

	const getIndex = (index:number) => {
		let i = ''
		switch (index) {
			case 1:
				i = '一'
				break
			case 2:
				i = '二'
					break
			case 3:
				i = '三'
				break
			case 4:
				i = '四'
				break
			case 5:
				i = '五'
				break
			default:
				i = '' + index
				break
		}
		return i
	}


	const renderRRItem = (item:InfoBrifRR, index:number) => {
		return <div key={'sec' + index} className='ahead-subpoints'>
			<div className='ahead-section'>
				{item.section}
			</div>
			{item.extra.map((extra) => {
				return <div className='ahead-extra indent'>
					{extra}
				</div>
			})}
			
		</div>
	}

	const renderItem = (item:InfoBrif,index:number) => {
		return <div key={'sec' + index}>
			<div className='ahead-section'>
				<span>{getIndex(index+1)}、</span>{item.section}
			</div>
			{/* <ol>
				{item.points.map((item,index) => {
					return <li>{item.brife}</li>
				})}
			</ol> */}
			{item.points.map((subitem,ind) => {
				return <div key={index+''+ind}>
					<div className='ahead-brife'><span>{ind+1}.&nbsp;</span>{subitem.brife}</div>
					<div className='ahead-extra' style={{display:subitem.extra?'flex':'none'}}>
					{subitem.extra ? subitem.extra : ''}
					</div>
					<div style={{display:subitem.subPoints?'flex':'none',flexDirection:'column'}}>
					{subitem.subPoints ? subitem.subPoints.map((it, inx) => {
						return <div className='ahead-subpoints' key={index+''+ind+inx}><span>({inx+1})&nbsp;</span>{it}</div>
					}) : ''}
					</div>
					<div className='ahead-append' style={{display:subitem.append?'flex':'none'}}>
					{subitem.append?subitem.append:''}
					</div>
				</div>
			})}
			{/* <div className='section-title' style={{ fontWeight: 500 }}>
				<span>{item.title}</span>
				<span className='section-title-mount'>{item.payment}</span></div>
			<div className='section-list'>
				<ol>
					{item.options.map((it, iex) => {
						return <li key={index+'li'+iex} className='section-list-item' style={{ textAlign: 'justify' }}>
							<span>{iex+1}、</span>{it}
						</li>
					})}
				</ol>
			</div> */}
		</div>
	}


	const [isPersonal, setPersonal] = useState(true)

	const renderTab = () => {
		return <div style={{display:'flex', flexDirection: "row" }}>
			<div className={isPersonal ? 'fee-tab fee-tab-selected' : 'fee-tab'}
				onClick={() => { setPersonal(true) }}>人人安康个人版</div>
			<div className={!isPersonal ? 'fee-tab fee-tab-selected' : 'fee-tab'}
				onClick={() => { setPersonal(false) }}>人人安康家庭版</div>
		</div>
	}

	const renderBody = () => {
		if (type == pageTaikang) {
			return <div>
				<div className='ahead-title'>投保须知及声明</div>
			{info.map(renderItem)}
			</div>
		} else {
			
			return <div>
				{renderTab()}
				{infoRR.filter(item=>isPersonal?!item.asFamilly:item.asFamilly).map(renderRRItem)}
			</div>
		}
	}

	return (
		<div style={{flex:1,position:'relative',display:'flex',flexDirection:'column'}}>
			<Nav title='投保须知' style={{position:"fixed",backgroundColor:'#fff',padding:0,...{paddingTop:!!isIphoneX()?'0.88rem':'0.4rem'}}}></Nav>
			
			<div className='insu-wrap'>
			<div className='nav-padding'></div>

			{renderBody()}

		</div>
		</div>
		
    // <div className="demo-wrap">
    //   <div className="title">demo1</div>
    //   {/* <div>{JSON.stringify(result)}</div> */}
    //   <Button type="primary" size="small" inline className="btn">
    //     demo1
    //   </Button>
    //   <Button type="primary" size="large" className="btn">
    //     demo2
    //   </Button>
    // </div>
  )
}
// InsuranceSecurePlan.options = { disableHeader: false, wrapperClassName: '' }
export default InsuranceKnownAhead
