import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'
import InsuranceSecurePlan from './InsuranceSecurePlan';
import InsuranceFeeRate from './InsuranceFeeRate';
import InsuranceKnownAhead from './InsuranceKnownAhead';
import InsuranceCareerDanger from './InsuranceCareerDanger';
import InsuranceLicenceList from './InsuranceLicenceList';
import InsuranceLicenceC from './InsuranceLicenceC';
import InsuranceLicenceAppend from './InsuranceLicenceAppend';
import InsuranceLicenceExtend from './InsuranceLicenceExtend';
import InsuranceClaimsPay from './InsuranceClaimsPay';
import InsuranceValueableService from './InsuranceValueableService';
import InsurancePDFViewer from './InsurancePDFViewer';
import InsuranceClaimsToKnown from './InsuranceClaimsToKnown';
import InsuranceCustomerToKnown from './InsuranceCustomerToKnown';
import InsuranceDelegateLicence from './InsuranceDelegateLicence';
import TKhospital from './TKhospital'
import TKStrickenIntro from './TKStrickenIntro'
import PersonalClause from './PersonalClause'


const asyncInsuranceDetailsPage = Loadable({
    loader: () => import('./InsuranceDetails'),
    loading: MyLoadingComponent
})
const asyncInsuranceRRABPage = Loadable({
	loader: () => import('./InsuranceRRAB'),
	loading: MyLoadingComponent
})
const asyncPayResult = Loadable({
    loader: () => import('./payResult'),
    loading: MyLoadingComponent
})
const InsuranceDetails: Module = {
    name: 'insurance',
    routes: [
        {
            name: '泰康保险详情页',
            path: '/insuranceDetails',
            component: asyncInsuranceDetailsPage,
			},
			{
				name: '人人安康保险详情页',
				path: '/InsuranceRRAB',
				component: asyncInsuranceRRABPage,
			},
			{
					name: '保障计划',
					path: '/secureplan/:type',
					component: InsuranceSecurePlan,
			},
			{
				name: '费率表',
				path: '/feerate/:type',
				component: InsuranceFeeRate,
		},
				{
					name: '费率表',
					path: '/feerate/:type/:index',
					component: InsuranceFeeRate,
			},
			{
				name: '理赔须知',
				path: '/claimstoknown',
				component: InsuranceClaimsToKnown,
			},
			{
				name: '理赔攻略',
				path: '/claims',
				component: InsuranceClaimsPay,
			},
			{
				name: '客户告知书',
				path: '/customertoknown',
				component: InsuranceCustomerToKnown,
			},
			{
				name: '保险经纪委托协议',
				path: '/delegate',
				component: InsuranceDelegateLicence,
			},
			{
				name: '增值服务',
				path: '/valueable',
				component: InsuranceValueableService,
			},
				{
					name: '投保须知',
					path: '/informer/:type',
					component: InsuranceKnownAhead,
				},
				{
					name: '危险职业表',
					path: '/careerrisk',
					component: InsuranceCareerDanger,
				},
				{
					name: '保险条款',
					path: '/licenceslist',
					component: InsuranceLicenceList,
			},
			{
				name: '保险条款C',
				path: '/licencesC',
				component: InsuranceLicenceC,
			},
			{
				name: '保险条款附加',
				path: '/licencesA',
				component: InsuranceLicenceAppend,
			},
			{
				name: '保险条款扩展',
				path: '/licencesE',
				component: InsuranceLicenceExtend,
			},
			{
				name: '支付结果页',
				path: '/payResult/:resultType',
				component: asyncPayResult,
			},
			{
				name: 'PDF浏览',
				path: '/pdf',
				component: InsurancePDFViewer,
			},
			{
				name: '泰康合作医院清单pdf',
				path: '/TKhospital',
				component: TKhospital,
			},
			{
				name: '99种重疾介绍',
				path: "/TKStrickenIntro",
				component: TKStrickenIntro,
			},
			{
				name: '人人安康个人版条款',
				path: "/PersonalClause",
				component: PersonalClause,
			},
    ],
}
export default InsuranceDetails
