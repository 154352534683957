import {httpPost,httpGet,httpPut,httpDelete} from "services/http"
type int = number;
		type List<T> = Array<T>
		type Collection<T> = Array<T>
		
type JsonNode = any
type long = number
interface KeyValueDTO<T,T1>{key:T,value:T1}
export interface AuthTokenVO {
  /** 用于刷新token的令牌 */
  refreshToken?: string
  /** token */
  token?: string
}

export interface CooperationOrganizationVO {
  /** 图标 */
  icon?: string
  /** 名称 */
  name?: string
  /** 类型 */
  type?: string
}

export interface PageList<T> {
  /** 数据总条数，前端接口可忽略改字段 */
  count?: number
  /** 数据集合 */
  entities?: T[]
  /** 当前页码 */
  pageNo?: number
  /** 每页条数 */
  pageSize?: number
}

export interface LecturerListVO {
  /** 创建时间 */
  createDate?: string
  /** 关注状态 0无关系 1我关注的 2关注我的 3互相关注 */
  followFlag?: number
  /** kid */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  recommendCover?: string
  /** 讲师推荐 0不推荐 1推荐 */
  recommendFlag?: number
  /** 专家推荐 0不推荐 1推荐 */
  recommendHome?: number
  /** 排序值 */
  sortFlag?: number
  /** 简介 */
  userDesc?: string
  /** 用户id */
  userId?: number
  /** 头像 */
  userImg?: string
  /** 姓名 */
  userName?: string
  /** 手机号码 */
  userPhone?: string
  /** 讲师擅长领域 */
  userSpecial?: string
  /** 讲师单位 */
  userUnit?: string
}

export interface NutritionistTitleInfo {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 上下架标识 0:上架 1:下架 */
  shelveFlag?: number
  /** 排序值 */
  sort?: number
  /** 头衔名称 */
  title?: string
}

export interface QuestionnaireAnswerDTO {
  answers?: Answer[]
  questionnaireId?: number
}

export interface Answer {
  answerId?: number
  description?: string
  problemId?: number
}

export interface UserQuestionnaire {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 1:删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 问卷id */
  questionnaireId?: number
  /** 0引导 1:需求 */
  questionnaireType?: number
  /** 用户id */
  userId?: number
}

export interface UserQuestionnaireVO {
  kid?: number
  name?: string
  problemList?: Problem[]
  valid?: number
}

export interface Problem {
  answer?: number
  answers?: ProblemAnswer[]
  description?: string
  imgUrl?: string
  kid?: number
  problem?: string
  sort?: number
  type?: number
}

export interface ProblemAnswer {
  kid?: number
  name?: string
  sort?: number
}

export interface UserQuestionnaireBatch {
  createDate?: string
  delFlag?: number
  kid?: number
  name?: string
}

export interface Questionnaire {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 1:删除 */
  del?: number
  /** 内容介绍 */
  detail?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 问题数量 */
  problemCount?: number
  /** 问卷名称 */
  questionnaireName?: string
  /** 问卷类型，0引导问卷，1需求问卷 */
  questionnaireType?: number
  /** 排序 */
  sort?: number
  /** 可更新表示0，可更新，1不可更新 */
  updateFlag?: number
  /** 有效 0:无 1:有 */
  valid?: number
}

export interface LoginVO {
  /** token信息 */
  authInfo?: AuthTokenVO
  /** 用户基本信息 */
  user?: UserBaseInfoVO
  /** 注册标识(只登陆false，注册并登录true) */
  withRegister?: boolean
}

export interface UserBaseInfoVO {
  /** 活动渠道码 */
  activityChannelCode?: string
  /** 应用渠道码(客户端渠道包) */
  appChannel?: string
  /** 城市编码 */
  cityCode?: string
  /** 讲师佣金率 */
  commissionRate?: number
  /** 星座 */
  constellation?: string
  /** 合同照片 */
  contractPhotoUrl?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效  1:删除 */
  delFlag?: number
  /** 需求状态 0:未互动 1:已互动，未提需求，2:已提需求，3:已提需求已完成 */
  demandStatus?: number
  /** 家乡 */
  hometown?: string
  /** 身份证号 */
  idCard?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 现居地 */
  livingPlace?: string
  /** 邀请码 */
  myInviteCode?: string
  /** 讲师运营人员 */
  operationDirector?: string
  /** 运营电话 */
  operatorPhone?: string
  /** 极光设备唯一id */
  registrationId?: string
  /** 用户角色集合 */
  roles?: UserRole[]
  /** 游客标识 0-正常用户 1-游客 */
  touristFlag?: number
  /** 年龄 */
  userAge?: number
  /** 用户背景图片 */
  userBgImg?: string
  /** 出生年月日 */
  userBirthday?: string
  /** 用户简介 */
  userDesc?: string
  /** 用户扩展字段 */
  userExt?: string
  /** 用户性别 0-女 1-男 */
  userGenders?: number
  /** 用户id */
  userId?: number
  /** 头像 */
  userImg?: string
  /** 姓名 */
  userName?: string
  /** 昵称 */
  userNickName?: string
  /** 用户手机号码 */
  userPhone?: string
  /** 用户二维码地址 */
  userQr?: string
  /** 用户签名 */
  userSignature?: string
  /** 用户状态 0-正常 1-冻结 2-注销 */
  userStatus?: number
  /** 马甲状态 0-普通用户 1-马甲 */
  vestFlag?: number
}

export interface UserRole {
  /** 角色 */
  role?: string
  /** 角色头衔 */
  roleTitle?: string
}

export interface PwdLoginDTO {
  /** 密码 */
  password?: string
  /** 手机号 */
  phone?: string
}

export interface VerifyCodeLoginDTO {
  /** 手机号 */
  phone?: string
  /** 验证码 */
  verifyCode?: string
  /** 验证码业务类型(2.登录,4注册/登录) */
  verifyServiceCode?: number
}

export interface RegisterDTO {
  /** 活动渠道码 */
  activityChannelCode?: string
  /** 现居地 */
  livingPlace?: string
  /** 出生年月日 */
  userBirthday?: string
  /** 注册渠道 */
  userChannel?: string
  /** 用户扩展属性 */
  userExt?: string
  /** 用户性别 0-女 1-男 2-其他 */
  userGenders?: number
  /** 用户头像 */
  userImg?: string
  /** 姓名 */
  userName?: string
  /** 昵称 */
  userNickName?: string
  /** 手机号 */
  userPhone?: string
  /** 登录密码 */
  userPwd?: string
  /** 邀请码 */
  userRegInviterCode?: string
  /** 验证码 */
  veriCode?: string
  /** 验证码业务类型(1.注册,4注册/登录) */
  verifyServiceCode?: number
}

export interface ResetPasswordDTO {
  /** 新密码 */
  newPassword?: string
  /** 手机号 */
  phone?: string
  /** 验证码 */
  verifyCode?: string
}

export interface UserSearchVO {
  /** 关注类型 1未关注 2已关注 */
  followType?: number
  /** 角色集合 */
  roles?: string[]
  /** 用户ID */
  userId?: number
  /** 头像 */
  userImg?: string
  /** 用户名称 */
  userName?: string
  /** 用户昵称 */
  userNickName?: string
}

export interface UserConditioningInfo {
  /** 调理方向 */
  conditioningName?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 描述 */
  descriptions?: string
  /** 图标 */
  icon?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 上下架标识 0:上架 1:下架 */
  shelveFlag?: number
  /** 排序值 */
  sort?: number
}

export interface UserEvaluateNutritionistInfoVO {
  /** 评价内容 */
  content?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 营养师ID */
  nutritionistUserId?: number
  /** 咨询记录ID */
  recordId?: number
  /** 角色类型 1营养师 2管家 */
  roleType?: number
  /** 评价分数 */
  score?: number
  /** 置顶状态 0 未置顶 1 已置顶 */
  topStatus?: number
  /** 患者ID */
  userId?: number
  /** 头像 */
  userImg?: string
  /** 昵称 */
  userNickName?: string
}

export interface UserNutritionistVO {
  /** 账户 */
  accountName?: string
  /** 银行卡号 */
  accountNumber?: number
  /** 认证类型 1-营养师 2-健康管理专家 3-临床营养师 */
  authType?: number
  /** 认证类型名称 */
  authTypeName?: string
  /** 认证时间 */
  authenticationTime?: string
  /** 银行 */
  bank?: string
  /** 资质证书 */
  certificate?: string
  /** 营养师费率 */
  commissionRate?: number
  /** 调理方向 */
  conditioning?: string
  /** 营养师调理方向 */
  conditioningList?: UserConditioningInfo[]
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 价格 */
  dietitianServiceSettingVO?: DietitianServiceSettingVO
  /** 每月咨询费 */
  feeMonth?: number
  /** 每次咨询费 */
  feeTime?: number
  /** 每次会员咨询费 */
  feeTimeVip?: number
  /** 咨询量 */
  inquiries?: number
  /** 个人介绍 */
  introduce?: string
  /** 是否会员 */
  joinVip?: boolean
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 头衔 */
  nutritionTitleId?: string
  /** 咨询费用营养贝 */
  price?: number
  /** 是否设置口碑 0 取消,1开启 */
  recommendFlag?: number
  /** 服务提供 */
  renderService?: string
  /** 好评率 */
  score?: number
  /** 家政服务人数 */
  serverNumber?: number
  /** 订单数 */
  serverOrder?: number
  /** 上下架状态 0上架 1下架 */
  shaveFlag?: number
  /** 营养师头衔方向 */
  titles?: NutritionistTitleInfo[]
  /** 头衔 */
  userDesc?: string
  /** 用户id */
  userId?: number
  /** 营养师头像 */
  userImg?: string
  /** 营养师姓名 */
  userName?: string
  /** 手机号 */
  userPhone?: string
  /** 用户状态 0正常 1已禁用 */
  userStatus?: number
}

export interface DietitianServiceSettingVO {
  consultation?: DietitianServiceSetting
  services?: DietitianServiceSetting[]
}

export interface DietitianServiceSetting {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 营养师id */
  dietitianId?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 服务时长 */
  servicePeriod?: number
  /** 服务价格 */
  servicePrice?: number
  /** 1启用0禁用 */
  serviceStatus?: number
  /** 1天2月 */
  serviceTimeUnit?: number
  /** 服务类型1咨询2营养家政 */
  serviceType?: number
}

export interface BankInfo {
  bankName?: string
}

export interface UserActivityPhoneAttributes {
  /** 活动渠道 */
  activityChannel?: string
  /** 创建时间 */
  createDate?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 采集的手机属性 */
  phoneAttributes?: string
}

export interface UserRelationVO {
  /** 星座 */
  constellation?: string
  /** 是否关注（0未关注,1已关注,3互相关注） */
  followFlag?: number
  /** 现居地 */
  livingPlace?: string
  /** 用户角色集合 */
  roles?: UserRole[]
  /** 用户背景图片 */
  userBgImg?: string
  /** 出生年月日 */
  userBirthday?: string
  /** 用户性别 0女/1男 */
  userGenders?: number
  /** 用户账户id */
  userId?: number
  /** 头像 */
  userImg?: string
  /** 姓名 */
  userName?: string
  /** 昵称 */
  userNickName?: string
  /** 用户签名 */
  userSignature?: string
  /** 马甲状态 0-普通用户 1-马甲 */
  vestFlag?: number
}

export interface UserSitNutritionistInfoAppVO {
  /** 擅长 */
  goodAt?: string
  /** kid */
  kid?: number
  /** 简介 */
  synopsis?: string
  /** 标签集合 */
  tagName?: string[]
  /** 头衔 */
  title?: string
  /** userId */
  userId?: number
  /** 头像 */
  userImg?: string
  /** 姓名 */
  userName?: string
  /** 用户手机号码 */
  userPhone?: string
}

export interface ThirdLoginVO {
  binding?: boolean
  /** 登录校验标识 */
  checkFlag?: boolean
  /** 用户登录信息 */
  loginVO?: LoginVO
  /** 第三方用户openId */
  openId?: string
  /** 第三方用户id */
  thirdId?: string
}

export interface CheckLoginAppleDTO {
  /** jwt格式验证字符串 */
  identityToken?: string
  /** apple授权唯一标识 */
  userId?: string
}

export interface CheckLoginMiniProgramDTO {
  /** 微信小程序授权code */
  code?: string
  /** 完整用户信息的加密数据 */
  encryptedData?: string
  /** 加密算法的初始向量(如获取更多用户信息传) */
  iv?: string
}

export interface BindingPhoneDTO {
  /** 绑定类型0-weixin  1-weibo 2-qq 3-weixinWeb 4-小程序  5-apple 6-支付宝 */
  loginType?: number
  /** 业务类型由接入方自定义,例如:1.注册、2 .登录、3.找回密码、4注册/登录、5其他等 */
  serviceCode?: number
  /** 第三方账户id */
  thirdId?: string
  /** 手机号 */
  userPhone?: string
  /** 验证码 */
  veriCode?: string
}

export interface WxJsapiSignature {
  appid?: string
  noncestr?: string
  signature?: string
  timestamp?: number
  url?: string
}

export interface DietitianServiceSettingDTO {
  consultation?: DietitianServiceSetting
  services?: DietitianServiceSetting[]
}

export interface ExcelExportRecord {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 导出Excel类型 */
  excelType?: string
  /** 导出码 */
  exportCode?: string
  /** 导出描述 */
  exportDesc?: string
  /** 导出参数 */
  exportParams?: string
  /** 导出状态：0未执行 1导出中 2全部导出 3部分导出 4导出失败 */
  exportStatus?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 结果excel文件地址 */
  resultExcel?: string
  /** 无标题模板导出 */
  withoutTitle?: boolean
}

export interface LecturerAccountPeriodVO {
  /** 账期编号 */
  accountNumber?: string
  balance?: number
  balanceIos?: number
  /** 合同照片 */
  contractPhotoUrl?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 结算金额 */
  currentSettlementAmount?: number
  /** ios结算金额 */
  currentSettlementAmountIos?: number
  /** 当期结算日期 */
  currentSettlementDate?: string
  /** 讲师佣金率 */
  feeRate?: number
  /** 分布式唯一ID */
  kid?: number
  /** 上期结算日期 */
  lastSettlementDate?: string
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 讲师唯一标识 */
  lecturerId?: number
  lecturerName?: string
  operationalDirector?: string
  /** 订单交易金额 */
  orderAmount?: number
  /** 订单数 */
  orderNumber?: number
  /** 待结算时间 */
  pendingTime?: string
  /** 状态 1.已出账  2.已对账 3.待结算  4.已结算 */
  periodStatus?: number
  /** 平台服务费 */
  platformInto?: number
  /** 对账时间 */
  reconciliationTime?: string
  /** 其他已结算金额 */
  settlementAmount?: number
  /** ios已结算金额 */
  settlementAmountIos?: number
  /** 结算周期 1.周  2.半月 3.月 */
  settlementCycle?: number
  /** 结算时间 */
  settlementTime?: string
  /** 税率 */
  taxRate?: number
  /** 税收类型1税前2税后 */
  taxType?: number
  /** 未结算金额 */
  unsettlementAmount?: number
}

export interface QuestionnaireAnswer {
  /** 0:否 1:是 */
  answer?: number
  /** 答案id */
  answerId?: number
  /** 答案id */
  batchId?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 问题描述 */
  description?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 问题id */
  problemId?: number
  /** 问卷id */
  questionnaireId?: number
  /** 用户id */
  userId?: number
}

export interface QuestionnaireProblemAnswer {
  /** 问题名称 */
  answerName?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 是否删除 */
  del?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 问卷id */
  problemId?: number
  /** 问卷id */
  questionnaireId?: number
  /** 排序 */
  sort?: number
  /** 有效 0:无 1:有 */
  valid?: number
}

export interface QuestionnaireProblemTag {
  /** 答案id */
  answerId?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 问题id */
  problemId?: number
  /** 选项0:否 1:是 */
  problemOption?: number
  /** 问卷id */
  questionnaireId?: number
  /** 用户id */
  userId?: number
  /** 标签id */
  userTagId?: number
  /** 有效 0:否 1:是 */
  valid?: number
}

export interface QuestionnaireProblem {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 1:删除 */
  del?: number
  /** 问题背景图 */
  imgUrl?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 问题名称 */
  problemName?: string
  /** 1:仅选项，2：仅文本，3：皆可 */
  problemType?: number
  /** 问卷id */
  questionnaireId?: number
  /** 排序 */
  sort?: number
  /** 有效 0:无 1:有 */
  valid?: number
}

export interface ShellAccountLogDTO {
  /** 交易动作 1.增加 2.减少 */
  actionType?: number
  /** 交易金额 */
  amount?: number
  /** 业务描述 */
  bizDesc?: string
  /** 业务标题 */
  bizTitle?: string
  /** 1.充值 2.消费 3.收益 4.结算 5.代客充值  */
  bizType?: number
  /** 交易时间 精确到毫秒 */
  transactionTime?: string
}

export interface ShellAccountLogAppQueryDTO {
  /** pageNo */
  pageNo?: number
  /** pageSize */
  pageSize?: number
  /** 下单时间结束 */
  timeEnd?: string
  /** 下单时间开始 */
  timeStart?: string
}

export interface UserAccountPeriodDTO {
  kids?: number[]
  /** 2营养师 */
  userRole?: number
}

export interface UserAccountPeriodVO {
  /** 账期编号 */
  accountNumber?: string
  balance?: number
  balanceIos?: number
  /** 合同照片 */
  contractPhotoUrl?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 结算金额 */
  currentSettlementAmount?: number
  /** 现金结算金额 */
  currentSettlementAmountCash?: number
  /** ios结算金额 */
  currentSettlementAmountIos?: number
  /** 当期结算日期 */
  currentSettlementDate?: string
  /** 讲师佣金率 */
  feeRate?: number
  /** 分布式唯一ID */
  kid?: number
  /** 上期结算日期 */
  lastSettlementDate?: string
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  operationalDirector?: string
  /** 订单交易金额 */
  orderAmount?: number
  /** 订单数 */
  orderNumber?: number
  /** 待结算时间 */
  pendingTime?: string
  /** 状态 1.已出账 2.已对账 3.待结算 4.已结算 */
  periodStatus?: number
  /** 平台服务费 */
  platformServiceFee?: number
  /** 对账时间 */
  reconciliationTime?: string
  /** 未结算金额 */
  refundAmount?: number
  /** 已结算金额 */
  settlementAmount?: number
  /** 现金结算金额 */
  settlementAmountCash?: number
  /** ios结算金额 */
  settlementAmountIos?: number
  /** 结算周期 1.周 2.半月 3.月 */
  settlementCycle?: number
  /** 结算时间 */
  settlementTime?: string
  /** 税率 */
  taxRate?: number
  /** 税型1税前2税后 */
  taxType?: number
  /** 未结算金额 */
  unsettlementAmount?: number
  /** 用户唯一标识 */
  userId?: number
  userName?: string
  /** 2营养师3坐诊专家 */
  userRole?: number
}

export interface PhoneVerifyDTO {
  /** 业务类型由接入方自定义,例如:1.注册、2 .登录、3.找回密码、4注册/登录、5其他等 */
  serviceCode?: number
  /** 手机号 */
  userPhone?: string
  /** 验证码 */
  veriCode?: string
}

export interface EditPasswordDTO {
  /** 新密码 */
  newPassword?: string
  /** 旧密码 */
  oldPassword?: string
}

export interface LogoutDTO {
  /** 极光设备唯一id */
  registrationId?: string
}

export interface BindPushVO {
  /** 极光设备唯一id */
  registrationId?: string
}

export interface UpdateUserDTO {
  /** 城市编码 */
  cityCode?: string
  /** 星座 */
  constellation?: string
  /** 家乡 */
  hometown?: string
  /** 身份证号 */
  idCard?: string
  /** 现居地 */
  livingPlace?: string
  /** 用户背景图片 */
  userBgImg?: string
  /** 出生年月日 */
  userBirthday?: string
  /** 用户简介 */
  userDesc?: string
  /** 用户扩展字段 */
  userExt?: string
  /** 用户性别 0-女 1-男 */
  userGenders?: number
  /** 头像 */
  userImg?: string
  /** 姓名 */
  userName?: string
  /** 昵称 */
  userNickName?: string
  /** 用户签名 */
  userSignature?: string
}

export interface CheckInfoVO {
  /** 审核状态，0 审核中，1 审核通过，2 审核拒绝，3 未提交 */
  checkStatus?: number
  /** 审核失败原因 */
  failInfo?: string
}

export interface UserDemandPortrayal {
  /** 地址编码 */
  addressCode?: string
  /** 地址 */
  addressName?: string
  /** 过敏史  0:无 1:有 */
  allergy?: number
  /** 生日 */
  birthday?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 身高 */
  height?: number
  /** 过往病史 0 未选择 1，痛风 2，糖尿病 3，脑卒中 4，肥胖 5，癌症 6，心血管病 7，其它 */
  history?: number
  /** 分布式唯一ID */
  kid?: number
  /** 劳动强度 */
  labourLevel?: number
  /** 最后需求时间 */
  lastDemand?: string
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 成员性别 */
  sex?: number
  /** 补充 */
  supplement?: string
  /** 用户id */
  userId?: number
  /** 用户名称 */
  userName?: string
  /** 最新体重 */
  weight?: number
  /** 需求权重快照 */
  weightsRecord?: string
}

export interface UserDeviceLoginLog {
  /** 客户端版本号 */
  clientVersion?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 0正常1删除 */
  delFlag?: number
  /** 设备id */
  deviceId?: string
  /** 设备名称 */
  deviceName?: string
  /** 在线设备类型 */
  deviceType?: string
  /** ip地址 */
  ip?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后登录时间 */
  lastLoginDate?: string
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 坐标经度 */
  loginX?: number
  /** 坐标纬度 */
  loginY?: number
  /** 刷新token */
  refreshToken?: string
  /** token */
  token?: string
  /** 用户ID */
  userId?: number
}

export interface UserNutritionistDTO {
  /** 账户 */
  accountName?: string
  /** 银行卡号 */
  accountNumber?: number
  /** 认证类型 1-营养师 2-健康管理专家 3-临床营养师 */
  authType?: number
  /** 认证时间 */
  authenticationTime?: string
  /** 银行 */
  bank?: string
  /** 资质证书 */
  certificate?: string
  /** 资质证明 */
  certificateList?: string[]
  /** 营养师费率 */
  commissionRate?: number
  /** 调理方向 */
  conditioning?: string
  /** 调理方向 */
  conditioningList?: number[]
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 营养师服务 */
  dto?: DietitianServiceSettingDTO
  /** 每月咨询费 */
  feeMonth?: number
  /** 每次咨询费 */
  feeTime?: number
  /** 身份证号码 */
  idCard?: string
  /** 咨询量 */
  inquiries?: number
  /** 个人介绍 */
  introduce?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 头衔 */
  nutritionTitleId?: string
  /** 咨询费用营养贝 */
  price?: number
  /** 是否设置口碑 0 取消,1开启 */
  recommendFlag?: number
  /** 服务提供 */
  renderService?: string
  /** 好评率 */
  score?: number
  /** 上下架状态 0上架 1下架 */
  shaveFlag?: number
  /** 头衔 */
  titles?: string[]
  /** 头衔 */
  userDesc?: string
  /** 用户id */
  userId?: number
  /** 个人照片 */
  userImg?: string
  /** 营养师姓名 */
  userName?: string
  /** 营养师手机号 */
  userPhone?: string
  /** 用户状态 0正常 1已禁用 */
  userStatus?: number
  /** 验证码 */
  verifyCode?: string
}

export interface BankAccountVO {
  /** 账户名称 */
  accountName?: string
  /** 银行卡号 */
  accountNumber?: string
  /** 银行名称 */
  bank?: string
}

export interface UserRegisterPhoneAttributes {
  /** 创建时间 */
  createDate?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 采集的手机属性 */
  phoneAttributes?: string
}

export interface UserQuestionnaireAnswer {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 问题描述 */
  description?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 问卷id */
  questionnaireId?: number
  /** 用户id */
  userId?: number
  /** 用户名称 */
  userName?: string
  /** 有效 0:否 1:是 */
  valid?: number
}

export interface BatchRequest<T> {
  /** 数据 */
  data?: number
  /** KID集合 */
  kidList?: number[]
  /** 请求类型 */
  type?: string
}

export interface UserRelationDTO {
  /** 目标用户ID */
  targetUserId?: number
}

export interface UserSitNutritionistBindTag {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 标签id */
  tagId?: number
  /** 坐诊营养师userid */
  userId?: number
}

export interface UserSitNutritionistInfoVO {
  /** 银行账户名 */
  accountName?: string
  /** 银行卡号 */
  accountNumber?: string
  /** 银行 */
  bank?: string
  /** 费率 */
  commissionRate?: number
  /** 创建时间 */
  createDate?: string
  /** 删除标识 0：未删除 1：已删除 */
  delFlag?: number
  /** 第一次坐诊时间 */
  firstSitDate?: string
  /** 擅长 */
  goodAt?: string
  /** kid */
  kid?: number
  /** 最后一次坐诊时间 */
  lastSitDate?: string
  /** 服务人数 */
  servedNum?: number
  /** 一周坐诊价目表 逗号隔开 */
  sittingPrice?: string
  /** 排序 */
  sortNum?: number
  /** 简介 */
  synopsis?: string
  /** 标签id集合 */
  tagIds?: number[]
  /** 标签集合 */
  tagName?: string[]
  /** 头衔 */
  title?: string
  /** userId */
  userId?: number
  /** 头像 */
  userImg?: string
  /** 姓名 */
  userName?: string
  /** 用户手机号码 */
  userPhone?: string
  /** 用户状态 0正常 1已禁用 */
  userStatus?: number
}

export interface UserSitNutritionistInfoAppDTO {
  /** 擅长 */
  goodAt?: string
  /** kid */
  kid?: number
  /** 简介 */
  synopsis?: string
}

export interface UserSitNutritionistLog {
  /** 预约日期 */
  appointmentDate?: string
  /** 预约结束时段 */
  appointmentEndTime?: string
  /** 预约开始时段 */
  appointmentStartTime?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0：未删除 1：已删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 坐诊营养师id */
  nutritionitId?: number
  /** 操作人id */
  operationId?: number
  /** 操作角色  0 营养师 1用户 2 系统 */
  operationRole?: number
  /** 操作类型  0 打开诊室 1 邀请问诊 2结束问诊 3关闭诊室 4 取消问诊 */
  operationType?: number
  /** 用户姓名 */
  userName?: string
}

export interface UserSitNutritionistTag {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 上架状态：0->上架；1->下架 */
  shelveFlag?: number
  /** 标签名 */
  tagName?: string
}

export interface UserTagInfo {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 默认标签 1为默认 */
  defaultFlag?: number
  /** 删除标识 0：未删除 1：已删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 标签名称 */
  tagName?: string
  /** 用户人数 */
  userNumber?: number
}

export interface ListRequest<T> {
  /** 数据集合 */
  dataList?: number[]
  /** 请求类型 */
  type?: string
}

export interface ChangeLoginPhoneDTO {
  /** 新手机号验证信息 */
  newPhoneDTO?: PhoneVerifyDTO
  /** 原手机号验证信息 */
  oldPhoneDTO?: PhoneVerifyDTO
}

export interface ThirdUserAccountVO {
  /** 第三方用户相关信息,map中的key的说明wx:微信账户 ,wb:微博用户,qq:qq用户 */
  map?: any
  /** 用户手机号 */
  userPhone?: string
}

export interface ThirdUserBindingVO {
  /** 绑定标识 */
  bindingFlag?: boolean
  userThirdLogin?: UserThirdLogin
}

export interface UserThirdLogin {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0正常 1删除 */
  delFalg?: number
  /** 第三方账户性别1男2女 */
  gender?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 登录类型0-weixin  1-weibo 2-qq 3-weixinWeb 4-微信小程序  5-apple */
  loginType?: number
  /** 三方账户昵称 */
  nickName?: string
  /** 第三方账户openId */
  openId?: string
  /** 第三方账户id */
  thirdId?: string
  /** 用户id */
  userId?: number
  /** 第三方账户头像 */
  userImg?: string
}

export interface ThirdUserBindingDTO {
  /** 微信授权code */
  code?: string
  /** 绑定账户类型 0:微信,1:微博，2:qq 3:(weixinWeb)微信公众号 */
  type?: number
}
export default {
  auth: {
    /**
    * 刷新token
    */
    refreshToken(authTokenVO: AuthTokenVO): Promise<AuthTokenVO> {
      return httpPost(`/platform-user/v5.0/pb/auth/action/refreshToken`, authTokenVO).then((res:any) => res.data.data)
    },
  },
  healths: {
    /**
    * 健康检查
    */
    check(): Promise<boolean> {
      return httpGet(`/platform-user/v5.0/pb/healths/action/check`).then((res:any) => res.data.data)
    },
  },
  lecturerInfos: {
    /**
    * 查询合作机构
    */
    listCooperationOrganization(params: { pageNo?: number, pageSize?: number }): Promise<PageList<CooperationOrganizationVO>> {
      return httpGet(`/platform-user/v5.0/pb/lecturer-infos/action/list-cooperation-organization`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 首页推荐专家分页接口
    */
    listRecomend(params: { pageNo?: number, pageSize?: number }): Promise<PageList<LecturerListVO>> {
      return httpGet(`/platform-user/v5.0/pb/lecturer-infos/action/list-recomend`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 营养健康学院推荐关注讲师列表接口
    */
    listRecommendFollow(params: { pageNo?: number, pageSize?: number }): Promise<PageList<LecturerListVO>> {
      return httpGet(`/platform-user/v5.0/pb/lecturer-infos/action/list-recommend-follow`,  {params} ).then((res:any) => res.data.data)
    },
  },
  nutritionistTitleInfos: {
    /**
    * 头衔查询
    */
    searchAll(): Promise<List<NutritionistTitleInfo>> {
      return httpGet(`/platform-user/v5.0/pb/nutritionist-title-infos/action/search-all`).then((res:any) => res.data.data)
    },
  },
  questionnaireAnswers: {
    /**
    * 用户提交问卷
    */
    create(questionnaireAnswer: QuestionnaireAnswerDTO): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pb/questionnaire-answers/action/create`, questionnaireAnswer).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<QuestionnaireAnswer>> {
      return httpGet(`/platform-user/v5.0/pt/questionnaire-answers/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  questionnaires: {
    /**
    * 删除用户问卷
    */
    delUserQuestionnaires(userQuestionnaire: UserQuestionnaire): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pb/questionnaires/action/delUserQuestionnaires`, userQuestionnaire).then((res:any) => res.data.data)
    },
    /**
    * 用户查询需求引导问卷
    */
    getDemandGuideQuestionnaire(): Promise<UserQuestionnaireVO> {
      return httpGet(`/platform-user/v5.0/pb/questionnaires/action/getDemandGuideQuestionnaire`).then((res:any) => res.data.data)
    },
    /**
    * 用户查询需求问卷问题
    */
    getDemandQuestionnaire(): Promise<UserQuestionnaireVO> {
      return httpGet(`/platform-user/v5.0/pb/questionnaires/action/getDemandQuestionnaire`).then((res:any) => res.data.data)
    },
    /**
    * 用户查询需求问卷kid
    */
    getDemandQuestionnaireKid(): Promise<number> {
      return httpGet(`/platform-user/v5.0/pb/questionnaires/action/getDemandQuestionnaireKid`).then((res:any) => res.data.data)
    },
    /**
    * 用户查询需求问卷历史
    */
    getDemandQuestionnaireList(): Promise<List<UserQuestionnaireBatch>> {
      return httpGet(`/platform-user/v5.0/pb/questionnaires/action/getDemandQuestionnaireList`).then((res:any) => res.data.data)
    },
    /**
    * 查询用户是否做过需求问卷
    */
    getDemandQuestionnaireStatus(): Promise<boolean> {
      return httpGet(`/platform-user/v5.0/pb/questionnaires/action/getDemandQuestionnaireStatus`).then((res:any) => res.data.data)
    },
    /**
    * 根据批次号查询问卷记录
    */
    getQuestionnaireHistory(params: { kid: number }): Promise<UserQuestionnaireVO> {
      return httpGet(`/platform-user/v5.0/pb/questionnaires/action/getQuestionnaireHistory`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 用户查询需求问卷
    */
    getUserQuestionnaires(params: { userId: number }): Promise<List<Questionnaire>> {
      return httpGet(`/platform-user/v5.0/pb/questionnaires/action/getUserQuestionnaires`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<Questionnaire>> {
      return httpGet(`/platform-user/v5.0/pt/questionnaires/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  userAccounts: {
    /**
    * 校验手机号是否存在
    */
    checkPhone(params: { phone: string }): Promise<boolean> {
      return httpGet(`/platform-user/v5.0/pb/user-accounts/action/checkPhone`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 手机号密码登录
    */
    loginByPassword(pwdLoginDTO: PwdLoginDTO): Promise<LoginVO> {
      return httpPost(`/platform-user/v5.0/pb/user-accounts/action/loginByPassword`, pwdLoginDTO).then((res:any) => res.data.data)
    },
    /**
    * 手机号短信登录
    */
    loginByVerifyCode(verifyCodeLoginDTO: VerifyCodeLoginDTO): Promise<LoginVO> {
      return httpPost(`/platform-user/v5.0/pb/user-accounts/action/loginByVerifyCode`, verifyCodeLoginDTO).then((res:any) => res.data.data)
    },
    /**
    * 手机号注册
    */
    registerByPhone(registerDTO: RegisterDTO): Promise<LoginVO> {
      return httpPost(`/platform-user/v5.0/pb/user-accounts/action/registerByPhone`, registerDTO).then((res:any) => res.data.data)
    },
    /**
    * 手机号注册&登录
    */
    registerOrLoginByPhone(registerDTO: RegisterDTO): Promise<LoginVO> {
      return httpPost(`/platform-user/v5.0/pb/user-accounts/action/registerOrLoginByPhone`, registerDTO).then((res:any) => res.data.data)
    },
    /**
    * 游客注册&登录
    */
    registerOrLoginForTourist(): Promise<LoginVO> {
      return httpPost(`/platform-user/v5.0/pb/user-accounts/action/registerOrLoginForTourist`).then((res:any) => res.data.data)
    },
    /**
    * 重置密码
    */
    resetPassword(resetPasswordDTO: ResetPasswordDTO): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pb/user-accounts/action/resetPassword`, resetPasswordDTO).then((res:any) => res.data.data)
    },
    /**
    * 游客绑定手机号成为正式用户
    */
    bindingPhoneForTourist(bindingPhone: PhoneVerifyDTO): Promise<LoginVO> {
      return httpPut(`/platform-user/v5.0/pt/user-accounts/action/bindingPhoneForTourist`, bindingPhone).then((res:any) => res.data.data)
    },
    /**
    * 修改密码
    */
    editPassword(editPasswordDTO: EditPasswordDTO): Promise<boolean> {
      return httpPut(`/platform-user/v5.0/pt/user-accounts/action/editPassword`, editPasswordDTO).then((res:any) => res.data.data)
    },
    /**
    * 设置支付密码
    */
    editPayPassword(editPasswordDTO: EditPasswordDTO): Promise<boolean> {
      return httpPut(`/platform-user/v5.0/pt/user-accounts/action/editPayPassword`, editPasswordDTO).then((res:any) => res.data.data)
    },
    /**
    * 获取设置过支付密码设置标识 0未设置 1已设置
    */
    getPayPwdFlag(): Promise<number> {
      return httpGet(`/platform-user/v5.0/pt/user-accounts/action/getPayPwdFlag`).then((res:any) => res.data.data)
    },
    /**
    * 用户退出登录
    */
    logout(logoutDTO: LogoutDTO): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/user-accounts/action/logout`, logoutDTO).then((res:any) => res.data.data)
    },
    /**
    * 重置支付密码
    */
    resetPayPassword(resetPasswordDTO: ResetPasswordDTO): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/user-accounts/action/resetPayPassword`, resetPasswordDTO).then((res:any) => res.data.data)
    },
  },
  userBaseInfos: {
    /**
    * 校验昵称是否存在（true存在 false不存在）
    */
    checkNickName(params: { userNickName: string }): Promise<boolean> {
      return httpGet(`/platform-user/v5.0/pb/user-base-infos/action/checkNickName`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 服务端关键字查询用户列表
    */
    searchByKeyword(params: { keyword: string, pageNo?: number, pageSize?: number }): Promise<PageList<UserSearchVO>> {
      return httpGet(`/platform-user/v5.0/pb/user-base-infos/action/searchByKeyword`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 绑定Jpush注册ID
    */
    bindJpushId(bindPushVO: BindPushVO): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/user-base-infos/action/bindJpushId`, bindPushVO).then((res:any) => res.data.data)
    },
    /**
    * 编辑用户信息（APP专用）
    */
    updateForApp(updateUserDTO: UpdateUserDTO): Promise<boolean> {
      return httpPut(`/platform-user/v5.0/pt/user-base-infos/action/updateForApp`, updateUserDTO).then((res:any) => res.data.data)
    },
  },
  userConditioningInfos: {
    /**
    * APP端查询分页数据
    */
    searchConditioning(): Promise<List<UserConditioningInfo>> {
      return httpGet(`/platform-user/v5.0/pb/user-conditioning-infos/action/search-conditioning`).then((res:any) => res.data.data)
    },
  },
  userEvaluateNutritionistInfos: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { nutritionistUserId: number, pageNo?: number, pageSize?: number, roleType: string }): Promise<PageList<UserEvaluateNutritionistInfoVO>> {
      return httpGet(`/platform-user/v5.0/pb/user-evaluate-nutritionist-infos/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  userNutritionistInfos: {
    /**
    * 口碑营养师
    */
    hotNutritionist(params: { authType?: number }): Promise<List<UserNutritionistVO>> {
      return httpGet(`/platform-user/v5.0/pb/user-nutritionist-infos/action/hot-nutritionist`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 营养师详情
    */
    nutritionistDetail(params: { userId: number }): Promise<UserNutritionistVO> {
      return httpGet(`/platform-user/v5.0/pb/user-nutritionist-infos/action/nutritionist-detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 银行名称查询列表
    */
    searchBank(params: { pageNo?: number, pageSize?: number }): Promise<PageList<BankInfo>> {
      return httpGet(`/platform-user/v5.0/pb/user-nutritionist-infos/action/search-bank`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app端营养师认证申请
    */
    apply(userNutritionistDTO: UserNutritionistDTO): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/user-nutritionist-infos/action/apply`, userNutritionistDTO).then((res:any) => res.data.data)
    },
    /**
    * app端营养师认证
    */
    register(userNutritionistDTO: UserNutritionistDTO): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/user-nutritionist-infos/action/register`, userNutritionistDTO).then((res:any) => res.data.data)
    },
    /**
    * 个人账户查询
    */
    searchAccount(): Promise<BankAccountVO> {
      return httpGet(`/platform-user/v5.0/pt/user-nutritionist-infos/action/search-account`).then((res:any) => res.data.data)
    },
    /**
    * 设置个人账户
    */
    setAccount(params: { accountName: string, accountNumber: string, bank: string }): Promise<boolean> {
      return httpGet(`/platform-user/v5.0/pt/user-nutritionist-infos/action/set-account`,  {params} ).then((res:any) => res.data.data)
    },
  },
  userPhoneAttributes: {
    /**
    * 用户渠道属性提交
    */
    submit(userActivityPhoneAttributes: UserActivityPhoneAttributes): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pb/user-phone-attributes/action/submit`, userActivityPhoneAttributes).then((res:any) => res.data.data)
    },
    /**
    * 用户注册属性和渠道属性匹配
    */
    register(userRegisterPhoneAttributes: UserRegisterPhoneAttributes): Promise<string> {
      return httpPost(`/platform-user/v5.0/pt/user-phone-attributes/action/register`, userRegisterPhoneAttributes).then((res:any) => res.data.data)
    },
  },
  userRelationFollows: {
    /**
    * 关注列表
    */
    listByUserId(params: { followType: string, pageNo?: number, pageSize?: number, targetUserId: number }): Promise<PageList<UserRelationVO>> {
      return httpGet(`/platform-user/v5.0/pb/user-relation-follows/action/listByUserId`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 批量关注
    */
    batchStar(batchRequest: BatchRequest<long>): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/user-relation-follows/action/batch-star`, batchRequest).then((res:any) => res.data.data)
    },
    /**
    * 关注列表
    */
    list(params: { followType: string, pageNo?: number, pageSize?: number }): Promise<PageList<UserRelationVO>> {
      return httpGet(`/platform-user/v5.0/pt/user-relation-follows/action/list`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 关注列表
    */
    listUserid(params: { followType: string, pageNo?: number, pageSize?: number }): Promise<List<long>> {
      return httpGet(`/platform-user/v5.0/pt/user-relation-follows/action/list-userid`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 关注
    */
    star(userRelationDTO: UserRelationDTO): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/user-relation-follows/action/star`, userRelationDTO).then((res:any) => res.data.data)
    },
    /**
    * 取消关注
    */
    unStar(userRelationDTO: UserRelationDTO): Promise<boolean> {
      return httpDelete(`/platform-user/v5.0/pt/user-relation-follows/action/unStar`, userRelationDTO).then((res:any) => res.data.data)
    },
  },
  userSitNutritionistInfos: {
    /**
    * 患者查询坐诊营养师
    */
    appUserGet(params: { userId: number }): Promise<UserSitNutritionistInfoAppVO> {
      return httpGet(`/platform-user/v5.0/pb/user-sit-nutritionist-infos/action/appUserGet`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPagePb(params: { pageNo?: number, pageSize?: number }): Promise<PageList<UserSitNutritionistInfoAppVO>> {
      return httpGet(`/platform-user/v5.0/pb/user-sit-nutritionist-infos/action/list-page-pb`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP查询坐诊营养师
    */
    appGet(): Promise<UserSitNutritionistInfoVO> {
      return httpGet(`/platform-user/v5.0/pt/user-sit-nutritionist-infos/action/appGet`).then((res:any) => res.data.data)
    },
    /**
    * APP修改坐诊营养师信息
    */
    appUpdate(userSitNutritionistInfoAppDTO: UserSitNutritionistInfoAppDTO): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/user-sit-nutritionist-infos/action/appUpdate`, userSitNutritionistInfoAppDTO).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<UserSitNutritionistInfoAppVO>> {
      return httpGet(`/platform-user/v5.0/pt/user-sit-nutritionist-infos/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  userThirdLogins: {
    /**
    * appleId三方登录授权
    */
    checkLoginApple(checkLoginAppleDTO: CheckLoginAppleDTO): Promise<ThirdLoginVO> {
      return httpPost(`/platform-user/v5.0/pb/user-third-logins/action/check-login-apple`, checkLoginAppleDTO).then((res:any) => res.data.data)
    },
    /**
    * 微信小程序用户登录授权
    */
    checkLoginMiniprogram(checkLoginMiniProgramDTO: CheckLoginMiniProgramDTO): Promise<ThirdLoginVO> {
      return httpPost(`/platform-user/v5.0/pb/user-third-logins/action/check-login-miniprogram`, checkLoginMiniProgramDTO).then((res:any) => res.data.data)
    },
    /**
    * APP端查第三方登录校验--qq
    */
    checkLoginQq(params: { accessToken: string }): Promise<ThirdLoginVO> {
      return httpGet(`/platform-user/v5.0/pb/user-third-logins/action/check-login-qq`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查第三方登录校验--微信
    */
    checkLoginWx(params: { code: string }): Promise<ThirdLoginVO> {
      return httpGet(`/platform-user/v5.0/pb/user-third-logins/action/check-login-wx`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 微信公众号登录第三方登录校验--微信公众号登录
    */
    checkLoginWxWeb(params: { code: string }): Promise<ThirdLoginVO> {
      return httpGet(`/platform-user/v5.0/pb/user-third-logins/action/check-login-wx-web`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查第三方登录--注册并绑定手机号
    */
    registerBindingPhone(bindingPhoneDTO: BindingPhoneDTO): Promise<ThirdLoginVO> {
      return httpPost(`/platform-user/v5.0/pb/user-third-logins/action/register-binding-phone`, bindingPhoneDTO).then((res:any) => res.data.data)
    },
    /**
    * APP取消第三方账户绑定
    */
    cancelThirdUserBinding(params: { Type: number }): Promise<boolean> {
      return httpGet(`/platform-user/v5.0/pt/user-third-logins/action/cancel-third-user-binding`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP更改手机号
    */
    changeLoginPhone(changeLoginPhoneDTO: ChangeLoginPhoneDTO): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/user-third-logins/action/change-login-phone`, changeLoginPhoneDTO).then((res:any) => res.data.data)
    },
    /**
    * APP更改手机号校验
    */
    checkLoginPhone(checkLoginPhoneDTO: PhoneVerifyDTO): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/user-third-logins/action/check-login-phone`, checkLoginPhoneDTO).then((res:any) => res.data.data)
    },
    /**
    * APP查询用户信息
    */
    getUserAccountMsg(): Promise<ThirdUserAccountVO> {
      return httpGet(`/platform-user/v5.0/pt/user-third-logins/action/get-user-account-msg`).then((res:any) => res.data.data)
    },
    /**
    * APP第三方账户绑定
    */
    thirdUserBinding(thirdUserBindingDTO: ThirdUserBindingDTO): Promise<ThirdUserBindingVO> {
      return httpPost(`/platform-user/v5.0/pt/user-third-logins/action/third-user-binding`, thirdUserBindingDTO).then((res:any) => res.data.data)
    },
    /**
    * APP端查第三方登录--微信公众号绑定手机号
    */
    wxwebBindingPhone(wxWebBindingPhoneDTO: PhoneVerifyDTO): Promise<ThirdLoginVO> {
      return httpPost(`/platform-user/v5.0/pt/user-third-logins/action/wxweb-binding-phone`, wxWebBindingPhoneDTO).then((res:any) => res.data.data)
    },
  },
  wxMps: {
    /**
    * 微信公众号jsapi加签名
    */
    jsapiSignature(params: { url: string }): Promise<WxJsapiSignature> {
      return httpGet(`/platform-user/v5.0/pb/wx-mps/action/jsapi-signature`,  {params} ).then((res:any) => res.data.data)
    },
  },
  dietitianServiceSettings: {
    /**
    * app查询营养师价目详情(营养师设置价格用不含当前登录用户的购买状态)
    */
    getSettings(params: { kid: number }): Promise<DietitianServiceSettingVO> {
      return httpGet(`/platform-user/v5.0/pt/dietitian-service-settings/action/getSettings`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端设置服务价格
    */
    modifySettings(dto: DietitianServiceSettingDTO): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/dietitian-service-settings/action/modifySettings`, dto).then((res:any) => res.data.data)
    },
  },
  excelExports: {
    /**
    * 合作方导出数据
    */
    export(excelExportRecord: ExcelExportRecord): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/excel-exports/action/export`, excelExportRecord).then((res:any) => res.data.data)
    },
  },
  excelImports: {
    /**
    * 合作方导入数据
    */
    import(params: { createDate?: string, createUserId?: number, importCode?: string, importDesc?: string, importExcel?: string, importParams?: string, importStatus?: number, kid?: number, lastUpdateDate?: string, lastUpdateUserId?: number, resultExcel?: string, withoutTitle?: boolean }): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/excel-imports/action/import`,  {params} ).then((res:any) => res.data.data)
    },
  },
  lecturerAccountPeriods: {
    /**
    * APP端确认收益
    */
    confirmProfit(kids: number[]): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/lecturer-account-periods/action/confirmProfit`, kids).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<LecturerAccountPeriodVO>> {
      return httpGet(`/platform-user/v5.0/pt/lecturer-account-periods/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  questionnaireProblemAnswers: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<QuestionnaireProblemAnswer>> {
      return httpGet(`/platform-user/v5.0/pt/questionnaire-problem-answers/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  questionnaireProblemTags: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<QuestionnaireProblemTag>> {
      return httpGet(`/platform-user/v5.0/pt/questionnaire-problem-tags/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  questionnaireProblems: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<QuestionnaireProblem>> {
      return httpGet(`/platform-user/v5.0/pt/questionnaire-problems/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  shellAccountLogs: {
    /**
    * app端查询用户营养贝账户明细
    */
    listPage(dto: ShellAccountLogAppQueryDTO): Promise<PageList<ShellAccountLogDTO>> {
      return httpPut(`/platform-user/v5.0/pt/shell-account-logs/action/list-page`, dto).then((res:any) => res.data.data)
    },
  },
  shellAccounts: {
    /**
    * app获取消费账户可用营养贝数目
    */
    getAvaliableNum(): Promise<number> {
      return httpGet(`/platform-user/v5.0/pt/shell-accounts/action/getAvaliableNum`).then((res:any) => res.data.data)
    },
    /**
    * app获取收益账户可用营养贝数目
    */
    getIncomeAvaliableNum(): Promise<number> {
      return httpGet(`/platform-user/v5.0/pt/shell-accounts/action/getIncomeAvaliableNum`).then((res:any) => res.data.data)
    },
  },
  userAccountPeriods: {
    /**
    * APP端确认收益
    */
    confirmProfit(dto: UserAccountPeriodDTO): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/user-account-periods/action/confirmProfit`, dto).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number, userRole: string }): Promise<PageList<UserAccountPeriodVO>> {
      return httpGet(`/platform-user/v5.0/pt/user-account-periods/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  userCheckInfos: {
    /**
    * 查询认证状态
    */
    searchCheckStatus(): Promise<CheckInfoVO> {
      return httpGet(`/platform-user/v5.0/pt/user-check-infos/action/search-checkStatus`).then((res:any) => res.data.data)
    },
  },
  userDemandPortrayals: {
    /**
    * app查询画像详情
    */
    detail(): Promise<UserDemandPortrayal> {
      return httpGet(`/platform-user/v5.0/pt/user-demand-portrayals/action/detail`).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<UserDemandPortrayal>> {
      return httpGet(`/platform-user/v5.0/pt/user-demand-portrayals/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app查询画像名称
    */
    name(): Promise<string> {
      return httpGet(`/platform-user/v5.0/pt/user-demand-portrayals/action/name`).then((res:any) => res.data.data)
    },
    /**
    * app查询画像状态
    */
    status(): Promise<boolean> {
      return httpGet(`/platform-user/v5.0/pt/user-demand-portrayals/action/status`).then((res:any) => res.data.data)
    },
    /**
    * app更改用户画像
    */
    update(userDemandPortrayal: UserDemandPortrayal): Promise<boolean> {
      return httpPost(`/platform-user/v5.0/pt/user-demand-portrayals/action/update`, userDemandPortrayal).then((res:any) => res.data.data)
    },
  },
  userDeviceLoginLogs: {
    /**
    * APP端设备删除接口
    */
    delete(params: { kid: number }): Promise<boolean> {
      return httpGet(`/platform-user/v5.0/pt/user-device-login-logs/action/delete`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端管理登录详情接口
    */
    detail(params: { kid: number }): Promise<UserDeviceLoginLog> {
      return httpGet(`/platform-user/v5.0/pt/user-device-login-logs/action/detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    *  APP端查询设备数据
    */
    divList(): Promise<List<UserDeviceLoginLog>> {
      return httpGet(`/platform-user/v5.0/pt/user-device-login-logs/action/div-list`).then((res:any) => res.data.data)
    },
  },
  userQuestionnaireAnswers: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<UserQuestionnaireAnswer>> {
      return httpGet(`/platform-user/v5.0/pt/user-questionnaire-answers/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  userQuestionnaires: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<UserQuestionnaire>> {
      return httpGet(`/platform-user/v5.0/pt/user-questionnaires/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  userSitNutritionistBindTags: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<UserSitNutritionistBindTag>> {
      return httpGet(`/platform-user/v5.0/pt/user-sit-nutritionist-bind-tags/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  userSitNutritionistLogs: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<UserSitNutritionistLog>> {
      return httpGet(`/platform-user/v5.0/pt/user-sit-nutritionist-logs/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  userSitNutritionistTags: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<UserSitNutritionistTag>> {
      return httpGet(`/platform-user/v5.0/pt/user-sit-nutritionist-tags/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  userTagInfos: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<UserTagInfo>> {
      return httpGet(`/platform-user/v5.0/pt/user-tag-infos/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP用户类型打标签
    */
    tag(types: ListRequest<int>): Promise<boolean> {
      return httpPut(`/platform-user/v5.0/pt/user-tag-infos/action/tag`, types).then((res:any) => res.data.data)
    },
  },
}