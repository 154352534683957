import React, { useState, useEffect } from 'react'
import './tkhospital.css'
import  { environmentIsNative } from 'utils/reactNativeMessage';
import isIphoneX from 'utils/isIphoneX';


const TKhospital: PageComponent = ({
...props
}) => {
	const [iframeUrl, setIframeUrl] = useState('')
	const windowsHeight = window.innerHeight;
	useEffect(() => {
		let locationState = props.location &&  props.location.state
		let pdf = locationState.pdfUrl
		console.log("TKhospital-pdfUrl:",locationState,pdf)
		setIframeUrl(pdf)
		reSetSize()
	}, [props])
	
	const reSetSize=()=> {
		let iframeContent = document.getElementById("iframeContent");
		if (iframeContent) {
			iframeContent.style.cssText = 'width:100%;height:100%'
		}
	}
	
  // 返回按钮
  const handleBack = () => {
		window.history.back()
  }
	const renderHd = () => {
    return (
			<div className="hdWrap" id="header">
				{!!environmentIsNative() ? 	<div style={!!isIphoneX() ? {height:'0.88rem'} : {height:'0.4rem'}}></div> :null }
				<div className ='hdCotentWrap'>	
					<span className="iconfont iconback " onClick={handleBack} />
					<div className="name">泰康合作医院清单</div>
				</div>
			</div>
    )
	}
	
	let paddingTopH = !!environmentIsNative() ? (!!isIphoneX() ? '1.94rem' :"1.46rem") : '1.06rem'
	return (
		<div className='tkHospital' style={{paddingTop:paddingTopH,height:windowsHeight }}>
			{renderHd()}
			<iframe id='iframeContent' title='泰康合作医院清单' src={iframeUrl}></iframe>
	
		</div>
	)
}
export default TKhospital