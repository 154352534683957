import CourseDetailPage from './CourseDetail'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncCourseDetailPage = Loadable({
    loader: () => import('./CourseDetail'),
    loading: MyLoadingComponent
})
// component: CourseDetailPage,

const CourseDetail: Module = {
    name: 'coursedetail',
    routes: [
        {
            name: '课程详情预览',
            path: '/coursedetail/sharepreview/:kid',
            component: asyncCourseDetailPage,
        },
    ],
}
export default CourseDetail