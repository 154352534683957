import QuestionaireDetailPage from './QuestionaireDetail'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncQuestionaireDetailPage = Loadable({
  loader: () => import('./QuestionaireDetail'),
  loading: MyLoadingComponent,
})
// component: QuestionaireDetailPage,
const QuestionaireDetail: Module = {
  name: 'questionairedetail',
  routes: [
    {
      name: '问卷详情',
      path: '/questionairedetail/share',
      component: asyncQuestionaireDetailPage,
    },
  ],
}
export default QuestionaireDetail
