import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncHuize = Loadable({
	loader: () => import('./Huize'),
	loading: MyLoadingComponent
})

const Huize: Module = {
	name: 'Huize',
	routes: [
		{
			name: '惠泽特医',
			path: '/mall/activepage/huize/:kid',
			component: asyncHuize,
		},
	],
}
export default Huize
