import React, { useState, useEffect } from 'react'
import './tkhospital.css'
import  { environmentIsNative } from 'utils/reactNativeMessage';
import isIphoneX from 'utils/isIphoneX';


const TKStrickenIntro: PageComponent = ({
...props
}) => {
	const [iframeUrl, setIframeUrl] = useState('')
	const windowsHeight = window.innerHeight;
	useEffect(() => {
		let locationState = props.location &&  props.location.state
		let iframeUrl = locationState.iframeUrl
		setIframeUrl(iframeUrl)
		reSetSize()
	}, [props])
	
	const reSetSize=()=> {
		let iframeContent = document.getElementById("iframeContent");
		if (iframeContent) {
			iframeContent.style.cssText = 'width:100%;height:100%'
		}
	}
	
  // 返回按钮
  const handleBack = () => {
		window.history.back()
  }
	const renderHd = () => {
    return (
			<div className="hdWrap" id="header">
				{!!environmentIsNative() ? 	<div style={!!isIphoneX() ? {height:'0.88rem'} : {height:'0.4rem'}}></div> :null }
				<div className ='hdCotentWrap'>	
					<span className="iconfont iconback " onClick={handleBack} />
					<div className="name">99种重疾简介</div>
				</div>
			</div>
    )
	}
	
	let paddingTopH = !!environmentIsNative() ? (!!isIphoneX() ? '1.94rem' :"1.46rem") : '1.06rem'
	return (
		<div className='tkHospital' style={{paddingTop:paddingTopH,height:windowsHeight }}>
			{renderHd()}
			<iframe id='iframeContent' name='iframeContent' title='99种重疾简介' src={'https://act.tk.cn/58tongzhen-medical/#/DiseaseList'}></iframe>
	
		</div>
	)
}
export default TKStrickenIntro