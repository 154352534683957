/**
 * 商品简介
 */
import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react'
import { getIntPrice, getDecimalPrice, formPrice } from 'utils/price'
import { ProductDetailVO, ProductAttributeValueVO } from 'api/mall'
import GoodSpec from '../GoodSpec'
import { AttributeType } from '../../Share'
import assets from 'assets'
import GoodParams from '../GoodParams'
import { Icon, Modal } from 'antd-mobile'
import './good-summary.css'
import { ProductContext } from 'modules/goods/ProductDetail'
import InlineItemLink from '../InlineItemLink'

interface ModalContext {
  setModalShow: Function
}
const ModalContext = React.createContext(null as ModalContext)

const AttrModal: React.FC<{}> = props => {
  const [modalShow, setModalShow] = useState<boolean>(true)
  return (
    <ModalContext.Provider value={{ setModalShow }}>
      <Modal popup visible={modalShow} onClose={() => setModalShow(false)} maskClosable={true} animationType="slide-up">
        {props.children}
      </Modal>
    </ModalContext.Provider>
  )
}
const ServiceModal: React.FC<{}> = props => {
  const modalContext = useContext(ModalContext)
  const productContext = useContext(ProductContext)

  const dismissModal = useCallback(() => {
    modalContext.setModalShow(false)
  }, [modalContext])

  const serviceData = useMemo(() => {
    let service = productContext.goods && productContext.goods.attributes
    if (!service) return
    return service.filter(item => item.attributeType === AttributeType.Service)
  }, [productContext.goods])

  return (
    <div className="serviceDesc">
      <div className="serviceTitle">服务说明</div>
      <div className="closeIconWrap" onClick={dismissModal}>
        <Icon type="close" size="sm" color="#666" />
      </div>
      {serviceData.map((item: any, index: number) => {
        return (
          <div className="serviceSection" key={`serviceNode${index}`}>
            <div className="serviceContentTitle">{item.attributeName}</div>
            <div className="serviceContentValue">{item.value}</div>
          </div>
        )
      })}
    </div>
  )
}

const ParamsModal: React.FC<{}> = props => {
  const modalContext = useContext(ModalContext)
  const productContext = useContext(ProductContext)

  const dismissModal = useCallback(() => {
    modalContext.setModalShow(false)
  }, [modalContext])

  const paramsData = useMemo(() => {
    let service = productContext.goods && productContext.goods.attributes
    if (!service) return
    return service.filter(item => item.attributeType === AttributeType.Params)
  }, [productContext.goods])

  return (
    <div className="serviceDesc paramsWrap">
      <div className="serviceTitle paramTitle">商品参数</div>
      <div className="closeIconWrap" onClick={dismissModal}>
        <Icon type="close" size="sm" color="#666" />
      </div>
      {paramsData.map((item: any, index: number) => {
        return (
          <div className="paramsSection" key={`paramsNode${index}`}>
            <div className="paramsKey">{item.attributeName}</div>
            <div className="paramsValue">{item.value}</div>
          </div>
        )
      })}
    </div>
  )
}

const SkuModal: React.FC<{}> = () => {
  return <div></div>
}

// 认证类别 0 无公害 1 绿色 2 有机 3地理 4 其他 5 无
const certLogos: { [key: number]: string } = {
  0: assets.goods.harmless_logo,
  1: assets.goods.green_logo,
  2: assets.goods.organic_logo,
  3: assets.goods.geography_logo,
  4: assets.goods.others_logo,
  5: assets.goods.others_logo,
  19: assets.goods.inspection_logo,
  20: assets.goods.trademark_logo,
}
const GoodSummary: React.FC<{}> = props => {
  const productContext = useContext(ProductContext)
  let data = productContext.goods || {}
  const [modalShow, setModalShow] = useState<boolean>()
  const [modalType, setModalType] = useState<string>()
  console.log('productContext.goods', productContext)
  // let { certs = [], suit, noSuit } = productContext.goods

  const attributes = useMemo(() => {
    return productContext.goods && productContext.goods.attributes
  }, [productContext.goods])

  //const certs = useMemo(() => certs.filter(item => !!item.certPic), [certs])

  let skuSpec = useMemo(() => {
    return attributes && attributes.filter(item => item.attributeType === AttributeType.Spec)
  }, [attributes])

  let services = useMemo(() => {
    return attributes && attributes.filter(item => item.attributeType === AttributeType.Service)
  }, [attributes])

  const params = useMemo(() => {
    return attributes && attributes.filter(item => item.attributeType === AttributeType.Params)
  }, [attributes])

  // let adapt = useMemo(() => {
  //   return attributes.filter(item => item.attributeType === AttributeType.Adapt)
  // }, [attributes])
  // const adapt = useMemo(() => {
  //   if (!suit) return
  //   let value = suit.split(',').join('、')
  //   return { title: '适宜人群', value }
  // }, [suit])

  // let taboo = useMemo(() => {
  //   return attributes.filter(item => item.attributeType === AttributeType.Taboo)
  // }, [attributes])
  //服务说明显示控制
  // const taboo = useMemo(() => {
  // 	if (!noSuit) return
  // 	let
  // }, [])

  const addrName = useMemo(() => {
    if (!data || !data.address) return
    let addrObj = data.address && data.address[0]
    return `${addrObj.provinceName}${addrObj.cityName}`
  }, [data])

  const servicesTotal = useMemo(() => {
    let addedStr = ''
    services &&
      services.forEach((item, index) => {
        //加上下一个是否会超过限制
        let tempAdded = `${addedStr} ${item.attributeName}`
        if (addedStr.length < 14 && tempAdded.length <= 14) {
          addedStr = tempAdded
        }
      }, services)
    return addedStr.trim()
  }, [services])

  //商品参数
  const goodParams = useMemo(() => {
    let goodParamsTemp: string[] =
      params &&
      params.slice(0, 2).map((item, index) => {
        return item.attributeName
      })
    if (!goodParamsTemp) return ''
    return `${goodParamsTemp.toString()}...`
  }, [params])

  const onClose = () => {
    setModalShow(false)
  }
  // 显示商品服务和参数modal
  // const showGoodModal = (type: string, attributeData: any = []) => (e: any) => {
  //   e.preventDefault()
  //   setModalShow(true)
  //   switch (type) {
  //     case 'service':
  //     case 'params':
  //     case 'adapt':
  //     case 'taboo':
  //       return <GoodParams attributeData={attributeData} type={type} onClose={onClose} />
  //     case 'spec':
  //       return <GoodSpec specData={data} chosedSpec={chosedSpec} onClose={onClose} />
  //     default:
  //       return '请指定类型'
  //   }
  // }
  let [chosedSpecStr, setChosedSpecStr] = useState('')
  // useEffect(() => {
  //   if (!chosedSpec || !Object.keys(chosedSpec).length) {
  //     setChosedSpecStr('')
  //     return
  //   }
  //   //对选择的规格排序
  //   let orderArr = skuSpec.map((item, index) => {
  //     return chosedSpec[item.attributeName]
  //   })
  //   let orderStr = orderArr.join('/')
  //   setChosedSpecStr(orderStr)
  // }, [skuSpec, chosedSpec])

  // const renderOptionalNode = (type: string, attrData: ProductAttributeValueVO[]) => {
  //   if (!attrData.length) return null
  //   return (
  //     <div className="infoRow rowBetween" onClick={showGoodModal(type, attrData)}>
  //       <div className="inlineRow">
  //         <div className="infoLabel">{attrData[0].attributeTypeName}</div>
  //         <div className="infoValue">{attrData[0].attributeName}</div>
  //       </div>
  //       <Icon type="right" className="arrowRightIcon" />
  //     </div>
  //   )
  // }

  const showModal = (type: string) => {
    setModalType(type)
    setModalShow(true)
  }
  const modalContent = useMemo(() => {
    switch (modalType) {
      case 'params':
        return <ParamsModal />
      case 'service':
        return <ServiceModal />
      case 'sku':
        return <SkuModal />
      default:
        return null
    }
  }, [modalType])
  return (
    <div className="good-summary">
      <div className="goodInfoWrap">
        <div className="priceWrap">
          <span className="priceSignal">¥</span>
          <span className="priceTxt">{getIntPrice(data.salePrice || 0)}</span>
          <span className="priceFloatTxt">.{getDecimalPrice(data.salePrice || 0)}</span>
          <span className="priceDelete">¥{formPrice(data.originalPrice || 0)}</span>
        </div>
        <div className="goodNameTxt">{data.productName}</div>
      </div>
      <div className="chooseArea">
        <div className="certRow">
          <div className="standardWrap">
            <span className="pageTitle safeTxt">质量保障</span>
          </div>
          <div>
            {/* {certs.map((item, index) => (
              <div
                className="certWrap"
                key={`cert${index}`}
                onClick={props.onPressStandard && props.onPressStandard(item)}>
                <img alt="" src={certLogos[item.certType]} className="certLogo" />
              </div>
            ))} */}
          </div>
        </div>
        <InlineItemLink title="发货">
          {addrName} 快递:{formPrice(data.freightFee)}
        </InlineItemLink>
        <InlineItemLink
          title="服务说明"
          onClick={() => {
            showModal('service')
          }}>
          {servicesTotal
            ? servicesTotal.split(' ').map((item, index) => (
                <div className="inlineRow serviceItemWrap" key={`service${index}`}>
                  <Icon type="check-circle" className="serviceIcon" size="md" />
                  <div key={index} className="infoValue">
                    {item}
                  </div>
                </div>
              ))
            : null}
        </InlineItemLink>
        <InlineItemLink
          title="商品参数"
          onClick={() => {
            showModal('params')
          }}>
          {goodParams}
        </InlineItemLink>
        <InlineItemLink title="选择规格" onClick={() => showModal('sku')}></InlineItemLink>
        {/* <div className="infoRow rowBetween" onClick={showGoodModal('service', services)}>
          <div className="inlineRow">
            <div className="infoLabel">服务说明</div>
            {servicesTotal
              ? servicesTotal.split(' ').map((item, index) => (
                  <div className="inlineRow serviceItemWrap" key={`service${index}`}>
                    <Icon type="check-circle" className="serviceIcon" size="md" />
                    <div key={index} className="infoValue">
                      {item}
                    </div>
                  </div>
                ))
              : null}
          </div>
          <Icon type="right" className="arrowRightIcon" />
        </div>
        <div className="infoRow rowBetween" onClick={showGoodModal('params', params)}>
          <div className="commonStyles.inlineRow">
            <div className="infoLabel">商品参数</div>
            <div className="infoValue">{goodParams}</div>
          </div>
          <Icon type="right" className="arrowRightIcon" />
        </div>
        <div
          className={`infoRow rowBetween ${adapt.length || taboo.length ? null : 'lastLine'}`}
          onClick={showGoodModal('spec', data)}>
          <div className="inlineRow">
            <div className="infoLabel">选择规格</div>
            <div className="infoValue">{chosedSpecStr}</div>
          </div>
          <Icon type="right" className="arrowRightIcon" />
        </div>
        {renderOptionalNode('adapt', adapt)}
        {renderOptionalNode('taboo', taboo)} */}
      </div>
      <div className="pageSection">
        <div className="pageTitle">商品简介</div>
        <div className="summaryTxt">{data.subTitle}</div>
      </div>

      {/* <Modal popup visible={modalShow} onClose={() => setModalShow(false)} maskClosable={true} animationType="slide-up">
        {showModal()}
			</Modal> */}
      <ModalContext.Provider value={{ setModalShow }}>
        <Modal
          popup
          visible={modalShow}
          onClose={() => setModalShow(false)}
          maskClosable={true}
          animationType="slide-up">
          {modalContent}
        </Modal>
      </ModalContext.Provider>
    </div>
  )
}

export default GoodSummary
