import React, { useState, useEffect, useCallback } from 'react'
import assets from 'assets'
import mallApi, { CouponRecordsMyVO, CouponRecordsAppDTO, CouponRecordsAppBaseVO, CouponRecordsAppVO } from 'api/mall'
import './couponStyle.css'
import LoadingView from './LoadingView'
import { Toast, Flex } from 'antd-mobile'
import isIphoneX from 'utils/isIphoneX'
interface Props {
  onItemPress?: (_?: CouponRecordsAppVO) => void
  onDismiss?: (_?: any) => void
  callback?: (_?: any) => void
  selectId?: number

  couponRecordsParams?: CouponRecordsAppDTO[]
}
const CouponModal: React.FC<Props> = ({
  onItemPress,
  onDismiss,
  callback,
  selectId,
  couponRecordsParams,
  ...props
}) => {
  const [showLoading, setShowLoading] = useState(false)
  let [selectCouponId, setSelectCouponId] = useState(selectId) // 选中的优惠券id
  let [selectCouponObj, setSelectCouponObj] = useState({} as CouponRecordsAppVO) // 选中的优惠券信息obj
  const [listData, setListData] = useState([] as CouponRecordsMyVO[])

  useEffect(() => {
    setSelectCouponId(selectId)
  }, [selectId])

	useEffect(() => {
		
    setShowLoading(true)
    mallApi.couponRecordss
      .useCouponList(couponRecordsParams)
      .then((res: CouponRecordsAppBaseVO) => {
        // console.warn('modal-优惠券列表res', res)
        setShowLoading(false)
				setListData(res.list || [])
				if (selectId) {
          const item = res.list.find(coupon => coupon.kid === selectId)
          setSelectCouponObj(item)
        }
      })
      .catch(err => {
        setShowLoading(false)
        if (err.code >= 1000) {
          let errRes = err.userInfo || {}
          Toast.fail(errRes.msg || '网络出小差啦')
        }
      })
  }, [couponRecordsParams,selectId ])

  // 点击每一个item选中 再次点击取消选中 与其他item互斥
  const handleItem = useCallback(
    (item: CouponRecordsAppVO) => {
      if (item.kid === selectCouponId) {
        // 点击选中的 执行取消选中
        setSelectCouponObj({})
        setSelectCouponId(null)
      } else {
        setSelectCouponObj(item)
        setSelectCouponId(item.kid)
      }
    },
    [selectCouponId],
  )
  // 右上角关闭按钮 只关闭modal
  const handleClose = useCallback(() => {
    onDismiss && onDismiss()
  }, [onDismiss])

  // 确认按钮  将选中的优惠券数据传出去 并关闭modal
  const handleOkBtn = useCallback(() => {
    onDismiss && onDismiss()
    onItemPress && onItemPress(selectCouponObj)
  }, [onDismiss, onItemPress, selectCouponObj])

	const coupon_selected = require('../../../assets/image/coupon/coupon_selected.png')
 const coupon_unSelect = require('../../../assets/image/coupon/coupon_unSelect.png')
  const CouponItem = useCallback(
    (item: CouponRecordsMyVO,index:number) => {
      return (
        <div className='couponItemWrap' key={`CouponItem${index}`}
          onClick={() => handleItem(item)}>
          <div className='couponItemLeft'>
            <div className= 'titleTxt'>
              {item.couponName}
						</div>
						<div
							style={{
								display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '0.1rem',
								marginBottom: '0.16rem',
              }}>
             
							<div className='linearGradient'>
								
								{item.couponType === 1 ? '限商品' : '全e店'}
							</div>
              <span className='discountInfo'>
                {item.useCondition === 0
                  ? `立减${item.faceValue / 100}`
                  : `满${item.conditionPrice / 100}减${item.faceValue / 100}`}
              </span>
            </div>
          
            <div className='timeTxt'>使用时间：</div>
            <span className='timeTxt'>
              {item.validPeriodStartTime.slice(0, -3)}至{item.validPeriodEndTime.slice(5, -3)}
            </span>
            
					</div>
          <div className='couponItemRight'>
            <div style={{ flexDirection: 'row', alignItems: 'baseline' }}>
						<span style={{ color: '#F40D0C', fontSize:'0.34rem' }}>￥</span>
							<span style={{ fontWeight: 700, fontSize: '0.7rem', color: '#F40D0C' }}>
                {item.faceValue / 100}
              </span>
            </div>
            <div className='useBtnWrap'>
              <span className='useBtnTxt'>立即使用</span>
            </div>
            <div className='cicleTop'></div>
            <div className='cicleBottom'></div>
          </div>
					<div className='selectImgWrap'>
					<img className="selectImg" src={item.kid === selectCouponId ? coupon_selected : coupon_unSelect} alt="" />
						
          </div>
        </div>
      )
    },
    [handleItem, selectCouponId],
  )
  return (
    <div className='CouponModalWrap'>
      <div className='overlayTitle'>选择优惠券</div>
      <div onClick={handleClose} className='closeIconWrap'>
			<img className='close-icon' src={require('../../../assets/image/goods/close-icon.png')} alt=""/>
      </div>
			
			{showLoading ? (
				<LoadingView />
       
      ) : (
					<div style={{
						paddingBottom: '0.20rem',
            paddingLeft: '0.33rem',
            paddingRight: '0.37rem',
					}}>
						{
							!listData.length ?
								<div style={{
									minHeight: '200px',
									fontSize: '0.26rem',
									color: '#777',
									lineHeight: '200px'}}>暂无可用优惠券</div>
								:listData.map((item, index) => {
								return CouponItem(item,index)
							})
						}
					</div>
				
      )}
      <div
				style={{
					display:'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
          paddingTop: '0.23rem',
          paddingBottom: !!isIphoneX() ? '0.57rem' :'0.23rem' ,
        }}>
        <div
          onClick={handleOkBtn}
					style={{
						lineHeight: '0.64rem',
            height: '0.64rem',
            width: '5.34rem',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0.39rem',
						backgroundColor: '#FF9624',
						fontSize: '0.32rem', fontWeight: 700, color: '#fff'
          }}>
          确认
        </div>
      </div>
    </div>
  )
}

export default CouponModal

