import ArticleSharePage from './Article'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncArticleSharePage = Loadable({
    loader: () => import('./Article'),
    loading: MyLoadingComponent
})
// component: ArticleSharePage,
const lecturerArticle: Module = {
    name: 'lecturerarticle',
    routes: [
        {
            name: '讲师文章详情',
            path: '/lecturerarticle/share/:kid',
            component: asyncArticleSharePage,
        },
    ],
}
export default lecturerArticle
