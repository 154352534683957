import {httpPost,httpGet,httpPut,httpDelete} from "services/http"
type int = number;
		type List<T> = Array<T>
		type Collection<T> = Array<T>
		
type JsonNode = any
type long = number
interface KeyValueDTO<T,T1>{key:T,value:T1}
export interface EvaluateVipRecordDTO {
  /** 购买时间 */
  buyTime?: string
  /** 套餐编码，多个英文逗号分隔 */
  packageCodes?: string
  /** 编码 */
  recordCode?: string
  /** 名称 */
  recordName?: string
  /** 类型（1-问卷，2-会员，3-套餐） */
  recordType?: number
  /** 临时会员 0-否 1-是 */
  tempFlag?: number
  /** 时间单位 */
  timeUnit?: string
  /** 用户ID */
  userId?: number
  /** 有效时间 */
  validTime?: number
}

export interface Expert {
  /** 擅长 */
  goodAt?: string
  /** 简介 */
  synopsis?: string
  /** 标签id集合 */
  tagIds?: number[]
  /** 标签集合 */
  tagName?: string[]
  /** 头衔 */
  title?: string
  /** ID */
  userId?: number
  /** 图像 */
  userImg?: string
  /** 姓名 */
  userName?: string
  /** 一周排班信息 */
  weekDays?: WeekDay[]
}

export interface WeekDay {
  /** 是否为当天 */
  current?: boolean
  /** 对应的日期 */
  ddate?: string
  /** 是否可约 */
  enable?: boolean
  /** 汉字 */
  name?: string
  /** 数字 */
  num?: number
  /** 当天的价格,单位分 */
  price?: number
  /** 对应的日期(字符串) */
  sdate?: string
}

export interface PageList<T> {
  /** 数据总条数，前端接口可忽略改字段 */
  count?: number
  /** 数据集合 */
  entities?: T[]
  /** 当前页码 */
  pageNo?: number
  /** 每页条数 */
  pageSize?: number
}

export interface ExpertDay {
  /** 号源数(0约满1未满) */
  amount?: number
  /** 唯一识别码 */
  idCode?: string
  /** 权益状态 */
  joinVip?: boolean
  /** 该时段总价格,单位分 */
  price?: number
  /** 该时段折扣后总价格,单位分 */
  priceVIP?: number
  /** 开始时间 */
  timeBegin?: string
  /** 结束时间 */
  timeEnd?: string
  /** 会员状态 */
  vipState?: boolean
}

export interface EvaluateClassifyHomePage {
  /** 用户购买状态信息 */
  buyRecord?: EvaluateQuestionnaireRecordVO
  /** 分类编码 */
  classifyCode?: string
  /** 分类名称 */
  classifyName?: string
  /** 计数 */
  count?: number
  /** 描述 */
  description?: string
  /** 免费状态，true免费 false收费 */
  free?: boolean
  /** 图片ICON */
  imgUrl?: string
  /** 原价格 */
  originalPrice?: number
  /** 价格期限 */
  pricePeriod?: string
  /** 现价格 */
  salePrice?: number
  /** 显示计数（0不显示 1显示） */
  showCount?: number
  /** 显示价格（0不显示 1显示） */
  showPrice?: number
  /** 排序（越小越靠前） */
  sort?: number
  /** 类型（1问卷 2分类 3预告） */
  type?: number
}

export interface EvaluateQuestionnaireRecordVO {
  /** 开始时间 */
  beginTime?: string
  /** 购买状态（true购买过 false未购买过） */
  bought?: boolean
  /** 综合截止时间 */
  endTime?: string
  /** 是否有效（true有效 false无效） */
  valid?: boolean
}

export interface EvaluateClassifyNode {
  /** 用户购买状态信息 */
  buyRecord?: EvaluateQuestionnaireRecordVO
  /** 子节点 */
  children?: EvaluateClassifyNode[]
  /** 总问卷数 */
  count?: number
  /** 免费状态，true免费 false收费 */
  free?: boolean
  id?: string
  /** 图标ICON */
  imgUrl?: string
  /** 业务唯一ID */
  kid?: number
  /** 分类/问卷名称 */
  name?: string
  /** 套餐状态，true套餐 false非套餐 */
  packageFlag?: boolean
  parentId?: string
  /** 父级ID */
  parentKid?: number
  /** 问卷有效价格信息 */
  priceInfo?: EvaluateClassifyPriceItemValidVO
  /** 类型 1分类 2问卷 */
  type?: number
  /** 分类/问卷编码 */
  value?: string
}

export interface EvaluateClassifyPriceItemValidVO {
  /** 是否显示会员价 */
  joinVip?: boolean
  /** 原价格 */
  originalPrice?: number
  /** 价格编码 */
  priceCode?: string
  /** 分类价格信息表KID */
  priceKid?: number
  /** 价格类型 1 问卷 2 会员 3套餐 */
  priceType?: number
  /** 现价格 */
  salePrice?: number
  /** 时间单位 */
  timeUnit?: string
  /** 有效时间 */
  validTime?: number
  /** 会员价 */
  vipPrice?: number
}

export interface EvaluateClassifyPriceVO {
  /** 用户购买状态信息 */
  buyRecord?: EvaluateQuestionnaireRecordVO
  /** 问卷编码，多个英文逗号分隔 */
  classifyCodes?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 排序（越小越靠前） */
  displaySort?: number
  /** 价格条目 */
  itemList?: EvaluateClassifyPriceItem[]
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 价格类型 1 问卷 2 会员 3套餐 */
  priceType?: number
  /** 商品介绍 */
  saleDesc?: string
  /** 售卖商品名称 */
  saleName?: string
  /** 上架状态：0->上架；1->下架 */
  shelveFlag?: number
}

export interface EvaluateClassifyPriceItem {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 排序 */
  displaySort?: number
  /** 是否显示会员价 */
  joinVip?: boolean
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 原价格 */
  originalPrice?: number
  /** 分类价格信息表KID */
  priceKid?: number
  /** 现价格 */
  salePrice?: number
  /** 时间单位 */
  timeUnit?: string
  /** 有效时间 */
  validTime?: number
  /** 会员价格 */
  vipPrice?: number
}

export interface ReportTotal {
  /** 访问者（被赠送的人） */
  accessUserId?: number
  /** 分类编码 */
  classifyCode?: string
  /** 分类名称 */
  classifyName?: string
  /** 报告组 */
  groups?: ReportGroup[]
  /** 成员年龄 */
  memberAge?: number
  /** 成员性别 */
  memberGender?: number
  /** 成员标识 */
  memberKid?: number
  /** 成员名称 */
  memberName?: string
  /** 成员类型 */
  memberType?: string
  /** 报告唯一标识 */
  reportKid?: number
  /** 报告名称 */
  reportName?: string
  /** 报告类型 */
  reportType?: string
}

export interface ReportGroup {
  description?: string
  display?: boolean
  groupCode?: string
  groupName?: string
  iconUrl?: string
  sort?: number
  units?: ReportUnit<IReportUnitData>[]
}

export interface IReportUnitData {
}

export interface ReportUnit<T> {
  data?: T[]
  description?: string
  display?: boolean
  evaluates?: ReportUnitResult[]
  unitCode?: string
  unitGroup?: string
  unitName?: string
}

export interface ReportUnitResult {
  /** 建议 */
  advice?: string
  key?: string
  name?: string
  /** 风险 */
  risk?: string
  /** 状态类型 */
  stateType?: string
  /** 提示 */
  tips?: string
}

export interface EvaluateVipRecordVO {
  /** 开始时间 */
  beginTime?: string
  /** 购买状态（true购买过 false未购买过） */
  bought?: boolean
  /** 综合截止时间 */
  endTime?: string
  /** 地理位置计数统计 */
  statistic?: ReportStatisticResultVO
  /** vip为true才有意义，是否临时会员（true临时会员 false正式会员） */
  tempVip?: boolean
  /** 是否有效（true有效 false无效） */
  valid?: boolean
  /** 宣传视频 */
  video?: EvaluateHomePageVideoVO
  /** 会员购买状态（true购买过会员 false未购过会员） */
  vip?: boolean
}

export interface ReportStatisticResultVO {
  /** 当前城市范围计数 */
  cityWideCount?: number
  /** 国家范围计数 */
  countryWideCount?: number
}

export interface EvaluateHomePageVideoVO {
  /** 封面图 */
  coverImg?: string
  /** 时长（秒） */
  duration?: number
  /** 资源地址 */
  resourceUrl?: string
}

export interface NounClass {
  /** 分类名称 */
  className?: string
  /** 排序 */
  classOrder?: number
  /** 状态,0-未启用，1-启用 */
  classStatus?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 除标识 0:有效 1:删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
}

export interface NounInfo {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 除标识 0:有效 1:删除 */
  delFlag?: number
  /** 解释内容 */
  explainContent?: string
  /** 食物表图片 */
  foodTable?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 所属分类 */
  nounClass?: number
  /** 名词英文 */
  nounEn?: string
  /** 名词名称 */
  nounName?: string
  /** 名词状态,0-未启用,1-已启用 */
  nounStatus?: number
}

export interface SchemeHeadDTO {
  buyStatus?: boolean
  foreword?: string
  forewordTitle?: string
  /** 表单集 */
  formArray?: Form[]
  formFlag?: boolean
  schemeKid?: number
  schemeName?: string
}

export interface Form {
  /** 编码 */
  formCode?: string
  /** 编码 */
  formName?: string
  /** 类型:input/select/combox/chips */
  formType?: string
  /** 验证 */
  input?: Input
  /** 验证 */
  validate?: Validate
}

export interface Input {
  /** 备注说明 */
  description?: string
  /** 选项值 */
  optionArray?: Option[]
  /** 占位符 */
  placeholder?: string
  /** 前缀 */
  prefix?: string
  /** 后缀 */
  suffix?: string
  /** 问题 */
  title?: string
}

export interface Option {
  /** 选项名 */
  name?: string
  /** 选项值 */
  value?: string
}

export interface Validate {
  /** 最大长度 */
  maxLength?: number
  /** 最大值 */
  maxValue?: string
  /** 最小长度 */
  minLength?: number
  /** 最小值 */
  minValue?: string
  /** 必填项 */
  required?: boolean
  /** 格式:number/date/text */
  schemeType?: string
}

export interface CompNutrient {
  nutrient?: string
  unit?: string
  weight?: number
}

export interface UserResourceDTO {
  anne?: string
  /** 附件 */
  annex?: string
  /** 附件 */
  annexDto?: UserResourceAnnex
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 资源详情 */
  detail?: string
  /** 截止时间 */
  endTime?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 页码 */
  pageNo?: number
  /** 每页条数 */
  pageSize?: number
  /** 手机号 */
  phone?: string
  /** 备注 */
  remark?: string
  /** 状态 0未回访 1已回访 3已回访，继续跟进 */
  resourceStatus?: number
  /** 开始时间 */
  startTime?: string
  /** 用户id */
  userId?: number
  /** 姓名 */
  userName?: string
}

export interface UserResourceAnnex {
  imgs?: Img[]
}

export interface Img {
  height?: number
  url?: string
  weight?: number
}

export interface VIPThroughTrainDetailVO {
  /** 会员卡列表 */
  cardList?: VipThroughTrainCard[]
  /** 会员卡权益列表 */
  rightsList?: VipThroughTrainRights[]
  /** 用户基础信息 */
  userBaseInfo?: UserBaseInfo
  /** 用户续费信息 */
  vipThroughTrain?: VipThroughTrain
}

export interface VipThroughTrainCard {
  /** 卡片名称 */
  cardName?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 是否删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 原价 */
  orginPrice?: number
  /** 售价 */
  salePrice?: number
  /** 排序 */
  sortNum?: number
  /** 0.下架 1.上架 */
  state?: number
  /** 提示语图片 */
  tipsImg?: string
  /** 单位 1.月卡 2.年卡 */
  unit?: number
}

export interface VipThroughTrainRights {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 是否删除 */
  delFlag?: number
  /** 权益描述 */
  descr?: string
  /** 权益图标 */
  icon?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 权益名称 */
  rightsName?: string
  /** 权益类型 */
  rightsType?: number
  /** 排序 */
  sortNum?: number
  /** 0.下架 1.上架 */
  state?: number
  /** 使用折扣 */
  useDiscount?: number
  /** 使用标题 */
  useTitle?: string
  /** 使用方式 1:折扣  2:会员价 3.专属权益 */
  useWay?: number
}

export interface UserBaseInfo {
  /** 活动渠道码 */
  activityChannelCode?: string
  /** 应用渠道码(客户端渠道包) */
  appChannel?: string
  /** 城市编码 */
  cityCode?: string
  /** 星座 */
  constellation?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效  1:删除 */
  delFlag?: number
  /** 需求状态 0:未互动 1:已互动，未提需求，2:已提需求，3:已提需求已完成 */
  demandStatus?: number
  /** 家乡 */
  hometown?: string
  /** 身份证号 */
  idCard?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 现居地 */
  livingPlace?: string
  /** 邀请码 */
  myInviteCode?: string
  /** 极光设备唯一id */
  registrationId?: string
  /** 游客标识 0-正常用户 1-游客 */
  touristFlag?: number
  /** 年龄 */
  userAge?: number
  /** 用户背景图片 */
  userBgImg?: string
  /** 出生年月日 */
  userBirthday?: string
  /** 用户简介 */
  userDesc?: string
  /** 用户扩展字段 */
  userExt?: string
  /** 用户性别 0-女 1-男 */
  userGenders?: number
  /** 用户id */
  userId?: number
  /** 头像 */
  userImg?: string
  /** 姓名 */
  userName?: string
  /** 昵称 */
  userNickName?: string
  /** 用户手机号码 */
  userPhone?: string
  /** 用户二维码地址 */
  userQr?: string
  /** 用户签名 */
  userSignature?: string
  /** 用户状态 0-正常 1-冻结 2-注销 */
  userStatus?: number
  /** 马甲状态 0-普通用户 1-马甲 */
  vestFlag?: number
}

export interface VipThroughTrain {
  /** 开始时间 */
  beginTime?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 截止时间 */
  endTime?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** userId */
  userId?: number
}

export interface BackgroundRechargeVipRecord {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 充值额度  默认单位：月 */
  rechargeNum?: number
  /** 充值类型（1-测评问卷，2-餐桌看营养，3-会员直通车） */
  rechargeType?: number
  /** 被充值用户id */
  userId?: number
}

export interface DailyBloodSugarDTO {
  /** 餐后血糖 */
  afterMeal?: number
  /** 空腹血糖 */
  beforeMeal?: number
  /** 打卡日历时间 */
  markDate?: string
}

export interface DailyBloodSugar {
  /** 餐后血糖 */
  afterMeal?: number
  /** 空腹血糖 */
  beforeMeal?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 打卡日历时间 */
  markDate?: string
  /** 家庭成员ID */
  memberKid?: number
}

export interface DailyBloodSugarListVO {
  /** 餐后最大值 */
  afterMax?: number
  /** 餐后最小值 */
  afterMin?: number
  /** 餐前最大值 */
  beforeMax?: number
  /** 餐前最小值 */
  beforeMin?: number
  /** 打卡列表 */
  list?: DailyBloodSugarVO[]
}

export interface DailyBloodSugarVO {
  /** 餐后血糖 */
  afterMeal?: number
  /** 空腹血糖 */
  beforeMeal?: number
  /** 打卡日历时间 */
  markDate?: string
}

export interface BodyDataVo {
  /** 记录日期 */
  date?: string
  /** 记录值 */
  record?: number
  /** 记录类型 */
  type?: number
}

export interface DailyBodyDataForm {
  /** 身体各项数据 */
  bodyDates?: BodyData[]
  /** 日期 */
  date?: string
}

export interface BodyData {
  /** 今日数据 */
  record?: number
  /** 目标数据 */
  targetRecord?: number
  /** 记录类型(1身高、2体重、3胸围、4腰围、5臀围、6手臂围、7大腿围、8小腿围、9体脂率、10BMI、11腰臀比、12腰高比) */
  type?: number
}

export interface DailyBodyDataVo {
  /** 改变数值 */
  change?: number
  /** 最大记录值 */
  maxRecord?: number
  /** 最小记录值 */
  minRecord?: number
  /** 记录数据 */
  recordData?: DailyBodyRecordVo[]
  /** 记录天数量 */
  recordDate?: number
  /** 目标数值 */
  targetRecord?: number
}

export interface DailyBodyRecordVo {
  /** 记录日期 */
  markDate?: string
  /** 记录数值 */
  record?: number
}

export interface DailyDietDTO {
  /** 成份 */
  dietComps?: DietComp[]
  /** 名称 */
  dietName?: string
  /** 饮食类型 1 早餐、2 早加餐、 3 午餐、 4 午加餐  5 晚餐 6 晚加餐 */
  dietType?: number
  /** 商品食材ID */
  foodId?: number
  /** 分布式唯一ID */
  kid?: number
  /** 打卡日历时间 */
  markDate?: string
  /** 标准单位 */
  normalUnit?: string
  /** 标准值 */
  normalValue?: number
  /** 菜谱类型 1 系统菜谱 2 用户自定义菜谱 */
  ownerType?: number
  /** 自定义规格数量 */
  quality?: number
  /** 自定义规格 */
  qualityUnit?: string
  /** 菜谱ID */
  recipesId?: number
}

export interface DietComp {
  /** 标识 */
  foodKid?: number
  /** 名称 */
  foodName?: string
  /** 名称 */
  foodNameSpecial?: string
  /** 可选单位集 */
  optionalUnits?: OptionalUnit[]
  /** 膳食分量占比 */
  percent?: number
  /** 默认单位(个/碗/盘.....) */
  unit?: string
  /** 摄入量 */
  weight?: number
}

export interface OptionalUnit {
  /** 换算单位 */
  convertUnit?: string
  /** 换算值 */
  convertValue?: number
  /** 默认标识 0:非默认 1:默认 */
  defaultFlag?: number
  /** 计量单位 */
  foodUnit?: string
}

export interface DailyDietListForm {
  /** 早餐 */
  breakfast?: MealDiet[]
  /** 早加餐 */
  breakfastPlus?: MealDiet[]
  /** 晚餐 */
  dinner?: MealDiet[]
  /** 晚加餐 */
  dinnerPlus?: MealDiet[]
  /** 午餐 */
  lunch?: MealDiet[]
  /** 午加餐 */
  lunchPlus?: MealDiet[]
}

export interface MealDiet {
  dailyKid?: number
  /** 成份 */
  dietComps?: DietComp[]
  /** 名称 */
  dietName?: string
  /** 食谱总重量(可选，打卡) */
  dietWeight?: number
  /** 菜谱类型 1 系统菜谱 2 用户自定义菜谱 */
  ownerType?: number
  /** 份数 */
  quality?: number
  /** 食谱单位(份/克) */
  qualityUnit?: string
  /** 食谱标识（可选，打卡) */
  recipeKid?: number
  /** 食物排序（可选，打卡） */
  sort?: number
}

export interface DailyDietForm {
  /** 早餐 */
  breakfast?: Diet[]
  /** 早加餐 */
  breakfastPlus?: Diet[]
  /** 晚餐 */
  dinner?: Diet[]
  /** 晚加餐 */
  dinnerPlus?: Diet[]
  /** 午餐 */
  lunch?: Diet[]
  /** 午加餐 */
  lunchPlus?: Diet[]
}

export interface Diet {
  /** 成份 */
  dietComps?: DietComp[]
  /** 名称 */
  dietName?: string
  /** 食谱总重量(可选，打卡) */
  dietWeight?: number
  /** 份数 */
  quality?: number
  /** 食谱单位(份/克) */
  qualityUnit?: string
  /** 食谱标识（可选，打卡) */
  recipeKid?: number
  /** 食物排序（可选，打卡） */
  sort?: number
}

export interface DailyKcalVO {
  /** 早餐 */
  breakfast?: DietMainInfo
  /** 早加餐 */
  breakfastPlus?: DietMainInfo
  /** 晚餐 */
  dinner?: DietMainInfo
  /** 晚加餐 */
  dinnerPlus?: DietMainInfo
  /** 午餐 */
  lunch?: DietMainInfo
  /** 午加餐 */
  lunchPlus?: DietMainInfo
  /** 运动 */
  sports?: DietMainInfo
}

export interface DietMainInfo {
  /** 酒精 */
  alcohol?: number
  /** 个人摄入的能量公式  =  碳水化合物 g* 4 + 脂肪* 9  +蛋白质g+4  + 酒精* 7 */
  calories?: number
  /** 碳水化合物 */
  carbohydrates?: number
  /** 脂肪 */
  fat?: number
  /** 蛋白质 */
  protein?: number
}

export interface EvaluateDietPlanVO {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 类型 0 标准膳食计划 1 自定义 */
  dietType?: string
  /** 疾病分类 */
  diseaseClassify?: string
  /** 疾病编码 */
  diseaseCode?: string
  /** 疾病名称 */
  diseaseName?: string
  /** 能量 */
  energy?: number
  /** 能量最高值 */
  energyMax?: number
  /** 能量最低值 */
  energyMin?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 膳食餐次数据 */
  mealList?: EvaluateDietPlanDetailVO[]
  /** 蛋白质 */
  protein?: number
  /** 蛋白质最高值 */
  proteinMax?: number
  /** 蛋白质最低值 */
  proteinMin?: number
  /** 报告ID */
  reportKid?: number
  /** 期数 */
  term?: string
  /** 友情提示 */
  tips?: string
  /** 总碳水化合物 */
  totalCarbohydrate?: number
  /** 总能量 */
  totalEnergy?: number
  /** 总脂肪 */
  totalFat?: number
  /** 总血糖负荷 */
  totalGlycemicLoad?: number
  /** 总蛋白质 */
  totalProtein?: number
}

export interface EvaluateDietPlanDetailVO {
  /** 膳食数据 */
  foodList?: EvaluateDietPlanDetail[]
  /** 餐次（1-早餐 2-早加餐 3-中餐 4-中加餐 5-晚餐 6-晚加餐） */
  mealTimes?: number
}

export interface EvaluateDietPlanDetail {
  /** 碳水化合物 */
  carbohydrate?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 膳食计划KID */
  dietPlanKid?: number
  /** 能量 */
  energy?: number
  /** 交换份类别1 能量 2 蛋白质 */
  exchangeType?: number
  /** 脂肪 */
  fat?: number
  /** 食材类别KID */
  foodClassify?: number
  /** 食材分类名称 */
  foodClassifyName?: string
  /** 食材份数 */
  foodCopies?: number
  /** 食材KID */
  foodId?: number
  /** 食材名称 */
  foodName?: string
  /** 食材重量 */
  foodWeight?: number
  /** 血糖负荷 */
  glycemicLoad?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 餐次（1-早餐 2-早加餐 3-中餐 4-中加餐 5-晚餐 6-晚加餐） */
  mealTimes?: number
  /** 蛋白质 */
  protein?: number
  /** 展示字段 */
  showFields?: string
  /** 排序 */
  sort?: number
}

export interface DailyReportCollect {
  /** 打卡类型 */
  classifyCode?: string
  /** 打卡名称 */
  classifyName?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 内容 */
  description?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 打卡时间 */
  markerDate?: string
  /** 用户标识 */
  memberKid?: number
  /** 变更次数 */
  modifyCount?: number
}

export interface DailyReport {
  /** 内容 */
  content?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 打卡唯一标识 */
  drKid?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 单元项 */
  unitCode?: string
  /** 单元名称 */
  unitName?: string
}

export interface DailySportDTO {
  /** 运动描述 */
  description?: string
  /** kcal */
  kcal?: number
  /** 主键 */
  kid?: number
  /** 打卡日历时间 */
  markDate?: string
  /** 成员单位 */
  memberKid?: number
  /** 运动时间，单位:分钟 */
  runTime?: number
  /** 运动项目 */
  sportsEvents?: string
}

export interface DailySportsBaseVO {
  /** 运动项 */
  sports?: DailySportsBase[]
  /** 运动类型 */
  sportsEvents?: string
}

export interface DailySportsBase {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 运动描述 */
  description?: string
  /** 身体活动强度 */
  kcalValue?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 运动类型唯一标识 */
  sportTypeKid?: number
  /** 运动项目 */
  sportsEvents?: string
  /** 运动图片标识 */
  sportsLogUrl?: string
}

export interface DailySportVO {
  /** 运动描述 */
  description?: string
  /** 每一个运动项目根据体重和时间算出来的卡路里 */
  kcalValue?: number
  kid?: number
  /** 打卡日历时间 */
  markDate?: string
  /** 成员单位 */
  memberKid?: number
  /** 运动时间，单位:分钟 */
  runTime?: number
  /** 运动项目 */
  sportsEvents?: string
  /** 运动图片标识 */
  sportsLogUrl?: string
}

export interface DailyStep {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 打卡日历时间 */
  markDate?: string
  /** 成员单位 */
  memberKid?: number
  /** 计算步数 */
  steps?: number
}

export interface DailyStepVO {
  /** 全局唯一ID */
  kid?: number
  /** 打卡日历时间 */
  markDate?: string
  /** 计算步数 */
  steps?: number
}

export interface DailyWater {
  /** 饮水量 */
  capacity?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 打卡日历时间 */
  markDate?: string
  /** 成员单位 */
  memberKid?: number
}

export interface DailyWaterVO {
  /** 饮水量 */
  capacity?: number
  /** 全局唯一ID */
  kid?: number
  /** 打卡日历时间 */
  markDate?: string
}

export interface SmartNutritionVO {
  /** 摄入能量和目标能量 */
  dailyIntakeNutrition?: DailyIntakeNutritionVO
  /** 默认营养计划标题 */
  defaultNutritionTitle?: string
  evaluate?: boolean
  /** 卡路里 */
  kcal?: number
  weightManagement?: boolean
}

export interface DailyIntakeNutritionVO {
  /** 卡路里/能量 当前值 单位 卡 */
  caloriesExactValue?: number
  /** 卡路里/能量 摄入参考值标准值 单位 卡 */
  caloriesMin?: number
  /** 碳水化合物 当前值 单位 g */
  carbohydratesExactValue?: number
  /** 碳水化合物 摄入参考值标准值 单位 g */
  carbohydratesMin?: number
  /** 脂肪 当前值 单位 g */
  fatExactValue?: number
  /** 脂肪 摄入参考值标准值 单位 g */
  fatMin?: number
  /** 蛋白质 当前值 单位 g */
  proteinExactValue?: number
  /** 蛋白质 摄入参考值标准值 单位 g */
  proteinMin?: number
}

export interface UserEvaluateVO {
  evaluate?: boolean
}

export interface DailyDataVO {
  /** 血糖 */
  bloodSugar?: DailyBloodSugar
  /** 身体数据 */
  dailyBodyData?: BodyDataVo[]
  dailyReport?: boolean
  /** 是否糖尿病人 */
  diabetes?: boolean
  /** 是否显示管理营养计划入口 */
  display?: boolean
  /** 摄入营养 */
  intakeNutrition?: DailyIntakeNutritionVO
  /** 卡路里 */
  kcal?: DailyKcalVO
  /** 步数 */
  step?: DailyStepVO
  /** 问卷状态(0:未做问卷,1:做过问卷) */
  type?: number
  /** 摄入水 */
  water?: DailyWaterVO
}

export interface DailyRecordVO {
  /** 打卡日报返回为日报kid,运动以及膳食返回memberKid */
  data?: number
  /** 日期 */
  recordDate?: string
}

export interface DailyReportVO {
  /** 报告单元 */
  reportUnits?: ReportUnit<IReportUnitData>[]
  /** 报告类型(1:良好营养;2:糖尿病;3:减重;4:增重;) */
  type?: number
}

export interface CreateReservationVO {
  idCode?: string
  userId?: number
}

export interface UserVisitVO {
  bespeakDate?: string
  exportId?: number
  reservationId?: number
}

export interface ExportRoomInfoVO {
  exportImg?: string
  exportName?: string
  reservationList?: DiagnosisReservationVO[]
  userCount?: number
}

export interface DiagnosisReservationVO {
  /** 开始时段 */
  beginTimeRange?: string
  /** 预约日期 */
  bespeakDate?: string
  /** 预约状态  预约中-1、支付中-2、预约成功-3、预约失败-3、问诊中-4、准备-5、等待-6、就诊完成-7、预约过期-8 */
  bespeakStatus?: number
  /** 完成时间 */
  completeTime?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  endAge?: number
  endBespeakDate?: string
  endPayTime?: string
  /** 结束时段  */
  endTimeRange?: string
  endTimeRangeStr?: string
  exportDesc?: string
  /** 专家编号 */
  exportId?: number
  exportName?: string
  exportPhone?: string
  /** 营养师视频 */
  exportUrl?: string
  exporttUrlList?: string[]
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 金额 */
  money?: number
  /** 快到预约时间是否通知 0-未通知，1-已通知 */
  notice?: number
  /**  支付时间 */
  payTime?: string
  /** 用户排班编码 */
  scheduleCode?: string
  /** 结算金额 */
  settleMoney?: number
  showMoney?: string
  showSettleMoney?: string
  startAge?: number
  startBespeakDate?: string
  startPayTime?: string
  startTimeRange?: string
  /** 用户年龄 */
  userAge?: number
  userImg?: string
  /** 用户姓名 */
  userName?: string
  userPhone?: string
  userSeqStr?: string
  /** 用户排序号 */
  userSequence?: number
  /**  用户姓别 */
  userSex?: number
  /** 用户视频 */
  userUrl?: string
  userUrlList?: string[]
}

export interface DiagnosisReservation {
  /** 开始时段 */
  beginTimeRange?: string
  /** 预约日期 */
  bespeakDate?: string
  /** 预约状态  预约中-1、支付中-2、预约成功-3、预约失败-3、问诊中-4、准备-5、等待-6、就诊完成-7、预约过期-8 */
  bespeakStatus?: number
  /** 完成时间 */
  completeTime?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 结束时段  */
  endTimeRange?: string
  /** 专家编号 */
  exportId?: number
  /** 营养师视频 */
  exportUrl?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 金额 */
  money?: number
  /** 快到预约时间是否通知 0-未通知，1-已通知 */
  notice?: number
  /**  支付时间 */
  payTime?: string
  /** 用户排班编码 */
  scheduleCode?: string
  /** 结算金额 */
  settleMoney?: number
  /** 用户年龄 */
  userAge?: number
  /** 用户姓名 */
  userName?: string
  /** 用户排序号 */
  userSequence?: number
  /**  用户姓别 */
  userSex?: number
  /** 用户视频 */
  userUrl?: string
}

export interface DiseaseNutritionAdvise {
  /** 建议信息 */
  adviseInfo?: string
  /** 建议优先级 */
  advisePriority?: number
  /** 食物分类 */
  classify?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 疾病编码 */
  diseaseCode?: string
  /** 疾病名称 */
  diseaseName?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 食材名称 */
  material?: string
  /** 营养素名称 */
  nutrient?: string
}

export interface UserBackVisitForm {
  /** 是否符合实际 */
  actual?: Answer
  /** 建议 */
  advise?: string
  /** 联系手机号 */
  contactPhone?: boolean
  /** 联系短信 */
  contactPhoneMsg?: boolean
  /** 联系微信号 */
  contactWeChat?: boolean
  /** 评估名称 */
  evaluateName?: string
  /** 评估编号(KID) */
  evaluateNo?: number
  /** 是否健康问卷 */
  healthy?: boolean
  /** 是否改善饮食 */
  improveDiet?: Answer
  /** 是否摄入蔬菜 */
  intakeVeg?: Answer
  /** 是否符合操作 */
  operable?: Answer
  /** 是否保持活动次数适量 */
  sportCountKeep?: Answer
  /** 是否保持活动时长适量 */
  sportDurationKeep?: Answer
}

export interface Answer {
  flag?: boolean
  reason?: string
}

export interface EvaluateBackVisitListVO {
  evaluateName?: string
  evaluateNo?: number
  healthy?: boolean
}

export interface EvaluateMemberExt {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 身高（cm） */
  height?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 成员唯一标识 */
  memberKid?: number
  /** 受孕状态 */
  pregnant?: number
  /** 运动次数 */
  sportCount?: number
  /** 运动时长 */
  sportDuration?: number
  /** 饮用水摄入量 */
  waterQuality?: number
  /** 体重（kg） */
  weight?: number
}

export interface EvaluateMember {
  /** 地址编码 */
  addressCode?: string
  /** 地址 */
  addressName?: string
  /** 过敏史  0:无 1:有 */
  allergy?: number
  /** 出生年月 */
  birthday?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 成员性别 */
  gender?: number
  /** 过往病史 0 未选择 1，痛风 2，糖尿病 3，脑卒中 4，肥胖 5，癌症 6，心血管病 7，其它 */
  history?: number
  /** 分布式唯一ID */
  kid?: number
  /** 劳动强度 */
  labourLevel?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 所属标识 0:否 1:是 */
  ownFlag?: number
  /** 成员姓名 */
  userName?: string
  /** 用户手机 */
  userPhone?: string
}

export interface DynamicQuestionnaireCheck {
  /** 匹配脚本 */
  applicableExpr?: string
  /** 动态编码 */
  dynamicCode?: string
  /** 成员信息 */
  member?: EvaluateMember
}

export interface NextForm {
  /** 动态编码 */
  dynamicCode?: string
  /** 表单编码 */
  formCode?: string
}

export interface UserQuestionnaireNext {
  /** 问卷分类编码 */
  classifyCode?: string
  /** 当前表单记录 */
  currPart?: UserQuestionnairePart<JsonNode>
  /** 历史表单记录 */
  historyPart?: UserQuestionnairePart<JsonNode>[]
  /** 当前成员标识 */
  memberKid?: number
}

export interface UserQuestionnairePart<T> {
  /** 数据 */
  data?: T
  /** 动态编码 */
  dynamicCode?: string
  /** 表单编码 */
  formCode?: string
  /** 表单排序 */
  fromIndex?: number
  /** 是否跳过 */
  skip?: boolean
}

export interface DynamicQuestionnaireResult {
  /** 动态标准集 */
  dynamicForm?: DynamicQuestionnaireCollect
  /** 扩展信息表单编码 */
  extForm?: string[]
  /** 成员信息 */
  member?: EvaluateMember
  /** 成员扩展信息 */
  memberExt?: EvaluateMemberExtForm
}

export interface DynamicQuestionnaireCollect {
  /** 匹配脚本 */
  applicableExpr?: string
  /** 动态编码 */
  dynamicCode?: string
  /** 动态名称 */
  dynamicName?: string
  /** 表单集 */
  formArray?: Form[]
  /** 参考类型 */
  referFlag?: number
  /** 引导文案 */
  statement?: DynamicQuestionnaireStatement
  /** 版本号 */
  version?: number
}

export interface DynamicQuestionnaireStatement {
  /** 文件地址 */
  contentUrl?: string
  /** 疾病名称 */
  diseaseName?: string
  /** 标识 */
  statementKey?: string
  /** 名称/标题 */
  statementName?: string
}

export interface EvaluateMemberExtForm {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 就餐地点 */
  eatPlaceArray?: string[]
  /** 食物禁忌 */
  foodForbidArray?: string[]
  /** 身高（cm） */
  height?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 成员唯一标识 */
  memberKid?: number
  /** 受孕状态 */
  pregnant?: number
  /** 运动次数 */
  sportCount?: number
  /** 运动时长 */
  sportDuration?: number
  /** 饮用水摄入量 */
  waterQuality?: number
  /** 体重（kg） */
  weight?: number
}

export interface EvaluateReportCollect {
  /** 可访问用户 */
  accessUserId?: number
  /** 适用编码 */
  applicableCode?: string
  /** 适用名称 */
  applicableName?: string
  /** 回访标识 0:否 1:是 */
  backFlag?: number
  /** 批次编号 */
  batchNo?: number
  /** 渠道编码 */
  channelCode?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 报告项名称集 */
  itemNames?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 成员年龄 */
  memberAge?: number
  /** 成员性别 */
  memberGender?: number
  /** 成员标识 */
  memberKid?: number
  /** 成员名称 */
  memberName?: string
  /** 成员类型 */
  memberType?: string
  /** 报告名称 */
  name?: string
  /** 支付标识 */
  paymentFlag?: number
  /** 已读标识 0:否 1:是 */
  readFlag?: number
  /** 显示标识 */
  showFlag?: number
  /** 健康healthy/自评ordinary/疾病disease/家庭family */
  type?: string
}

export interface ReportGenerateForm {
  /** 渠道标识 */
  channel?: string
  /** 分类标识 */
  classifyCode?: string
  dynamicForms?: UserQuestionnairePart<JsonNode>[]
  /** 表单版本 */
  formVersion?: number
  /** 成员标识 */
  memberKid?: number
  /** 成员类型 */
  memberType?: string
  /** 会话标示 */
  sessionId?: string
  /** 会话类型 */
  sessionType?: string
}

export interface ReportFamilyCollect {
  /** 家庭报告 */
  familyReport?: ReportUnit<IReportUnitData>
  /** 成员报告集 */
  members?: EvaluateReportCollect[]
}

export interface EvaluateStatistics {
  /** 适用编码 */
  applicableCode?: string
  /** 适用名称 */
  applicableName?: string
  /** 问卷计次 */
  count?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 是否存在打卡日报 */
  dailyFlag?: number
  /** 是否默认膳食推荐 */
  defaultDietFlag?: number
  /** 是否默认营养计划 */
  defaultFlag?: number
  /** 是否存在推荐膳食 */
  dietFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后测评时间 */
  lastEvaluateDate?: string
  /** 最后生成报告的批次号 */
  lastReportBatch?: number
  /** 最后生成报告的KID */
  lastReportKid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 成员ID */
  memberKid?: number
  /** 健康healthy/自评ordinary/疾病disease/家庭family */
  type?: string
  /** 有效表示 */
  validFlag?: number
}

export interface JSONObject {
}

export interface EvaluateUserRecordVO {
  /** 失效列表 */
  invalidList?: EvaluateVipRecord[]
  /** 有效列表 */
  validList?: EvaluateVipRecord[]
}

export interface EvaluateVipRecord {
  /** 开始时间 */
  beginTime?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 截止时间 */
  endTime?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 父级KID */
  parentKid?: number
  /** 编码 */
  recordCode?: string
  /** 名称 */
  recordName?: string
  /** 类型（1-问卷，2-会员，3-套餐） */
  recordType?: number
  /** 临时会员 0-否 1-是 */
  tempFlag?: number
}

export interface ExcelExportRecord {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 导出Excel类型 */
  excelType?: string
  /** 导出码 */
  exportCode?: string
  /** 导出描述 */
  exportDesc?: string
  /** 导出参数 */
  exportParams?: string
  /** 导出状态：0未执行 1导出中 2全部导出 3部分导出 4导出失败 */
  exportStatus?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 结果excel文件地址 */
  resultExcel?: string
  /** 无标题模板导出 */
  withoutTitle?: boolean
}

export interface ExchangeCodeValueVO {
  /** 1会员直通车2餐桌看营养3膳食营养测评会员4膳食营养测评卡 */
  applicationType?: number
  /** 0未兑换未使用1已兑换2已使用 */
  codeStatus?: number
  /** 兑换码 */
  codeValue?: string
  /** 创建时间 */
  createDate?: string
  /** 兑换时间 */
  exchangeDate?: string
  /** 唯一标识数字最多9位用于生成code */
  identifyNum?: number
  /** 兑换码信息kid */
  infoKid?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 报告KID */
  reportKid?: number
  /** 使用时间 */
  useDate?: string
  /** 使用者id */
  userId?: number
  /** 用户名 */
  userName?: string
  /** 用户手机号 */
  userPhone?: string
  /** 1进行中2已结束 */
  validStatus?: number
}

export interface ExchangeCodeValueUseDTO {
  /** code码kid */
  codeKid?: number
  /** 报告ID */
  reportKid?: number
}

export interface HealthButlerGroup {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 组名 */
  groupName?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 分流状态 0-正常分流 1-停止分流 */
  shuntStatus?: number
  /** 分流权重,默认值为100(最大值为10000) */
  shuntWeight?: number
}

export interface HealthManagerServiceDetail {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 增值服务ID */
  detailId?: number
  /** 增值服务类型 1问卷 2课程 3餐桌看营养 4问卷VIP */
  detailType?: number
  /** 有效时长 */
  duration?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 管理服务ID */
  managerServiceId?: number
  /** 推荐说明 */
  recommendDesc?: string
  /** 服务方案ID */
  servicePlanId?: number
  /** 时长单位 */
  unit?: number
}

export interface HealthManagerService {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 推荐说明类型 1图文 2视频 */
  descType?: number
  /** 描述 */
  description?: string
  /** 时效时长 */
  duration?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 服务管家userId */
  managerServerId?: number
  /** 价格 */
  price?: number
  /** 售价 */
  salePrice?: number
  /** 服务方案ID */
  servicePlanId?: number
  /** 时效时长单位 1周 2月 3年 4次 */
  unit?: number
  /** 视频首帧图地址 */
  videoCover?: string
  /** 视频时长 */
  videoDuration?: number
  /** 视频资源URL */
  videoUrl?: string
}

export interface HealthNeedOrderDetail {
  /** 视频资源首帧图url */
  coverImage?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 视频或者音频资源时长 */
  duration?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 需求工单ID */
  needOrderId?: number
  /** 资源类型 0图片 1视频 2音频 */
  resourceType?: number
  /** 资源url */
  resourceUrl?: string
}

export interface HealthNeedOrderRecordMsg {
  /** 消息附件 */
  attach?: string
  /** 消息内容 */
  body?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0正常1删除 */
  delFlag?: number
  /** 事件类型 1点对点消息 */
  eventType?: number
  /** 消息扩展字段 */
  ext?: string
  /** 消息发送者的用户账号 */
  fromAccount?: string
  /** 消息发送方的客户端IP地址 */
  fromClientIp?: string
  /** 消息发送方的客户端端口号 */
  fromClientPort?: string
  /** 发送客户端类型： AOS、IOS、PC、WINPHONE、WEB、REST */
  fromClientType?: string
  /** 发送设备id */
  fromDeviceId?: string
  /** 发送方昵称 */
  fromNick?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 消息发送时间 */
  msgTimestamp?: string
  /** TEXT ：文本消息 PICTURE ：图片消息 AUDIO ：语音消息 VIDEO ：视频消息 LOCATION ：地理位置 NOTIFICATION ：通知 FILE ：文件消息 TIPS ：提示类型消息 CUSTOM ：自定义消息 */
  msgType?: string
  /** 客户端消息Id */
  msgidClient?: string
  /** 需求工单ID */
  needOrderId?: number
  /** 消息接收者的用户账号 */
  toAccount?: string
}

export interface HealthNeedOrderRecordMsgVO {
  /** 消息附件 */
  attach?: string
  /** 消息内容 */
  body?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0正常1删除 */
  delFlag?: number
  /** 事件类型 1点对点消息 */
  eventType?: number
  /** 消息扩展字段 */
  ext?: string
  /** 消息发送者的用户账号 */
  fromAccount?: string
  /** 消息发送方的客户端IP地址 */
  fromClientIp?: string
  /** 消息发送方的客户端端口号 */
  fromClientPort?: string
  /** 发送客户端类型： AOS、IOS、PC、WINPHONE、WEB、REST */
  fromClientType?: string
  /** 发送设备id */
  fromDeviceId?: string
  /** 发送方昵称 */
  fromNick?: string
  /** 消息发送者的用户信息 */
  fromUser?: UserSimpleVO
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 消息发送时间 */
  msgTimestamp?: string
  /** TEXT ：文本消息 PICTURE ：图片消息 AUDIO ：语音消息 VIDEO ：视频消息 LOCATION ：地理位置 NOTIFICATION ：通知 FILE ：文件消息 TIPS ：提示类型消息 CUSTOM ：自定义消息 */
  msgType?: string
  /** 客户端消息Id */
  msgidClient?: string
  /** 需求工单ID */
  needOrderId?: number
  /** 消息接收者的用户账号 */
  toAccount?: string
}

export interface UserSimpleVO {
  /** 星座 */
  constellation?: string
  /** 现居地 */
  livingPlace?: string
  /** 用户角色集合 */
  roles?: UserRole[]
  /** 用户背景图片 */
  userBgImg?: string
  /** 出生年月日 */
  userBirthday?: string
  /** 用户性别 0女/1男 */
  userGenders?: number
  /** 用户账户id */
  userId?: number
  /** 头像 */
  userImg?: string
  /** 姓名 */
  userName?: string
  /** 昵称 */
  userNickName?: string
  /** 用户签名 */
  userSignature?: string
  /** 马甲状态 0-普通用户 1-马甲 */
  vestFlag?: number
}

export interface UserRole {
  /** 角色 */
  role?: string
  /** 角色头衔 */
  roleTitle?: string
}

export interface HealthNeedOrderDTO {
  /** 入口来源Code */
  importCode?: number
  /** 入口来源kid */
  importKid?: string
  /** 入口来源类型 1干预分类 2专区 */
  importType?: number
  kid?: number
  /** 需求描述 */
  orderDesc?: string
  /** 需求图片或视频 */
  resourceList?: HealthNeedOrderDetail[]
  /** 第三方营养师ID */
  thirdServerId?: number
  /** 客服转单描述 */
  transferDesc?: string
}

export interface HealthNeedOrderDetailVO {
  /** 生成时间 */
  createDate?: string
  /** 删除标识 0未删除 1已删除 */
  delFlag?: number
  /** 首图资源url */
  imageUrl?: string
  /** 需求描述 */
  orderDesc?: string
  /** 工单来源 1我要服务 2指定营养师 3需求画像新增 4需求画像修改 5客服转单 6客服互动  7再次互动 */
  orderSource?: number
  /** 工单状态 0待处理 1咨询中 2已出方案 3已完成 4不处理 5中止 6挂起 */
  orderStatus?: number
  /** 出方案时间 */
  planDate?: string
  /** 需求图片或视频 */
  resourceList?: HealthNeedOrderDetail[]
  /** 平台营养师ID */
  serverId?: number
  /** 需求标签 */
  transferDesc?: string
}

export interface HealthNeedOrder {
  /** 转单客服ID */
  agentId?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0未删除 1已删除 */
  delFlag?: number
  /** 首图资源url */
  imageUrl?: string
  /** 入口来源Code */
  importCode?: number
  /** 入口来源kid */
  importKid?: string
  /** 入口来源类型 1干预分类 2专区 3测评报告 */
  importType?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 需求描述 */
  orderDesc?: string
  /** 工单来源 1我要服务 2指定营养师 3需求画像新增 4需求画像修改 5客服转单 6客服互动 7客服互动-转单 8我有需求-转单 9再次互动 10再次互动-转单 */
  orderSource?: number
  /** 工单状态 0待处理 1咨询中 2已出方案 3已完成 4不处理 5中止 6挂起 */
  orderStatus?: number
  /** 出方案时间 */
  planDate?: string
  /** 接单或转单时间 */
  serverDate?: string
  /** 平台营养师ID */
  serverId?: number
  /** 第三方营养师ID */
  thirdServerId?: number
  /** 客服转单描述 */
  transferDesc?: string
}

export interface HealthNeedOrderAdminDTO {
  evaluateIds?: number[]
  /** 入口来源Code */
  importCode?: number
  /** 入口来源kid */
  importKid?: string
  /** 入口来源类型 1干预分类 2专区 */
  importType?: number
  kid?: number
  managerId?: number
  /** 需求描述 */
  orderDesc?: string
  /** 需求图片或视频 */
  resourceList?: HealthNeedOrderDetail[]
  /** 第三方营养师ID */
  thirdServerId?: number
  /** 客服转单描述 */
  transferDesc?: string
  userId?: number
}

export interface HealthServiceOrderRecordMsg {
  /** 消息附件 */
  attach?: string
  /** 消息内容 */
  body?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0正常1删除 */
  delFlag?: number
  /** 事件类型 1点对点消息 */
  eventType?: number
  /** 消息扩展字段 */
  ext?: string
  /** 消息发送者的用户账号 */
  fromAccount?: string
  /** 消息发送方的客户端IP地址 */
  fromClientIp?: string
  /** 消息发送方的客户端端口号 */
  fromClientPort?: string
  /** 发送客户端类型： AOS、IOS、PC、WINPHONE、WEB、REST */
  fromClientType?: string
  /** 发送设备id */
  fromDeviceId?: string
  /** 发送方昵称 */
  fromNick?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 消息发送时间 */
  msgTimestamp?: string
  /** TEXT ：文本消息 PICTURE ：图片消息 AUDIO ：语音消息 VIDEO ：视频消息 LOCATION ：地理位置 NOTIFICATION ：通知 FILE ：文件消息 TIPS ：提示类型消息 CUSTOM ：自定义消息 */
  msgType?: string
  /** 客户端消息Id */
  msgidClient?: string
  /** 需求工单ID */
  needOrderId?: number
  /** 服务工单ID */
  serviceOrderId?: number
  /** 消息接收者的用户账号 */
  toAccount?: string
}

export interface HealthServiceOrderRecordMsgVO {
  /** 消息附件 */
  attach?: string
  /** 消息内容 */
  body?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0正常1删除 */
  delFlag?: number
  /** 事件类型 1点对点消息 */
  eventType?: number
  /** 消息扩展字段 */
  ext?: string
  /** 消息发送者的用户账号 */
  fromAccount?: string
  /** 消息发送方的客户端IP地址 */
  fromClientIp?: string
  /** 消息发送方的客户端端口号 */
  fromClientPort?: string
  /** 发送客户端类型： AOS、IOS、PC、WINPHONE、WEB、REST */
  fromClientType?: string
  /** 发送设备id */
  fromDeviceId?: string
  /** 发送方昵称 */
  fromNick?: string
  /** 消息发送者的用户信息 */
  fromUser?: UserSimpleVO
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 消息发送时间 */
  msgTimestamp?: string
  /** TEXT ：文本消息 PICTURE ：图片消息 AUDIO ：语音消息 VIDEO ：视频消息 LOCATION ：地理位置 NOTIFICATION ：通知 FILE ：文件消息 TIPS ：提示类型消息 CUSTOM ：自定义消息 */
  msgType?: string
  /** 客户端消息Id */
  msgidClient?: string
  /** 需求工单ID */
  needOrderId?: number
  /** 服务工单ID */
  serviceOrderId?: number
  /** 消息接收者的用户账号 */
  toAccount?: string
}

export interface HealthServiceOrder {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 首图资源url */
  imageUrl?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 需求工单ID */
  needOrderId?: number
  /** 需求描述 */
  orderDesc?: string
  /** 工单状态 0咨询中 1已关闭 */
  orderStatus?: number
  /** 第三方营养师ID */
  thirdServerId?: number
  /** 用户ID */
  userId?: number
}

export interface HealthServicePlanDetail {
  /** 商品app名称 */
  appName?: string
  /** 方案内容ID */
  contentId?: number
  /** 方案内容类型 1治疗方案 2营养辅助（商品） 3健康管理服务 4专属机构服务（占位） */
  contentType?: number
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 推荐说明类型 1图文 2视频 */
  descType?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 商户ID */
  merchantId?: number
  /** 需求工单ID */
  needOrderId?: number
  /** 价格 */
  price?: number
  /** 服务月数或商品数量 */
  quantity?: number
  /** 推荐说明 */
  recommendDesc?: string
  /** 服务方案ID */
  servicePlanId?: number
  /** 商品SKU ID */
  skuId?: number
  /** 时效单位 1周 2月 3年 4次 */
  unit?: number
  /** 视频首帧图地址 */
  videoCover?: string
  /** 视频时长 */
  videoDuration?: number
  /** 视频资源URL */
  videoUrl?: string
}

export interface HealthServicePlanVO {
  /** 描述 */
  description?: string
  kid?: number
  /** 管家服务 */
  managerService?: HealthServicePlanDetailVO<HealthManagerServiceVO>
  /** 商户列表 */
  merchantList?: ProductMerchantVO[]
  /** 需求工单ID */
  needOrderId?: number
  /** 方案状态 0有效 1失效 3待审核 4审核未通过 */
  planStatus?: number
  /** 商品列表 */
  productList?: HealthServicePlanDetailVO<ProductSkuSimpleVO>[]
  /** 营养治疗服务 */
  thirdPlanList?: HealthServicePlanDetailVO<UserNutritionistVO>[]
  /** 方案名称 */
  title?: string
}

export interface HealthManagerServiceVO {
  /** 推荐说明类型 1图文 2视频 */
  descType?: number
  /** 描述 */
  description?: string
  /** 详情列表 */
  detailList?: HealthManagerServiceDetailVO<string>[]
  /** 时效时长 */
  duration?: number
  kid?: number
  /** 管家 */
  manageServer?: UserBaseInfoVO
  /** 价格 */
  price?: number
  /** 售价 */
  salePrice?: number
  /** 服务方案ID */
  servicePlanId?: number
  /** 服务名称 */
  title?: string
  /** 时效时长单位 1周 2月 3年 4次 */
  unit?: number
  /** 视频首帧图地址 */
  videoCover?: string
  /** 视频时长 */
  videoDuration?: number
  /** 视频资源URL */
  videoUrl?: string
}

export interface HealthManagerServiceDetailVO<T> {
  /** 封面图 */
  coverImage?: T
  /** 描述 */
  description?: T
  /** 增值服务ID */
  detailId?: number
  /** 增值服务类型 1问卷 2课程 3餐桌看营养 4测评VIP */
  detailType?: number
  /** 详情类型数据 */
  detailTypeData?: T
  /** 时效时长 */
  duration?: number
  kid?: number
  /** 管理服务ID */
  managerServiceId?: number
  /** 单价 */
  price?: number
  /** 数量 */
  quantity?: number
  /** 推荐说明 */
  recommandDesc?: T
  /** 标题 */
  title?: T
  /** 时效时长单位 1周 2月 3年 */
  unit?: number
}

export interface UserBaseInfoVO {
  /** 活动渠道码 */
  activityChannelCode?: string
  /** 应用渠道码(客户端渠道包) */
  appChannel?: string
  /** 城市编码 */
  cityCode?: string
  /** 讲师佣金率 */
  commissionRate?: number
  /** 星座 */
  constellation?: string
  /** 合同照片 */
  contractPhotoUrl?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效  1:删除 */
  delFlag?: number
  /** 需求状态 0:未互动 1:已互动，未提需求，2:已提需求，3:已提需求已完成 */
  demandStatus?: number
  /** 家乡 */
  hometown?: string
  /** 身份证号 */
  idCard?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 现居地 */
  livingPlace?: string
  /** 邀请码 */
  myInviteCode?: string
  /** 讲师运营人员 */
  operationDirector?: string
  /** 运营电话 */
  operatorPhone?: string
  /** 极光设备唯一id */
  registrationId?: string
  /** 用户角色集合 */
  roles?: UserRole[]
  /** 游客标识 0-正常用户 1-游客 */
  touristFlag?: number
  /** 年龄 */
  userAge?: number
  /** 用户背景图片 */
  userBgImg?: string
  /** 出生年月日 */
  userBirthday?: string
  /** 用户简介 */
  userDesc?: string
  /** 用户扩展字段 */
  userExt?: string
  /** 用户性别 0-女 1-男 */
  userGenders?: number
  /** 用户id */
  userId?: number
  /** 头像 */
  userImg?: string
  /** 姓名 */
  userName?: string
  /** 昵称 */
  userNickName?: string
  /** 用户手机号码 */
  userPhone?: string
  /** 用户二维码地址 */
  userQr?: string
  /** 用户签名 */
  userSignature?: string
  /** 用户状态 0-正常 1-冻结 2-注销 */
  userStatus?: number
  /** 马甲状态 0-普通用户 1-马甲 */
  vestFlag?: number
}

export interface HealthServicePlanDetailVO<T> {
  /** 详情详细数据 */
  detailData?: T
  /** 详情基础数据 */
  servicePlanDetail?: HealthServicePlanDetail
}

export interface ProductMerchantVO {
  merchantId?: number
  merchantName?: string
  merchantPic?: string
  productList?: HealthServicePlanDetailVO<ProductSkuSimpleVO>[]
}

export interface ProductSkuSimpleVO {
  /** SKUid */
  count?: number
  /** 会员权益（是否展示会员价） */
  joinVip?: boolean
  /** 商户标识码 */
  merchantCode?: string
  /** 商户ID */
  merchantId?: number
  /** 商户图标 */
  merchantLogo?: string
  /** 商户名 */
  merchantName?: string
  /** 规格图片 */
  pic?: string
  /** 商品ID */
  productId?: number
  /** 商品名 */
  productName?: string
  /** 销售价格 */
  salePrice?: number
  /** 销量 */
  sales?: number
  /** 结算价 */
  settlePrice?: number
  /** sku编码 */
  skuCode?: string
  /** SKUid */
  skuKid?: number
  /** 销售规格 */
  spec?: string
  /** 库存 */
  stock?: number
  /** 简介 */
  subTitle?: string
  /** 会员价格 */
  vipPrice?: number
}

export interface UserNutritionistVO {
  /** 账户 */
  accountName?: string
  /** 银行卡号 */
  accountNumber?: number
  /** 认证类型 1-营养师 2-健康管理专家 3-临床营养师 */
  authType?: number
  /** 认证类型名称 */
  authTypeName?: string
  /** 认证时间 */
  authenticationTime?: string
  /** 银行 */
  bank?: string
  /** 资质证书 */
  certificate?: string
  /** 营养师费率 */
  commissionRate?: number
  /** 调理方向 */
  conditioning?: string
  /** 营养师调理方向 */
  conditioningList?: UserConditioningInfo[]
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 价格 */
  dietitianServiceSettingVO?: DietitianServiceSettingVO
  /** 每月咨询费 */
  feeMonth?: number
  /** 每次咨询费 */
  feeTime?: number
  /** 每次会员咨询费 */
  feeTimeVip?: number
  /** 咨询量 */
  inquiries?: number
  /** 个人介绍 */
  introduce?: string
  /** 是否会员 */
  joinVip?: boolean
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 头衔 */
  nutritionTitleId?: string
  /** 咨询费用营养贝 */
  price?: number
  /** 是否设置口碑 0 取消,1开启 */
  recommendFlag?: number
  /** 服务提供 */
  renderService?: string
  /** 好评率 */
  score?: number
  /** 家政服务人数 */
  serverNumber?: number
  /** 订单数 */
  serverOrder?: number
  /** 上下架状态 0上架 1下架 */
  shaveFlag?: number
  /** 营养师头衔方向 */
  titles?: NutritionistTitleInfo[]
  /** 头衔 */
  userDesc?: string
  /** 用户id */
  userId?: number
  /** 营养师头像 */
  userImg?: string
  /** 营养师姓名 */
  userName?: string
  /** 手机号 */
  userPhone?: string
  /** 用户状态 0正常 1已禁用 */
  userStatus?: number
}

export interface UserConditioningInfo {
  /** 调理方向 */
  conditioningName?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 描述 */
  descriptions?: string
  /** 图标 */
  icon?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 上下架标识 0:上架 1:下架 */
  shelveFlag?: number
  /** 排序值 */
  sort?: number
}

export interface DietitianServiceSettingVO {
  consultation?: DietitianServiceSetting
  services?: DietitianServiceSetting[]
}

export interface DietitianServiceSetting {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 营养师id */
  dietitianId?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 服务时长 */
  servicePeriod?: number
  /** 服务价格 */
  servicePrice?: number
  /** 1启用0禁用 */
  serviceStatus?: number
  /** 1天2月 */
  serviceTimeUnit?: number
  /** 服务类型1咨询2营养家政 */
  serviceType?: number
}

export interface NutritionistTitleInfo {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0:有效 1:删除 */
  delFlag?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 上下架标识 0:上架 1:下架 */
  shelveFlag?: number
  /** 排序值 */
  sort?: number
  /** 头衔名称 */
  title?: string
}

export interface HealthServicePlan {
  /** 审核描述 */
  auditDesc?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0未删除 1已删除 */
  delFlag?: number
  /** 描述 */
  description?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 需求工单ID */
  needOrderId?: number
  /** 方案状态 0有效 1失效 3待审核 4审核未通过 */
  planStatus?: number
  /** 方案类型 1通用方案 2需求方案 */
  planType?: number
  /** 上下架标识 0上架 1下架 */
  shelveFlag?: number
  /** 方案名称 */
  title?: string
}

export interface NutritionConsultationMsgVO {
  /** 消息附件 */
  attach?: string
  /** 消息内容 */
  body?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 删除标识 0正常1删除 */
  delFlag?: number
  /** 事件类型 1点对点消息 */
  eventType?: number
  /** 消息扩展字段 */
  ext?: string
  /** 消息发送者的用户账号 */
  fromAccount?: string
  /** 消息发送方的客户端IP地址 */
  fromClientIp?: string
  /** 消息发送方的客户端端口号 */
  fromClientPort?: string
  /** 发送客户端类型： AOS、IOS、PC、WINPHONE、WEB、REST */
  fromClientType?: string
  /** 发送设备id */
  fromDeviceId?: string
  /** 发送方昵称 */
  fromNick?: string
  /** 消息发送者的用户信息 */
  fromUser?: UserSimpleVO
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 消息发送时间 */
  msgTimestamp?: string
  /** TEXT ：文本消息 PICTURE ：图片消息 AUDIO ：语音消息 VIDEO ：视频消息 LOCATION ：地理位置 NOTIFICATION ：通知 FILE ：文件消息 TIPS ：提示类型消息 CUSTOM ：自定义消息 */
  msgType?: string
  /** 客户端消息Id */
  msgidClient?: string
  /** 咨询记录ID */
  recordId?: number
  /** 咨询会话ID */
  sessionId?: string
  /** 消息接收者的用户账号 */
  toAccount?: string
}

export interface HealthConsultationStatusVO {
  /** 是否正在咨询中 true-是 false-否 */
  consulting?: boolean
  /** 咨询到期时间 */
  endTime?: string
  /** 对方是否已经锁定我的咨询,锁定则无法购买 true是 false否 */
  lockBought?: boolean
  /** 咨询记录ID */
  recordId?: number
  /** 角色类型 1营养师 2管家 */
  roleType?: number
  /** 咨询开始时间 */
  startTime?: string
}

export interface NutritionConsultationRecordVO {
  /** 营养师认证类型 1-营养师 2-健康管理专家 3-临床营养师 */
  authType?: number
  /** 调理方向集合 */
  conditioningList?: UserConditioningInfo[]
  /** 调理方向，多个英文逗号分隔 */
  conditionings?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 营养师&管家信息 */
  dietitian?: UserNutritionistVO
  /** 营养师&管家ID */
  dietitianId?: number
  /** 营养师是否支持开通家政服务 true支持 false不支持 */
  dietitianServiceFlag?: boolean
  /** 病情信息 */
  diseaseInfo?: string
  /** 疾病信息是否已经发送 0未发送 1已发送 */
  diseaseSendFlag?: number
  /** 截至时间 */
  endTime?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 成员信息（冗余防止变更） */
  memberInfo?: string
  /** 成员ID */
  memberKid?: number
  /** 工单ID */
  orderId?: number
  /** 价格id */
  priceId?: number
  /** 咨询类型1单次咨询 2家政服务咨询 */
  recordType?: number
  /** 预约状态（0无需预约 1预约中 2拒绝 3同意待付款 4同意已经付款 5取消 6待同意超时 7待付款超时） */
  reserveStatus?: number
  /** 角色类型 1营养师 2管家 */
  roleType?: number
  /** 会话id */
  sessionId?: string
  /** 会话状态（0无会话 1预约（预约中，同意待付款） 2未开始（同意已经付款） 3咨询中 4待评价 5已完成 6新购买覆盖） */
  sessionStatus?: number
  /** 开始时间 */
  startTime?: string
  /** 用户信息 */
  user?: UserSimpleVO
}

export interface ConsultationSessionVO {
  /** 咨询记录ID */
  recordId?: number
  /** 咨询类型1单次咨询 2家政服务 */
  recordType?: number
  /** 角色类型 1营养师 2管家 */
  roleType?: number
  /** 会话状态（0无会话 1预约（预约中，同意待付款） 2未开始（同意已经付款） 3咨询中 4待评价 5已完成） */
  sessionStatus?: number
}

export interface KidRequest<T> {
  /** 数据 */
  data?: T
  /** kid */
  kid?: number
}

export interface SingleReserveDto {
  /** 营养师ID */
  dietitianId?: number
  /** 预约咨询价格ID */
  priceId?: number
  /** 预约开始时间 */
  startTime?: string
}

export interface NutritionConsultationStatusVO {
  /** 咨询到期时间 */
  endTime?: string
  /** 对方营养师是否已经锁定我的咨询 true是 false否 */
  lockBought?: boolean
  /** 咨询记录ID */
  recordId?: number
  /** 咨询类型1单次咨询 2家政服务咨询, 家政服务覆盖单次咨询 */
  recordType?: number
  /** 咨询开始时间 */
  startTime?: string
  /** 咨询状态 0-无记录 1-预约中 2-预约同意等待付款 3预约同意已经付款但未开始 4咨询中 */
  status?: number
}

export interface ConsultationSubmitVO {
  /** 调理方向，多个英文逗号分隔，家政服务必填 */
  conditionings?: string
  /** 创建人ID */
  createUserId?: number
  /** 营养师ID */
  dietitianId?: number
  /** 病症情况，单次咨询必填 */
  diseaseInfoDTO?: DiseaseInfoDTO
  /** 成员ID */
  memberKid?: number
  /** 价格id */
  priceId?: number
  /** 咨询ID */
  recordId?: number
  /** 咨询类型1单次咨询 2家政服务 */
  recordType?: number
  /** 会话id */
  sessionId?: string
}

export interface DiseaseInfoDTO {
  /** 病症描述 */
  description?: string
  /** 病症图片 */
  images?: string
}

export interface ConsultationSubmitDTO {
  /** 调理方向，多个英文逗号分隔，家政服务必填 */
  conditionings?: string
  /** 营养师ID */
  dietitianId?: number
  /** 病症情况，单次咨询必填 */
  diseaseInfoDTO?: DiseaseInfoDTO
  /** 成员ID */
  memberKid?: number
  /** 价格ID */
  priceId?: number
  /** 咨询类型1单次咨询 2家政服务 */
  recordType?: number
}

export interface ConsultationEvaluateDTO {
  /** 评价内容 */
  content?: string
  /** 咨询记录ID */
  recordId?: number
  /** 评价分数 */
  score?: number
}

export interface DefaultNutritionDTO {
  kid?: number
  name?: string
}

export interface NutritionVO {
  /** 报告batch */
  batchNo?: number
  /** 标识code */
  code?: string
  /** 是否有日报 */
  daily?: boolean
  /** 是否默认 */
  defaultNutritionPlan?: boolean
  /** kid */
  kid?: number
  /** 成员标识 */
  memberNo?: number
  /** 名称 */
  name?: string
}

export interface SettingNutritionVO {
  diets?: DietVO[]
  nutritious?: NutritionVO[]
}

export interface DietVO {
  /** 是否默认 */
  defaultDiet?: boolean
  /** kid */
  kid?: number
  /** 名称 */
  name?: string
  /** 计划 */
  plan?: string
  /** 报告Kid */
  reportKid?: number
}

export interface SchemeBodyDTO {
  conclusion?: string
  conclusionTitle?: string
  inputFlag?: boolean
  kcal?: number
  protein?: number
  recipeFlag?: boolean
  scheme?: any
  schemeName?: string
}

export interface TableNutritionVipRecordVO {
  /** 开始时间 */
  beginTime?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  currentDateTime?: string
  /** 截止时间 */
  endTime?: string
  /** 剩余体验次数 */
  freeTimes?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 名称 */
  recordName?: string
  /** 总体验次数 */
  totalFreeTimes?: number
}

export interface NutritionAnalysisForm {
  address?: string
  diets?: DietForm[]
  memberKids?: string[]
  returnReportKid?: boolean
}

export interface DietForm {
  diet?: Diet
  imageUrl?: string
}

export interface BookInfoVO {
  /** 菜品数量 */
  dietCount?: number
  /** 订单ID */
  kid?: number
  /** 会员价 */
  memberPrice?: number
  /** 订单名称 */
  orderName?: string
  /** 订单总价 */
  price?: number
  /** 剩余免费次数 */
  remainFreeTime?: number
  /** 总免费次数 */
  totalFreeTime?: number
  /** 订单用户ID */
  userId?: number
}

export interface UserResource {
  anne?: string
  /** 附件 */
  annex?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 资源详情 */
  detail?: string
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 手机号 */
  phone?: string
  /** 备注 */
  remark?: string
  /** 状态 0未回访 1已回访 3已回访，继续跟进 */
  resourceStatus?: number
  /** 用户id */
  userId?: number
  /** 姓名 */
  userName?: string
}

export interface UserScheme {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 身高 */
  height?: number
  /** 是否填写 */
  inputFlag?: number
  /** 卡路里 */
  kcal?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 计划标识 */
  planFlag?: number
  /** 方案id */
  planId?: number
  /** 蛋白质 */
  protein?: number
  /** 方案code */
  schemeCode?: string
  /** 方案name */
  schemeName?: string
  /** 用户id */
  userId?: number
  /** 体重 */
  weight?: number
}

export interface CheckSchemeVO {
  buyScheme?: boolean
  kid?: number
  name?: string
}

export interface SchemeMQ {
  birthday?: string
  code?: string
  gender?: number
  height?: number
  labourLevel?: number
  name?: string
  sbqs?: string
  sftx?: string
  userId?: number
  weight?: number
}

export interface VipThroughTrainLog {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 天数 */
  days?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 订单id */
  orderId?: number
  /** 记录时间 */
  recordTime?: string
  /**  1.正常购买 2.后台充值 3.兑换码 */
  sources?: number
  /** userId */
  userId?: number
}

export interface WomanInfo {
  /** 孕前体重 */
  beforeGestationWeight?: number
  /** 出生日期 */
  birthday?: string
  /** 分娩日期 */
  childbearingDay?: string
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 目前体重 */
  currentWeight?: number
  /** 数据类型 0-孕期 1-产后 */
  dataType?: number
  /** 身高 */
  height?: number
  /** 分布式唯一ID */
  kid?: number
  /** 末次经期 */
  lastMenstrualPeriod?: string
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 妊妇姓名 */
  womanName?: string
}

export interface WomanWeight {
  /** 创建时间 */
  createDate?: string
  /** 创建人ID */
  createUserId?: number
  /** 数据类型 0-孕期 1-产后 */
  dataType?: number
  /** 分布式唯一ID */
  kid?: number
  /** 最后修改时间 */
  lastUpdateDate?: string
  /** 最后修改人ID */
  lastUpdateUserId?: number
  /** 称重日期 */
  weighDay?: string
  /** 重量 */
  weight?: number
  /** 妊妇编号 */
  womanTid?: number
}

export interface WeightCurveChVO {
  afterChildbirthDay?: number
  bmiList?: Point[]
  childbearingDay?: string
  realWeightList?: Point[]
}

export interface Point {
  x?: number
  y?: number
}

export interface IncreaseWeightCurveVO {
  bmi?: number
  bmiMessage?: string
  cueMessage?: string
  gestationDays?: number
  incWeightStandardHighList?: Point[]
  incWeightStandardLowList?: Point[]
  lastWeightStatus?: number
  preProductionDay?: string
  realWeightList?: Point[]
}

export interface TableNutritionVipRecordDTO {
  /** 购买时间 */
  buyTime?: string
  /** 名称 */
  recordName?: string
  /** 时间单位 */
  timeUnit?: string
  /** 用户ID */
  userId?: number
  /** 有效时间 */
  validTime?: number
}
export default {
  evaluateVipRecords: {
    /**
    * 创建或延长会员（只能内网调用）
    */
    createOrExtend(evaluateVipRecordDTO: EvaluateVipRecordDTO): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/evaluate-vip-records/action/createOrExtend`, evaluateVipRecordDTO).then((res:any) => res.data.data)
    },
    /**
    * 查询用户VIP权限状态（未登录）
    */
    getVipStatusForPublic(params: { cityCode?: string }): Promise<EvaluateVipRecordVO> {
      return httpGet(`/evaluate/v5.0/pb/evaluate-vip-records/action/getVipStatusForPublic`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询用户VIP权限状态（已登录）
    */
    getVipStatus(params: { cityCode?: string }): Promise<EvaluateVipRecordVO> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-vip-records/action/getVipStatus`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询用户购买记录
    */
    listRecord(): Promise<EvaluateUserRecordVO> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-vip-records/action/listRecord`).then((res:any) => res.data.data)
    },
  },
  diagnosisSchedules: {
    /**
    * APP端查询某天所有专家排班
    */
    dayExperts(params: { pageNo?: number, pageSize?: number, week?: number }): Promise<PageList<Expert>> {
      return httpGet(`/evaluate/v5.0/pb/diagnosis-schedules/action/day-experts`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询专家某天排班
    */
    expertDay(params: { expertId: number, week: number }): Promise<List<ExpertDay>> {
      return httpGet(`/evaluate/v5.0/pb/diagnosis-schedules/action/expert-day`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询专家一周排班
    */
    expertWeek(params: { expertId: number }): Promise<Expert> {
      return httpGet(`/evaluate/v5.0/pb/diagnosis-schedules/action/expert-week`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询一周信息
    */
    weeks(params: { weekType?: string }): Promise<Collection<WeekDay>> {
      return httpGet(`/evaluate/v5.0/pb/diagnosis-schedules/action/weeks`,  {params} ).then((res:any) => res.data.data)
    },
  },
  evaluateClassifies: {
    /**
    * 问卷分类首页查询
    */
    getHomePage(): Promise<List<EvaluateClassifyHomePage>> {
      return httpGet(`/evaluate/v5.0/pb/evaluate-classifies/action/get-home-page`).then((res:any) => res.data.data)
    },
    /**
    * 问卷分类查询
    */
    getTree(): Promise<List<EvaluateClassifyNode>> {
      return httpGet(`/evaluate/v5.0/pb/evaluate-classifies/action/get-tree`).then((res:any) => res.data.data)
    },
    /**
    * 营养干预集查询
    */
    getPlanTreeSet(): Promise<List<EvaluateClassifyNode>> {
      return httpGet(`/evaluate/v5.0/pb/evaluate-classifies/action/getPlanTreeSet`).then((res:any) => res.data.data)
    },
  },
  evaluateClassifyPrices: {
    /**
    * APP查询购买详情页(问卷、VIP、套餐)
    */
    detailForApp(params: { priceCode: string, priceType: string }): Promise<EvaluateClassifyPriceVO> {
      return httpGet(`/evaluate/v5.0/pb/evaluate-classify-prices/action/detailForApp`,  {params} ).then((res:any) => res.data.data)
    },
  },
  evaluateUserReports: {
    /**
    * 查询示例报告
    */
    getExample(params: { diseaseCode: string }): Promise<ReportTotal> {
      return httpGet(`/evaluate/v5.0/pb/evaluate-user-reports/action/get-example`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询餐桌营养报告
    */
    getTableNutrition(params: { batchNo?: number, reportKid?: number }): Promise<List<ReportGroup>> {
      return httpGet(`/evaluate/v5.0/pb/evaluate-user-reports/action/get-table-nutrition`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 跟据订单编号查询餐桌营养报告（查询到了客户端跳转报告页面，未查询到的客户端跳转报告列表（异步生成的订单））
    */
    getTableNutritionByOrder(params: { orderKid: number, returnReportKid?: boolean }): Promise<number> {
      return httpGet(`/evaluate/v5.0/pb/evaluate-user-reports/action/get-table-nutrition-byOrder`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询报告下所有评测单元(小程序-预览)
    */
    previewAllByMiniProgram(params: { reportKid: number }): Promise<ReportTotal> {
      return httpGet(`/evaluate/v5.0/pb/evaluate-user-reports/action/preview-all-byMiniProgram`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询报告
    */
    getAll(params: { batchNo?: number, memberKid?: number, reportKid?: number }): Promise<ReportTotal> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-user-reports/action/get-all`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询报告下所有评测单元(h5)
    */
    getAllByH5(params: { reportKid: number }): Promise<ReportTotal> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-user-reports/action/get-all-byH5`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询报告下所有评测单元(小程序)
    */
    getAllByMiniProgram(params: { reportKid: number }): Promise<ReportTotal> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-user-reports/action/get-all-byMiniProgram`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询报告分享
    */
    getShare(params: { batchNo?: number, memberKid?: number, reportKid?: number }): Promise<JSONObject> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-user-reports/action/get-share`,  {params} ).then((res:any) => res.data.data)
    },
  },
  healths: {
    /**
    * 健康检查
    */
    check(): Promise<boolean> {
      return httpGet(`/evaluate/v5.0/pb/healths/action/check`).then((res:any) => res.data.data)
    },
  },
  nounClasss: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<NounClass>> {
      return httpGet(`/evaluate/v5.0/pb/noun-classs/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  nounInfos: {
    /**
    * APP端查询单个详情
    */
    detail(params: { kid: number }): Promise<NounInfo> {
      return httpGet(`/evaluate/v5.0/pb/noun-infos/action/detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询文本名词解释
    */
    explain(params: { text: string }): Promise<Map<string,string>> {
      return httpGet(`/evaluate/v5.0/pb/noun-infos/action/explain`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listByClsname(params: { className: string, pageNo?: number, pageSize?: number }): Promise<PageList<NounInfo>> {
      return httpGet(`/evaluate/v5.0/pb/noun-infos/action/list-by-clsname`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { classId: number, pageNo?: number, pageSize?: number }): Promise<PageList<NounInfo>> {
      return httpGet(`/evaluate/v5.0/pb/noun-infos/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端库存名词搜索
    */
    nounSearch(params: { nounName: string }): Promise<List<NounInfo>> {
      return httpGet(`/evaluate/v5.0/pb/noun-infos/action/nounSearch`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询单个名词解释
    */
    singleExplain(params: { nounName: string }): Promise<string> {
      return httpGet(`/evaluate/v5.0/pb/noun-infos/action/singleExplain`,  {params} ).then((res:any) => res.data.data)
    },
  },
  schemesHead: {
    /**
    * 查询方案
    */
    detail(params: { code: string }): Promise<SchemeHeadDTO> {
      return httpGet(`/evaluate/v5.0/pb/schemes-head/action/detail`,  {params} ).then((res:any) => res.data.data)
    },
  },
  userBookInfos: {
    /**
    * 获取汇总营养素
    */
    totalCompNutrient(params: { batchNo?: number, dietName: string, reportKid?: number }): Promise<List<CompNutrient>> {
      return httpGet(`/evaluate/v5.0/pb/user-book-infos/action/total-comp-nutrient`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 餐桌营养分析（既不是会员又没有免费次数时-创建预定订单）
    */
    analysisOrder(nutritionAnalysisForm: NutritionAnalysisForm): Promise<number> {
      return httpPost(`/evaluate/v5.0/pt/user-book-infos/action/analysisOrder`, nutritionAnalysisForm).then((res:any) => res.data.data)
    },
    /**
    * 餐桌营养分析（有会员或者存在免费次数时使用-创建预定订单并直接生成报告）
    */
    freeNutritionAnalysis(nutritionAnalysisForm: NutritionAnalysisForm): Promise<number> {
      return httpPost(`/evaluate/v5.0/pt/user-book-infos/action/freeNutritionAnalysis`, nutritionAnalysisForm).then((res:any) => res.data.data)
    },
    /**
    * 餐桌营养预定订单查询&验证
    */
    orderInfo(params: { orderKid: number }): Promise<BookInfoVO> {
      return httpGet(`/evaluate/v5.0/pt/user-book-infos/action/orderInfo`,  {params} ).then((res:any) => res.data.data)
    },
  },
  userResources: {
    /**
    * 新增用户资源
    */
    create(userResourceDTO: UserResourceDTO): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pb/user-resources/action/create`, userResourceDTO).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<UserResource>> {
      return httpGet(`/evaluate/v5.0/pt/user-resources/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  vipThroughTrainCards: {
    /**
    * app会员直通车详情分享页
    */
    shareDetail(): Promise<VIPThroughTrainDetailVO> {
      return httpGet(`/evaluate/v5.0/pb/vip-through-train-cards/action/share-detail`).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<VipThroughTrainCard>> {
      return httpGet(`/evaluate/v5.0/pt/vip-through-train-cards/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  backgroundRechargeVipRecords: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<BackgroundRechargeVipRecord>> {
      return httpGet(`/evaluate/v5.0/pt/background-recharge-vip-records/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  dailyBloodSugars: {
    /**
    * 日常血糖打卡
    */
    post(dto: DailyBloodSugarDTO): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/daily-blood-sugars`, dto).then((res:any) => res.data.data)
    },
    /**
    * 最新打卡记录
    */
    last(): Promise<DailyBloodSugar> {
      return httpGet(`/evaluate/v5.0/pt/daily-blood-sugars/action/last`).then((res:any) => res.data.data)
    },
    /**
    * 血糖数据列表
    */
    listAdmin(params: { endTime?: string, pageNo?: number, pageSize?: number, startTime?: string }): Promise<DailyBloodSugarListVO> {
      return httpGet(`/evaluate/v5.0/pt/daily-blood-sugars/action/list-admin`,  {params} ).then((res:any) => res.data.data)
    },
  },
  dailyBodyData: {
    /**
    * 查询某天数据
    */
    get(params: { date: string }): Promise<List<BodyDataVo>> {
      return httpGet(`/evaluate/v5.0/pt/daily-body-data`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 新增(更新)DailyBodyData
    */
    post(dailyBodyDataForm: DailyBodyDataForm): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/daily-body-data`, dailyBodyDataForm).then((res:any) => res.data.data)
    },
  },
  dailyBodyDataNewest: {
    /**
    * 查询最新数据
    */
    get(): Promise<List<BodyDataVo>> {
      return httpGet(`/evaluate/v5.0/pt/daily-body-data-newest`).then((res:any) => res.data.data)
    },
  },
  dailyBodyDatas: {
    /**
    * 查询每日数据
    */
    get(params: { date: string, type: string }): Promise<DailyBodyDataVo> {
      return httpGet(`/evaluate/v5.0/pt/daily-body-datas`,  {params} ).then((res:any) => res.data.data)
    },
  },
  dailyDiets: {
    /**
    * 日常膳食打卡
    */
    post(dto: DailyDietDTO): Promise<number> {
      return httpPost(`/evaluate/v5.0/pt/daily-diets`, dto).then((res:any) => res.data.data)
    },
    /**
    * 日常膳食打卡记录查询
    */
    list(params: { dietType?: string, kid?: string, markDate: string }): Promise<DailyDietListForm> {
      return httpGet(`/evaluate/v5.0/pt/daily-diets/action/list`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * listDaily2
    */
    list2(params: { dietType?: number, kid?: number, markDate?: string }): Promise<DailyDietForm> {
      return httpGet(`/evaluate/v5.0/pt/daily-diets/action/list2`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 膳食打卡营养查询
    */
    listDailyFoodComps(params: { dietType?: string, markDate: string }): Promise<DailyKcalVO> {
      return httpGet(`/evaluate/v5.0/pt/daily-diets/action/listDailyFoodComps`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP查询报告膳食计划
    */
    reportPlan(): Promise<EvaluateDietPlanVO> {
      return httpGet(`/evaluate/v5.0/pt/daily-diets/action/report-plan`).then((res:any) => res.data.data)
    },
    /**
    * 日常膳食打卡变更摄入量
    */
    put(params: { kid: number }, dto: DailyDietDTO): Promise<boolean> {
      return httpPut(`/evaluate/v5.0/pt/daily-diets/${params.kid}`, dto,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 日常膳食打卡删除
    */
    delete(params: { kid: number }): Promise<boolean> {
      return httpDelete(`/evaluate/v5.0/pt/daily-diets/${params.kid}`,  {params} ).then((res:any) => res.data.data)
    },
  },
  dailyReportCollects: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<DailyReportCollect>> {
      return httpGet(`/evaluate/v5.0/pt/daily-report-collects/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  dailyReports: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<DailyReport>> {
      return httpGet(`/evaluate/v5.0/pt/daily-reports/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  dailySports: {
    /**
    * 日常运动打卡录入
    */
    post(dailySportDto: DailySportDTO): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/daily-sports`, dailySportDto).then((res:any) => res.data.data)
    },
    /**
    * 日常运动打卡查询
    */
    selectById(params: { kid?: string, markDate: string }): Promise<List<DailySportVO>> {
      return httpGet(`/evaluate/v5.0/pt/daily-sports/action/selectById`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 日常运动打卡修改
    */
    update(dailySportDto: DailySportDTO): Promise<boolean> {
      return httpPut(`/evaluate/v5.0/pt/daily-sports/action/update`, dailySportDto).then((res:any) => res.data.data)
    },
    /**
    * 日常运动打卡删除
    */
    delete(params: { kid: number }): Promise<boolean> {
      return httpDelete(`/evaluate/v5.0/pt/daily-sports/${params.kid}`,  {params} ).then((res:any) => res.data.data)
    },
  },
  dailySportsBases: {
    /**
    * 运动数据模糊查询
    */
    selectByKeyWord(params: { keyword?: string }): Promise<List<DailySportsBaseVO>> {
      return httpGet(`/evaluate/v5.0/pt/daily-sports-bases/action/selectByKeyWord`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 运动时间体重查询
    */
    selectByUserId(): Promise<number> {
      return httpGet(`/evaluate/v5.0/pt/daily-sports-bases/action/selectByUserId`).then((res:any) => res.data.data)
    },
    /**
    * 运动数据查询
    */
    get(params: { kid: number }): Promise<DailySportsBase> {
      return httpGet(`/evaluate/v5.0/pt/daily-sports-bases/${params.kid}`,  {params} ).then((res:any) => res.data.data)
    },
  },
  dailySteps: {
    /**
    * 日常计步打卡录入
    */
    post(dailyStep: DailyStep): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/daily-steps`, dailyStep).then((res:any) => res.data.data)
    },
    /**
    * 日常计步打卡查询
    */
    selectById(params: { markDate: string }): Promise<DailyStepVO> {
      return httpGet(`/evaluate/v5.0/pt/daily-steps/action/selectById`,  {params} ).then((res:any) => res.data.data)
    },
  },
  dailyWaters: {
    /**
    * 日常饮水打卡录入
    */
    post(dailyWater: DailyWater): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/daily-waters`, dailyWater).then((res:any) => res.data.data)
    },
    /**
    * 日常饮水打卡查询
    */
    selectById(params: { markDate: string }): Promise<DailyWaterVO> {
      return httpGet(`/evaluate/v5.0/pt/daily-waters/action/selectById`,  {params} ).then((res:any) => res.data.data)
    },
  },
  daily: {
    /**
    * 获取主页状态与数据
    */
    getSmartNutrition(): Promise<SmartNutritionVO> {
      return httpGet(`/evaluate/v5.0/pt/daily/action/get-SmartNutrition`).then((res:any) => res.data.data)
    },
    /**
    * 获取用户是否做过测评
    */
    getUserEvaluate(): Promise<UserEvaluateVO> {
      return httpGet(`/evaluate/v5.0/pt/daily/action/get-UserEvaluate`).then((res:any) => res.data.data)
    },
    /**
    * 主页数据
    */
    getData(params: { date: string }): Promise<DailyDataVO> {
      return httpGet(`/evaluate/v5.0/pt/daily/action/get-data`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 获取日历打卡状态
    */
    getRecord(params: { afterDate: string, beforeDate: string, type: string }): Promise<List<DailyRecordVO>> {
      return httpGet(`/evaluate/v5.0/pt/daily/action/get-record`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 打卡日报
    */
    getReport(params: { date: string, kId?: string }): Promise<DailyReportVO> {
      return httpGet(`/evaluate/v5.0/pt/daily/action/get-report`,  {params} ).then((res:any) => res.data.data)
    },
  },
  diagnosisReservations: {
    /**
    * 创建预约订单
    */
    createReservationOrder(createReservationVO: CreateReservationVO): Promise<number> {
      return httpPost(`/evaluate/v5.0/pt/diagnosis-reservations/action/createReservationOrder`, createReservationVO).then((res:any) => res.data.data)
    },
    /**
    * 专家邀请提示
    */
    exportInviteNotice(params: { reservationId: number, userId: number }): Promise<boolean> {
      return httpGet(`/evaluate/v5.0/pt/diagnosis-reservations/action/exportInviteNotice`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 专家邀请用户进入诊室进行就诊
    */
    exportInviteUserVisit(userVisitVO: UserVisitVO): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/diagnosis-reservations/action/exportInviteUserVisit`, userVisitVO).then((res:any) => res.data.data)
    },
    /**
    * 获取专家一天的所有预约信息
    */
    getExportReservation(): Promise<ExportRoomInfoVO> {
      return httpGet(`/evaluate/v5.0/pt/diagnosis-reservations/action/getExportReservation`).then((res:any) => res.data.data)
    },
    /**
    * 获取用户预约的记录
    */
    getUserReservationByType(params: { pageNo?: number, pageSize?: number, type: number }): Promise<PageList<DiagnosisReservationVO>> {
      return httpGet(`/evaluate/v5.0/pt/diagnosis-reservations/action/getUserReservationByType`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<DiagnosisReservation>> {
      return httpGet(`/evaluate/v5.0/pt/diagnosis-reservations/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 创用户主动进入诊室进行就诊，在就诊中
    */
    userActiveInVisit(userVisitVO: UserVisitVO): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/diagnosis-reservations/action/userActiveInVisit`, userVisitVO).then((res:any) => res.data.data)
    },
  },
  diseaseNutritionAdvises: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<DiseaseNutritionAdvise>> {
      return httpGet(`/evaluate/v5.0/pt/disease-nutrition-advises/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  evaluateBackVisit: {
    /**
    * 提交回访问卷
    */
    post(body: UserBackVisitForm): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/evaluate-back-visit`, body).then((res:any) => res.data.data)
    },
    /**
    * 查询回访问卷类型
    */
    getType(): Promise<string> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-back-visit/action/get-type`).then((res:any) => res.data.data)
    },
  },
  evaluateBackVisits: {
    /**
    * 查询用户待随访问卷列表
    */
    get(): Promise<List<EvaluateBackVisitListVO>> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-back-visits`).then((res:any) => res.data.data)
    },
  },
  evaluateDietPlans: {
    /**
    * APP查询膳食计划
    */
    getPlan(): Promise<EvaluateDietPlanVO> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-diet-plans/action/get-plan`).then((res:any) => res.data.data)
    },
    /**
    * APP查询报告膳食计划
    */
    getPlan1200(): Promise<EvaluateDietPlanVO> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-diet-plans/action/get-plan-1200`).then((res:any) => res.data.data)
    },
    /**
    * APP查询报告膳食计划
    */
    getPlan358(params: { energy: number }): Promise<EvaluateDietPlanVO> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-diet-plans/action/get-plan-358`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP查询膳食计划交换份（分页查询）
    */
    getPlanExchange(params: { dietPlanDetailKid: number, pageNo?: number, pageSize?: number }): Promise<PageList<EvaluateDietPlanDetail>> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-diet-plans/action/get-plan-exchange`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP查询报告膳食计划
    */
    getReportPlan(params: { reportKid: number }): Promise<EvaluateDietPlanVO> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-diet-plans/action/get-report-plan`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP查询方案膳食计划
    */
    getSchemePlan(params: { code: string, kcal: number, protein: number }): Promise<EvaluateDietPlanVO> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-diet-plans/action/get-scheme-plan`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP用kid查询方案膳食计划
    */
    getSchemePlanKid(params: { schemeKid: number }): Promise<EvaluateDietPlanVO> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-diet-plans/action/get-scheme-plan-kid`,  {params} ).then((res:any) => res.data.data)
    },
  },
  evaluateMemberExts: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<EvaluateMemberExt>> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-member-exts/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  evaluateMembers: {
    /**
    * 新增家庭成员
    */
    post(evaluateFamilyMember: EvaluateMember): Promise<number> {
      return httpPost(`/evaluate/v5.0/pt/evaluate-members`, evaluateFamilyMember).then((res:any) => res.data.data)
    },
    /**
    * 查询成员列表
    */
    getAll(): Promise<List<EvaluateMember>> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-members/action/get-all`).then((res:any) => res.data.data)
    },
    /**
    * 查询家庭成员
    */
    get(params: { kid: number }): Promise<EvaluateMember> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-members/${params.kid}`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 编辑家庭成员
    */
    put(params: { kid: number }, evaluateFamilyMember: EvaluateMember): Promise<number> {
      return httpPut(`/evaluate/v5.0/pt/evaluate-members/${params.kid}`, evaluateFamilyMember,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 删除家庭成员
    */
    delete(params: { kid: number }): Promise<boolean> {
      return httpDelete(`/evaluate/v5.0/pt/evaluate-members/${params.kid}`,  {params} ).then((res:any) => res.data.data)
    },
  },
  evaluateQuestionnaire: {
    /**
    * 问卷校验
    */
    dynamicCheck(body: DynamicQuestionnaireCheck): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/evaluate-questionnaire/action/dynamic-check`, body).then((res:any) => res.data.data)
    },
    /**
    * 下一个问卷
    */
    nextForm(body: UserQuestionnaireNext): Promise<NextForm> {
      return httpPost(`/evaluate/v5.0/pt/evaluate-questionnaire/action/next-form`, body).then((res:any) => res.data.data)
    },
    /**
    * 开始问卷
    */
    startForm(params: { classifyCode: string, formVersion?: number, memberKid?: number }): Promise<DynamicQuestionnaireResult> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-questionnaire/action/start-form`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 开始问卷(匿名)
    */
    startFormAnonymous(params: { classifyCode: string }): Promise<DynamicQuestionnaireResult> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-questionnaire/action/start-form-anonymous`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 开始问卷(自己)
    */
    startFormAuto(params: { classifyCode: string, memberKid?: number }): Promise<DynamicQuestionnaireResult> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-questionnaire/action/start-form-auto`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 开始问卷(代做)
    */
    startFormSubstitute(params: { classifyCode: string, memberKid: number }): Promise<DynamicQuestionnaireResult> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-questionnaire/action/start-form-substitute`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 问卷引导
    */
    statementForm(params: { classifyCode: string }): Promise<DynamicQuestionnaireStatement> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-questionnaire/action/statement-form`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 提交问卷
    */
    submitForm(generateForms: ReportGenerateForm[]): Promise<List<EvaluateReportCollect>> {
      return httpPost(`/evaluate/v5.0/pt/evaluate-questionnaire/action/submit-form`, generateForms).then((res:any) => res.data.data)
    },
    /**
    * 提交问卷(活动)
    */
    submitFormActivity(generateForms: ReportGenerateForm[]): Promise<List<EvaluateReportCollect>> {
      return httpPost(`/evaluate/v5.0/pt/evaluate-questionnaire/action/submit-form-activity`, generateForms).then((res:any) => res.data.data)
    },
  },
  evaluateReportCollects: {
    /**
    * 匿名领取
    */
    anonymousReceive(kids: number[]): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/evaluate-report-collects/action/anonymous-receive`, kids).then((res:any) => res.data.data)
    },
    /**
    * 删除报告
    */
    batchDelete(kids: number[]): Promise<number> {
      return httpPut(`/evaluate/v5.0/pt/evaluate-report-collects/action/batch-delete`, kids).then((res:any) => res.data.data)
    },
    /**
    * 查询家庭报告组
    */
    getFamily(params: { collectBatchNo: number }): Promise<ReportFamilyCollect> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-report-collects/action/get-family`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 是否做过相关活动报告
    */
    hasReported(params: { classifyCode: string }): Promise<EvaluateReportCollect> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-report-collects/action/has-reported`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询报告集列表
    */
    pageList(params: { memberType?: string, onlyMine?: number, pageNo?: number, pageSize?: number, paymentFlag?: number, yearMonth?: string }): Promise<PageList<EvaluateReportCollect>> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-report-collects/action/page-list`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 代做赠送
    */
    substitutePresent(kids: number[]): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/evaluate-report-collects/action/substitute-present`, kids).then((res:any) => res.data.data)
    },
  },
  evaluateStatisticss: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<EvaluateStatistics>> {
      return httpGet(`/evaluate/v5.0/pt/evaluate-statisticss/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  excelExports: {
    /**
    * 合作方导出数据
    */
    export(excelExportRecord: ExcelExportRecord): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/excel-exports/action/export`, excelExportRecord).then((res:any) => res.data.data)
    },
  },
  excelImports: {
    /**
    * 合作方导入数据
    */
    import(params: { createDate?: string, createUserId?: number, importCode?: string, importDesc?: string, importExcel?: string, importParams?: string, importStatus?: number, kid?: number, lastUpdateDate?: string, lastUpdateUserId?: number, resultExcel?: string, withoutTitle?: boolean }): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/excel-imports/action/import`,  {params} ).then((res:any) => res.data.data)
    },
  },
  exchangeCodeInfos: {
    /**
    * 兑换码兑换
    */
    consumeCode(params: { code?: string }): Promise<boolean> {
      return httpGet(`/evaluate/v5.0/pt/exchange-code-infos/action/consumeCode`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 查询我兑换过的code权益
    */
    myCode(params: { applicationType?: string, codeStatus?: string, pageNo?: number, pageSize?: number }): Promise<PageList<ExchangeCodeValueVO>> {
      return httpGet(`/evaluate/v5.0/pt/exchange-code-infos/action/my-code`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 使用兑换码
    */
    useCode(codeValueUseDTO: ExchangeCodeValueUseDTO): Promise<boolean> {
      return httpPut(`/evaluate/v5.0/pt/exchange-code-infos/action/use-code`, codeValueUseDTO).then((res:any) => res.data.data)
    },
  },
  healthButlerGroups: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<HealthButlerGroup>> {
      return httpGet(`/evaluate/v5.0/pt/health-butler-groups/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  healthManagerServiceDetails: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<HealthManagerServiceDetail>> {
      return httpGet(`/evaluate/v5.0/pt/health-manager-service-details/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  healthManagerServices: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<HealthManagerService>> {
      return httpGet(`/evaluate/v5.0/pt/health-manager-services/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  healthNeedOrderDetails: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<HealthNeedOrderDetail>> {
      return httpGet(`/evaluate/v5.0/pt/health-need-order-details/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  healthNeedOrderRecordMsgs: {
    /**
    * 需求工单聊天记录-APP新增
    */
    create(healthNeedOrderRecordMsg: HealthNeedOrderRecordMsg): Promise<number> {
      return httpPost(`/evaluate/v5.0/pt/health-need-order-record-msgs/action/create`, healthNeedOrderRecordMsg).then((res:any) => res.data.data)
    },
    /**
    * APP端-需求工单聊天记录（查历史）
    */
    pageHistory(params: { kid: number, pageSize?: number, versionKid?: number }): Promise<PageList<HealthNeedOrderRecordMsgVO>> {
      return httpGet(`/evaluate/v5.0/pt/health-need-order-record-msgs/action/page-history`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端-需求工单聊天记录（查新）
    */
    pageNew(params: { kid: number, pageSize?: number, versionKid?: number }): Promise<PageList<HealthNeedOrderRecordMsgVO>> {
      return httpGet(`/evaluate/v5.0/pt/health-need-order-record-msgs/action/page-new`,  {params} ).then((res:any) => res.data.data)
    },
  },
  healthNeedOrders: {
    /**
    * 服务端-我要服务-创建需求工单
    */
    create(dto: HealthNeedOrderDTO): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/health-need-orders/action/create`, dto).then((res:any) => res.data.data)
    },
    /**
    * 服务端-服务进度查询-咨询中和已出方案详情
    */
    detail(params: { kid: number }): Promise<HealthNeedOrderDetailVO> {
      return httpGet(`/evaluate/v5.0/pt/health-need-orders/action/detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * App端是否存在需求工单
    */
    hasOrder(): Promise<boolean> {
      return httpGet(`/evaluate/v5.0/pt/health-need-orders/action/has-order`).then((res:any) => res.data.data)
    },
    /**
    * 服务端-服务进度查询-咨询中和已出方案列表
    */
    list(params: { pageNo?: number, pageSize?: number }): Promise<PageList<HealthNeedOrder>> {
      return httpGet(`/evaluate/v5.0/pt/health-need-orders/action/list`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询工单状态
    */
    status(params: { kid: number }): Promise<HealthNeedOrder> {
      return httpGet(`/evaluate/v5.0/pt/health-need-orders/action/status`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端根据用户查询工单状态 1有未完成的需求工单 2无未完成的需求工单
    */
    statusByUser(): Promise<number> {
      return httpGet(`/evaluate/v5.0/pt/health-need-orders/action/status-by-user`).then((res:any) => res.data.data)
    },
    /**
    * 转单-客服APP
    */
    transfer(dto: HealthNeedOrderAdminDTO): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/health-need-orders/action/transfer`, dto).then((res:any) => res.data.data)
    },
  },
  healthServiceOrderRecordMsgs: {
    /**
    * 服务工单聊天记录-APP新增
    */
    create(healthServiceOrderRecordMsg: HealthServiceOrderRecordMsg): Promise<number> {
      return httpPost(`/evaluate/v5.0/pt/health-service-order-record-msgs/action/create`, healthServiceOrderRecordMsg).then((res:any) => res.data.data)
    },
    /**
    * APP端-服务工单聊天记录（查历史）
    */
    pageHistory(params: { kid: number, pageSize?: number, versionKid?: number }): Promise<PageList<HealthServiceOrderRecordMsgVO>> {
      return httpGet(`/evaluate/v5.0/pt/health-service-order-record-msgs/action/page-history`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端-服务工单聊天记录（查新）
    */
    pageNew(params: { kid: number, pageSize?: number, versionKid?: number }): Promise<PageList<HealthServiceOrderRecordMsgVO>> {
      return httpGet(`/evaluate/v5.0/pt/health-service-order-record-msgs/action/page-new`,  {params} ).then((res:any) => res.data.data)
    },
  },
  healthServiceOrders: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number, status: number }): Promise<PageList<HealthServiceOrder>> {
      return httpGet(`/evaluate/v5.0/pt/health-service-orders/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  healthServicePlanDetails: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<HealthServicePlanDetail>> {
      return httpGet(`/evaluate/v5.0/pt/health-service-plan-details/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  healthServicePlans: {
    /**
    * APP端根据ID查询详情
    */
    detail(params: { kid: number }): Promise<HealthServicePlanVO> {
      return httpGet(`/evaluate/v5.0/pt/health-service-plans/action/detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端需求工单ID查询方案详情
    */
    detailByNeed(params: { needOrderId: number }): Promise<HealthServicePlanVO> {
      return httpGet(`/evaluate/v5.0/pt/health-service-plans/action/detail-by-need`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<HealthServicePlan>> {
      return httpGet(`/evaluate/v5.0/pt/health-service-plans/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  nutritionConsultationMsgs: {
    /**
    * 查询咨询中的评测报告详情信息
    */
    getEvaluateReport(params: { recordId: number, reportKid: number }): Promise<ReportTotal> {
      return httpGet(`/evaluate/v5.0/pt/nutrition-consultation-msgs/action/get-evaluate-report`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询消息记录分页数据
    */
    pageRecordMsg(params: { lastKid?: number, pageSize?: number, recordId: number }): Promise<PageList<NutritionConsultationMsgVO>> {
      return httpGet(`/evaluate/v5.0/pt/nutrition-consultation-msgs/action/page-record-msg`,  {params} ).then((res:any) => res.data.data)
    },
  },
  nutritionConsultationRecords: {
    /**
    * 用户视角-查询健康咨询（营养师&管家）状态信息
    */
    consultingStatus(params: { roleType: string, targetId: number }): Promise<HealthConsultationStatusVO> {
      return httpGet(`/evaluate/v5.0/pt/nutrition-consultation-records/action/consulting-status`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * app查询健康咨询（营养师&管家）记录详情
    */
    detail(params: { kid: number }): Promise<NutritionConsultationRecordVO> {
      return httpGet(`/evaluate/v5.0/pt/nutrition-consultation-records/action/detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 获取健康咨询（营养师&管家）会话信息
    */
    getSession(params: { otherUserId: number }): Promise<ConsultationSessionVO> {
      return httpGet(`/evaluate/v5.0/pt/nutrition-consultation-records/action/get-session`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 用户视角-标记已经发送病情信息
    */
    markSendDisease(kidRequest: KidRequest<string>): Promise<boolean> {
      return httpPut(`/evaluate/v5.0/pt/nutrition-consultation-records/action/mark-send-disease`, kidRequest).then((res:any) => res.data.data)
    },
    /**
    * 用户视角-我的咨询
    */
    myConsultation(params: { pageNo?: number, pageSize?: number, sessionStatus?: number }): Promise<PageList<NutritionConsultationRecordVO>> {
      return httpGet(`/evaluate/v5.0/pt/nutrition-consultation-records/action/my-consultation`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 用户视角-我的健康咨询（营养师&管家）
    */
    myConsultations(params: { pageNo?: number, pageSize?: number, roleType: string, status: string }): Promise<PageList<NutritionConsultationRecordVO>> {
      return httpGet(`/evaluate/v5.0/pt/nutrition-consultation-records/action/my-consultations`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 营养师视角-我服务的客户
    */
    myCustomer(params: { customerStatus?: string, pageNo?: number, pageSize?: number }): Promise<PageList<NutritionConsultationRecordVO>> {
      return httpGet(`/evaluate/v5.0/pt/nutrition-consultation-records/action/my-customer`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 营养师&管家视角-我服务的客户
    */
    myCustomers(params: { pageNo?: number, pageSize?: number, roleType: string, status: string }): Promise<PageList<NutritionConsultationRecordVO>> {
      return httpGet(`/evaluate/v5.0/pt/nutrition-consultation-records/action/my-customers`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 用户视角-我开通的家政营养师
    */
    myDietitian(params: { pageNo?: number, pageSize?: number }): Promise<PageList<NutritionConsultationRecordVO>> {
      return httpGet(`/evaluate/v5.0/pt/nutrition-consultation-records/action/my-dietitian`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 营养师视角-我的预约客户申请
    */
    myReserveCustomer(params: { pageNo?: number, pageSize?: number }): Promise<PageList<NutritionConsultationRecordVO>> {
      return httpGet(`/evaluate/v5.0/pt/nutrition-consultation-records/action/my-reserve-customer`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 用户视角-单次咨询预约
    */
    reserve(singleReserveDto: SingleReserveDto): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/nutrition-consultation-records/action/reserve`, singleReserveDto).then((res:any) => res.data.data)
    },
    /**
    * 营养师视角-预约同意
    */
    reserveAgree(kidRequest: KidRequest<string>): Promise<boolean> {
      return httpPut(`/evaluate/v5.0/pt/nutrition-consultation-records/action/reserve-agree`, kidRequest).then((res:any) => res.data.data)
    },
    /**
    * 用户视角-预约取消
    */
    reserveCancel(kidRequest: KidRequest<string>): Promise<boolean> {
      return httpPut(`/evaluate/v5.0/pt/nutrition-consultation-records/action/reserve-cancel`, kidRequest).then((res:any) => res.data.data)
    },
    /**
    * 营养师视角-预约拒绝
    */
    reserveRefuse(kidRequest: KidRequest<string>): Promise<boolean> {
      return httpPut(`/evaluate/v5.0/pt/nutrition-consultation-records/action/reserve-refuse`, kidRequest).then((res:any) => res.data.data)
    },
    /**
    * 用户视角-查询我与营养师之间的咨询有效状态和时间
    */
    status(params: { dietitianId: number }): Promise<NutritionConsultationStatusVO> {
      return httpGet(`/evaluate/v5.0/pt/nutrition-consultation-records/action/status`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 用户视角-提交咨询记录
    */
    submit(submitDTO: ConsultationSubmitDTO): Promise<ConsultationSubmitVO> {
      return httpPost(`/evaluate/v5.0/pt/nutrition-consultation-records/action/submit`, submitDTO).then((res:any) => res.data.data)
    },
    /**
    * 用户视角-提交健康咨询（营养师&管家）评价
    */
    submitEvaluate(evaluate: ConsultationEvaluateDTO): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/nutrition-consultation-records/action/submit-evaluate`, evaluate).then((res:any) => res.data.data)
    },
  },
  action: {
    /**
    * 设置默认膳食推荐
    */
    diet(kid: DefaultNutritionDTO): Promise<boolean> {
      return httpPut(`/evaluate/v5.0/pt/nutrition/action/default/diet`, kid).then((res:any) => res.data.data)
    },
    /**
    * 设置默认营养计划
    */
    plan(kid: DefaultNutritionDTO): Promise<boolean> {
      return httpPut(`/evaluate/v5.0/pt/nutrition/action/default/plan`, kid).then((res:any) => res.data.data)
    },
  },
  nutrition: {
    /**
    * 获取用户营养计划
    */
    title(): Promise<NutritionVO> {
      return httpGet(`/evaluate/v5.0/pt/nutrition/action/title`).then((res:any) => res.data.data)
    },
  },
  nutritious: {
    /**
    * 管理营养计划列表
    */
    get(): Promise<SettingNutritionVO> {
      return httpGet(`/evaluate/v5.0/pt/nutritious`).then((res:any) => res.data.data)
    },
  },
  schemesBody: {
    /**
    * 查询方案内容
    */
    detail(params: { code: string }): Promise<SchemeBodyDTO> {
      return httpGet(`/evaluate/v5.0/pt/schemes-body/action/detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * kid查询方案内容
    */
    detailKid(params: { kid: number }): Promise<SchemeBodyDTO> {
      return httpGet(`/evaluate/v5.0/pt/schemes-body/action/detail-kid`,  {params} ).then((res:any) => res.data.data)
    },
  },
  tableNutritionVipRecords: {
    /**
    * 获取用户餐桌营养期限
    */
    getTableNutritionVipRecord(): Promise<TableNutritionVipRecordVO> {
      return httpPost(`/evaluate/v5.0/pt/table-nutrition-vip-records/action/getTableNutritionVipRecord`).then((res:any) => res.data.data)
    },
    /**
    * 创建或延长会员（只能内网调用）
    */
    createOrExtend(dto: TableNutritionVipRecordDTO): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/table-nutrition-vip-records/action/createOrExtend`, dto).then((res:any) => res.data.data)
    },
  },
  userSchemes: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<UserScheme>> {
      return httpGet(`/evaluate/v5.0/pt/user-schemes/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 提交填写信息
    */
    schemeInput(schemeMQ: SchemeMQ): Promise<CheckSchemeVO> {
      return httpPost(`/evaluate/v5.0/pt/user-schemes/action/schemeInput`, schemeMQ).then((res:any) => res.data.data)
    },
  },
  valuateUserReports: {
    /**
    * 查询最新报告
    */
    getLatest(params: { classifyCode: string }): Promise<ReportTotal> {
      return httpGet(`/evaluate/v5.0/pt/valuate-user-reports/action/get-latest`,  {params} ).then((res:any) => res.data.data)
    },
  },
  vipThroughTrainLogs: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<VipThroughTrainLog>> {
      return httpGet(`/evaluate/v5.0/pt/vip-through-train-logs/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  vipThroughTrainRightss: {
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<VipThroughTrainRights>> {
      return httpGet(`/evaluate/v5.0/pt/vip-through-train-rightss/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
  },
  vipThroughTrain: {
    /**
    * app会员直通车详情
    */
    detail(): Promise<VIPThroughTrainDetailVO> {
      return httpGet(`/evaluate/v5.0/pt/vip-through-train/action/detail`).then((res:any) => res.data.data)
    },
    /**
    * 用户会员状态 -1:未开通 0:已过期 1:生效中
    */
    getState(): Promise<number> {
      return httpGet(`/evaluate/v5.0/pt/vip-through-train/action/getState`).then((res:any) => res.data.data)
    },
    /**
    * 查询用户会员状态与权益的关系 返回:-2.用户未开通会员  -1.用户会员已过期 0.用户开通了会员但改卡无此项权益  1.用户开通了会员且该卡有此项权益
    */
    getStateWithRights(params: { type: number }): Promise<number> {
      return httpGet(`/evaluate/v5.0/pt/vip-through-train/action/getStateWithRights`,  {params} ).then((res:any) => res.data.data)
    },
  },
  womanInfos: {
    /**
    * 前端新增妊妇基本信息
    */
    create(womanInfo: WomanInfo): Promise<number> {
      return httpPost(`/evaluate/v5.0/pt/woman-infos/action/create`, womanInfo).then((res:any) => res.data.data)
    },
    /**
    * 前端新增妊妇产后基本信息
    */
    createCh(womanInfo: WomanInfo): Promise<number> {
      return httpPost(`/evaluate/v5.0/pt/woman-infos/action/createCh`, womanInfo).then((res:any) => res.data.data)
    },
    /**
    * 前端获取妊妇基本信息详情
    */
    detail(params: { dataType?: number, kid: number }): Promise<WomanInfo> {
      return httpGet(`/evaluate/v5.0/pt/woman-infos/action/detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { pageNo?: number, pageSize?: number }): Promise<PageList<WomanInfo>> {
      return httpGet(`/evaluate/v5.0/pt/woman-infos/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 前端更新妊妇基本信息
    */
    update(womanInfo: WomanInfo): Promise<boolean> {
      return httpPut(`/evaluate/v5.0/pt/woman-infos/action/update`, womanInfo).then((res:any) => res.data.data)
    },
    /**
    * 前端更新妊妇产后基本信息
    */
    updateCh(womanInfo: WomanInfo): Promise<boolean> {
      return httpPut(`/evaluate/v5.0/pt/woman-infos/action/updateCh`, womanInfo).then((res:any) => res.data.data)
    },
  },
  womanWeight: {
    /**
    * 删除妊妇体重信息
    */
    delete(params: { kid: number }): Promise<boolean> {
      return httpDelete(`/evaluate/v5.0/pt/woman-weight/${params.kid}`,  {params} ).then((res:any) => res.data.data)
    },
  },
  womanWeights: {
    /**
    * 新增称重记录
    */
    create(womanWeight: WomanWeight): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/woman-weights/action/create`, womanWeight).then((res:any) => res.data.data)
    },
    /**
    * 新增产后称重记录
    */
    createCh(womanWeight: WomanWeight): Promise<boolean> {
      return httpPost(`/evaluate/v5.0/pt/woman-weights/action/createCh`, womanWeight).then((res:any) => res.data.data)
    },
    /**
    * 获取称重记录详情
    */
    detail(params: { kid: number }): Promise<WomanWeight> {
      return httpGet(`/evaluate/v5.0/pt/woman-weights/action/detail`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 获取产后用户增涨曲线信息
    */
    getWeightCurveCh(params: { womanKid: number }): Promise<WeightCurveChVO> {
      return httpGet(`/evaluate/v5.0/pt/woman-weights/action/getWeightCurveCh`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 获取用户增涨曲线信息
    */
    getWomanIncWeightCurve(params: { womanKid: number }): Promise<IncreaseWeightCurveVO> {
      return httpGet(`/evaluate/v5.0/pt/woman-weights/action/getWomanIncWeightCurve`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * APP端查询分页数据
    */
    listPage(params: { dataType?: number, month: string, pageNo?: number, pageSize?: number, womanKid: number }): Promise<PageList<WomanWeight>> {
      return httpGet(`/evaluate/v5.0/pt/woman-weights/action/list-page`,  {params} ).then((res:any) => res.data.data)
    },
    /**
    * 更新称重记录
    */
    update(womanWeight: WomanWeight): Promise<boolean> {
      return httpPut(`/evaluate/v5.0/pt/woman-weights/action/update`, womanWeight).then((res:any) => res.data.data)
    },
    /**
    * 更新产后称重记录
    */
    updateCh(womanWeight: WomanWeight): Promise<boolean> {
      return httpPut(`/evaluate/v5.0/pt/woman-weights/action/updateCh`, womanWeight).then((res:any) => res.data.data)
    },
  },
}