import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncTopic = Loadable({
	loader: () => import('./Topic'),
	loading: MyLoadingComponent
})
const asyncEnroll = Loadable({
	loader: () => import('./Enrolls'),
	loading: MyLoadingComponent,
})
const asyncExamined = Loadable({
	loader: () => import('./Examined'),
	loading: MyLoadingComponent,
})

const asyncRanking = Loadable({
	loader: () => import('./Ranking'),
	loading: MyLoadingComponent,
})

const Topic: Module = {
	name: 'topic',
	routes: [
		{
			name: '话题活动',
			path: '/topicActivity/:kid',
			component: asyncTopic,
		},
		{
			name: '报名列表',
			path: '/topicActivity/enrolls/:kid',
			component: asyncEnroll,
		},
		{
			name: '审核列表',
			path: '/topicActivity/exmained/:kid',
			component: asyncExamined,
		},
		{
			name: '排行榜列表',
			path: '/topicActivity/Ranking/:kid',
			component: asyncRanking,
		},
	],
}
export default Topic
