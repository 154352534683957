import React, { useState, useEffect } from 'react'
// import nutrition from 'api/nutrition'
import { Button } from 'antd-mobile'
import './insu-licence.css'
import Nav from 'components/nav/Nav';


/**
 * type ：taikang、renbao, 
 * @param props 
 */

const InsuranceValueableService: PageComponent = props => {
  useEffect(() => {
    // nutrition.foodInfos.get({ kid: 234 }).then(setSesult)
		// console.log('result - :',result)
		window.scrollTo(0, 0)
		// document.body.scrollTo({top:0,left:0})
	}, [])
	
	return (
		<div style={{flex:1,position:'relative'}}>
			<Nav title={'增值服务告知书'} style={{position:'fixed',backgroundColor:'#fff'}}></Nav>
		<div className='insulic-wrap' style={{paddingTop: 0}}>		
				<div className='nav-padding'></div>
			
				<div>
				健康关爱增值服务不构成保险合同的一部分
				</div>

				<span className='licenceTitle center bold'>健康关爱增值服务告知书</span>
				<div className='licenceContent'>
			<div className='indent '>
			尊敬的客户: 您好，感谢您投保中国人民财产保险股份有限公司的“人人安康”百万医疗保险产品，您在得到我们
			提供的保障的同时，还将享有下表所述的“健康关爱增值服务”，且无需为此项服务额外支付任何费用。 如有疑问，请致电我公司全国统一客服热线 95518。
		</div>
		<div className='indent '>
		特别提示:本告知书中的增值服务仅适用保险期间起始日期在 2020 年 1 月 1 日(含)至 2020 年 12 月 31 日(含)之间的保单。
		</div>
					</div>
				<span className='licenceSection bold' >  一、您享受的“健康关爱增值服务”具体如下:</span>
				<div className='licenceContent'>
			<div className='indent '>
			服务项目
		</div>
		<div className='indent '>
		健康咨询 (全科医生)
		</div>
		<div className='indent '>
		导医导诊(挂号+陪诊，1 次/年) 国内二次诊断(1 次/年) 住院费用垫付
		</div>
		<div className='indent '>
		服务描述
		</div>
		<div className='indent '>
		由家庭医生提供日常健康咨询;提供多名医生的背 靠背回复 提供国内三甲医院副主任及以上医师的门诊预约， 并在就诊当天提供专人陪诊 提供国内三甲医院副主任及以上医师出具的二次诊 断报告 对于符合垫付条件的，由专属服务人员在医院现场 提供垫付住院费用服务并协助办理出入院和理赔
		</div>
		<div className='indent '>
		使用条件
		</div>
		<div className='indent '>
		被保险人及家属;不限疾 病，不限次数
		</div>
		<div className='indent '>
		被保险人本人;不指定医 院及专家;限定恶性肿瘤
		</div>
		<div className='indent '>
		被 保 险 人 本 人 ; 不 限 疾 病，不限次数
		</div>
		<div className='indent '>
		被 保 险 人 本 人 ; 不 限 疾 病，不限次数
		</div>
		              备注: 1.使用条件中的“限定恶性肿瘤”是指被保险人初步诊断或确诊患有保险条款中所定义的恶性肿
瘤。
视同为本服务告知书的一部分。 5.使用条件中的次数限定，均指本保单年度内的次数，该次数每保单年度更新，但未使用的次数不
可累计进入下一保单年度。 6.超过服务标准的额外服务内容、服务平台上展示的其他服务项目，均可以按照低于市场价的优惠
价格自费采购使用，提交申请后服务商会与您直接协商自费服务的内容、标准及费用，本公司对您与服 务商协商的内容不承担责任。

		</div>
				
		<span className='licenceSection bold' > 二、“健康关爱增值服务”的有效期</span>
				<div className='licenceContent'>
			<div className='indent '>
			自您的保险合同生效之日起 10 个工作日后，“健康关爱增值服务”开始生效，该服务有效期为一年; 若您所购买的“人人安康”百万医疗保险产品失效或终止，则增值服务自动终止;如有服务内容或期限变 动，本公司将提前告知您。
2.服务项目中“1 次/年”是指每保单年度最多使用 1 次。 3.如您所投保产品因退保等各种情形而失效或终止，健康关爱增值服务也随之终止。 4.各项服务的详细内容、服务时效、注意事项等，以服务网站和微信公众号的介绍为准，介绍内容
健康关爱增值服务不构成保险合同的一部分
		</div>
					</div>


					<span className='licenceSection bold' >  三、您如何获取各项服务</span>
				<div className='licenceContent'>
			<div className='indent '>
			通过我公司官方网站上“人人安康”百万医疗保险产品页面点击登陆; 或者登录健康关爱专属网站 http://cicare.mobilelab.cn/;或者微信公众 号搜索“健康关爱增值服务平台”，或扫描右侧平台二维码:
		</div>
		<div className='indent '>
		按照下面您的用户名登录，通过在线申请方式为您及您的家人获取各项 服务。首次登录服务平台请选择“首次激活”登录页面。
		</div>
		<div className='indent '>
		用户名:保单号码 初始密码:666666(请在登陆后修改密码)
		</div>
					</div>

					<span className='licenceSection bold' > 四、其它需要您留意的事项</span>
				<div className='licenceContent'>
			<div className='indent '>
			(一)“健康关爱增值服务”是中国人民财产保险股份有限公司特别为投保“人人安康”百万医疗保 险产品的被保险人提供的在保险合同约定以外的增值服务，此增值服务不构成保险合同的一部分，中国人 民财产保险股份有限公司保留在服务有效期内调整或终止“健康关爱增值服务”的权利;若中国人民财产 保险股份有限公司停止提供“健康关爱增值服务”，我们将在公司官方网站及时公告。
		</div>
		<div className='indent '>
		(二)各项服务均有相应的使用条件，您可以通过健康关爱专属网站或微信公众号查询您能免费使用 的各项服务，在满足使用条件时可申请使用。
		</div>
		<div className='indent '>
		(三)在使用各项就医服务过程中，如被保险人不能按时赴约，需要提前 24 小时通知服务公司取消安 排，否则视为已经使用该项服务。
		</div>
		<div className='indent '>
		(四)被保险人有提供完整真实病历资料的义务，本公司及服务公司对相关资料和信息负有保密的责 任。
		</div>
		<div className='indent '>
		(五)本增值服务所提供的任何医学建议仅具有参考价值，被保险人拥有采纳或不采纳的最终决定权; 同时，是否允许使用服务及医学专家的建议均不能作为同意理赔或拒绝理赔的依据。
		</div>
		<div className='indent '>
		(六)中国人民财产保险股份有限公司拥有“健康关爱增值服务”的最终解释权。
		</div>
					</div>





			</div>
			


		</div>
  )
			}
// InsuranceSecurePlan.options = { disableHeader: false, wrapperClassName: '' }
export default InsuranceValueableService
