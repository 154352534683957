import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncHaishuidao = Loadable({
	loader: () => import('./Haishuidao'),
	loading: MyLoadingComponent
})

const Haishuidao: Module = {
	name: 'Haishuidao',
	routes: [
		{
			name: '海水稻',
			path: '/mall/activepage/haishuidao/:kid',
			component: asyncHaishuidao,
		},
	],
}
export default Haishuidao
