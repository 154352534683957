import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncHaishuidao = Loadable({
	loader: () => import('./Publicbenefit'),
	loading: MyLoadingComponent
})

const Publicbenefit: Module = {
	name: 'Publicbenefit',
	routes: [
		{
			name: '公益活动到测评页',
			path: '/mall/activepage/publicbenefit',
			component: asyncHaishuidao,
		},
	],
}
export default Publicbenefit
