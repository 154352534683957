import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'
const asyncConference = Loadable({
	loader: () => import('./Conference'),
	loading: MyLoadingComponent
})
const asyncConference612 = Loadable({
    loader: () => import('./Conference612'),
    loading: MyLoadingComponent
})
const Conference: Module = {
	name: 'branstory',
	routes: [
		{
			name: '会场活动',
			path: '/conferenceActivity',
			component: asyncConference,
		},
        {
            name: '612送会员活动',
            path: '/conferencevip',
            component: asyncConference612,
        },
	],
}
export default Conference
