import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncMall = Loadable({
	loader: () => import('./Mall'),
	loading: MyLoadingComponent
})
// component: BrandStoryPage,
const Mall: Module = {
	name: 'branstory',
	routes: [
		{
			name: '商城活动',
			path: '/mallActivity/:kid',
			component: asyncMall,
		},
	],
}
export default Mall
