import ShopDetailPage from './ShopDetail'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncShopDetailPage = Loadable({
    loader: () => import('./ShopDetail'),
    loading: MyLoadingComponent
})
// component: ShopDetailPage,
const ShopDetail: Module = {
    name: 'shopdetail',
    routes: [
        {
            name: '店铺详情',
            path: '/shopdetail/share/:kid',
            component: asyncShopDetailPage,
        },
    ],
}
export default ShopDetail
