import LecturerDetailsPage from './LecturerDetails'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncLecturerDetailsPage = Loadable({
    loader: () => import('./LecturerDetails'),
    loading: MyLoadingComponent
})
// component: LecturerDetailsPage,
const LecturerDetails: Module = {
    name: 'lecturerdetails',
    routes: [
        {
            name: '讲师个人主页详情',
            path: '/lecturerdetails/share/:kid',
            component: asyncLecturerDetailsPage,
        },
    ],
}
export default LecturerDetails