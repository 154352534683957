import Loadable from 'react-loadable'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
// import LiveStreamPage from './StoreTrends'

const asyncStoreTrendsPage = Loadable({
    loader: () => import('./StoreTrends'),
    loading: MyLoadingComponent
})
// component: LiveStreamPage,
const StoreTrends: Module = {
    name: 'storetrends',
    routes: [
      //   {
      //       name: '直播详情',
      //       path: '/livestream/share/:kid',
      //       component: asyncStoreTrendsPage,
			// },
			{
				name: '直播详情',
				path: '/store/share/:kid',
				component: asyncStoreTrendsPage,
			}
    ],
}
export default StoreTrends
