import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncDinnerTableNutritionPage = Loadable({
  loader: () => import('./DinnerTableNutrition'),
  loading: MyLoadingComponent,
});
const asyncallNutrient = Loadable({
  loader: () => import('./allNutrient'),
  loading: MyLoadingComponent,
});
const asyncnutrientElement = Loadable({
  loader: () => import('./nutrientElement'),
  loading: MyLoadingComponent,
})

const DinnerTableNutrition: Module = {
  name: 'dinnerTableNutrition',
  routes: [
    {
      name: '餐桌营养报告',
      path: '/dinnerTableNutrition/share/:kid/:lock/:name',
      component: asyncDinnerTableNutritionPage,
    },
    {
      name: '所有营养素',
      path: '/dinnerTableNutrition/allNutrient/:foodKid/:materialName',
      component: asyncallNutrient,
    },
    {
      name: '食物营养元素',
      path: '/dinnerTableNutrition/nutrientElement/:foodKid',
      component: asyncnutrientElement,
    },

  ],
}
export default DinnerTableNutrition
