import CookDetailShare from './CookDetailShare'
import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncCookDetailShare = Loadable({
    loader: () => import('./CookDetailShare'),
    loading: MyLoadingComponent
})
// component: LecturerDetailsPage,
const CookModule: Module = {
    name: 'cook',
    routes: [
        {
            name: '烹饪详情',
            path: '/cookdetailshare/component',
            component: asyncCookDetailShare,
        }
    ],
}
export default CookModule