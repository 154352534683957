import React, { useState, useEffect } from 'react'
import { Toast } from 'antd-mobile';
import Header from 'components/Header'
import Footer from 'components/Footer'
import DownPop from 'components/DownPop'

const GroupMainPage: PageComponent =  props => {
    return (
        <div className="GroupMainPage">
            GroupMain
        </div>
    )
}
GroupMainPage.options = { disableHeader: false, wrapperClassName: '' }
export default GroupMainPage